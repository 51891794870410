import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal,Card} from 'react-bootstrap';
import { programaActions, alertActions } from '../../_actions';
import {FormPrograma} from '..';
import { Link } from 'react-router-dom';
import { alertConfirm,  esConFull,esSinToken } from '../../_helpers';
import ReactTooltip from 'react-tooltip';

class MisProgramas extends React.Component {

    constructor(props){
       super(props);       
       this.handleShowConfirm=this.handleShowConfirm.bind(this);
    }

    state = {
        show: false,
        showConfirm:false,
        programa:{}
    };

    componentDidMount(){
        if(this.props.programa.misProgramas===undefined)this.props.getProgramasPU();
    }

    hacerFavorita= (item) => {
        const values = {
            tipo:1,
            id:item.id
        }
        this.props.toogleFavorito(values)
    };

    handleClose = () => {
        this.setState({show: false});
    };

    handleShow = () => {
        this.setState({show: true,  programa:{}});       
    };

    handleShowEditar = (item) => {
        this.setState({show: true,  programa:item});
    };

    handleShowConfirm = (data) => {
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${data.nombre}?`,
            'Sí, eliminar',
            'No',
            this.cerrarAlert,
            this.eliminarPrograma,
            null,
            true
        )  
        this.setState({
                programa:data,
                alert: getAlert
            });
    }

    cerrarAlert = () => {
        this.setState({programa:{},alert: null});
    } 

    eliminarPrograma = () => {      
        this.props.eliminarPrograma(this.state.programa, this.cerrarAlert);
      
    };

    render(){
        const { programa,userToken } = this.props;
        const conToken = !esSinToken(userToken);  
        const hayAcceso = (esConFull(userToken));
        return (
            <div className="main-content">
                <Container fluid>   
                      {hayAcceso &&<Row className="mb-5 mt-5">
                        <Col xs={12}>
                        <button className="btns btns-cielo bg-gradient-programa text-white"  onClick={this.handleShow}>
                            <i className="align-middle icon-th-list txt-22"></i> Nuevo programa
                            </button>
                        </Col>
                        </Row>}                
                    <Row> 
                        <div className="cont-sensor mt-4">
                            <div className="d-flex flex-wrap  justify-content-center">
                                {programa.misProgramas!==undefined && programa.misProgramas.map((programa, index)=>                            
                                    <Col key={index} lg={4} xl={4} sm={12} md={6} className="mb-4">                                        
                                        <div className="text-white th_cielo hoverable card-components shadow">                                           
                                            <Card.Header >
                                                <Row>
                                                    <Col xs={10} className="">
                                                <Link to={"/home/programa?id="+programa.id} className="cursor" disabled={!hayAcceso} >
                                                    <h3 className="txt-oswald text-cielo mayuscula text-truncate">
                                                        <i className="text-cielo  icon-th-list mr-2"></i>
                                                        <span className="text-white">{programa.nombre}</span>
                                                    </h3>
                                                </Link>
                                                </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Text className="text-muted font-weight-bold">
                                                <span className="txt-oswald font-weight-light"> ESTADO:</span> {programa.estado.descripcion}
                                                </Card.Text>
                                            </Card.Body>
                                                    
                                        </div>
                                        {hayAcceso && <div className="edit">
                                            <>                                             
                                            <a data-tip data-for='elimina'><i className="fas fa-trash-alt mr-2 cursor"  onClick={() => this.handleShowConfirm(programa)}></i></a>
                                                 <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                      <span>Elimina Equipo</span>
                                                </ReactTooltip>
                                            <a data-tip data-for='edita'><i className="fas fa-pencil-alt mr-2 cursor"  onClick={() => this.handleShowEditar(programa)}></i></a>
                                            <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                <span>Editar Equipo</span>
                                            </ReactTooltip></>

                                        </div>}
                                    </Col>                        
                                )}  
                            </div> 
                        </div>
                    </Row>
                    <Row className="justify-content-center th_cielo">
                    <div className="cont-sensor mt-4">
                    <div className="d-flex flex-wrap  justify-content-center">
                    {(programa.misProgramas===undefined || programa.misProgramas.length===0) &&
                    <>
                            <Col xs={12} md={7} lg={5}>
                            <div className="card-components border-left-c  fadeIn">
                                <Row className="p-3 ">
                                    <Col xs={2} className="text-center p-2">
                                    <i className="icon-bell txt-42 icon-gradient-programa"></i>
                                    </Col>
                                    <Col xs={10} className="text-right p-2">
                                        <span className="text-muted txt-12"> MIS PROGRAMAS</span> <br></br> 
                                        <span className="text-white txt-18 font-weight-bold"> NO EXISTEN PROGRAMAS DEFINIDOS</span> 
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                            {!hayAcceso     &&
                               <Col xs={12} md={7} lg={5}>
                                   <div className="card-components border-left-c  fadeIn">
                               <Row className="p-3 ">
                                   <Col xs={2} className="text-center p-2">
                                   <i className="icon-bell txt-42 icon-gradient-programa "></i>
                                   </Col>
                                   <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS PROGRAMAS</span> <br></br> 
                                   <span className="text-white txt-18 font-weight-bold">Tu tipo de suscripción
                                    no permite utilizar esta sección, pasate a Full ya!</span> 
                                   </Col>
                               </Row>
                               </div>
                           </Col>}
                            {!conToken &&
                               <Col xs={12} md={7} lg={5}>
                                   <div className="card-components border-left-c  fadeIn">
                               <Row className="p-3 ">
                                   <Col xs={2} className="text-center p-2">
                                   <i className="icon-bell txt-42 icon-gradient-programa "></i>
                                   </Col>
                                   <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS PROGRAMAS</span> <br></br> 
                                   <span className="text-white txt-18 font-weight-bold">Contrata ya una suscripción full!</span> 
                                   </Col>
                               </Row>
                               </div>
                           </Col>} 
                           </>
                    }
                    </div>
                    </div>
                    </Row>
                            
                    
                </Container>
                {this.state.alert}
                {this.state.show && <Modal size="lg" show={this.state.show} onHide={this.handleClose}  backdrop="static" keyboard={false}>
                                <Modal.Header className="bg-gradient-programa text-white" closeButton>
                                    <Modal.Title><i className=" icon-th-list"></i> Nuevo programa</Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                    <FormPrograma programa={this.state.programa}  handleClose={this.handleClose}/>
                                </Modal.Body>                     
                            </Modal>} 
            </div>
        );
    }
}

function mapState(state) {
    const { userToken } = state.authentication;
    const { programa } = state;
    return { programa,userToken };
}

const actionCreators = {
    agregarProgramaGenerado:programaActions.agregarProgramaGenerado,
    enviarError:alertActions.enviarError,
    eliminarPrograma:programaActions.eliminarPrograma,    
    getProgramasPU:programaActions.getProgramasPU
}

const connectedMisProgramas = connect(mapState, actionCreators)(MisProgramas);
export { connectedMisProgramas as MisProgramas };