import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field  } from 'formik';
import * as Yup from 'yup';
import { programaService } from '../../_services';
import {Col,Row} from 'react-bootstrap';
import 'react-widgets/dist/css/react-widgets.css';
import {alertActions,programaActions,utilActions} from '../../_actions';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import NumberPicker from 'react-widgets/lib/NumberPicker'
import { LoadingBoton,ErrorMessage } from '../../_helpers';

simpleNumberLocalizer()

class FormTarea extends React.Component {
  

  state = {
      grabando:false
  };

render() {

       const {tarea} = this.props;
  
      let inic={
        id: '',
        nombre: '',
        duracionHoras:0,
        minutos:0,
        duracionDias:0,
        proximaTareaAutomatica:false
      }

      const validationSchema = Yup.object().shape({
        nombre: Yup.string()
          .min(2, 'Muy corto')
          .max(50, 'Muy largo. Máx 50')
          .required('Requerido'),
        duracionHoras: Yup.number().typeError('Solo estan permitidos numeros enteros')   
          .required('Requerido').min(0,'Minimo 0'),
        duracionDias: Yup.number().typeError('Solo estan permitidos numeros enteros')     
          .required('Requerido').min(0,'Minimo 0'),
        minutos: Yup.number().typeError('Solo estan permitidos numeros enteros')     
          .required('Requerido').min(0,'Minimo 0')
      });

      if(tarea!==undefined && tarea!=={})inic=utilActions.setearValores(inic,tarea);

return(

  <div>
    <Formik
      enableReinitialize= {true}
      initialValues={inic}
      validationSchema={validationSchema}
      onSubmit={values => {
          values.idPrograma= this.props.idPrograma;              
          
          this.setState({grabando:true});
      
          programaService.grabarTarea(values).then(
            data => {  
                this.setState({grabando:false});
                this.props.agregarTareaGenerada(data,values.id==='');
                this.props.handleClose();
            },
            error=>{
              this.setState({grabando:false});
              this.props.enviarError(error);
            }
        );

      }}
    >
      {({ errors, touched,values,setFieldValue }) => (
        
        <Form  className="th_cielo">
          <Row>
              <Col xs={12}>
              <label htmlFor="nombre">Nombre</label><ErrorMessage name={`nombre`} />
              <Field name="nombre" />
              </Col>
          </Row>
          <Row>
              <Col xs={12} lg={3}>
                <label htmlFor="duracionDias">Días</label><ErrorMessage name={`duracionDias`} />
                <NumberPicker max={10000} min={0} name="duracionDias" defaultValue={values.duracionDias}  onChange={value => setFieldValue("duracionDias", value)} /> 
              </Col>
              <Col xs={12} lg={3}>
                <label htmlFor="duracionHoras">Horas</label><ErrorMessage name={`duracionHoras`} />
                <NumberPicker max={10000} min={0} name="duracionHoras" defaultValue={values.duracionHoras}  onChange={value => setFieldValue("duracionHoras", value)} /> 
              </Col>
              <Col xs={12} lg={3}>
                <label htmlFor="minutos">Minutos</label><ErrorMessage name={`minutos`} />
                <NumberPicker max={60} min={0} name="minutos" defaultValue={values.minutos}  onChange={value => setFieldValue("minutos", value)} />       
              </Col>

              <Col xs={12} className="mt-4">
              <label>¿Ejecutar automáticamente la próxima tarea?</label>
                <div className="radio-item">                
                <Field className="option-input checkbox" type="checkbox" name="proximaTareaAutomatica"/>
                <ErrorMessage name={`proximaTareaAutomatica`} />
                <label className="label-check" htmlFor="proximaTareaAutomatica">Sí</label>
                </div>
              </Col>
          </Row>

              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                  <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                  <i className="icon-times"></i>  Cerrar
                  </button>
                  <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                  <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                  </button>
              </div>
             
        </Form>
      )}
    </Formik>
  </div>
)};
}

function mapState(state) {
  return {  };
}

const actionCreators = {
  agregarTareaGenerada:programaActions.agregarTareaGenerada,
  enviarError:alertActions.enviarError
}

const connectedFormTarea = connect(mapState, actionCreators)(FormTarea);
export { connectedFormTarea as FormTarea };