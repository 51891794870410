export const eventoConstants = {
    EVENTO_GET_PU_SUCCESS: 'EVENTO_GET_PU_SUCCESS',
    AGREGAR_EVENTO_GENERADO:'AGREGAR_EVENTO_GENERADO',
    EVENTO_ELIMINADO:'EVENTO_ELIMINADO',
    ACTUALIZAR_EVENTO:'ACTUALIZAR_EVENTO',
    EVENTO_TOOGLE_ESTADO:'EVENTO_TOOGLE_ESTADO',
    EVENTO_PROGRAMADO:2,
    EVENTO_AUTOMATICO:1,
    EVENTO_DATA_GENERAL:'EVENTO_DATA_GENERAL'
};
