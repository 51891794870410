import http from '../_helpers/http.service';
import { refreshToken } from '../_helpers';
export const userService = {
    login,
    logout,
    register,
    getAll,
    reenviarActivacion,
    chequearMailExiste,
    chequearUsuarioExiste,
    recuperarPassword,
    cambiarPassword,
    actualizarUsuario,
    getLogueado,
    estaLogueado,
    loginGoogle,
    grabarPushSuscripcion,
    enviarCodigoRegistracion,
    cancelarSuscripcion,
    cancelarSuscripcionUser,
    traerRoles,
    buscarUsuarios,
    refrescarToken,
    guardarToken,
    traerInfoGral
    
};

const apiUrl=process.env.REACT_APP_API_URL;


function traerInfoGral() {

    var url = '/perfil/traerInfoGral';
    return http.get(url);
}

function buscarUsuarios(values) {   
    var url = '/api/usuarios/buscarUsuarios';    
    return http.post(url,JSON.stringify(values));
}

function guardarToken (user){
    if(user)
        localStorage.setItem('user',JSON.stringify(user));
}


function refrescarToken(){
    const url=apiUrl+'/oauth/access_token?grant_type=refresh_token&refresh_token='+refreshToken();  
    console.log('URL DE REGFRESG ACA',url)          
    let res = fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer'
    }).then(res => res.json()).then(user => {     
        console.log('user',user) 
        if(user.access_token)         
            localStorage.setItem('user',JSON.stringify(user));   
    });
}

function traerRoles() {
    var url = "/api/usuario/traerRoles";
    return http.get(url)
    .then(data => {
        try{
            let rolesFinal=[];
            data.roles.forEach((element, i1) => { 
                rolesFinal.push(element.authority);
            });
            let user = JSON.parse(localStorage.getItem('user'));
            user.roles=rolesFinal;
            localStorage.setItem('user',JSON.stringify(user));           
        }catch(err){
            console.warn('err',err);
        }
     
    });
}


function login(username, password) {
    var url = '/api/login';
    return http.post(url,JSON.stringify({username: username,password: password}))
    .then(user => {
        try{
            localStorage.setItem('user',JSON.stringify(user))
            return user;
        }catch(err){
            console.warn('************************************************err',err);
        }
     
    });
}

function loginGoogle(gResponse) {
    var url = '/api/login';

    return http.post(url,JSON.stringify({username: '',password: '',gTokenId:gResponse.tokenId}))
    .then(user => {
        localStorage.setItem('user',JSON.stringify(user))
        return user;
    });
}

function enviarCodigoRegistracion(mail) {

    var url = '/usuario/enviarCodigoRegistracion';
    return http.post(url,JSON.stringify({mail:mail}));
}

function grabarPushSuscripcion(suscripcion,key,auth) {

    var url = '/api/usuario/grabarPushSuscripcion';    
    return http.post(url,JSON.stringify({key:key,auth:auth,endpoint:suscripcion.endpoint}));
}

function estaLogueado(){
    const user = localStorage.getItem('user')
    return user!==null;
}

function logout() {    
    localStorage.removeItem('userLogged');
    localStorage.removeItem('user');
}

function getAll() {

    var url = '/api/usuarios/todos';
    return http.get(url);
}

function getLogueado() {

    var url = '/api/usuario/';
    return http.get(url);
}

function register(user) {
    var url = '/usuario/registrar';
    return http.post(url,JSON.stringify(user));
}

function cancelarSuscripcion(values) {
    var url = '/api/ventas/cancelarSuscripcion';
    return http.post(url,JSON.stringify(values));
}


function cancelarSuscripcionUser() {
    var url = '/api/ventas/cancelarSuscripcionUser';
    return http.get(url);
}




function actualizarUsuario(users) {
    var url = '/api/usuario/actualizar';
    return http.post(url,JSON.stringify(users));
}

function reenviarActivacion(codigo) {
    var jCodigo = { codigo: codigo};
    var url = '/usuario/reenviarActivacion';
 
    return http.post(url,JSON.stringify(jCodigo));
}

function chequearMailExiste(mail) {
    var jCodigo = { mail: mail};
    var url = '/usuario/chequearMailExiste';
    return http.post(url,JSON.stringify(jCodigo));
}

function chequearUsuarioExiste(mail) {
    var jCodigo = { mail: mail};
    var url = '/usuario/chequearUsuarioExiste';
    return http.post(url,JSON.stringify(jCodigo));
}

function recuperarPassword(mail) {
    var jCodigo = { mail: mail};
    var url = '/usuario/recuperarPassword';
    return http.post(url,JSON.stringify(jCodigo));
}

function cambiarPassword(values) {   

    var url = values.esRecuperacion?'/usuario/cambiarPassword':'/api/usuario/cambiarPassword';
    return http.post(url,JSON.stringify(values));
}
