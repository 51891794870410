import React from 'react';
import { connect } from 'react-redux';
import {Button,ListGroup,Card} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import NumberFormat from 'react-number-format';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactTooltip from 'react-tooltip';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';



class CardSuscripcion extends React.Component {
    
    state={      
        active:false
     }
    componentDidMount(){
    }


    render(){
        const { suscripcion } = this.props;
        return (
                <div className="th_recursos">
                    <div>
                        <Card.Header className="text-center">
                           {(!suscripcion.soloGratis && !suscripcion.puedeSerGratis)? <h3 className="txt-64 txt-oswald ">
                                {suscripcion.costo}<small>$</small>  - {suscripcion.costoPaypal}<small>U$D</small>
                            </h3>:
                            <h3 className="txt-64 txt-oswald ">
                               <small> Gratis</small>
                            </h3>}
                        </Card.Header>
                         <ListGroup.Item className="text-center bg-recursos text-dark txt-oswald">MENSUAL </ListGroup.Item>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="txt-22 font-weight-bold text-center mayuscula txt-oswald  text-muted">{suscripcion.nombre}</ListGroup.Item>
                            <ListGroup.Item className="text-center text-white"><span className="text-center material-icons material-icons-outlined">verified_user</span> {suscripcion.tipoSuscripcion.descripcion}</ListGroup.Item>
                        </ListGroup>
                        <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    <>Conocé más sobre <span className="font-weight-bold text-center">{suscripcion.nombre}</span></>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ListGroup variant="flush">                                        
                                        <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined text-recursos">developer_board</span> Cantidad variables</small>
                                        <span className="txt-22 text-center mayuscula txt-oswald "> {suscripcion.cantidadSensores}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined text-recursos">mobile_friendly</span> Cantidad dispositivos</small>
                                        <span className="txt-22 text-center mayuscula txt-oswald "> {suscripcion.cantidadDispositivos}</span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined text-recursos">how_to_reg</span> Cantidad usuarios</small>
                                        <span className="txt-22 text-center mayuscula txt-oswald "> {suscripcion.cantidadUsuarios}</span>
                                        </ListGroup.Item>
                                        {(!suscripcion.soloGratis && !suscripcion.puedeSerGratis) && 
                                        <>
                                            <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined">filter_1</span> MENSUAL</small>
                                                <span className="txt-22 text-center mayuscula txt-oswald "> <NumberFormat prefix={'$'} value= {suscripcion.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," /> <span>-</span> <NumberFormat prefix={'U$D'} value= {suscripcion.costoPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined">filter_3</span> TRIMESTRAL</small>
                                                <span className="txt-22 text-center mayuscula txt-oswald "> <NumberFormat prefix={'$'} value= {suscripcion.costoTres} displayType={'text'} thousandSeparator="." decimalSeparator="," /> <span>-</span> <NumberFormat prefix={'U$D'} value= {suscripcion.costoTresPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></span>
                                            </ListGroup.Item>                     
                                            <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined">filter_6</span> SEMESTRAL</small>
                                                <span className="txt-22 text-center mayuscula txt-oswald "> <NumberFormat prefix={'$'} value= {suscripcion.costoSeis} displayType={'text'} thousandSeparator="." decimalSeparator="," /> <span>-</span> <NumberFormat prefix={'U$D'} value= {suscripcion.costoSeisPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></span>
                                            </ListGroup.Item> 
                                            <ListGroup.Item className="text-center"><small className="text-muted"><span className="material-icons material-icons-outlined">calendar_today</span> Costo Anual</small>
                                                <span className="txt-22 text-center mayuscula txt-oswald "> <NumberFormat prefix={'$'} value= {suscripcion.costoDoce} displayType={'text'} thousandSeparator="." decimalSeparator="," /> <span>-</span> <NumberFormat prefix={'U$D'} value= {suscripcion.costoDocePaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></span>
                                            </ListGroup.Item> 
                                        </>
                                        }
                            
                                    </ListGroup>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                   
                    </Card.Body>
                    <Card.Footer className="text-center">
                    {!suscripcion.soloGratis && 
                        <><Button data-tip data-for='susc' className="text-dark mt-1" onClick={()=>this.props.contratar(suscripcion,false)}><span className="material-icons material-icons-outlined text-dark align-middle">payment</span> Contratar</Button>
                        <ReactTooltip place="bottom" id='susc' aria-haspopup='false' >
                            <span>Contratar Suscripción</span>
                        </ReactTooltip> 
                        </>
                    }

                       {(suscripcion.soloGratis ||suscripcion.puedeSerGratis) && 
                        <>
                            <button data-tip data-for='susc' className="btns ml-3 mt-1 btns-outline-recursos transparent text-white" onClick={()=>this.props.contratar(suscripcion,true)}><span className="material-icons material-icons-outlined text-white align-middle">payment</span> Probar {!suscripcion.soloGratis && suscripcion.cantMesesGratis+' mes gratis'} </button>
                            <ReactTooltip place="bottom" id='susc' aria-haspopup='false' >
                                <span>Probar {suscripcion.cantMesesGratis} mes gratis</span>
                            </ReactTooltip>
                        </>}
                    </Card.Footer>
                    </div>
                    </div>   
        );
    }
}

function mapState(state) {       
    return {};
}

const actionCreators = {
}

const connectedCardSuscripcion = connect(mapState, actionCreators)(CardSuscripcion);
export { connectedCardSuscripcion as CardSuscripcion };

