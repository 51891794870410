import React from 'react';
import { connect } from 'react-redux';
import {Row,Col,Modal} from 'react-bootstrap';
import { alertActions} from '../../_actions';
import { userService } from '../../_services';
import { Formik, Form,  Field } from 'formik';
import {ERR, ErrorMessage, LoadingBoton} from '../../_helpers';
import * as Yup from 'yup';

class FormCambiarPassword extends React.Component { 
 
     state = {
         grabando:false,
         mostrar:false
     };
 
     componentDidMount(){
      
     }

     render(){
          const passwoordRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
          const {mostrar} = this.state;
          const {esRecuperacion,hash} = this.props;
          let inic={
            password:'',
            confpassword:'',
            codigo:''
          }

         const validationSchema = Yup.object().shape({
              codigo:esRecuperacion?Yup.number().typeError('Solo numeros').min(100000,'Minimo 6 caracteres').required(ERR.REQ):Yup.number(),
              password: Yup.string().required(ERR.REQ).min(8, 'El password debe contener al menos 8 caracteres').matches(passwoordRegExp,'Al menos 8 caracteres, una letra y un número!').max(50, 'Demasiado largo'),
              confpassword: Yup.string().required(ERR.REQ).oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden")
          });
   
         return(
         
          <Modal show={this.props.show} onHide={()=>this.props.handleClose()} backdrop="static"  >
          <Modal.Header className="bg-iot  text-dark" closeButton>
              <Modal.Title><span className="material-icons">password</span> Cambio de contraseña</Modal.Title>
          </Modal.Header> 
          <Modal.Body>
                 <Formik
                     enableReinitialize= {true}
                     initialValues={inic}
                     validationSchema={validationSchema}
                     onSubmit={(values ) => {
                       if(esRecuperacion){
                        values.esRecuperacion=esRecuperacion;
                        values.hash = hash;
                       }else values.esRecuperacion=false;
                      

                      this.setState({grabando:true});     
                      userService.cambiarPassword(values).then(
                        data => { 
                          this.setState({grabando:false});         
                          this.props.enviarSuccess('Password actualizado exitosamente.');                         
                          this.props.handleClose();
                        },
                        error=>{
                          this.setState({grabando:false});      
                          this.props.enviarError(error);
                        }
                      );   
          
                     }}>
                             {({ errors, touched,values }) => (
                             <Form>
                               <Row>

                                {esRecuperacion &&<Col sm={12}>                                   
                                    
                                    <label htmlFor="codigo">Codigo enviado</label>
                                    <ErrorMessage name={`codigo`} />
                                    <div className="input-group mb-2">
                                      <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="icon-lock"></i></div>
                                      </div>
                                        <Field name="codigo" type={"text"}/>
                                    </div>
                                    
                                </Col>}

                                  <Col sm={12}>                                   
                                 
                                        <label htmlFor="password">Contraseña </label>
                                        <ErrorMessage name={`password`} />
                                        <div className="input-group mb-2">
                                          <div className="input-group-prepend">
                                            <div className="input-group-text"><i className="icon-lock"></i></div>
                                          </div>
                                            <Field name="password" type={mostrar?"text":"password"}/>
                                            <button type="button" className="btns btns-link ojo" onClick={()=>this.setState({mostrar:!this.state.mostrar})}><span className="material-icons material-icons-outlined txt-27">remove_red_eye</span></button>
                                        </div>
                                       
                                  </Col>
                                  <Col sm={12}>  
                                      <label htmlFor="confpassword">Confirmar Contraseña </label>
                                          <ErrorMessage name={`confpassword`} />
                                        <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="icon-lock"></i></div>
                                        </div>
                                        <Field name="confpassword" type={mostrar?"text":"password"}/>
                                        </div>
                                  </Col>    
                                 </Row>                               
                                 <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                    <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                                    <i className="icon-times"></i>  Cerrar
                                    </button>
                                    <button className="btns btns-secundario" type="submit"  disabled={this.state.grabando}>
                                    <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                                    </button>
                                  </div> 
                             </Form>
                             )}
                 </Formik>
                 </Modal.Body>                     
          </Modal> 
         )};
}

function mapState(state) {
    return {  };
}

const actionCreators = {
  enviarError: alertActions.error,
  enviarSuccess:alertActions.success
}

const connectedFormCambiarPassword = connect(mapState, actionCreators)(FormCambiarPassword);
export { connectedFormCambiarPassword as FormCambiarPassword };