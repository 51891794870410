import { sensorConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const utilActions = {
    armarSelectSearch,
    isEmpty,
    setearValores,
    traerPalabrasTabla,
    reducirComboParaTabla,
    aplastarLista,
    trValue,
    llenarLista,
    extraerDeLista,
    pasarListaSensoresAShadows,
    armarLayouts,
    armarLayoutsNuevoElemento

};

function armarLayoutsNuevoElemento(sensoresLista,layouts){
    const sensoresListaFinal = utilActions.pasarListaSensoresAShadows(sensoresLista);
    if(sensoresListaFinal.length>layouts.lg.length){              
        const difference = sensoresListaFinal.filter(x => !layouts.lg.some(y=>{                    
            return y.i===x.idShadow.toString()
            } 
        ));

        if(difference && difference.length>0){
            difference.forEach((val,index)=>{
                layouts.lg.push({
                    i:val.idShadow.toString(),
                    x: 0,
                    y:Infinity,
                    w:3,
                    h:2,
                    minW:3,
                    minH:2,      
                    maxW:12,
                    maxH:4
                })
    
                layouts.md.push({
                    i:val.idShadow.toString(),
                    x: 0,
                    y:Infinity,
                    w:3,
                    h:2,
                    minW:3,
                    minH:2,      
                    maxW:12,
                    maxH:4
                })
    
                layouts.xs.push({
                    i:val.idShadow.toString(),
                    x: 0,
                    y:Infinity,
                    w:6,
                    h:1,
                    minW:6,
                    minH:1,       
                    maxW:12,
                    maxH:2
                }) 
            })
                       
        }
    }
}

        
function armarLayouts(layouts,sensoresLista){
    let layoutLg = [];
    let layoutMd = [];
    let layoutXs = [];
    let xLg=0;
    let yLg=0;
    let xXs=0;
    let yXs=0;
    const maxXLg=9;
    const maxXXs=6;
    const minXLg=3;
    const minXXs=6;
    const minYLg=2;
    const minYXs=1;
    
    pasarListaSensoresAShadows(sensoresLista).forEach((val,index)=>{
        definirValueInicial(val,layoutLg,layoutMd,layoutXs,xLg,yLg,xXs,yXs);

        xLg=xLg+minXLg;
        xXs=xXs+minXXs;

        if(xLg>maxXLg){
            xLg=0;
            yLg=yLg+minYLg;
        }

        if(xXs>maxXXs){
            xXs=0;
            yXs=yXs+minYXs;
        }
    })
    layouts.lg=layoutLg;
    layouts.md=layoutMd;
    layouts.xs=layoutXs;
}

function definirValueInicial(val,layoutLg,layoutMd,layoutXs,xLg,yLg,xXs,yXs){
    const id= val.idShadow.toString();
    layoutLg.push({
        i:id,
        x: xLg,
        y: yLg,
        w:3,
        h:2,
        minW:3,
        minH:2,      
        maxW:12,
        maxH:4
    })
    layoutMd.push({
        i:id,
        x: xLg,
        y: yLg,
        w:3, 
        h:2,
        minW:3, 
        minH:2,        
        maxW:12,
        maxH:4
    })
    layoutXs.push({
        i:id,
        x: xXs,
        y: yXs,
        w:6,
        h:1,
        minW:6,
        minH:1,       
        maxW:12,
        maxH:2
    })
}

function pasarListaSensoresAShadows(sensoresLista){
    let sensoresListaFinal = [];

    sensoresLista.forEach((sensorSimulado,index)=>{
        sensorSimulado.shadows.forEach((shadow,index)=>{
            let sensorFinal = Object.assign({}, sensorSimulado);
            sensorFinal.tipoWidget=shadow.tipoWidget;
            sensorFinal.valorMinReferencia=shadow.valorMinReferencia;
            sensorFinal.valorMaxReferencia=shadow.valorMaxReferencia;
            sensorFinal.labelShadow=shadow.nombre;
            sensorFinal.idShadow=shadow.id;
            try{
                sensorFinal.ultimoValor=shadow.tipoWidget.id===sensorConstants.TIPO_WIDGET_MAPA?JSON.parse(sensorFinal.ultimoValor):sensorFinal.ultimoValor
            }catch(err){
                sensorFinal.ultimoValor=sensorSimulado.ultimoValor;
            }
            
            sensorFinal.cantidadHoras=shadow.cantidadHoras;
            sensoresListaFinal.push(sensorFinal);
        })
    })
    
    return sensoresListaFinal;
}


function llenarLista(listaIds, sensores){
   let listaSensoresLlena=[];

   if(!sensores)return listaSensoresLlena;
   if(listaIds){
        listaIds.forEach((sen) => { 
            const sensorC = sensores.find(senLLeno=>senLLeno.id===sen.id);
            if(sensorC)
                listaSensoresLlena.push(sensorC)
        });
   }

   return listaSensoresLlena;
}

function extraerDeLista(id, sensores){    
    return sensores?sensores.find(senLLeno=>senLLeno.id===id):{};
 }


function armarSelectSearch(arrayInicial,arrayOpciones,defaultDesc,defaultValue,defaultCampoDesc,defaultCampoId){
    if(arrayInicial){
        if(defaultDesc!==null)arrayOpciones.push( {name: defaultDesc, value: defaultValue})
        arrayInicial.forEach((mod, index) => { 
            arrayOpciones.push( {label: mod[defaultCampoDesc], value: (''+mod[defaultCampoId])})
        }); 
    }
}

function isEmpty(obj) {
    if(obj==undefined)return true;
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function setearValores(objFinal,objInicial) {
    for(var key in objInicial) {
        if(objInicial.hasOwnProperty(key)){
            objFinal[key] = objInicial[key] && objInicial[key]!==''?objInicial[key]:objFinal[key];
        }            
    }
    return objFinal;
}


function trValue(data){

}
function aplastarLista(data){

    let dataFlat=[];

    if(data){
        data.forEach((objeto,index)=>{
            let fila={};
            for(var key in objeto) {
                if(key!=='tableData')fila[key]=aplanarDep(objeto,key);                       
            }
            dataFlat.push(fila);
        })   
    }
 
    return dataFlat;    
}

function aplanarDep(objeto,key){
    try{
        if(Array.isArray(objeto[key])) return '-';
        if(objeto[key].descripcion!==undefined)return objeto[key].descripcion;
        if(objeto[key].titulo!==undefined)return objeto[key].titulo;
        return objeto[key]
    }catch(err){
        return objeto[key];
    }
}

function reducirComboParaTabla(dinamicObject,otroFiltro){

    const final = dinamicObject.filter(eq => eq.idNivelDependencia === otroFiltro)

    let obj = final.reduce(function(acc, cur, i) {         
            acc[cur.id] = cur.descripcion;
            return acc;
     
        }, {});
    return obj;    
}

function  traerPalabrasTabla(){
    return {
        pagination: {
            labelRowsSelect:'objetos',
            labelDisplayedRows: '{from}-{to} de {count}'
        },
        toolbar: {
            searchTooltip:'Buscar',
            searchPlaceholder:'Escriba el texto... para filtrar',
            nRowsSelected: '{0} fila(s) seleccionadas'
        },
        header: {           
            actions: 'Acciones'
        },
        body: {
            emptyDataSourceMessage: 'No hay nada para mostrar',
            filterRow: {
                filterTooltip: 'Filtrar'
            }
        }
    }
}