import http from '../_helpers/http.service';

export const programaService = {
    getProgramasPU,
    grabarPrograma,
    eliminarPrograma,
    grabarTarea,
    eliminarTarea,
    ejecutar,
    cancelarEjecucion,
    getProgramaPorId,
    continuarEjecucion,
    grabarEventoTarea,
    grabarAccionTarea,
    eliminarAccionTarea,
    eliminarEventoTarea,
    intercambiarTarea
};


function getProgramasPU() {    
    var url = '/api/programas/pu';
    return http.get(url);
}

function getProgramaPorId(id) {
    
    var url = '/api/programas/porId';
    return http.post(url,JSON.stringify({id:id}));
}

function intercambiarTarea(id,idPrograma) {
    
    var url = '/api/programas/intercambiarTarea';

    return http.post(url,JSON.stringify({id:id,idPrograma:idPrograma}));
    
}

function grabarPrograma(values) {
    
    var url = '/api/programas/grabar';
    return http.post(url,JSON.stringify(values));
}

function grabarTarea(values) {
    
    var url = '/api/programas/tarea/grabar';

    return http.post(url,JSON.stringify(values));
}

function eliminarPrograma(values) {
    
    var url = '/api/programas/eliminar';

    return http.post(url,JSON.stringify(values));
}

function eliminarTarea(values) {
    
    var url = '/api/programas/tarea/eliminar';

    return http.post(url,JSON.stringify(values));
}

function ejecutar(id,idTareaComienza) {
    
    var url = '/api/programas/ejecutar';
    return http.post(url,JSON.stringify({id:id,idTareaComienza:idTareaComienza}));
}

function continuarEjecucion(id) {
    
    var url = '/api/programas/continuarEjecucion';

    return http.post(url,JSON.stringify({id:id}));
}

function grabarEventoTarea(values) {
    
    var url = '/api/programas/tarea/grabarEvento';
    return http.post(url,JSON.stringify(values));
}

function eliminarEventoTarea(values) {
    
    var url = '/api/programas/tarea/eliminarEvento';

    return http.post(url,JSON.stringify(values));
}

function grabarAccionTarea(values) {
    
    var url = '/api/programas/tarea/grabarAccion';

    return http.post(url,JSON.stringify(values));
}

function eliminarAccionTarea(values) {
    
    var url = '/api/programas/tarea/eliminarAccion';

    return http.post(url,JSON.stringify(values));
}

function cancelarEjecucion(values) {
    
    var url = '/api/programas/cancelarEjecucion';

    return http.post(url,JSON.stringify(values));
}
