import React from 'react';
import {FormDatosUsuario} from '../../_components/Perfil';
import {Modal} from 'react-bootstrap';

export default class FormModalUsuario extends React.Component {
    
render() {
  
return(

  <div>
        <Modal show={this.props.show} onHide={()=>this.props.handleClose()}>
            <Modal.Header className="bg-iot text-dark" closeButton>
                <Modal.Title>Nuevo usuario</Modal.Title>
            </Modal.Header> 
            <Modal.Body>
              <FormDatosUsuario accion='nuevo' handleCloseNuevo={this.props.handleClose} />
            </Modal.Body>                     
        </Modal> 

  </div>
)};
}