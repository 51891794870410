import { notificacionConstants } from '../_constants';
import { notificacionService } from '../_services';
import { alertActions } from '.';
import { equipoActions } from './equipo.actions';
import * as serviceWorker from '../serviceWorker';
import { ORIGEN_NOTIFICACION } from '../_helpers';
export const notificacionActions = {
    getNotificacionesPU,
    marcarLeidas,
    nuevaNotificacion
};

function marcarLeidas() {
    return dispatch => {
        notificacionService.marcarLeidas()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: notificacionConstants.NOTIFICACION_MARCAR_LEIDAS, data } }
}

function nuevaNotificacion(notificacion) {
    return dispatch => {
        let data={notificacion:JSON.parse(notificacion)};
        serviceWorker.displayNotification(data.titulo);
        //Si es una notificacion que me asignaron un equipo mando a actualizar para que le aparezca
        if(data.origen===ORIGEN_NOTIFICACION.GRUPO && data.idRef=='0')dispatch(equipoActions.getEquiposPU());
        dispatch(success(data));                 
    };

    function success(data) { return { type: notificacionConstants.NOTIFICACION_NUEVA, data } }
}


function getNotificacionesPU() {
    
    return dispatch => {
        notificacionService.getNotificacionesPU()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: notificacionConstants.NOTIFICACION_GETPU_SUCCESS, data } }
}




