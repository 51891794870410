import React from 'react';
import { LoadingBoton } from '../../../_helpers';
import GChartWidget from '../../Home/GChartWidget';

export default class WidgetChart extends React.Component {

      state={
        cantidad:0
      }

      shouldComponentUpdate(nextProps, nextState){       
        if(this.props.sensor.historialWidget===undefined || 
          (nextProps.sensor.historialWidget!==undefined && this.state.cantidad !==nextProps.sensor.historialWidget.length)
         )
          return true
        else
          return false
        
      }

      componentDidMount(){
        if(this.props.sensor.historialWidget===undefined)this.props.buscarMiHistorial();
      }

       
      
      render(){
        
          const {historialWidget}= this.props.sensor;
          const{grabando} = this.props;
          let chartDataGrafico = []; 
          if(historialWidget!==undefined){                  
            historialWidget.forEach((registro, index) => {   
              
                let fe = new Date(registro.fecha);    
                fe.setUTCHours(registro.hora)
                fe.setUTCMinutes(registro.minuto)
                fe.setUTCSeconds(registro.segundo)       
                       
                const valor = parseFloat(registro.valor);
                if(!isNaN(valor))
                    chartDataGrafico.push({x:fe.getTime(), y:valor});
            });
            if(this.state.cantidad!==historialWidget.length)this.setState({cantidad:historialWidget.length})
          }

          return(
              <>
                <div className="cargando">{grabando && <span className="text-muted">Actualizando Gráfico<LoadingBoton grabando={grabando}/></span>}</div>
                {(historialWidget!==undefined && !grabando) &&  
                    <GChartWidget unidad={''} gdata={chartDataGrafico}/>}
              </>
         )
    }
}