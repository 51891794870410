import React from 'react';
import { connect } from 'react-redux';
import {Row,Modal,Col} from 'react-bootstrap';
import { alertActions,userActions, utilActions } from '../../_actions';
import { userService } from '../../_services';
import { Formik, Form,  Field } from 'formik';
import * as Yup from 'yup';
import { LoadingBoton, ErrorMessage } from '../../_helpers';
import Select from 'react-select';
import _ from 'lodash';
class FormDatosUsuario extends React.Component {
 
    constructor(props){
       
        super(props);
        if(this.props.accion==='actualizar')
             this.state.nuevo=false;
        this.usuarioExiste = _.debounce(this.usuarioExiste, 300);
        this.mailExiste = _.debounce(this.mailExiste, 300);        
     }

     componentDidMount(){
       if(this.props.codigosPais===undefined)this.props.traerInfoGral();
     }

     usuarioExiste=(value)=>{
      let valorFinal= false
      valorFinal=userService.chequearUsuarioExiste(value).then(
        data => {   
         
          if (data.status===1)valorFinal= false;                      
          if (data.status===3)valorFinal= true;
          return valorFinal;
        },
        error=>{valorFinal= true}
      ); 
      return valorFinal;
     }

     mailExiste=(value)=>{
      let valorFinal= false
      valorFinal=userService.chequearMailExiste(value).then(
        data => {   
          if (data.status===1)valorFinal= false;                      
          if (data.status===3)valorFinal= true;
          return valorFinal;
        },
        error=>{valorFinal= true}
      );  
      return valorFinal;
     }

     setGrabando=(grab)=>{
       this.setState({grabando:grab});
     }

     enviarCodigoRegistracion(mail){
        this.setGrabando(true);
        userService.enviarCodigoRegistracion(mail).then(
          data => {   
            this.setState({
              codigoEnviado:true,
              hash:data.hash,
              grabando:false
            });
            this.props.enviarSuccess('Se ha enviado a su casilla seleccionada un código, ingreselo a continuación y confirmelo.');
          },
          error=>{
            this.setGrabando(false);
            this.props.enviarError(error);
          }
        );
       
     }
 
     state = {
         hash:'',         
         nuevo: true,
         codigoEnviado:false,
         codigoIngresado:'',
         mailValidado:false,
         grabando:false,
         mostrar:false
     };

     render(){
        let inic={
            nombre: '',
            mail: '',
            telefono: '',
            username: '',
            password: '',
            confpassword: '',
            codigoPais:''
        }
        //traer de la base los datos del usuario
         let {user,codigosPais} = this.props;   
         if(!user)user={};         

         const {nuevo} = this.state;        
         const phoneRegExp = /^[0-9]{3,14}$/
         const passwoordRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

         const thisAdentro = this;
         let validateActualizar = {
            nombre: Yup.string().min(2, 'Muy corto').max(100, 'No puede exceder los 100 caracteres').required('Requerido'),       
            telefono: Yup.string().min(2, 'Muy corto').max(50, 'No puede exceder los 50 caracteres').matches(phoneRegExp, 'Ingrese número válido (Ej:xxxxxxxxxx)'),
            codigoPais: Yup.string(),
            username: Yup.string().min(2, 'Muy corto').max(50, 'No puede exceder los 50 caracteres').test('checkDuplUsername', 'Usuario  existente', function (value) {                                
              if(!nuevo && user && value===inic.username)             
                return true;                  
                else return thisAdentro.usuarioExiste(value)  ;
            }).required('Requerido'),
            mail: Yup.string()
            .min(2, 'Muy corto')
            .max(100, 'No puede exceder los 100 caracteres')
            .email('Mail incorrecto')
            .test('checkDuplUsername', 'Mail existente', function (value) {
              if(!nuevo && user && value===inic.mail)             
                return true;
                else return thisAdentro.mailExiste(value);
            }).required('Requerido'),    
        };

        let validateNuevo ={};

         if (nuevo){
          validateNuevo = {
                mail: Yup.string()
                  .min(2, 'Muy corto')
                  .max(100, 'No puede exceder los 100 caracteres')
                  .email('Mail incorrecto')
                  .test('checkDuplUsername', 'Mail existente', function (value) {                      
                    return thisAdentro.mailExiste(value);  
                  }).required('Requerido'),              
            password: Yup.string().min(8, 'El password debe contener al menos 8 caracteres').matches(passwoordRegExp,'Al menos 8 caracteres, una letra y un número!').max(50, 'Demasiado largo').required('Requerido'),
            confpassword: Yup.string().oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden").required('Requerido')
          };
         }

         let validacionFinal = Object.assign(validateActualizar, validateNuevo);      
   
         const validationSchema = Yup.object().shape(
          validacionFinal                    
         );   

         if(user!==undefined && user!=={})inic=utilActions.setearValores(inic,user);
         return(
         
             <div>
                 <Formik
                     enableReinitialize= {true}
                     initialValues={inic}
                     validationSchema={validationSchema}
                     onSubmit={(values ) => {  
                      console.log(user,values, (user.mail===values.mail || (this.state.codigoEnviado && values.codigoIngresado && values.codigoIngresado!==''))  ) 
                          if(user.mail===values.mail || (this.state.codigoEnviado && values.codigoIngresado && values.codigoIngresado!=='')){
                            this.setGrabando(true);
                            values.hash=this.state.hash;
                            if(nuevo){
                              userService.register(values).then(
                                user => {  
                                  let redirect = true;

                                  if(this.props.handleCloseNuevo!==undefined)redirect=false;   
                                  this.props.login(values.username,values.password,'','',redirect);

                                  if(this.props.handleCloseNuevo!==undefined)this.props.handleCloseNuevo();

                                  this.props.enviarSuccess('El usuario se ha registrado exitosamente.');
                                  this.setGrabando(false);
                                },
                                error=>{
                                  this.props.enviarError(error);
                                  this.setGrabando(false);
                                }
                              );
                            }else{
                                userService.actualizarUsuario(values).then(
                                  data => {                                      
                                    this.props.enviarSuccess('Se han actualizado los valores correctamente');
                                    this.setGrabando(false);
                                  },
                                  error=>{
                                    this.props.enviarError(error);
                                    this.setGrabando(false);
                                  }
                                );
                            } 
                          }else{
                            if(!this.state.codigoEnviado)
                                this.enviarCodigoRegistracion(values.mail);
                          }
             
                     }}>
                             {({ errors, touched,values,handleSubmit,setFieldValue }) => (
                             <Form>
                                <Row>
                                <Col sm={12}>
                                 <label htmlFor="nombre">Nombre</label>
                                 <ErrorMessage name={`nombre`} />
                                 <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="icon-admin"></i></div>
                                    </div>
                                 <Field  className="form-control" name="nombre" />
                                 </div>
                                 </Col>
                                 </Row>                                 

                                <Row>
                                {/*<Col lg={6} sm={12}>
                                 <div>
                                 <label htmlFor="numeroDocumento">Documento </label>
                              
                                 <ErrorMessage name={`numeroDocumento`} />
                                 <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="icon-user-card"></i></div>
                                    </div>
                                 <Field  className="form-control"  name="numeroDocumento" />
                                 </div>
                                 </div>
                             </Col>*/}
                                 <Col lg={6} sm={12}>
                                 <div>
                                 <label>Codigo País</label>
                                 <ErrorMessage name={`codigoPais`} />
                                 <Select
                                      value={codigosPais ? codigosPais.find(option => option.codigoPais === values.codigoPais) : ''}
                                      getOptionLabel={option =>`${option.descripcion}`}
                                      getOptionValue={option => option.id}
                                      onChange={so =>{
                                          setFieldValue(`codigoPais`,so?so.codigoPais:'')
                                      }}
                                      isSearchable={true}
                                      placeholder="Seleccione uno"
                                      options={codigosPais}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                        ...theme.colors,
                                          primary25: '#ffe699',
                                          primary: '#ffc107',
                                        },
                                      })}
                                  />
                                 </div>
                                 </Col>
                                 <Col lg={5} sm={12}>
                                  <label htmlFor="telefono">Celular </label>
                                  <ErrorMessage name={`telefono`} />
                                    <div className="input-group mb-2">
                                      <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="icon-phone"></i></div>
                                      </div>
                                      <Field  className="form-control"  name="telefono" />
                                    </div>
                                  </Col>
                                  <Col sm={12} lg={7}>
                                      <label htmlFor="mail">Mail </label>
                                      <ErrorMessage name={`mail`} />
                                      <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text"><i className="icon-mail"></i></div>
                                        </div>
                                        <Field className="form-control" name="mail"/>
                                        </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm={12}>
                                    <label htmlFor="username">Usuario </label>
                                    <ErrorMessage name={`username`} />
                                            <div className="input-group mb-2">
                                              <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="icon-admin"></i></div>
                                              </div>
                                              <Field  className="form-control"  name="username" />
                                            </div>
                                      </Col>
                                </Row>                             
                                  {nuevo && 
                                     <div>
                                        <label htmlFor="password">Contraseña </label>
                                        <ErrorMessage name={`password`} />
                                        <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="icon-lock"></i></div>
                                        </div>
                                        <Field type={this.state.mostrar?"text":"password"} className="form-control"  name="password" />
                                        <button type="button" className="btns btns-link ojo" onClick={()=>this.setState({mostrar:!this.state.mostrar})}><span className="material-icons material-icons-outlined txt-27">{this.state.mostrar?"remove_red_eye":"visibility_off"}</span></button>
                                        </div>
                            
                                         <label htmlFor="confpassword">Confirmar Contraseña </label>
                                          <ErrorMessage name={`confpassword`} />
                                        <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="icon-lock"></i></div>
                                        </div>
                                        <Field type={this.state.mostrar?"text":"password"} className="form-control"  name="confpassword" />
                                        <button type="button" className="btns btns-link ojo" onClick={()=>this.setState({mostrar:!this.state.mostrar})}><span className="material-icons material-icons-outlined txt-27">{this.state.mostrar?"remove_red_eye":"visibility_off"}</span></button>
                                        </div>
                                    </div>
                                  }

                                  <Modal show={this.state.codigoEnviado}  onHide={()=>this.setState({codigoEnviado:false})}>
                                          <Modal.Header className="bg-gradient-equipos  text-white" closeButton>
                                              <Modal.Title>Ingrese a continuación el codigo</Modal.Title>
                                          </Modal.Header> 
                                          <Modal.Body>
                                          <Field  className="form-control"  name="codigoIngresado" />

                                          <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                              <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.setState({codigoEnviado:false})}>
                                              <i className="icon-times"></i> Cerrar
                                              </button>
                                              <button className="btns btns-secundario" onClick={()=>handleSubmit()} type="button" disabled={this.state.grabando}>
                                                  <i className="icon-floppy"></i> Enviar codigo <LoadingBoton grabando={this.state.grabando}/>
                                              </button>
                                          </div>                                       
                                          </Modal.Body>                     
                                 </Modal> 
                                
                                    <div className="row justify-content-center mt-5">
                                     
                                       <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                                          {(nuevo && !this.state.codigoEnviado) && 'Crear usuario'}
                                          {(!nuevo && !this.state.codigoEnviado) && 'Grabar cambios'}
                                           <LoadingBoton grabando={this.state.grabando}/>
                                        </button>
                                     
                                    </div>
                             </Form>
                             )}
                 </Formik>
             </div>
         )};
}

function mapState(state) {

    const { userData,codigosPais } = state.authentication;
    const user = userData;
    return { user ,codigosPais};
}

const actionCreators = {
  enviarError: alertActions.error,
  enviarSuccess:alertActions.success,
  login:userActions.login,
  traerInfoGral:userActions.traerInfoGral
}

const connectedFormDatosUsuario = connect(mapState, actionCreators)(FormDatosUsuario);
export { connectedFormDatosUsuario as FormDatosUsuario };