import React from 'react';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { alertActions } from '../_actions';
import { errorConstants } from '../_constants';

class Alerta extends React.Component {
    
      
  componentDidMount(){
      if(!this.props.data.confirm){
        setTimeout(() => {
          this.props.clearAlerts();
        }, 5000);
      }
  }

    renderizarError(codigo,data){
        switch (codigo) {
            case errorConstants.ERROR_GENERICO:
              return <>{data.message}</>
            case 3:
              return <>{data.message}</>  
            case 403:
                return <>Usuario y contraseña incorrectos</>
            default:
              return null;
          }
    }
    render(){
      
       const {data} = this.props;      
        return (
            <>
              {data.message && <Alert onClose={() => this.props.clearAlerts()} variant={data.type} className={`${data.clase} fade text-white alert fixed-top-alert`} dismissible >
              {data.codigo?this.renderizarError(data.codigo,data):data.message.codigo?data.message.codigo:data.message}
              </Alert>}
              {data.confirm!==undefined && data.confirm }
            </>
        );
    }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedAlerta = connect(mapState, actionCreators)(Alerta);
export { connectedAlerta as Alerta };