import { userConstants,equipoConstants } from '../_constants';

const userStoraged = localStorage.getItem("userLogged");
let user = userStoraged?JSON.parse(userStoraged):undefined;
let userToken = user?user.tokenActivo:undefined;
let userData =user;
const initialState = user ? { user,userToken,userData } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
        equipoNuevo:{idEquipoNuevo:action.idEquipoNuevo,esEquipoNuevo:action.esEquipoNuevo }
      };
      case userConstants.INFO_GENERAL_PERFIL:
        return {
          ...state,
          codigosPais: action.data.listados.codigosPais        
        };

    case userConstants.NUEVO_TOKEN:
      return {
        ...state,
        userToken: action.tokenActivo        
      };
    case userConstants.GETLOGUEADO_SUCCESS:
      return {
        ...state,
        user: action.user,
        userToken: action.user.tokenActivo,
        userData:action.user          
      };  
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};    
    case equipoConstants.EQUIPO_LIMPIAR_EQUIPO_NUEVO_SUCCESS:
      return {
        ...state,
        equipoNuevo:{idEquipoNuevo:'',esEquipoNuevo:false}
      };
      case userConstants.SUSCRIPCION_CANCELADA_USER:
        return {
          ...state,
          userToken: action.data.token        
        };
      
    
      case userConstants.LOGOUT:
        return {};  
    default:
      return state
  }
}