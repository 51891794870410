import React from 'react';
import MaterialTable from 'material-table';
import ReactTooltip from 'react-tooltip';

const _ = require('lodash');
export class TablaRegistroSensor extends React.Component {

    eliminarTodo=()=>{
        this.props.eliminarRegistro({
            id:0,
            todos:true
        })
    }

    eliminarRegistro=(row)=>{
        this.props.eliminarRegistro({
            id:row.id,
            todos:false
        })
    }

    render(){
       
        const header = [
            { title: 'Fecha', field: 'fecha' },
            { title: 'Valor',  render: row =>  
               row.valor
            },
            { title: 'Acciones',
                render: row => 
                <> 
                <button type="button" className="btns btns-link text-verde-azulado" onClick={()=>this.eliminarRegistro(row)}>
                <a data-tip data-for='delete'><span className="material-icons-outlined icon-image-preview text-muted txt-34">delete</span>
                </a></button>
                <ReactTooltip place="bottom" id='delete' aria-haspopup='true' >
                <span>Eliminar registro</span>
                </ReactTooltip>
                </>
            }
        ]
        const options={
            search: true,
            sorting: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 30 ,50, 75, 100]
        }  
        let datos= _.clone(this.props.data).sort((a, b) => a.idRegistro > b.idRegistro ? 1 : -1);
        
        return (
            <>
                <button type="button" className="btns btns-danger" onClick={()=>this.eliminarTodo()}>
                <a data-tip data-for='deleteT'> <span className="material-icons-outlined icon-image-preview txt-34">delete</span> 
                </a>
                </button>
                <ReactTooltip place="bottom" id='deleteT' aria-haspopup='true' >
                <span>Eliminar todos los registros</span>
                </ReactTooltip>

                <MaterialTable
                    columns={header}                   
                    data={datos}
                    title="Registros"
                    options={options}
                    defaultSort="asc"
                />
            </>
        );
    }
}