import React from 'react';
import { connect } from 'react-redux';
import {Modal,Row,Col,ModalFooter} from 'react-bootstrap';
import { Formik, Form,  Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { grupoActions } from '../../../_actions';

class FormAgregarUsuario extends React.Component {

  state={
  }

  
  
render() {
     let {misEquipos} = this.props;

     console.log(misEquipos);

     let inic = {
      username:'',
      equipoId:undefined
     }

     const validationSchema = Yup.object().shape( { 
      username: Yup.string().min(2, 'Muy corto').max(1000, 'No puede excedor los 100 carácteres').required('Requerido'),
      equipoId: Yup.string().required('Requerido')
    })

return(

  <div>
        <Modal show={this.props.show} onHide={()=>this.props.handleClose()} backdrop="static" size={"lg"} keyboard={false}  aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header className="bg-info" closeButton>
               <Modal.Title className="text-white" id="example-custom-modal-styling-title" data-for="ayudaAgregarUsuario">Agregar usuario</Modal.Title>
               <ReactTooltip place="bottom" id='ayudaAgregarUsuario' aria-haspopup='false' >
                      <span>Podrá darle acceso a un equipo que actualmente le pertenece a otro usuario existente en la plataforma</span>
               </ReactTooltip>
            </Modal.Header> 
            
            <Formik
                enableReinitialize= {true}
                initialValues={inic}
                validationSchema={validationSchema}
                onSubmit={values => {  
                  console.log(values)
                    this.props.agregarUsuario(values,this.props.handleClose);                    
                }}
              >
                {({ errors, touched,values,setFieldValue }) => (
                  
                  <Form className="th_info"> 
                  <Modal.Body className="mb-5">                   
                      <Row>
                         <Col xs="12">
                            <label htmlFor="username">Username/Email </label> <ErrorMessage name={`username`} />                                
                                    <Field className="form-control" name="username" />
                         
                         </Col>

                         <Col>
                         <label htmlFor="equipoId">Equipo a compartir </label>
                         <ErrorMessage name={`equipoId`} />  
                              <Select
                                      value={misEquipos ? misEquipos.find(option => option.id === values.equipoId) : ''}
                                      getOptionLabel={option =>`${option.nombre}`}
                                      getOptionValue={option => option.id}
                                      onChange={so =>{
                                          setFieldValue(`equipoId`,so?so.id:'')
                                      }}
                                      isSearchable={true}
                                      placeholder="Seleccione uno"
                                      options={misEquipos}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                        ...theme.colors,
                                          primary25: '#ffe699',
                                          primary: '#ffc107',
                                        },
                                      })}
                                  />
                         </Col>
                        </Row>
                  </Modal.Body>
                  <ModalFooter className="justify-content-center">
                        <button className="btn btn-link text-secundario btn-lg cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                        <i class="fas fa-times"></i>  Cerrar
                        </button>
                        <button className="btns btns-secundario btn-lg" type="submit">
                        <i class="fas fa-save"></i> Grabar
                        </button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>                   
        </Modal> 
        
  </div>
)};
}

function mapState(state) {
  const { misEquipos } = state.equipos;
  return {misEquipos};
}

const actionCreators = {
  agregarUsuario:grupoActions.agregarUsuario,
  
}

const connectedFormAgregarUsuario = connect(mapState, actionCreators)(FormAgregarUsuario);
export { connectedFormAgregarUsuario as FormAgregarUsuario };