import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal} from 'react-bootstrap';
import {FormDatosUsuario,FormCambiarPassword} from '../../_components/Perfil';
import * as serviceWorker from '../../serviceWorker';

class MisDatos extends React.Component {

    state={
        show:false,
        showTelegram:false
    }

    handleClose = () => {
        this.setState({show: false,showTelegram:false});
    };

    pedirSuscripcion = ()=>{
        serviceWorker.pushAskPermission();
        serviceWorker.pushSubscribeUser();        
    }

    componentDidMount(){      
    }

    render(){
        const {user} = this.props;
        return (
            <div className="main-content">
                <Container fluid>
                    <Row className="justify-content-center mt-5 th_cielo">
                        <Col lg={7} sm={12}>
                            <div className="card-components border-left-c">
                                <div className="card-body">
                                <Row>
                                    <Col lg={5} sm={3}>
                                        <span className="material-icons text-white lead">stay_primary_portrait</span> <span className="text-white">Aplicaciones externas sincronizadas</span>
                                    </Col>
                                    <Col  lg={7} sm={9} className="text-right">
                                        <Row>
                                            <Col xs={12}>
                                                {(user && user.telegramChatId && user.telegramChatId!=='')?<><p className="text-cielo lead font-weight-bold"><span className="material-icons-outlined align-middle">check_circle</span> Telegram activa</p></>:
                                                    <>
                                                        <p className="text-muted lead font-weight-bold"><span className="material-icons-outlined align-middle">phonelink_erase</span> Telegram no activa</p>
                                                        <hr></hr>

                                                        <button className="btns btns-cielo btn-sm" type="button" onClick={()=>this.setState({showTelegram:true})} >
                                                        <i className="fas fa-paper-plane"></i> ¿Comá activarla?
                                                        </button>
                                                    </>
                                                    }
                                            </Col>
                                        </Row>
                                        {/*<Row>
                                            <Col xs={12}>
                                             <span className="text-white">Notificaciones del explorador</span>
                                                {serviceWorker.estoyPermitido()?<span className="text-white"><b>Ya estas suscripto</b></span>:
                                                <button className="btns btns-cielo" type="button" onClick={()=>this.pedirSuscripcion()} >
                                                    <i className="fas fa-paper-plane"></i> Suscribir notificaciones
                                                </button>}
                                            </Col>
                                        </Row>*/}
                                       
                                    </Col>
                                </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                   {(user && !user.esUsuarioExterno)&& <Row className="justify-content-center mt-5">
                        <Col lg={7} sm={12}>
                            <div className="card-components border-left-c">
                            <div className="card-body">
                                <Row>
                            <Col lg={5} sm={3}>
                            <span className="material-icons text-white lead">lock</span> <span className="text-white lead">Credenciales</span>
                            </Col>
                            <Col  lg={7} sm={9} className="text-right">
                            <button className="btns btns-iot" type="button" onClick={()=>this.setState({show:true})} >
                            <span className="material-icons">password</span> Cambiar contraseña 
                            </button>
                            </Col>
                            </Row>
                            </div>
                            </div>
                        </Col>
                    </Row>} 
                    
                    <Row className="justify-content-center mt-5">
                        <Col lg={7} sm={12}>
                            <div className="card-components">
                            <div className="card-body">
                            <Col>
                            <FormDatosUsuario className="bg-iot" accion='actualizar' />
                            </Col>
                            </div>
                            </div>
                        </Col>
                    </Row> 
                </Container>

                {this.state.show && <FormCambiarPassword show={this.state.show} handleClose={()=>this.handleClose()} />} 
                {this.state.showTelegram && <Modal show={this.state.showTelegram} onHide={this.handleClose} backdrop="static"  >
                    <Modal.Header className="bg-cielo text-white" closeButton>
                        <Modal.Title><i className="fas fa-paper-plane"></i> ¿Comó activar Telegram?</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body className="th_uva">
                        <div className="sc-box mt-4">
                            <div className="sc-container sc-timeline">
                            <div className="sc-timeline-item">
                                <i className="bg-gradient-vistas sc-timeline-icon fas fa-download"></i> 
                                <div className="sc-timeline-info">
                                <div className="sc-timeline-details">
                                    <span className="sc-timeline-time">PASO 1</span>
                                </div>
                                <h6 className="sc-timeline-title">Instalar Telegram</h6>
                                </div>
                            </div>
                            <div className="sc-timeline-item sc-actionsheet-trigger">
                                <i className="bg-gradient-vistas sc-timeline-icon fas fa-cog"></i> 
                                <div className="sc-timeline-info">
                                <div className="sc-timeline-details">
                                    <span className="sc-timeline-time">PASO 2</span>
                                </div>
                                <h6 className="sc-timeline-title">Configurar correctamente el número de teléfono en el perfil. Ej: "1133334444" </h6>
                                </div>
                            </div>
                            <div className="sc-timeline-item">
                                <i className="bg-gradient-vistas sc-timeline-icon fas fa-search"></i> 
                                <div className="sc-timeline-info">
                                <div className="sc-timeline-details">
                                    <span className="sc-timeline-time">PASO 3</span>
                                </div>
                                
                                <h6 className="sc-timeline-title">Buscar nuestro usuario. IotechApp_bot</h6>
                                </div>
                            </div>
                            <div className="sc-timeline-item">
                                <i className="bg-gradient-vistas sc-timeline-icon fas fa-envelope"></i> 
                                <div className="sc-timeline-info">
                                <div className="sc-timeline-details">
                                    <span className="sc-timeline-time">PASO 4</span>
                                </div>
                                
                                <h6 className="sc-timeline-title">Enviarle un mensaje "/start"</h6>
                                </div>
                            </div>
                            <div className="sc-timeline-item">
                                <i className="bg-gradient-vistas sc-timeline-icon fas fa-share-alt"></i> 
                                <div className="sc-timeline-info">
                                <div className="sc-timeline-details">
                                    <span className="sc-timeline-time">PASO 5</span>
                                </div>
                                
                                <h6 className="sc-timeline-title"> Compartir tu contacto.</h6>
                                </div>
                            </div>
                            </div>
                        </div>
                    </Modal.Body>                     
                </Modal>} 
            </div>
        );
    }
}

function mapState(state) {   
    const { userData } = state.authentication;
    const user = userData;
    return { user };
}

const actionCreators = {
   
}

const connectedMisDatos = connect(mapState, actionCreators)(MisDatos);
export { connectedMisDatos as MisDatos };