import { vistaConstants,userConstants } from '../_constants';


export function vistas(state = {}, action) {
  switch (action.type) { 
    case vistaConstants.VISTAS_GET_PU_SUCCESS:
      return { 
        ...state,
        misVistas: action.misVistas
      };   
    case vistaConstants.VISTA_SELECCIONADA:
      return { 
        ...state,
        seleccionado: action.seleccionado
    };

    case vistaConstants.AGREGAR_VISTA_GENERADA:
        return {
          ...state,
          misVistas: state.misVistas.concat(action.data.vista)         
        };
    case vistaConstants.ACTUALIZAR_VISTA:
      return {
        ...state,       
        misVistas: state.misVistas.map(vista => {
          if (vista.id === action.data.vista.id) {            
            return action.data.vista
          }
          return vista;
        })
      };

      case  vistaConstants.ACTUALIZAR_LAYOUT_VISTA:     
      return {
        ...state,
        misVistas: state.misVistas.map(vista => {                          
              if (vista.id === action.data.id) {                
                const {layouts, ...vistaCopy } = vista;                
                return {layouts: action.data.layouts, ...vistaCopy};
              }else return vista; 
        })
      };  

    case vistaConstants.VISTA_ELIMINADA:
      return {
        ...state,
        misVistas: state.misVistas.filter(vista => vista.id !== action.vistaLlegada.id)
      };      
      
    case vistaConstants.VISTA_SENSOR_ELIMINADO:
      return {
        ...state,
        misVistas: state.misVistas.map(vista => {
          if(vista.id===action.vistaLlegada.id)
              vista.sensores = vista.sensores.filter(sensor => sensor.id !== action.sensor.id) 
          return vista;
        })
      };  
      
      case userConstants.LOGOUT:
        return {};
          
    default:
      return state
  }
}