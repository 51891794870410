import React from 'react';
import ReactApexChart from "react-apexcharts";

export default class GChartWidget extends React.Component {

  shouldComponentUpdate(nextProps, nextState){       
    
    if(this.props.gdata.length !== nextProps.gdata.length){
      return true
    }else{
      return false
    }
  }

   render(){
        
        let chartData = this.props.gdata;
        
        let options= {
          
          chart: {
            redrawOnParentResize: true,
            toolbar: {
                show: false,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true
                },
              },
                width: "100%",
                height: "100%",
                type: 'line',
                id: "basic-bar"
          },
          tooltip:{
            enabled: true,
            followCursor: true,
            x:{
              format:'HH:mm:ss',
              formatter: undefined
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {            
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 2,
            dashArray: 0, 
          },
          xaxis: {
            type:'datetime'
          }
        };     
                
        return (

            <div className="contenedor-chart">  
 
                 {(chartData!==undefined && chartData.length>0) ?  
                  <div id="chart">               
                      <ReactApexChart height="100%" options={options} series={[{
                      name: this.props.unidad,
                      data: chartData
                      }]}
                      type="line" /> 
                    </div>
                    : <div className="col-sm-12"><div className=" bg-dark p-2 mb-3 mt-5 border-left-c text-silver"> <i className="fas fa-exclamation-circle"></i>  Sin registros</div></div> }
            </div>      
        );
    }
}
