export const mqttEventosConstants = {
    NUEVO_VALOR_SENSOR:1,
    NUEVO_VALOR_PROGRAMA:2,
    NUEVA_NOTIFICACION:3,
    NUEVO_MENSAJE:4,
    ESTADO_EQUIPO:5,
    NUEVO_VALOR_COMPRA:6,
    NUEVO_TOKEN:7,
    ESTADO_SENSOR:8
};
