import React from 'react';
import ReactApexChart from "react-apexcharts";
import {Col, Row} from 'react-bootstrap';
export default class GChart extends React.Component {

  shouldComponentUpdate(nextProps, nextState){       
    
    if(this.props.gdata.length !== nextProps.gdata.length){
      return true
    }else{
      return false
    }
  }

   render(){
        
        let chartData = this.props.gdata;
        
        let options= {
          chart: {
            type: 'line',
            id: "basic-bar"
          },
          tooltip:{
            enabled: true,
            followCursor: true,
            x:{
              format:'HH:mm:ss',
              formatter: undefined
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {            
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0, 
          },
          xaxis: {
            type:'datetime'
          }
        };     
                
        return (

            <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 text-center">  
 
                 {(chartData!==undefined && chartData.length>0) ?  
                 <div id="chart">               
                    <ReactApexChart options={options} series={[{
                    name: this.props.unidad,
                    data: chartData
                  }]}
                    type="line" height={600} /> 
                    </div>
                    : <Row className="justify-content-center"><div className="col-sm-12 col-lg-8 border-left-c bg-dark p-2 mb-3 mt-3 text-silver"> <i className="fas fa-exclamation-circle"></i>  No se han encontrado registros para la fecha indicada</div> </Row>}
            </div>      
        );
    }
}
