import { eventoConstants,userConstants } from '../_constants';


export function evento(state = {}, action) {
  switch (action.type) { 
    case eventoConstants.EVENTO_GET_PU_SUCCESS:
      return { 
        ...state,
        misEventos: action.data.misEventos
      };   

      case eventoConstants.EVENTO_DATA_GENERAL:
        return { 
          ...state,
          tiposNotificaciones: action.data.listado.tiposNotificaciones,
          tiposAcciones: action.data.listado.tiposAcciones,
          tiposAccionesColectivas: action.data.listado.tiposAccionesColectivas,
          periodos: action.data.listado.periodos,
          tiposInformaciones: action.data.listado.tiposInformaciones,
          condiciones: action.data.listado.condiciones
        };   
    case eventoConstants.AGREGAR_EVENTO_GENERADO:
        return {
          ...state,
          misEventos: state.misEventos?state.misEventos.concat(action.data.evento):[action.data.evento]      
        };
    case eventoConstants.ACTUALIZAR_EVENTO:
      return {
        ...state,       
        misEventos: state.misEventos.map(evento => {
          if (evento.id === action.data.evento.id) {            
            return action.data.evento
          }
          return evento;
        }),
        seleccionado: action.data.evento
      };
    case eventoConstants.EVENTO_TOOGLE_ESTADO:
      return {
        ...state,       
        misEventos: state.misEventos.map(evento => {
          
          if (evento.id === action.values.id) {       
            evento.habilitado = action.values.habilitado;
            return evento;
          }
          return evento;
        })
      };
      
    case eventoConstants.EVENTO_ELIMINADO:
      return {
        ...state,
        misEventos: state.misEventos.filter(evento => evento.id !== action.eventoLlegado.id)
      };               
    case userConstants.LOGOUT:
      return {};
          
    default:
      return state
  }
}