import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {Container,Row,Col,ListGroup,Button,Tab,Tabs} from 'react-bootstrap';
import { sensorService } from '../../_services';
import CsvExporter from '../Home/CsvExporter';
import Form from 'react-bootstrap/Form';
import GChart from '../Home/GChart';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import { Graficador, history } from '../../_helpers';
import { sensorConstants } from '../../_constants';
import {TablaRegistroSensor} from "./TablaRegistroSensor"
import { equipoActions, sensorActions, utilActions } from '../../_actions';
import MapClass from './widgets/MapClass';
import MoonLoader from "react-spinners/MoonLoader";

registerLocale('es', es)
class InfoSensor extends React.Component {

    constructor(props){      
       super(props);
       let params = queryString.parse(this.props.location.search);        
       const {id} = params; 
       this.state.idSensor = parseInt(id);

    }


    componentDidMount(){
    }

    actualizarHistorial=(tipo)=>{
        if(this.state.sensor){
            this.setState({loading:true});
            const esMapa = this.state.sensor.tipoWidget.id===sensorConstants.TIPO_WIDGET_MAPA;
            sensorService.getHistorial( this.state.idSensor,tipo,this.state.fechaDesdeP,this.state.fechaHastaP).then(
                data => {      
                        const chartDataGrafico = []; 
                        const charDataLista = [];          
                        data.historial.forEach((registro, index) => {           
                            let fe= new Date(registro.fecha);
                            fe.setUTCHours(registro.hora)
                            fe.setUTCMinutes(registro.minuto)
                            fe.setUTCSeconds(registro.segundo)
                            
                            charDataLista.push({fecha:fe.toLocaleString(),valor:registro.valor,id:registro.idRegistro});
                            if(esMapa)
                                chartDataGrafico.push({x:fe.getTime(), y:registro.valor,id:registro.idRegistro});
                                else{
                                    const valor = parseFloat(registro.valor);
                                    if(!isNaN(valor))
                                        chartDataGrafico.push({x:fe.getTime(), y:valor,id:registro.idRegistro});
                                }
                        });
    
                        this.setState({
                            historial: chartDataGrafico,
                            historialLista: charDataLista,
                            loading:false
                        });
                }
            ); 
        }
        
    }

    handleChange = date => {
        let dia = date.getDate();
        let mes = date.getMonth()+1;
        if(mes<10)mes='0'+mes;
        if(dia<10)dia='0'+dia;
        this.setState({
          fechaDesdeP: dia+'/'+mes+'/'+date.getFullYear(),
          fechaDesde:date
        });
    };

    handleChangeHasta = date => {
        let dia = date.getDate();
        let mes = date.getMonth()+1;
        if(mes<10)mes='0'+mes;
        if(dia<10)dia='0'+dia;
        this.setState({
          fechaHastaP: dia+'/'+mes+'/'+date.getFullYear(),
          fechaHasta:date
        });
    };

    volverAtras = () =>{
        history.goBack();
    }

    borrarRegistroHistorial=(idRegistro)=>{
        if(idRegistro===0){
            this.setState({historialLista: [],
            historial: []});
        }else{
            this.setState({historialLista: this.state.historialLista.filter(function(reg) { 
                return reg.id !== idRegistro 
            }),
            historial: this.state.historial.filter(function(reg) { 
                return reg.id !== idRegistro 
            })});
        }

       
    }

    eliminarRegistro = (registro) =>{
        let values = {
            id:this.state.idSensor,
            idRegistro:registro.id,
            todos:registro.todos
        }
        this.props.eliminarRegistro(values,this.borrarRegistroHistorial);
        
    }

    state={
        idSensor:undefined,
        fechaDesde:'',
        fechaHasta:'',
        historial:[],
        historialLista:[],
        ultimoValor:'',
        fechaDesdeP:'',
        fechaHastaP:'',
        sensor:undefined,
        loading:false
    }

    render(){            
        let loading = this.props.sensores===undefined;
        let sensor={};
        let unidad='';
        let color='';
        if(!loading){
            sensor = utilActions.pasarListaSensoresAShadows(this.props.sensores).filter(sen=>sen.id===this.state.idSensor)[0];
            if(sensor===undefined){
                history.push('/home/listaEquipo');
                loading=true;
            }else{                
                unidad = sensorConstants.style[sensor.tipoSensor.id].unidad;
                color = sensorConstants.style[sensor.tipoSensor.id].unidad;
                if(this.state.sensor===undefined || sensor.ultimoValor!=this.state.sensor.ultimoValor)this.setState({sensor:sensor});
            }
        }      
        
        return (

            <div className={color+' main-content'}>
                {!loading&&<Container fluid className="th_recursos">
                    <Row className="fixed">
                        <Col sm={12}>
                            <button type="button" onClick ={() => this.volverAtras()} className="btns btns-iot text-dark" size="lg"> <i className="fas fa-arrow-left"></i> Volver</button> 
                        </Col>
                    </Row>
                    <Row className="mt-5-1 mb-2 text-white">
                        <Col sm={12}>
                            <h2 className="txt-oswald text-truncate"><i className={sensorConstants.style[sensor.tipoSensor.id].icono}></i> {sensor.labelUsuario}  </h2>

                            <h4 className="font-weight-light text-muted ml-3"> {sensor.tipoSensor.descripcion} </h4>

                        </Col>  
                    </Row>
                    <Row className="mt-5 justify-content-end">
                    <div className="filtros shadow">                        
                        {sensor.ultimoValor!==undefined &&
                            <Col sm={12} lg={3}>
                                  <p className="text-center text-white"> VALOR ACTUAL 
                                  <span className="badge bg-secundario border font-weigth-bold text-silver txt-14 ml-2">
                                        {(sensor.tipoWidget.id===sensorConstants.TIPO_WIDGET_MAPA) ? 
                                            sensor.ultimoValor?`${sensor.variableJson}:${sensor.ultimoValor[sensor.variableJson]}
                                            ${sensor.variableLongitud}:${sensor.ultimoValor[sensor.variableLongitud]}`:''
                                        :sensor.ultimoValor}
                                    </span>
                                    </p>
                                    <div className={"widgets "+(!sensor.conectado && ' disabled')} disabled={!sensor.conectado} >                                      
                                       {sensor.tipoWidget.id!==sensorConstants.TIPO_WIDGET_GRAFICO && <Graficador tipoWidget={sensor.tipoWidget.id} 
                                                sensor={sensor}
                                                enviarASensor={this.props.enviarASensor}
                                                width={250} 
                                                height={160}
                                                valorMinReferencia={parseFloat(sensor.valorMinReferencia)} 
                                                valorMaxReferencia={parseFloat(sensor.valorMaxReferencia)}
                                                valor={sensor.ultimoValor}
                                                unidad={unidad} />}
                                    </div>
                            </Col>
                        }
                    <Col sm={12} lg={9}>
                        <Row>
                                <Col sm={12}>
                                   <span className="text-white font-weight-bold text-muted"> <i className="fas fa-sliders-h"></i> Filtros</span>
                                </Col>
                                <Col sm={12} lg={6} className="mt-3">
                                <label>Hasta</label>
                                    <ListGroup className="list-group-horizontal-md">
                                        <ListGroup.Item action onClick={() => this.actualizarHistorial(1)}><span className="text-silver tilte-list">ÚLTIMAS</span><span className="subtilte-list text-white text-center"><span className="material-icons material-icons-outlined icon-image-preview align-middle">access_time</span>24hs</span> </ListGroup.Item>
                                        <ListGroup.Item action onClick={() => this.actualizarHistorial(2)}><span className="text-silver tilte-list">ÚLTIMAS</span><span className="subtilte-list text-white text-center"><span className="material-icons material-icons-outlined icon-image-preview align-middle">access_time</span>72hs</span></ListGroup.Item>
                                        <ListGroup.Item action onClick={() => this.actualizarHistorial(3)}><span className="text-silver tilte-list">MENSUAL</span><span className="subtilte-list text-white text-center"><span className="material-icons material-icons-outlined icon-image-preview align-middle">calendar_today</span></span></ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col sm={12} lg={6} className="mt-3" >    
                                    <Form>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <label>Desde</label>
                                                <DatePicker  dateFormat="dd/MM/yyyy" locale="es" selected={this.state.fechaDesde} onChange={this.handleChange}/>
                                                
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <label>Hasta</label>
                                                <DatePicker dateFormat="dd/MM/yyyy"  locale="es" selected={this.state.fechaHasta} onChange={this.handleChangeHasta}/>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                        </Row>
                    </Col>      
                    </div>
                    </Row>
                    <Row className="justify-content-end mb-5">
                        <Col sm={4} className="mt-3 text-right">
                            <Button onClick={() => this.actualizarHistorial(5)}> <i className="fas fa-filter"></i> Filtrar</Button>
                        </Col>
                    </Row>

                    {!this.state.loading?<Tabs  className="nav nav-pills justify-content-end nav-tabs mb-4"  defaultActiveKey="grafico" id="uncontrolled-tab-example">            
                        <Tab eventKey="list" title={<div><i className="fas fa-list-ul mt-1"></i> Listado</div>}>
                            {this.state.historialLista!==undefined &&
                              <div className="contenedor-chart">
                                 <TablaRegistroSensor tipoWidget={sensor.tipoWidget} data ={this.state.historialLista} eliminarRegistro={this.eliminarRegistro}/>
                              </div>
                            }
                        </Tab>

                        <Tab eventKey="grafico" title={<div><span className="material-icons">dashboard</span>Gráfico</div>}>
                            <div className="contenedor-chart shadow">
                                <Row className="mt-5 mb-2">
                                    <Col sm={12}>
                                        <div className="cont-csv text-right p-3">
                                            <CsvExporter data={this.state.historialLista}/>
                                        </div>
                                    </Col>
                                    <Col sm={12}>                                    
                                        {(sensor.tipoWidget.id!==sensorConstants.TIPO_WIDGET_MAPA) ?
                                            <GChart unidad={unidad} gdata={this.state.historial}/>
                                            :
                                            <>
                                            {(this.state.historial && this.state.historial.length>0) ? <MapClass
                                             variableLongitud={sensor.variableLongitud}
                                             variableJson={sensor.variableJson}
                                             width={600}
                                             height={400}
                                             direcciones={this.state.historial} />
                                            :<div className="col-sm-12 border-left-c bg-dark p-2 mb-3 mt-3 text-silver"> <i className="fas fa-exclamation-circle"></i> No hay registros para visualizar. Busque en otro rango de fechas</div>}
                                             </>
                                        }                                    
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                       
                    </Tabs>:<>
                    <Row className="justify-content-center"> 
                    <MoonLoader size={100} color={"#B1B1B1"} />
                    </Row>
                    </>
                    }
                   
                </Container>}
            </div>
        );
    }
}

function mapState(state) {
    const { equipos } = state;
    const {sensores} = equipos;
    return {sensores,equipos};
}

const actionCreators = {
    eliminarRegistro:sensorActions.eliminarRegistro,
    getEquiposPU: equipoActions.getEquiposPU,
    seleccionarSensor:sensorActions.seleccionarSensor,
    enviarASensor:sensorActions.enviarASensor,
    nuevoValorSensor:equipoActions.nuevoValorSensor
}

const connectedInfoSensor = connect(mapState, actionCreators)(InfoSensor);
export { connectedInfoSensor as InfoSensor };