import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FieldArray  } from 'formik';
import {ERR, ErrorMessage,LoadingBoton, TIPO_ACCION,TIPO_INFORMACION,TIPO_NOTIFICACION} from '../../_helpers';
import * as Yup from 'yup';
import {  programaService } from '../../_services';
import {accionColectivaActions, alertActions,equipoActions, eventoActions, programaActions, utilActions} from '../../_actions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { programaConstants, sensorConstants } from '../../_constants';
import Select from 'react-select';



class FormAccion extends React.Component {
  
  constructor(props){
    super(props);
  }

  componentDidMount(){  
    if(this.props.accionColectiva.misAcciones===undefined)this.props.traerAccionesColectivas();
    if(this.props.tipoNotificaciones===undefined)this.props.traerListadoGeneralEvento();
  }

  state = {
      esNuevo:true,
      grabando:false,
      tipoAccionTarea:'iniciales'
   };

render() {
      const {user,acciones,equipos,tipoAccion,tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones} = this.props;
      const optionsSensores = [];
      const sensoresL = equipos.sensores;
      const optionsSimple = [];
      const optionsSimpleToogle = [];
      if(equipos && equipos.misEquipos)equipos.misEquipos.forEach((eq, index) => { 

        const sensores = [];

        if(eq.sensores){
          eq.sensores.forEach((sensor, index) => { 
            const s = utilActions.extraerDeLista(sensor.id,sensoresL);
            sensores.push( {name: s.labelUsuario, value: (sensor.id+'')})
            optionsSimple.push( {descripcion: s.labelUsuario, id: (sensor.id)})
            if(s.shadows[0].tipoWidget.id===sensorConstants.TIPO_WIDGET_TOOGLE)optionsSimpleToogle.push( {descripcion: s.labelUsuario, id: (sensor.id)});
          }); 
        }
       
        if(sensores.length>0){
          const grupo ={
            type: 'group',
            name: eq.nombre,
            items: sensores
          }
          optionsSensores.push(grupo);
        }
      
      }); 


      const tarea ={acciones};

      let inic={
        acciones: []
      }       

       const schemaAcciones = Yup.object().shape({
        acciones:Yup.array().of(
          Yup.object().shape({
            tipoAccion: Yup.object({id: Yup.string().required(ERR.REQ)}),
            tiposNotificaciones: Yup.array().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.NOTIFICACION, 
              then: Yup.array().required(ERR.REQ).min(1,'Debe definir al menos una notificación').of(
                Yup.object({id: Yup.string().required(ERR.REQ)}))
            }),
            sensores: Yup.array().test('sensores','', function(item){
              if ((item===undefined || item.length===0) && this.parent.tipoAccion.id == TIPO_ACCION.PARTICULAR){
                 return this.createError({
                   path:`${this.path}`,
                   message:'Debe seleccionar al menos un sensor'
                 })
                }
             }),
            accionesColectivas: Yup.array().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.COLECTIVA, 
              then: Yup.array().min(1,'Debe definir al menos una accion colectiva').of(
                Yup.object({id: Yup.string().required('RE QA')}))
            }),
            tipoAccionColectiva: Yup.object().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.PARTICULAR, 
              then: Yup.object({id: Yup.string().required(ERR.REQ)})
            })
          })
        )});
       
        
      if(tarea!==undefined)inic=utilActions.setearValores(inic,tarea);
       
       

return(

  <div className="th_cielo">      
        <div className="">
            <Formik
              enableReinitialize= {true}
              initialValues={inic}
              validationSchema={schemaAcciones}
              onSubmit={values => {
                  this.setState({grabando:true});
                  values.idTarea = this.props.idTarea; 
                  values.idPrograma = this.props.idPrograma; 
                  values.tipoAccion = this.props.tipoAccion; 
                  
                  values.acciones.forEach(acc => {
                      if(acc.tipoAccion.id==TIPO_ACCION.NOTIFICACION ){
                        if(tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL)
                            acc.tiposInformaciones=[{id:TIPO_INFORMACION.INICIAL}]
                        if(tipoAccion===programaConstants.TIPO_ACCION_TAREA_FINAL)
                            acc.tiposInformaciones=[{id:TIPO_INFORMACION.FINAL}]
                      }
                  });
                 

                  programaService.grabarAccionTarea(values).then(
                    data => {  
                        data.idTarea = this.props.idTarea; 
                        data.idPrograma = this.props.idPrograma; 
                        data.tipoAccion = this.props.tipoAccion; 
                        this.props.agregarAccionTarea(data);
                        this.props.handleClose();
                        this.setState({grabando:false});
                    },
                    error=>{
                      this.props.enviarError(error);
                      this.setState({grabando:false});
                    }
                );
              }}
            >
              {({ errors, touched,values,setFieldTouched,setFieldValue }) => (
                <Row>
                  <Col xs={12}>
                    <Form>
                          
                    <Row>                         
                      <Col xs={12} className="text-dark">
                      <b>Configure las acciones {tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL?'iniciales':'de finalización'}</b>
                      <FieldArray
                            name="acciones"
                            render={arrayHelpers => (
                                <div key={arrayHelpers.idx} className="form-body mt-2">
                                  <div className="nueva-accion">
                                      <button className="btns btns-success btns-sm" type="button" onClick={() => arrayHelpers.push({ sensor: {id:''},tipoNotificacion: {id:''}, tipoAccion: {id:''}, tipoAccionColectiva: {id:''}, tipoInformacion: {id:''} })}>
                                        <i className="material-icons align-middle txt-16">add</i> Nueva acción
                                      </button>
                                    </div>
                                    {values.acciones.map((accion, index) => (
                                      <Row key={index}  className="mb-3 mt-3">
                                        <div key={index} className="col-sm-12 mb-2">
                                          <div>
                                              <div className="text-right mt-3">
                                                  <button className="btn btn-link cursor text-danger delete " type="button" onClick={() => arrayHelpers.remove(index)}>
                                                      <i className="fas fa-trash"></i>
                                                  </button>
                                              </div>                                  
                                                <br/>
                                                <label>Tipo de acción </label>  <ErrorMessage name={`acciones[${index}].tipoAccion.id`} />
                                                <Field value={values.acciones[index].tipoAccion.id} component="select" name={`acciones.${index}.tipoAccion.id`} >
                                                    <option value=''>Seleccione</option>                                        
                                                    {tiposAcciones && tiposAcciones.map(ts =>
                                                      <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                                                    )}
                                                </Field>
                                              
                                            </div>
                                          </div>
                                          {(values.acciones[index].tipoAccion.id==TIPO_ACCION.NOTIFICACION) &&
                                              <>
                                                  <Col xs={12}>
                                                  <FieldArray
                                                        name={`acciones[${index}].tiposNotificaciones`}    
                                                        render={arrayHelpers => (
                                                          <div className="mt-4 mb-4">                       
                                                                {tiposNotificaciones && tiposNotificaciones.map((tn, indexTn)=>  
                                                                    <div key={tn.id}>                                        
                                                                          <input
                                                                              className="option-input checkbox"
                                                                              name="tipoNoti"
                                                                              type="checkbox"
                                                                              disabled={tn.id===TIPO_NOTIFICACION.TELEGRAM && !user.telegramChatId}
                                                                              value={tn.id}
                                                                              checked={values.acciones[index].tiposNotificaciones && values.acciones[index].tiposNotificaciones.some(se=>se.id===tn.id)}
                                                                              onChange={e => {
                                                                                if (e.target.checked) {
                                                                                  arrayHelpers.push(tn);
                                                                                } else {
                                                                                  const idx = values.acciones[index].tiposNotificaciones.findIndex(se=>se.id===tn.id);                                                                         
                                                                                  arrayHelpers.remove(idx);
                                                                                }
                                                                              }}
                                                                          />
                                                                        <label className="label-check">{tn.descripcion}</label>
                                                                      </div>
                                                                  )}                      
                                                          </div>
                                                        )}
                                                      />
                                                      <ErrorMessage name={`acciones[${index}].tiposNotificaciones`} />  
                                                        <small>Te notificaremos {tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL?'del comienzo':'de la finalización'} de la tarea</small>
                                                  </Col>
                                                  </>
                                                  }
                                                  {(values.acciones[index].tipoAccion.id==TIPO_ACCION.COLECTIVA) &&
                                                    <Col xs={12}>
                                                        <label>¿Que acción colectiva desea ejecutar? </label>
                                                        <ErrorMessage name={`acciones[${index}].accionesColectivas`} />  
                                                        <FieldArray
                                                        name={`acciones[${index}].accionesColectivas`}
                                                        render={arrayHelpers => (
                                                          <div className="mt-4 mb-4">                       
                                                                {this.props.accionColectiva && this.props.accionColectiva.misAcciones.map((tn, indexTN)=>  
                                                                    <div key={tn.id}>                                        
                                                                          <input
                                                                              className="option-input checkbox"
                                                                              name="tipoAccColec"
                                                                              type="checkbox"
                                                                              value={tn.id}
                                                                              checked={values.acciones[index].accionesColectivas?values.acciones[index].accionesColectivas.some(se=>se.id===tn.id):false}
                                                                              onChange={e => {
                                                                                if (e.target.checked) {
                                                                                  arrayHelpers.push(tn);
                                                                                } else {
                                                                                  const idx = values.acciones[index].accionesColectivas.findIndex(se=>se.id===tn.id);                                                                         
                                                                                  arrayHelpers.remove(idx);
                                                                                }
                                                                              }}
                                                                          />
                                                                        <label className="label-check">{tn.nombre}</label>
                                                                      </div>
                                                                  )}   
                                                                   {(this.props.accionColectiva===undefined || this.props.accionColectiva.misAcciones.length===0) &&
                                                                  'No hay acciones automaticas creadas.'}                   
                                                          </div>
                                                        )}
                                                      />
                                                      
                                                    </Col>
                                                  }

                                                  {(values.acciones[index].tipoAccion.id==TIPO_ACCION.PARTICULAR) &&
                                                      <>
                                                      <Col xs={12}>
                                                            <label>¿Qué tipo de acción desea ejecutar?</label><ErrorMessage name={`acciones[${index}].tipoAccionColectiva.id`} />
                                                            <Field value={values.acciones[index].tipoAccionColectiva?values.acciones[index].tipoAccionColectiva.id:''} component="select" name={`acciones.${index}.tipoAccionColectiva.id`} >
                                                                <option value=''>Seleccione</option>                                              
                                                                {tiposAccionesColectivas && tiposAccionesColectivas.map(ts =>
                                                                  <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                                                                )}
                                                            </Field>
                                                            
                                                        </Col>
                                                      
                                                        <Col xs={12}>
                                                        <label>Elija sobre que sensor se ejecutara la acción  </label><ErrorMessage name={`acciones[${index}].sensores`} /> 
                                                        <Select
                                                            isClearable
                                                            isMulti
                                                            value={optionsSimpleToogle ? optionsSimpleToogle.filter(obj => (values.acciones[index].sensores && values.acciones[index].sensores.some(cam =>cam.id===obj.id))) : []}
                                                            getOptionLabel={option =>`${option.descripcion}`}
                                                            getOptionValue={option => option.id}
                                                            onChange={so =>{
                                                                setFieldValue(`acciones[${index}].sensores`,so?so:[]);
                                                            }}
                                                            isSearchable={true}
                                                            placeholder="Seleccione una"
                                                            options={optionsSimpleToogle}
                                                        />  
                                                                     
                                                        </Col>                                          
                                                      </>
                                                  }
                                                        </Row>
                                                  ))}
                                                      
                                                  </div>
                                              )}
                                          />
                                        
                                        </Col>
                                    </Row>
                          <div className="justify-content-center mt-3 p-3 row border-top bg-light">
                              <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                              <i className="icon-times"></i> Cerrar
                              </button>
                              
                                <button className="btns btns-secundario  mr-3" type="submit"  disabled={this.state.grabando}>
                                    <i className="icon-floppy"></i> Grabar
                                    <LoadingBoton grabando={this.state.grabando}/>
                                </button>
                            
                          </div>
                        
                    </Form>
                  </Col>
                </Row>
              )}
            </Formik>
    </div>
  </div>
)};
}

function mapState(state) {
  const { userData } = state.authentication;
  const user = userData;
  const { equipos, accionColectiva } = state;
  const { tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones} = state.evento;
  return { user,equipos,accionColectiva,tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones };
}

const actionCreators = {
  agregarAccionTarea:programaActions.agregarAccionTarea,
  enviarError:alertActions.enviarError,
  getEquiposPU: equipoActions.getEquiposPU,
  traerAccionesColectivas: accionColectivaActions.getAccionesColectivasPU,
  traerListadoGeneralEvento:eventoActions.traerListadoGeneralEvento
}

const connectedFormAccion = connect(mapState, actionCreators)(FormAccion);
export { connectedFormAccion as FormAccion };