export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    ACTUALIZAR_TOKEN:'ACTUALIZAR_TOKEN',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    NUEVO_TOKEN:'NUEVO_TOKEN',
    ACTUALIZAR_ROLES:'ACTUALIZAR_ROLES',

    GETLOGUEADO_SUCCESS:'GETLOGUEADO_SUCCESS',
    SUSCRIPCION_CANCELADA:'SUSCRIPCION_CANCELADA',
    
    ACTUALIZAR_USUARIO_SUCCESS: 'ACTUALIZAR_USUARIO_SUCCESS',
    ACTUALIZAR_USUARIO_ERROR: 'ACTUALIZAR_USUARIO_ERROR',
    ACTUALIZAR_USUARIO_ADM:'ACTUALIZAR_USUARIO_ADM',
    SUSCRIPCION_CANCELADA_USER:'SUSCRIPCION_CANCELADA_USER',

    GUARDAR_SUSCRIPCION:'GUARDAR_SUSCRIPCION',
    ELIMINAR_DEFAULT_SUSCRIPCION:'ELIMINAR_DEFAULT_SUSCRIPCION',
    INFO_GENERAL_PERFIL:'INFO_GENERAL_PERFIL'
}