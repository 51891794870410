import { accionColectivaConstants,userConstants } from '../_constants';


export function accionColectiva(state = {}, action) {
  switch (action.type) { 
    case accionColectivaConstants.ACCION_COLECTIVA_GET_PU_SUCCESS:
      return { 
        ...state,
        misAcciones: action.data.misAccionesColectivas
      };   
    case accionColectivaConstants.ACCION_COLECTIVA_SELECCIONADA:
      return { 
        ...state,
        seleccionado: state.misAcciones[action.seleccionado]
    };

    case accionColectivaConstants.AGREGAR_ACCION_COLECTIVA_GENERADA:
        return {
          ...state,
          misAcciones: state.misAcciones.concat(action.data.accionColectiva)         
        };
    case accionColectivaConstants.TOOGLE_FAVORITO:
      return {
        ...state,       
        misAcciones: state.misAcciones.map(accionColectiva => {
          if (accionColectiva.id === action.values.id)            
            accionColectiva.favorita=!accionColectiva.favorita          
          return accionColectiva;
        })
      };

    case accionColectivaConstants.ACTUALIZAR_ACCION_COLECTIVA:
      return {
        ...state,       
        misAcciones: state.misAcciones.map(accionColectiva => {
          if (accionColectiva.id === action.data.accionColectiva.id) {            
            return action.data.accionColectiva
          }
          return accionColectiva;
        }),
        seleccionado: action.data.accionColectiva
      };
    case accionColectivaConstants.ACCION_COLECTIVA_ELIMINADA:
      return {
        ...state,
        misAcciones: state.misAcciones.filter(accionColectiva => accionColectiva.id !== action.accionColectivaLlegada.id)
      };      
      
    case accionColectivaConstants.EJECUTAR_ACCION_COLECTIVA:
      return {
        ...state       
      };        
    case userConstants.LOGOUT:
      return {};
          
    default:
      return state
  }
}