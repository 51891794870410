import { programaConstants } from '../_constants';
import { programaService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const programaActions = {
    getProgramasPU,
    agregarProgramaGenerado,
    eliminarPrograma,
    agregarTareaGenerada,
    eliminarTarea,
    ejecutar,
    cancelarEjecucion,
    getProgramaPorId,
    continuarEjecucion,
    agregarAccionTarea,
    agregarEventoGenerado,
    removerEventoTarea,
    intercambiarTarea,
    actualizarPrograma
};



function getProgramasPU() {
    return dispatch => {
        programaService.getProgramasPU()
            .then(
                data => { 
                    data.misProgramas.sort(function(a, b){
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    })

                    data.misProgramas.forEach(prog => {
                        if(prog.estado.id===programaConstants.ESTADO_EJECUTANDO){
                            prog.historial.forEach(historial => {
                                if(historial.fechafinalizacion===undefined)
                                  prog.ejecucionActual = historial;
                            });
                        }

                        prog.tareas.sort(function(a, b){
                            if(a.orden < b.orden) { return -1; }
                            if(a.orden > b.orden) { return 1; }
                            return 0;
                        })
                    });
                 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.PROGRAMA_GET_PU_SUCCESS, data } }
}



function intercambiarTarea(idTarea,idPrograma) {
    return dispatch => {
        programaService.intercambiarTarea(idTarea,idPrograma)
            .then(
                data => { 
                    data.programa.tareas.sort(function(a, b){
                        if(a.orden < b.orden) { return -1; }
                        if(a.orden > b.orden) { return 1; }
                        return 0;
                    })
                    dispatch(success(data));            
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }
}

function agregarProgramaGenerado(data,esNuevo) {
    return dispatch => {
        if(esNuevo)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA,data} }
    function success(data) { return { type: programaConstants.AGREGAR_PROGRAMA_GENERADO,data} }
}

function eliminarPrograma(programaLlegado,exito) {
    return dispatch => {
        programaService.eliminarPrograma(programaLlegado)
            .then(
                programa => { 
                    dispatch(success(programaLlegado));
                    history.push('/home/misProgramas') ;
                    exito();                 
                },
                error => {    
                    dispatch(alertActions.error(error));                    
                }
            );
    };

    function success(programaLlegado) { return { type: programaConstants.PROGRAMA_ELIMINADO, programaLlegado } }
}

function agregarEventoGenerado(data,esNuevo,idTarea,idPrograma) {
    return dispatch => {    
        data= Object.assign(data, {'idTarea':idTarea});  
        data= Object.assign(data, {'idPrograma':idPrograma});  
        if(esNuevo)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: programaConstants.ACTUALIZAR_EVENTO_TAREA,data} }
    function success(data) { return { type: programaConstants.AGREGAR_EVENTO_TAREA,data} }
}

function removerEventoTarea(idEvento,idTarea,idPrograma) {
    const data={idEvento:idEvento,idTarea:idTarea,idPrograma:idPrograma}
    return dispatch => {
        programaService.eliminarEventoTarea(data)
            .then(
                evento => {                     
                    dispatch(success(data));                
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.EVENTO_TAREA_ELIMINADO, data } }
}

function agregarTareaGenerada(data,esNuevo) {
    return dispatch => {
        if(esNuevo)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: programaConstants.ACTUALIZAR_TAREA,data} }
    function success(data) { return { type: programaConstants.AGREGAR_TAREA_GENERADA,data} }
}

function agregarAccionTarea(data) {
    return dispatch => {        
        dispatch(success(data));     
    };

    function success(data) { return { type: programaConstants.AGREGAR_ACCION_TAREA,data} }
}



function eliminarTarea(tareaLlegada) {
    return dispatch => {
        programaService.eliminarTarea(tareaLlegada)
            .then(
                tarea => { 
                    dispatch(success(tareaLlegada));                
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(tareaLlegada) { return { type: programaConstants.TAREA_ELIMINADA, tareaLlegada } }
}

function ejecutar(programaLlegado,tareaComienza) {
    return dispatch => {
        programaService.ejecutar(programaLlegado.id,tareaComienza)
            .then(
                data => { 
                    if(data.programa.estado.id===programaConstants.ESTADO_EJECUTANDO){
                        data.programa.historial.forEach(historial => {
                            if(historial.fechafinalizacion===undefined)
                                 data.programa.ejecucionActual = historial;
                        });
                    }

                    data.programa.tareas.sort(function(a, b){
                        if(a.orden < b.orden) { return -1; }
                        if(a.orden > b.orden) { return 1; }
                        return 0;
                    })
                    
                    dispatch(success(data));          
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }
}

function continuarEjecucion(programa) {
    return dispatch => {
        programaService.continuarEjecucion(programa.id)
            .then(
                data => { 
                    data.programa.tareas.sort(function(a, b){
                        if(a.orden < b.orden) { return -1; }
                        if(a.orden > b.orden) { return 1; }
                        return 0;
                    })
                    if(data.programa.estado.id===programaConstants.ESTADO_EJECUTANDO){
                        data.programa.historial.forEach(historial => {
                            if(historial.fechafinalizacion===undefined)
                                 data.programa.ejecucionActual = historial;
                        });
                    }
                    dispatch(success(data));          
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }
}

function cancelarEjecucion(programa) {
    return dispatch => {
        programaService.cancelarEjecucion(programa)
            .then(
                data => { 
                    data.programa.tareas.sort(function(a, b){
                        if(a.orden < b.orden) { return -1; }
                        if(a.orden > b.orden) { return 1; }
                        return 0;
                    })
                    dispatch(success(data));          
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }
}



function actualizarPrograma(programa) {
    return dispatch => {
       let data={programa:JSON.parse(programa)};
            data.programa.tareas.sort(function(a, b){
                if(a.orden < b.orden) { return -1; }
                if(a.orden > b.orden) { return 1; }
                return 0;
            })

            if(data.programa.estado.id===programaConstants.ESTADO_EJECUTANDO){
                data.programa.historial.forEach(historial => {
                    if(historial.fechafinalizacion===undefined)
                            data.programa.ejecucionActual = historial;
                });
            }
            dispatch(success(data));          
        }
    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }    
};

function getProgramaPorId(idPrograma) {
    return dispatch => {
        programaService.getProgramaPorId(idPrograma)
            .then(
                data => { 
                    data.programa.tareas.sort(function(a, b){
                        if(a.orden < b.orden) { return -1; }
                        if(a.orden > b.orden) { return 1; }
                        return 0;
                    })

                    if(data.programa.estado.id===programaConstants.ESTADO_EJECUTANDO){
                        data.programa.historial.forEach(historial => {
                            if(historial.fechafinalizacion===undefined)
                                 data.programa.ejecucionActual = historial;
                        });
                    }
                    dispatch(success(data));          
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: programaConstants.ACTUALIZAR_PROGRAMA, data } }
}
