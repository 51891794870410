import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Container} from 'react-bootstrap';
import { productoConstants } from '../../_constants';
import { productoActions, utilActions } from '../../_actions';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import ScriptTag from 'react-script-tag';
import { CompraDP,ESTADO_COMPRA, TIPO_COMPRA } from '../../_helpers';
import { FormModificarCompra } from './FormModificarCompra';
import { FormConsulta, InfoConsultaUser } from '../Perfil';
import Moment from 'react-moment';
import { FormContratarSuscrip } from './FormContratarSuscrip';



class MisCompras extends React.Component {
    
    state={      
        show:false,
        compra:{},
        showChat:false,
        showSuscripcion:false
     }
    componentDidMount(){
      if(this.props.misCompras===undefined)this.props.traerMisCompras();
    }

    handleClose=()=>{
        this.setState({
            show:false,
            showChat:false,
            showSuscripcion:false
        })
    }
    
    modificarCompraSuscripcion=(compra)=>{
        this.setState({
            compra:compra,
            showSuscripcion:true
        })
    }

    modificarCompra=(compra)=>{
        this.setState({
            compra:compra,
            show:true
        })
    }

    iniciarChat = (row)=>{
        this.setState({
            compra:row,
            consulta:row.pedidoSt,
            showChat:true
        })
    }

    parseNombreCuotas =(cantidad)=>{
        if(cantidad===1)return 'Mensual';
        if(cantidad===3)return 'Trimestral';
        if(cantidad===6)return 'Semestral';
        if(cantidad===12)return 'Anual';
        return '';
    }

    parseNombreCuotasCheto =(cantidad)=>{
        if(cantidad===1)return ' mensuales';
        if(cantidad===3)return ' trimestrales';
        if(cantidad===6)return ' semestrales';
        if(cantidad===12)return ' anuales';
        return '';
    }

    render(){

        const { misCompras } = this.props;
        const header = [            
            { title: 'Fecha compra',
            render: row =>               
                new Date(row.fechaCreada).toLocaleDateString('es',{ year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit' })                
            }, 
            { title: 'Estado', field: 'estadoCompra.descripcion'},
            { title: 'Tipo compra',
            render: row =>  
                row.tipoCompra.descripcion +' '+ this.parseNombreCuotas(row.itemsCompra[0].cantidad)
            }, 
            { title: 'Medio de pago', field: 'medioPago.descripcion'},   
            //{ title: 'Envio', field: 'tipoEnvio.descripcion'},    
            { title: 'Total',
            render: row =>  
                <div>     
                    <NumberFormat prefix={'$'} value= {row.totalCompra } displayType={'text'} thousandSeparator="." decimalSeparator="," />  
                    {this.parseNombreCuotasCheto(row.itemsCompra[0].cantidad)}  
                </div> 
            },
            {
                title: 'Acciones',
                render: row =>  
                    <div>     
                        <button className="text-dark btns btns-iot" onClick={()=>this.iniciarChat(row)}><i className="material-icons-outlined icon-image-preview align-top">chat</i> Iniciar chat</button>
                        {(row.tipoCompra.id ===TIPO_COMPRA.PRODUCTO && row.estadoCompra.id===ESTADO_COMPRA.EN_ESPERA) &&
                            <button className="text-dark btns btns-arandano" onClick={()=>this.modificarCompra(row)}> Cambiar datos de la compra</button>
                        }
                        {(row.tipoCompra.id ===TIPO_COMPRA.PRODUCTO && row.medioPago.id===productoConstants.MEDIO_PAGO_MERCADOPAGO &&
                        row.estadoCompra.id===ESTADO_COMPRA.EN_ESPERA )&&
                         <form action="http://localhost:3000/tienda#carritoCompra" method="POST" id={row.id+"mercadoForm"}>
                            <ScriptTag src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js" async={true} data-preference-id={row.refMercadoPago} />                             
                         </form>} 

                         {(row.tipoCompra.id ===TIPO_COMPRA.LICENCIA && 
                             (row.modoGratuita || row.estadoCompra.id===ESTADO_COMPRA.FINALIZADA || row.estadoCompra.id===ESTADO_COMPRA.CANCELADA || row.estadoCompra.id===ESTADO_COMPRA.EN_ESPERA) )&&
                             <button className="text-dark btns btns-arandano" onClick={()=>this.modificarCompraSuscripcion(row)}> Iniciar pago</button>
                         } 
                          {(row.tipoCompra.id ===TIPO_COMPRA.LICENCIA && 
                             (row.estadoCompra.id===ESTADO_COMPRA.CANCELACION_SOLICITADA) )&&
                             <button className="text-dark btns btns-arandano" onClick={()=>this.modificarCompraSuscripcion(row)}> Reactivar licencia</button>
                         }
                    </div> 
                 
            }
        ];
        const options={
            search: false,
            sorting: true
        }  
        return (
            <div className="main-content mt-5">
                <Container fluid>            
                <MaterialTable
                    title={"Mis compras"}
                    columns={header}
                    data={misCompras}
                    options={options}
                    localization={utilActions.traerPalabrasTabla()}
                    detailPanel={rowData => {
                        return (
                            <CompraDP rowData={rowData} /> 
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()} 
                />   
                {this.state.show && 
                    <FormModificarCompra show={this.state.show} compra={this.state.compra} handleClose={this.handleClose} />} 

                {this.state.showSuscripcion && 
                    <FormContratarSuscrip show={this.state.showSuscripcion} compra={this.state.compra} suscripcion={this.state.compra.itemsCompra[0].suscripcion} handleClose={this.handleClose} />}   

                {this.state.showChat && <Modal show={this.state.showChat} onHide={this.handleClose}>
                    <Modal.Header className="bg-iot text-dark" closeButton>
                        <Modal.Title><i className="far fa-comments mr-1"></i> <span className=" font-weight-light text-14">Consulta por compra</span>
                        <br/> <span className=" font-weight-bold text-14">{this.state.compra.id}</span> </Modal.Title>
                    </Modal.Header> 
                    <Modal.Body>
                        {this.state.consulta!==undefined &&<InfoConsultaUser  esUser={true} esDeCompra={true} consulta={this.state.consulta} compra={this.state.compra} handleClose={this.handleClose}/>}
                        {this.state.consulta===undefined &&<FormConsulta esDeCompra={true} compra={this.state.compra} handleClose={this.handleClose}/>}
                    </Modal.Body>                     
                </Modal>}  
                </Container>  
            </div> 
        );
    }
}

function mapState(state) {       
    const { misCompras } = state.producto;
    return {misCompras };
}

const actionCreators = {    
    traerMisCompras:productoActions.traerMisCompras
}

const connectedMisCompras = connect(mapState, actionCreators)(MisCompras);
export { connectedMisCompras as MisCompras };

