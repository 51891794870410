import { programaConstants,userConstants,accionColectivaConstants } from '../_constants';

export function programa(state = {}, action) {
  switch (action.type) { 
    case programaConstants.PROGRAMA_GET_PU_SUCCESS:
      return { 
        ...state,
        misProgramas: action.data.misProgramas
      };   
      case programaConstants.PROGRAMA_SELECCIONADO:
        return { 
          ...state,
          seleccionado: action.seleccionado
      };
    case programaConstants.AGREGAR_PROGRAMA_GENERADO:
        return {
          ...state,
          misProgramas: state.misProgramas.concat(action.data.programa)         
        };

        case accionColectivaConstants.TOOGLE_FAVORITO_PROGRAMA:
      return {
        ...state,       
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.values.id)          
            programa.favorita=!programa.favorita 
          return programa;
        })
      };
    case programaConstants.ACTUALIZAR_PROGRAMA:
      return {
        ...state,       
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.data.programa.id) {            
            return action.data.programa
          }
          return programa;
        })
      };
    case programaConstants.PROGRAMA_ELIMINADO:
      return {
        ...state,
        misProgramas: state.misProgramas.filter(programa => programa.id !== action.programaLlegado.id)
      };       
      
    case programaConstants.AGREGAR_TAREA_GENERADA:
      return {
        ...state,
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.data.tarea.programa.id) {   
            programa.cantidadTareas = programa.cantidadTareas+1;
            programa.tareas = programa.tareas?programa.tareas.concat(action.data.tarea):[action.data.tarea];
            return programa;
          }
          return programa;
        })   
      };

    case programaConstants.TAREA_ELIMINADA:
      return {
        ...state,
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.tareaLlegada.idPrograma) {   
            programa.cantidadTareas = programa.cantidadTareas-1;
            programa.tareas=programa.tareas.filter(tarea => tarea.id !== action.tareaLlegada.id)       
            return programa;
          }
          return programa;
        })   
      };  
    case programaConstants.ACTUALIZAR_TAREA:
      return {
        ...state,       
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.data.idPrograma) {               
                let tareasMap= programa.tareas.map(tareaTemp => {
                if (tareaTemp.id === action.data.tarea.id) {  
                  return action.data.tarea;
                }
                return tareaTemp;
              })
              programa.tareas = tareasMap;          
            return programa;
          }
          return programa;
        })
      };

      case programaConstants.EVENTO_TAREA_ELIMINADO:
      return {
        ...state,
        misProgramas: state.misProgramas.map(programa => {
          if (programa.id === action.data.idPrograma) {               
            let tareasMap= programa.tareas.map(tareaTemp => {
            if (tareaTemp.id === action.data.idTarea) {  
              tareaTemp.eventos=tareaTemp.eventos.filter(evento => evento.id !== action.data.idEvento) 
            }
            return tareaTemp;
          })
          programa.tareas = tareasMap;          
        return programa;
      }
          return programa;
        })   
      };  
      

      case programaConstants.ACTUALIZAR_EVENTO_TAREA:
        return {
          ...state,       
          misProgramas: state.misProgramas.map(programa => {
            if (programa.id === action.data.idPrograma) {               
                  let tareasMap= programa.tareas.map(tareaTemp => {
                  if (tareaTemp.id === action.data.idTarea) {  
                    let eventosMap= tareaTemp.eventos.map(eventoTemp => {
                      if (eventoTemp.id === action.data.evento.id) {  
                        return action.data.evento;
                      }
                      return eventoTemp;
                    })
                    tareaTemp.eventos=eventosMap;
                  }
                  return tareaTemp;
                })
                programa.tareas = tareasMap;          
              return programa;
            }
            return programa;
          })
        };
  
        case programaConstants.AGREGAR_EVENTO_TAREA:
        return {
          ...state,
          misProgramas: state.misProgramas.map(programa => {
            if (programa.id === action.data.idPrograma) {   
                  let tareasMap= programa.tareas.map(tareaTemp => {
                    if (tareaTemp.id === action.data.idTarea) {  
                      tareaTemp.eventos = tareaTemp.eventos?tareaTemp.eventos.concat(action.data.evento):[action.data.evento];
                    }
                    return tareaTemp;
                  })
                  programa.tareas = tareasMap;          
                return programa;
            }
            return programa;
          })   
        };

        case programaConstants.AGREGAR_ACCION_TAREA:
          return {
            ...state,
            misProgramas: state.misProgramas.map(programa => {
              if (programa.id === action.data.idPrograma) {   
                    let tareasMap= programa.tareas.map(tareaTemp => {
                      if (tareaTemp.id === action.data.idTarea) { 
                        if(action.data.tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL) 
                          tareaTemp.accionesIniciales = action.data.acciones
                        if(action.data.tipoAccion===programaConstants.TIPO_ACCION_TAREA_FINAL) 
                          tareaTemp.accionesFinales = action.data.acciones  
                      }
                      return tareaTemp;
                    })
                    programa.tareas = tareasMap;          
                  return programa;
              }
              return programa;
            })   
          };

      
    case userConstants.LOGOUT:
      return {};
          
    default:
      return state
  }
}