import React from 'react';
import {Field,getIn } from 'formik';
import {productoConstants, sensorConstants} from '../_constants';
import {Col, Container, Row, Table,Badge} from 'react-bootstrap';
import BeatLoader from "react-spinners/BeatLoader";
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import ReactSpeedometer from 'react-d3-speedometer';
import LiquidFillGauge from 'react-liquid-gauge';
import Thermometer from 'react-thermometer-component';
import { color } from 'd3-color';
import MapClassMarker from '../_components/Sensor/widgets/MapClassMarker';
import Slider from 'react-input-slider';
import { GraficoSimple } from '../_components/Sensor/widgets/GraficoSimple';
export const ErrorMessage = ({ name }) => (
    <Field
      name={name}
    >
      {({ form}) => {  
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);    
        return touch && error?(
            <div className="help-block text-danger">
            <i className="fas fa-exclamation-circle"
            ></i> {typeof error === 'string' ?error:''}</div> 
        ):null;
      }}</Field>
  );

  export const LoadingBoton = ({ grabando }) => (
   
    <div className="sweet-loading">
      <BeatLoader  
        size={10}
        color={"#FFFFFF"}
        loading={grabando}
      />
    </div>
  );

  const esParseable=(sensor)=>{
      let returnValue =true;

      try{
        const lat = sensor.ultimoValor[sensor.variableJson];
        const long = sensor.ultimoValor[sensor.variableLongitud];        
        if(lat===undefined || long===undefined)returnValue=false;
      }catch(err){
        returnValue=false
      }
      return returnValue;
  }

  export const enviarASwitchear=(rowData)=>{
    if(!rowData.sensor.conectado)return;
    rowData.enviarASensor(rowData.sensor,'',undefined,true);
  }

  export const Graficador = ( rowData ) => (
  
      <div className="text-center">  
      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_TOOGLE && !rowData.soloLectura) &&
        <div className={rowData.valor==rowData.sensor.valorEncendido? 'mainbox active onoff': 'mainbox onoff'}>                                    
            <button className={rowData.valor==rowData.sensor.valorEncendido? 'btns  pulse-button text-center': 'btns btns-silver text-center'} size="sm" block="true" onClick={() => enviarASwitchear(rowData)}>
                {rowData.valor==rowData.sensor.valorEncendido? <><i className="fas fa-pause text-dark txt-18"></i></>: <><i className="fas text-dark fa-power-off txt-18"></i></>}
            </button>
        </div>
      }

      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_TOOGLE && rowData.soloLectura) &&
        <div className={'mainbox active onoff'}>                                    
            <button className={'btns  pulse-button text-center'} size="sm" block="true">
                {<i className="fas fa-pause txt-18"></i>}
            </button>
        </div>
      }

      

      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_MAPA && !rowData.soloLectura && esParseable(rowData.sensor)) &&        
         <MapClassMarker sensor={rowData.sensor}
         width="100%"
         height={rowData.height} />
      }
      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_MAPA && rowData.soloLectura) &&        
        <><section id="location-anim">
        <div id="dot" className="centered"><span className="material-icons">place</span></div>
        <div id="outer-circle" className="circle centered">
        <div id="inner-circle" className="circle centered"></div>
          </div>
      </section></>
      }

      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_DIMMER && rowData.soloLectura) &&        
        <Slider
          axis="x"
          xmin={0}
          xmax={100}
          x={50}
          styles={{
            active: {
              backgroundColor: '#08dbb2'
            },
          }}
        />
      } 

      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_DIMMER && !rowData.soloLectura) &&        
        <div>
         <span className="text-white font-weight-bold mr-1">{rowData.valorMinReferencia}</span>
            <Slider
              axis="x"
              xmin={parseFloat(rowData.valorMinReferencia)}
              xmax={parseFloat(rowData.valorMaxReferencia)}
              x={parseFloat(rowData.valor)}
              onChange={({ x }) => rowData.updateDimmer(x)}
              styles={{
            active: {
              backgroundColor: '#08dbb2'
            },
          }}
            />
        <span className="text-white font-weight-bold ml-1">{rowData.valorMaxReferencia}</span>
        </div>
      } 
        {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_GRAFICO && !rowData.soloLectura) &&
        <div>                                    
             <GraficoSimple sensor={rowData.sensor}
              modoSuperSimple={rowData.modoSuperSimple}
              layouts={rowData.layouts}/>
        </div>
      }

      {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_GRAFICO && rowData.soloLectura) &&
        <div className="iconi charti">
        <i></i>
        <i></i>
        <i></i>
      </div>
      }
        {rowData.tipoWidget===sensorConstants.TIPO_WIDGET_TEXTO &&
         <span className="texto-widget txt-42 font-weight-bold"> 
         {(typeof(rowData.valor)==='string' || typeof(rowData.valor)==='number')&&rowData.valor}
         {(typeof(rowData.valor)==='object')&&
                            <>
                             {rowData.valor?`Lat:${rowData.valor[rowData.sensor.variableJson]}
                             Long:${rowData.valor[rowData.sensor.variableLongitud]}`:''}
                             </>
                             }
         </span>
        }   
         {(rowData.tipoWidget!==sensorConstants.TIPO_WIDGET_TEXTO && rowData.tipoWidget!==sensorConstants.TIPO_WIDGET_TOOGLE && rowData.tipoWidget!==sensorConstants.TIPO_WIDGET_MAPA  && isNaN(rowData.valor)) &&
            <Badge className="bg-danger text-white txt-16">
              <span className="material-icons material-icons-outlined aling-middle">error_outline</span> 
              Valor recibido no numérico
            </Badge>
          }   
           {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_TACOMETRO && !isNaN(rowData.valor)) &&
              <ReactSpeedometer
              segments={10}
              width={rowData.width}
              height={rowData.height}
              minValue={parseFloat(rowData.valorMinReferencia)}
              maxValue={parseFloat(rowData.valorMaxReferencia)}
              value={parseFloat(rowData.valor)}
              currentValueText={rowData.unidad}
              startColor="LightGrey"
              endColor="LightSlateGrey"
              forceRender={false}
            />
            
           
         } 
            {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_TERMOMETRO && !isNaN(rowData.valor)) &&
              <Thermometer
                value={parseFloat(rowData.valor)}
                min={parseFloat(rowData.valorMinReferencia)}
                max={parseFloat(rowData.valorMaxReferencia)}
                steps="2"
                format="°C"
                size="medium"
                height={rowData.height}
              />
             
             }
             {(rowData.tipoWidget===sensorConstants.TIPO_WIDGET_LIQUIDO && !isNaN(rowData.valor)) &&
              <LiquidFillGauge
                  style={{ margin: '0 auto' }}
                  width={rowData.width}
                  height={rowData.height}
                  value={parseFloat(rowData.valor)}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  textRenderer={(props) => {
                      const value = Math.round(props.value);
                      const radius = Math.min(props.height / 2, props.width / 2);
                      const textPixels = (props.textSize * radius / 2);
                      const valueStyle = {
                          fontSize: textPixels
                      };
                      const percentStyle = {
                          fontSize: textPixels * 0.6
                      };

                      return (
                          <tspan>
                              <tspan className="value" style={valueStyle}>{value}</tspan>
                              <tspan style={percentStyle}>{props.percent}</tspan>
                          </tspan>
                      );
                  }}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
              
                waveStyle={{
                    fill: 'silver'
                }}
                  textStyle={{
                      fill: color('#9b9ea1').toString(),
                      fontFamily: 'Oswald'
                  }}
                  waveTextStyle={{
                      fill: color('#fff').toString(),
                      fontFamily: 'Oswald'
                  }}
              />
             }
      </div>  
     
  ); 


  export const UserDP = ({ rowData }) => (
    <Container fluid>    
      <Row className="bg-silver">
          <Col xs={3}>
              <h5><span className="badge badge-secondary font-weight-light">Equipos</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                   {rowData.equipos!==undefined && rowData.equipos.map((eq, index)=> 
                  <tr key={eq.id}> 
                      <td>
                        {eq.id}
                      </td>
                        <td>
                            {eq.nombre}
                            </td>
                        </tr>
                    )}
                </tbody>
               </Table>                  
          </Col>
          <Col xs={9}>
              <h5><span className="badge badge-secondary font-weight-light">¿Es usuario dueño de licencia?</span>{rowData.tieneUsuarioPadre?'No':'Si'}</h5>
              {!rowData.tieneUsuarioPadre &&<Table responsive>
                        <thead>
                            <tr>
                                <th>ID usuario hijo</th>
                                <th>Username hijo</th>
                            </tr>
                        </thead>
                        <tbody>
                   {rowData.usuariosHijos!==undefined && rowData.usuariosHijos.map((user, index)=> 
                  <tr key={user.id}> 
                      <td>
                        {user.id}
                      </td>
                        <td>
                          {user.username}
                          </td>
                      </tr>
                    )}
                </tbody>
               </Table>}                  
          </Col>    

          <Col xs={12}>
              <h5><span className="badge badge-secondary font-weight-light">Datos de token</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Estado</th>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Desde</th>
                                <th>Hasta</th>
                                <th>Dispositivos</th>
                                <th>Widgets</th>
                                <th>Usuarios</th>
                                <th>Dispositivos asignados</th>
                                <th>Widgets asignados</th>
                                <th>Usuarios asignados</th>
                            </tr>
                        </thead>
                        <tbody>                 
                     {rowData.tokenActivo&& <tr > 
                          <td>
                            {rowData.tokenActivo.id}
                          </td>
                          <td>
                             {rowData.tokenActivo.estado.descripcion}
                          </td>
                          <td>
                             {rowData.tokenActivo.tipoSuscripcion.descripcion}
                          </td>
                          <td>
                              <Moment date={rowData.tokenActivo.fechaCreada} format="DD/MM/YYYY hh:mm"/>    
                          </td>
                          <td>
                          <Moment date={rowData.tokenActivo.fechaDesde} format="DD/MM/YYYY hh:mm"/> 
                          </td>
                          <td>
                          <Moment date={rowData.tokenActivo.fechaHasta} format="DD/MM/YYYY hh:mm"/> 
                          </td>
                          <td>
                            {rowData.tokenActivo.cantidadDispositivos}
                          </td>
                          <td>
                            {rowData.tokenActivo.cantidadSensores}
                          </td>
                          <td>
                            {rowData.tokenActivo.cantidadUsuarios}
                          </td>
                          <td>
                            {rowData.tokenActivo.cantEquiposAsignados}
                          </td>
                          <td>
                            {rowData.tokenActivo.cantSensoresAsignados}
                          </td>
                          <td>
                            {rowData.tokenActivo.cantUsuariosAsignados}
                          </td>
                        </tr>}
                </tbody>
               </Table>                  
          </Col>    
      </Row>       
  </Container>  
  ); 

  export const EquipoDP = ({ rowData }) => (
    <Container fluid>    
      <Row className="bg-silver">     
          <Col xs={12}>
               <h5><span className="badge badge-secondary font-weight-light">Sensores</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Label</th>
                                <th>Tipo</th>
                                <th>Widget</th>
                                <th>Dato</th>
                                <th>Nexo</th>
                                <th>V. End</th>
                                <th>V. Apa</th>
                                <th>V. Max</th>
                                <th>V. min</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.sensores!==undefined && rowData.sensores.map((item, index)=>                                           
                                <tr key={'eq'+index}>
                                   <td>
                                     {item.id}    
                                   </td>
                                   <td>
                                      {item.labelUsuario}
                                   </td>
                                    <td>
                                      {item.tipoSensor.descripcion}
                                   </td>
                                   <td>
                                      {item.tipoWidget.descripcion}
                                   </td>
                                   <td>
                                      {item.tipoDato.descripcion}
                                   </td>
                                   <td>
                                   {item.nombreNexo}
                                   </td>
                                   <td>
                                   {item.valorEncendido}
                                   </td>
                                   <td>
                                   {item.valorApagado}
                                   </td>
                                   <td>
                                   {item.valorMaxReferencia}
                                   </td>
                                   <td>
                                   {item.valorMinReferencia}
                                   </td>
                                </tr>
                            )}
                        </tbody>
               </Table>             
          </Col>
          <Col xs={2}>
            <h5><span className="badge badge-secondary font-weight-light">Topic: {rowData.topic}</span></h5>
      </Col>    
      </Row>       
  </Container>  
  ); 
  

  export const CompraDP = ({ rowData,esAdmin }) => (
    <Container fluid>  
   {!esAdmin && <Row className="bg-silver">              
          <Col xs={12}>
          <h5><span className="badge badge-secondary font-weight-light">
          ID: <b>{rowData.id}</b>
            </span></h5>
            
            </Col>  
    </Row>}          
      <Row className="bg-silver">              
          <Col xs={12}>         
              <h5><span className="badge badge-secondary font-weight-light">Pagos recibidos</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Motivo</th>
                                <th>Origen</th>
                                <th>Monto</th>
                                {esAdmin &&<th>Monto Final recibido</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.pagos!==undefined && rowData.pagos.map((item, index)=>                                           
                                <tr key={'eq'+index}>
                                   <td>
                                      <Moment date={item.fecha} format="DD/MM/YYYY hh:mm"/>    
                                   </td>
                                   <td>
                                      {item.motivo}
                                   </td>
                                    <td>
                                      {item.origenPago.descripcion}
                                   </td>
                                   <td>
                                      {item.monto}
                                   </td>
                                   {esAdmin &&<td>
                                      {item.montoFinalRecibido}
                                   </td>}
                                </tr>
                            )}
                             {(rowData.pagos===undefined || rowData.pagos.length===0)&&                                       
                                <tr>
                                   <td colSpan="4"> No existen pagos registrados</td>
                                </tr>
                              }
                        </tbody>
               </Table>

          </Col>    

          {esAdmin && 
          <Col xs={6}>
            <h5><span className="badge badge-secondary font-weight-light">Suscripciones token asociados</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Estado</th>
                                <th>Fecha hasta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.suscripcionesToken!==undefined && rowData.suscripcionesToken.map((sus, index)=>                                           
                                <tr key={'sus'+index}>
                                   <td>
                                      {sus.id}
                                   </td>
                                   <td>
                                      {sus.estado.descripcion}
                                   </td>
                                   <td>
                                      <Moment date={sus.fechaHasta} format="DD/MM/YYYY hh:mm"/>      
                                   </td>
                                </tr>
                            )}
                        </tbody>
               </Table></Col>}
          
          {esAdmin && 
          <Col xs={6}>
            <h5><span className="badge badge-secondary font-weight-light">Equipos asignados</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.equipos!==undefined && rowData.equipos.map((item, index)=>                                           
                                <tr key={'eq'+index}>
                                   <td>
                                      {item.id}
                                   </td>
                                   <td>
                                      {item.nombre}
                                   </td>
                                </tr>
                            )}
                        </tbody>
               </Table></Col>}
               <Col xs={6}>
               <h5><span className="badge badge-secondary font-weight-light">Detalles compra</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>Cantidad</th>
                                <th>Producto</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.itemsCompra!==undefined && rowData.itemsCompra.map((item, index)=>                                           
                                <tr key={'com'+index}>
                                   <td>
                                      {item.cantidad}
                                   </td>

                                    {item.tipoCompra.id===TIPO_COMPRA.LICENCIA && 
                                    <>
                                      <td>
                                        {item.suscripcion.nombre}
                                      </td>
                                      <td>
                                      <NumberFormat prefix={'$'} value=  {item.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," /> 
                                       
                                      </td>  
                                    </>}  
                                    {item.tipoCompra.id===TIPO_COMPRA.PRODUCTO && 
                                    <>
                                      <td>
                                        {item.producto.nombre}
                                      </td>
                                      <td>
                                      <NumberFormat prefix={'$'} value=  {item.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," /> 
                                      
                                      </td>  
                                    </>}                              
                                </tr>
                            )}
                        </tbody>
               </Table>
          </Col>   

          <Col xs={6}>
               <h5><span className="badge badge-secondary font-weight-light">Movimientos</span></h5>
               <Table responsive>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Estado</th>
                                <th>Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                        {rowData.logs!==undefined && rowData.logs.map((log, index)=> 
                          <tr key={'com3'+index}>
                              <td>
                              <Moment date={log.fecha} format="DD/MM/YYYY hh:mm"/>      
                              </td>
                              <td>
                              {log.estado?log.estado.descripcion:''} 
                              </td>
                              <td>
                              {log.descripcion}     
                              </td>
                          </tr>           
                        )}   
                        </tbody>
               </Table>
              </Col>
              <Col xs={12}>
               <Row>
                  <Col xs={12}>
                        {rowData.estadoCompra.id===ESTADO_COMPRA.EN_ESPERA &&
                          <>
                            {rowData.medioPago.id===productoConstants.MEDIO_PAGO_EFECTIVO &&
                                <span>
                                  Estamos esperando a que vengas a retirar el producto para abonarlo!
                                </span>
                            }
                             {rowData.medioPago.id===productoConstants.MEDIO_PAGO_TRANSFERENCIA &&
                                <span>
                                  Estamos esperando la transferencia para confirmar el producto.
                                </span>
                            }
                             {rowData.medioPago.id===productoConstants.MEDIO_PAGO_MERCADOPAGO &&
                                <span>
                                  Estamos esperando que realices el pago por mercado pago.
                                </span>
                            }
                          </>
                        }

                        {(rowData.estadoCompra.id===ESTADO_COMPRA.PAGADA && rowData.tipoCompra.id===TIPO_COMPRA.PRODUCTO) &&
                          <>
                            {rowData.tipoEnvio.id===productoConstants.TIPO_ENVIO_CORREO &&
                                <span>
                                    Estamos preparando el producto para enviartelo!
                                </span>
                            }
                             {rowData.tipoEnvio.id===productoConstants.TIPO_ENVIO_RETIRO &&
                                <span>
                                  Estamos esperandote para que lo retires en la casa del poli.
                                </span>
                            }
                          </>
                        }

                        {rowData.estadoCompra.id===ESTADO_COMPRA.ENVIADA &&
                          <>
                            {rowData.tipoEnvio.id===productoConstants.TIPO_ENVIO_CORREO &&
                                <span>
                                    El producto esta viajando a tu domicilio
                                </span>
                            }
                          </>
                        }

                        {rowData.estadoCompra.id===ESTADO_COMPRA.ENTREGADA &&
                          <>
                            <span>
                                Ya tienes el producto! Empieza a utilizarlo, cualquier duda puedes contactarte con nosotros
                            </span>
                          </>
                        }
                  </Col>   
               </Row>
          </Col> 
      </Row>       
  </Container>  
  );  

  export const alertConfirm = (titulo,mensajeCuerpo,textoBotonSi,textoBotonNo,cancel,confirm,elementOnConfirm,simple,tipo=null,showConfirm=true) => {
    return(
   
          <div className="th_warning">
          <SweetAlert
            type={tipo?tipo:'warning'}
            showCancel={true}
            showConfirm={showConfirm}
            showCloseButton={true}
            closeOnClickOutside={false}
            title={titulo}
            confirmBtnText={textoBotonSi}
            cancelBtnText={textoBotonNo}
            onCancel={()=>cancel()}
            onConfirm={() => simple?confirm():confirm(elementOnConfirm,cancel)}
          >
              <hr></hr>
              <div className="text-dark">
                  {mensajeCuerpo}
              </div>
              <hr></hr>

          </SweetAlert>
          </div>
        )};


export const infoTablaDefault={
  search: '',
  page: 0,
  limit: 5,
  skip:0,
  totalCount: 0,
  orderBy:'',
  order:''
}
      
      

export const ERR = {
    REQ: 'Requerido',
    NUM: "Solo números, decimales separados por '.'",
    MIN: 'Minimo',
    MAX: 'Máximo'
}


export const TIPO_DATO = {
  PLANO: 1,
  JSON: 2 
}

export const TIPO_EVENTO = {
  AUTOMATICO: 1,
  PROGRAMADO: 2 
}

export const TIPO_COMPRA = {
  LICENCIA: 1,
  PRODUCTO: 2 
}

export const ESTADO_COMPRA = {
  EN_ESPERA: 1,
  PAGADA: 2,
  CANCELADA: 3 ,
  ENVIADA: 4 ,
  ENTREGADA: 5 ,
  CANCELACION_SOLICITADA: 6,
  FINALIZADA : 7,
  ACTIVADA:8
}

export const PERIODO = {
  DIARIO: 1,
  SEMANAL: 2 
}

export const TIPO_ACCION = {
  NOTIFICACION: 1,
  COLECTIVA: 2 ,
  PARTICULAR: 3
}

export const TIPO_VISTA = {
  EQUIPO: 1,
  VISTA: 5 
}

export const MODO_LAYOUT = {
  PC: 1,
  CELU_MINI: 2,
  CELU_LARGE:3
}


export const TIPO_EQUIPO = {
  PLACA: 1,
  DESARROLLADOR: 2 
}


export const TIPO_INFORMACION = {
  INICIAL: 5,
  FINAL: 6,
  EVENTO:7
}

export const TIPO_NOTIFICACION = {
  TELEGRAM: 5
}

export const ESTADO_TOKEN = {
  ACTIVO: 1,
  FINALIZADO: 2
}

export const WIDGET_TAMANIO={
  UnoUno:{id:1,w:6,h:1},
  UnoDos:{id:2,w:12,h:1},
  DosUno:{id:3,w:6,h:2},
  DosDos:{id:4,w:12,h:2}
}




export const DIAS_SEMANA = [{id:0,descripcion:'Domingo'},{id:1,descripcion:'Lunes'},{id:2,descripcion:'Martes'},{id:3,descripcion:'Miercoles'},{id:4,descripcion:'Jueves'},{id:5,descripcion:'Viernes'},{id:6,descripcion:'Sabado'}];