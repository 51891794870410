import { consultaConstants,userConstants } from '../_constants';


export function consultas(state = {}, action) {
  switch (action.type) { 
    case consultaConstants.CONSULTAS_GET_PU:
      return { 
        ...state,
        misConsultas: action.data.misPedidos
      }; 
        case consultaConstants.TIPOS_PROBLEMA:
          return { 
            ...state,
            tiposProblema: action.data.tiposProblema
          }; 
        
      case consultaConstants.MENSAJE_USUARIO:
        return {
          ...state,
          misConsultas: state.misConsultas.map(consulta => {           
              if (consulta.id===action.id){                
                  consulta.mensajes = consulta.mensajes.concat(action.data.mensaje)                      
              }             
              return consulta;
          })
        };

        case consultaConstants.TOOGLE_GLOBO:
          return {
            ...state,
            misConsultas: state.misConsultas.map(consulta => {           
                if (consulta.id===action.id)   
                    consulta.globoAbierto=!consulta.globoAbierto;   
                return consulta;
            })
          };

          case consultaConstants.CERRAR_GLOBO:
            return {
              ...state,
              misConsultas: state.misConsultas.map(consulta => {           
                  consulta.globoAbierto=false;   
                  return consulta;
              })
            };
        
        case consultaConstants.SELECCIONAR_CONSULTA_BURBUJA:
          return {
            ...state,
            misConsultas: state.misConsultas.map(consulta => {           
                if (consulta.id===action.id){                
                    consulta.globo=true;
                    consulta.globoAbierto=true;                      
                }             
                return consulta;
            })
          };

      case consultaConstants.LIMPIAR_BURBUJAS:
        return {
          ...state,
          misConsultas: state.misConsultas?state.misConsultas.map(consulta => {           
              consulta.globo=false;
              consulta.globoAbierto=false;        
              return consulta;
          }):[]
        };  
      case consultaConstants.ACTUALIZAR_ESTADO_CONSULTA:
          return {
            ...state,
            misConsultas: state.misConsultas.map(consulta => {           
                if (consulta.id===action.id){                
                    consulta.estadoSt=action.nEstado.estado;                
                }             
                return consulta;
            })
      };     
      case consultaConstants.CARGAR_MENSAJES:
        return {
          ...state,
          misConsultas: state.misConsultas.map(consulta => {
              if (consulta.id===action.data.pedido.id){ 
                  let consultaActualizada = action.data.pedido;
                  consultaActualizada.globo=consulta.globo;
                  consultaActualizada.globoAbierto=consulta.globoAbierto;                 
                  return consultaActualizada;                      
              }             
              return consulta;
          })
        };       
        
      case consultaConstants.AGREGAR_NUEVA_CONSULTA:
        return { 
          ...state,
          misConsultas: state.misConsultas?state.misConsultas.concat(action.data.pedido):[action.data.pedido]  
        };  
        case userConstants.LOGOUT:
          return {};    
        
    default:
      return state
  }
}