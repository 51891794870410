import http from '../_helpers/http.service';

export const productoService = {
    traerProductosUser,
    traerSuscripcionUser,
    traerCategoriaProductoUsr,
    traerTipoSuscripcion,
    traerMediosPago,
    generarCompra,
    traerMisCompras,
    modificarCompra,
    traerEStadosCompras,
    contratarSuscripcion,
    contratarSuscripcionPaypal
    
};


function contratarSuscripcion(values) {
   
    var url ='/api/ventas/generarCompraSuscripcion';    
    return http.post(url,JSON.stringify(values));
}

function contratarSuscripcionPaypal(values) {
   
    var url ='/api/ventas/generarCompraSuscripcionPaypal';    
    return http.post(url,JSON.stringify(values));
}




function traerMisCompras() {
   
    var url ='/api/ventas/traerMisCompras';    
    return http.get(url);
}

function traerEStadosCompras() {
   
    var url ='/api/ventas/getEstadosCompra';    
    return http.get(url);
}

function modificarCompra(values) {   
    var url ='/api/ventas/modificarCompra';    
    return http.post(url,JSON.stringify(values));
}

function generarCompra(values) {
       var url ='/api/ventas/generarCompra';    
    return http.post(url,JSON.stringify(values));
}

function traerMediosPago() {   
    var url ='/ventas/traerMediosPago';        
    return http.get(url);
}

function traerTipoSuscripcion() {   
    var url ='/api/ventas/tipoSuscripcion/listado';    
    return http.get(url);
}

function traerCategoriaProductoUsr() {   
    var url ='/ventas/traerCategoriaProductos';        
    return http.get(url);
}

function traerSuscripcionUser() {   
    var url ='/suscripciones/listado';    
    return http.get(url);
}


function traerProductosUser() {   
    var url ='/ventas/traerProductos';    
    return http.get(url);
}

