import React from 'react';
import { connect } from 'react-redux';
import {Row,Modal,Col,Card,Accordion,Badge} from 'react-bootstrap';
import { alertActions,equipoActions,sensorActions, utilActions } from '../../_actions';
import { sensorService } from '../../_services';
import { Formik, Form,  Field,FieldArray } from 'formik';
import ReactTooltip from 'react-tooltip';
import * as Yup from 'yup';
import { sensorConstants } from '../../_constants';
import Select from 'react-select';
import { ERR, ErrorMessage, LoadingBoton,Graficador,TIPO_DATO, esGratis } from '../../_helpers';
import {RadioGroup, Radio} from 'react-radio-group';

const formRef = React.createRef();

class FormEditarSensor extends React.Component { 
     constructor(props){
        super(props);
        
        if(this.props.sensor!==undefined){  
          this.state.idEquipo = this.props.sensor.idEquipo;
        }else
          this.state.idEquipo = this.props.equipo.id;
     }

     chequearHayMapa = (shadows) =>{
       
        let resultado = false;

        if(!shadows) resultado= false;
        else
          resultado=shadows.some(sha=>sha.tipoWidget && sha.tipoWidget.id==sensorConstants.TIPO_WIDGET_MAPA)        

        return resultado;
     }

     esTipoWidgetConRefMax=(tipoWidget)=>{
       const idTipo = tipoWidget?parseInt(tipoWidget.id):undefined;
       if(!idTipo)return false;
       if(idTipo!==sensorConstants.TIPO_WIDGET_MAPA && 
             idTipo!==sensorConstants.TIPO_WIDGET_GRAFICO && 
             idTipo!==sensorConstants.TIPO_WIDGET_TOOGLE && 
             idTipo!==sensorConstants.TIPO_WIDGET_TEXTO)return true
      
        return false;
     }

     chequearHayToogle = (shadows) =>{
      let resultado = false;

      if(!shadows) resultado= false;
      else
        resultado=shadows.some(sha=>sha.tipoWidget && sha.tipoWidget.id==sensorConstants.TIPO_WIDGET_TOOGLE)   

      return resultado;
   }

     componentDidMount(){  
        if(this.props.tiposSensores===undefined)this.props.traerTiposSensores();
        if(this.props.tiposWidget===undefined)this.props.traerGeneralSensor();
     }
     
     state = {
         grabando:false,
         idEquipo:''        
    };

     render(){     

        const {sensor,tiposSensores,tiposWidget,tiposDatos,sensores,userToken} = this.props;
        const {idEquipo} = this.state;
        
        let esNuevo = false;
        if(utilActions.isEmpty(sensor))
            esNuevo =true

        let inic={
            id:'',
            nombre: '',
            calibracion:0,
            valorEncendido:'',
            valorApagado:'',
            tipoSensor:{id:''},
            shadows:[],
            tipoDato:{id:''}         
        }

        
         const regexTopic = /[^-\s#$+]$/;
         let va = {
          labelUsuario: Yup.string().min(2, 'Muy corto').max(100, 'No puede exceder los 100 carácteres').required(ERR.REQ)
         };

          va.shadows = Yup.array().min(1,'Min 1 widget').max(2,'Max 2 widgets').required('Requerido').of(
            Yup.object(
              {
              nombre: Yup.string().max(100,"Max 100").min(2, 'Muy corto').required(ERR.REQ),
              tipoWidget:Yup.object({id: Yup.string().required(ERR.REQ)}),
              valorMaxReferencia: Yup.number().when('tipoWidget', {
                is: (val) => !this.esTipoWidgetConRefMax(val), 
                then: Yup.number().required(ERR.REQ).typeError('Solo números enteros')
              }),
              valorMinReferencia: Yup.number().when('tipoWidget', {
                is: (val) => !this.esTipoWidgetConRefMax(val),
                then: Yup.number().required(ERR.REQ).typeError('Solo números enteros')
              })
            })
          )

          va.labelUsuario = Yup.string().min(2, 'Muy corto').max(100, 'No puede exceder los 100 carácteres').required(ERR.REQ);
          va.calibracion= Yup.number().typeError('Solo números enteros').required(ERR.REQ);
          va.nombreNexo= Yup.string().matches(regexTopic,'No se pueden ingresar espacios en blanco ni caracteres especiales como "+", "$" o "#"')
                                      .min(2, 'Muy corto')
                                      .max(100, 'No puede exceder los 100 caracteres')
                                      .required(ERR.REQ)
                                      .test('checkTopicExistente', 'Nombre topic ya asignado', function (value) { 
                                      let valueReturn =true;                                                                    
                                      sensores.forEach((val,index)=>{      
                                          if(esNuevo){
                                            if(val.idEquipo===idEquipo && (val.nombreNexo===(idEquipo+value) || val.nombreNexo===(idEquipo+'/'+value)))
                                            valueReturn= false;
                                          }else{
                                            if(val.id!==sensor.id && val.idEquipo===idEquipo && (val.nombreNexo===(idEquipo+value) || val.nombreNexo===(idEquipo+'/'+value)))
                                            valueReturn= false;
                                          }  
                                      })  
                                      return valueReturn;
                                    });
          va.variableJson= Yup.string().when('tipoDato', {
            is: (val) => val.id == TIPO_DATO.JSON, 
            then: Yup.string().matches(regexTopic,'No se pueden ingresar espacios en blanco ni caracteres especiales como "+", "$" o "#"').min(2, 'Muy corto').max(100, 'No puede exceder los 100 carácteres').required(ERR.REQ)
          });
          va.variableLongitud= Yup.string().when('shadows', {
            is: (val) => this.chequearHayMapa(val),  
            then: Yup.string().matches(regexTopic,'No se pueden ingresar espacios en blanco ni caracteres especiales como "+", "$" o "#"')
            .min(2, 'Muy corto')
            .max(100, 'No puede exceder los 100 carácteres')
            .required(ERR.REQ)
          });
          va.tipoDato= Yup.object({id: Yup.string().required(ERR.REQ)});
          va.valorEncendido= Yup.string().when('shadows', {
            is: (val) => this.chequearHayToogle(val), 
            then: Yup.string().max(50, 'No puede exceder los 50 caracteres').required(ERR.REQ)
          });
          va.valorApagado= Yup.string().when('shadows', {
            is: (val) =>this.chequearHayToogle(val),  
            then: Yup.string().max(50, 'No puede exceder los 50 caracteres').required(ERR.REQ)
          });
          
         
         
   
         const validationSchema = Yup.object().shape(
            va
          );

        if(sensor!==undefined)inic=utilActions.setearValores(inic,sensor);
   
        if(!esNuevo){          
          let nexo= sensor.nombreNexo;
          inic.nombreNexo=nexo?nexo.substring(nexo.indexOf('/')+1,nexo.length):'';    
        }else{
          if(tiposSensores && tiposSensores.length===1){
            inic.valorMaxReferencia =tiposSensores[0].valorMaxReferencia;
            inic.valorMinReferencia =tiposSensores[0].valorMinReferencia;
          }else{
            inic.valorMaxReferencia = 0
            inic.valorMinReferencia = 0
          }
        }
         return(
         <>
         <Modal show={this.props.show} onHide={this.props.handleClose} className=" modal-dialog-scrollable" size="lg" backdrop="static" keyboard={false} scrollable={true}>
          <Modal.Header className="bg-recursos text-white" closeButton>
              <Modal.Title>Widget</Modal.Title>
          </Modal.Header> 
          <Modal.Body className="" >
             <div>
                 <Formik
                     enableReinitialize= {true}
                     initialValues={inic}
                     innerRef={formRef}
                     validationSchema={validationSchema}
                     onSubmit={(values ) => { 
                              this.setState({grabando:true});  
                              if(esNuevo || sensor.tipoSensor.id===sensorConstants.DESARROLLADOR){
                                values.idEquipo = this.state.idEquipo;

                                sensorService.agregarSensorDesarrollador(values).then(
                                  data => {                                
                                    this.props.agregarSensorDesarrollador(data,esNuevo);
                                    this.props.handleClose();
                                    this.setState({grabando:false});  
                                  },
                                  error=>{
                                    this.props.enviarError(error);
                                    this.setState({grabando:false});  
                                  }
                                );

                            }else{                            
                              sensorService.actualizarSensorUsuario(values).then(
                                data => {                                
                                  this.props.actualizarSensorUsuario(values);
                                  this.props.handleClose();
                                  this.setState({grabando:false});  
                                },
                                error=>{
                                  this.props.enviarError(error);
                                  this.setState({grabando:false});  
                                }
                              );
                            }   
                                  
                     }}>
                             {({ errors, touched,values,setFieldValue }) => (
                             <Form>
                                <Row className="th_recursos">
                                  <Col sm={12} lg={6}>
                                    <label htmlFor="nombre">Nombre Variable </label> 
                                    <ErrorMessage name={`labelUsuario`} />                                  
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text"><i className="material-icons-outlined icon-image-preview">settings_remote</i></div>
                                        </div>
                                        <Field className="form-control" name="labelUsuario" />
                                        
                                    </div>
                                  </Col>

                                  {esNuevo || sensor.tipoSensor.id===sensorConstants.DESARROLLADOR ?
                                  <>
                                  </>
                                  :
                                  <Col sm={12} lg={6}>
                                    <label htmlFor="nombre">Valor calibración</label> 
                                    <ErrorMessage name={`calibracion`} />                                  
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text"></div>
                                        </div>
                                        <Field className="form-control" name="calibracion" />
                                        
                                    </div>
                                  </Col>
                                  }
                                 

                                  {(esNuevo || sensor.tipoSensor.id===sensorConstants.DESARROLLADOR )  &&
                                  <>
                                      <Col sm={12} lg={6}>
                                        <label htmlFor="nombre">Nombre topic</label> 
                                        <ErrorMessage name={`nombreNexo`} />                                     
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                              <div className="input-group-text"></div>
                                            </div>
                                            <Field className="form-control" name="nombreNexo" />
                                            
                                        </div>
                                      </Col>   
                                      <Col sm={12} className="mb-4 mt-4">
                                            <FieldArray
                                                name={`shadows`}
                                                render={arrayHelpers => (
                                                  <div className="mt-4 mb-4"> 
                                                   <Accordion defaultActiveKey="0">                                                       
                                                      <label htmlFor="nombre">
                                                        <i className="fas fa-arrow-circle-right"></i> 
                                                          Elegí los widgets que mas se acomoden a tus objetivos <small>(Max 2)</small></label> 
                                                          <div className="nueva-accion">
                                                            <a className="btn btn-more bg-verde-azulado" data-tip data-for='more' type="button" 
                                                            onClick={() => 
                                                              values.shadows && values.shadows.length>=2?
                                                              this.props.enviarError("Puede elegir hasta dos widgets."):
                                                            arrayHelpers.push({id:'',nombre:'',valorMinReferencia:0,valorMaxReferencia:50, tipoWidget:{id:''}})}>
                                                              
                                                              <i className="material-icons align-middle txt-16">add</i>
                                                            </a>
                                                            <ReactTooltip place="bottom" id='more' aria-haspopup='false' >
                                                            <span>Agregar</span>
                                                            </ReactTooltip>  
                                                          </div>    
                                                          <ErrorMessage name={`shadows`} />  
                                                          
                                                          {values.shadows && values.shadows.map((shadow, indesShadow)=>
                                                          <>  
                                                          <Card key={indesShadow} className="border-0 shadow mt-3">
                                                            <Accordion.Toggle className="cursor" as={Card.Header} eventKey={indesShadow.toString()}>
                                                             <h4><Badge className="bg-secundario text-silver mt-4"> #{indesShadow+1} - {shadow.nombre}</Badge>

                                                              <a data-tip data-for='min' className="btn-min ml-4" type="button" 
                                                              onClick={() => arrayHelpers.remove(indesShadow)}>
                                                                <i className="fas fa-minus align-middle txt-16"></i>
                                                            </a>
                                                            <ReactTooltip place="bottom" id='min' aria-haspopup='false' >
                                                            <span>Quitar</span>
                                                            </ReactTooltip>  
                                                            </h4>
                                                            <span className="arrow-acc-head"><i className="fas ml-3 fa-angle-down"></i></span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={indesShadow.toString()}>
                                                             
                                                              <Card.Body>                                       
                                                                   <Row>
                                                                   <Col xs={12}>
                                                                        <label htmlFor="nombre">Nombre</label>
                                                                        <ErrorMessage name={`shadows[${indesShadow}].nombre`} />                                             
                                                                        <div className="input-group mb-2">
                                                                          <div className="input-group-prepend">
                                                                            <div className="input-group-text"></div>
                                                                          </div>
                                                                            <Field className="form-control" type="text" name={`shadows[${indesShadow}].nombre`} />
                                                                            
                                                                        </div>
                                                                    </Col>
                                                                   <div className="d-flex flex-wrap align-items-stretch widgets mt-4 p-3">
                                          
                                                                        <RadioGroup selectedValue={shadow.tipoWidget.id}
                                                                          onChange={(value)=> {
                                                                            setFieldValue(`shadows[${indesShadow}].tipoWidget.id`,value);
                                                                            if(value===sensorConstants.TIPO_WIDGET_MAPA)setFieldValue(`tipoDato.id`,TIPO_DATO.JSON);
                                                                          }}>
                                                                            <Row>
                                                                            {tiposWidget && tiposWidget.map((tipoW, indexTN)=>  
                                                                              <div key={indexTN} className="col-lg-6 mb-4"> 
                                                                              <Card className="border-0 shadow-1">
                                                                                <Card.Header>
                                                                                  <Row>
                                                                                    <Col xs={9}>
                                                                                    <label className="label-check font-weight-bold text-20" htmlFor={"Automatico"+indexTN} >
                                                                                    {tipoW.descripcion}
                                                                                    </label>  
                                                                                    </Col>
                                                                                    <Col xs={3} className="border-left">
                                                                                    {esGratis(userToken) && tipoW.id===sensorConstants.TIPO_WIDGET_MAPA&&
                                                                                    <>
                                                                                     <a data-tip data-for='soloPago'><i className="fas fa-exclamation-circle"></i></a>
                                                                                    <ReactTooltip place="bottom" id='soloPago' aria-haspopup='false' >
                                                                                        <span>Solo disponible para suscripciones pagas</span>
                                                                                    </ReactTooltip> 
                                                                                    </>
                                                                                    }
                                                                                      <Radio disabled={esGratis(userToken) && tipoW.id===sensorConstants.TIPO_WIDGET_MAPA} className="option-input checkbox" id={"Automatico"+indexTN} value={tipoW.id} /> 
                                                                                    </Col>
                                                                                  </Row>
                                                                                </Card.Header>
                                                                                <Card.Body>                                                   
                                                                                  <Graficador className="mt-5" tipoWidget={tipoW.id} width={200} height={160}
                                                                                    valorMinReferencia={0} valorMaxReferencia={100}                                                         
                                                                                    valor={50} unidad={""} soloLectura={true} />
                                                                                    </Card.Body>
                                                                                  </Card>
                                                                              </div>
                                                                            )}
                                                                            </Row>
                                                                      </RadioGroup>
                                                                      
                                                                     </div>

                                                                    {(this.esTipoWidgetConRefMax(shadow.tipoWidget)) && 
                                                                    <>
                                                                    <Col xs={6}>
                                                                        <label htmlFor="nombre">Valor mínimo referencia  
                                                                        <a data-tip data-for='valorMinH'><i className="fas fa-info-circle"></i></a>
                                                                          </label> 
                                                                            <ReactTooltip place="bottom" id='valorMinH' aria-haspopup='false' >
                                                                                <span>Es el valor mínimo para mostrar en los distintos widgets. Es sólo a modo visual.</span>
                                                                            </ReactTooltip>
                                                                        <ErrorMessage name={`shadows[${indesShadow}].valorMinReferencia`} />                                             
                                                                        <div className="input-group mb-2">
                                                                            <div className="input-group-prepend">
                                                                              <div className="input-group-text"></div>
                                                                            </div>
                                                                            <Field className="form-control" type="number" name={`shadows[${indesShadow}].valorMinReferencia`} />
                                                                            
                                                                        </div>
                                                                    </Col>
                                                                      <Col xs={6}>
                                                                          <label htmlFor="nombre">Valor máximo referencia
                                                                              <a data-tip data-for='valorMaxH'><i className="fas fa-info-circle"></i></a>
                                                                          </label> 
                                                                            <ReactTooltip place="bottom" id='valorMaxH' aria-haspopup='false' >
                                                                                <span>Es el valor tope para mostrar en los distintos widgets. Es solo a modo visual.</span>
                                                                            </ReactTooltip> 
                                                                          <ErrorMessage name={`shadows[${indesShadow}].valorMaxReferencia`} />                                             
                                                                          <div className="input-group mb-2">
                                                                              <div className="input-group-prepend">
                                                                                <div className="input-group-text"></div>
                                                                              </div>
                                                                              <Field className="form-control" type="number" name={`shadows[${indesShadow}].valorMaxReferencia`} />
                                                                              
                                                                          </div>
                                                                      </Col>
                                                                      </>}
                                                                    </Row> 
                                                              </Card.Body>
                                                              </Accordion.Collapse>
                                                              </Card>
                                                              </>
                                                              
                                                            )}   
                                                              {(values.shadows===undefined || values.shadows.length===0) &&
                                                            <><h4><Badge pill className="bg-secundario font-weight-light text-white mt-3">No hay widgets creados</Badge></h4></>}                   
                                                  </Accordion>  
                                                  </div>
                                                )}
                                              />         
                                      </Col>       
                                  </>
                                  }

                                    <Col sm={12} lg={12} className="mb-6">
                                          <label htmlFor="nombre">Tipo dato
                                          <a data-tip data-for='tipoDatoHelp'> <i className="fas fa-info-circle cursor"></i></a>
                                          </label> 
                                              <ReactTooltip place="bottom" id='tipoDatoHelp' aria-haspopup='false' >
                                                  <span>
                                                    Si elije plano, se enviara texto plano. Si elije JSON deberá elegir la variable.
                                                      {this.chequearHayMapa(values.shadows)&&'Al haber un widget mapa, sólo puede ser elegida el tipo dato JSON'}
                                                  </span>
                                              </ReactTooltip> 
                                          <ErrorMessage name={`tipoDato`} /> 
                                              <Select
                                                  value={tiposDatos ? tiposDatos.find(option => option.id === values.tipoDato.id) : ''}
                                                  getOptionLabel={option =>`${option.descripcion}`}
                                                  getOptionValue={option => option.id}
                                                  onChange={so =>{
                                                      setFieldValue(`tipoDato`,so?so:'')
                                                  }}
                                                  isSearchable={true}
                                                  placeholder="Seleccione uno"
                                                  options={this.chequearHayMapa(values.shadows)?
                                                    tiposDatos?tiposDatos.filter(td=>td.id===TIPO_DATO.JSON):tiposDatos:tiposDatos}
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                    ...theme.colors,
                                                      primary25: 'aquamarine',
                                                      primary: 'teal',
                                                    },
                                                  })}
                                              />   
                                      </Col>

                                      {(values.tipoDato.id===TIPO_DATO.JSON && !this.chequearHayMapa(values.shadows)) && 
                                      <Col sm={6} lg={6}>
                                          <label htmlFor="variableJson">
                                                Variable JSON 
                                                <a data-tip data-for='jsonHelp'> <i className="fas fa-info-circle cursor"></i></a>                                                
                                              </label>                                               
                                                <ReactTooltip place="bottom" id='jsonHelp' aria-haspopup='false' >
                                                    <span>EJ: {'Para json {value:4} requiere campo con valor:"value"'}</span>
                                                </ReactTooltip>
                                          <ErrorMessage name={`variableJson`} /> 
                                          <Field name="variableJson" />
                                      </Col>}

                                      {(this.chequearHayMapa(values.shadows)) && 
                                        <>
                                          <Col sm={6} lg={6}>
                                              <label htmlFor="latitud">
                                                Var Latitud 
                                                <a data-tip data-for='latHelp'><i className="fas fa-info-circle"></i></a>                                                
                                              </label>                                               
                                                <ReactTooltip place="bottom" id='latHelp' aria-haspopup='false' >
                                                    <span>EJ: {'Para json {latitud:-54.3234} requiere valor latitud'}</span>
                                                </ReactTooltip>
                                              <ErrorMessage name={`variableJson`} /> 
                                              <div className="input-group mb-2">
                                              <div className="input-group-prepend">
                                                <div className="input-group-text"></div>
                                              </div>
                                              <Field name="variableJson" />
                                              </div>
                                          </Col>
                                          <Col sm={6} lg={6}>
                                              <label htmlFor="variableLongitud">
                                                Var Longitud 
                                                <a data-tip data-for='longHelp'> <i className="fas fa-info-circle"></i></a>                                                
                                              </label>                                               
                                                <ReactTooltip place="bottom" id='longHelp' aria-haspopup='false' >
                                                    <span>EJ: {'Para json {longitud:34.3234} requiere valor longitud'}</span>
                                                </ReactTooltip>
                                              <ErrorMessage name={`variableLongitud`} /> 
                                              <div className="input-group mb-2">
                                              <div className="input-group-prepend">
                                                <div className="input-group-text"></div>
                                              </div>
                                              <Field name="variableLongitud" />
                                              </div>
                                          </Col>
                                        </>
                                      }
                                      {(this.chequearHayToogle(values.shadows)) &&
                                     <> 
                                      <Col xs={6}>
                                            <label htmlFor="valorEncendido">Valor activación 
                                              <a data-tip data-for='activacionHelp'><i className="fas fa-info-circle"></i></a>                                                
                                            </label>                                               
                                              <ReactTooltip place="bottom" id='activacionHelp' aria-haspopup='false' >
                                                  <span>Es el valor que se enviará cuando se prenda el Toogle</span>
                                              </ReactTooltip>
                                          <ErrorMessage name={`valorEncendido`} />
                                        
                                          <div className="input-group mb-2">
                                              <div className="input-group-prepend">
                                                <div className="input-group-text"></div>
                                              </div>
                                              <Field className="form-control" type="number" name="valorEncendido" />
                                             
                                          </div>
                                      </Col>
                                        <Col xs={6}>
                                           <label htmlFor="valorApagado">Valor activación 
                                              <a data-tip data-for='activacionOffHelp'><i className="fas fa-info-circle"></i></a>                                                
                                            </label>                                               
                                              <ReactTooltip place="bottom" id='activacionOffHelp' aria-haspopup='false' >
                                                  <span>Es el valor que se enviará cuando se apague el Toogle</span>
                                              </ReactTooltip>
                                            <ErrorMessage name={`valorApagado`} />                                         
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                      <div className="input-group-text"></div>
                                                </div>
                                                <Field className="form-control" type="number" name="valorApagado" />
                                                
                                            </div>
                                        </Col>
                                      </>}
                                        
                                 </Row>   
                                  <div className="justify-content-center mt-5 p-3 row border-top bg-light pie-fijo">
                                    <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                                      <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                                    </button> 
                                  </div>                            
                             </Form>
                             )}
                 </Formik>
             </div>
             </Modal.Body>
             </Modal>
             </>
         )};
}

function mapState(state) {
    const { userToken } = state.authentication;  
    const {tiposSensores,tiposWidget,tiposDatos,sensores} = state.equipos
    return { tiposSensores,tiposWidget,tiposDatos,sensores,userToken };
}

const actionCreators = {
  enviarError: alertActions.error,
  enviarSuccess:alertActions.success,
  actualizarSensorUsuario:sensorActions.actualizarSensorUsuario,
  agregarSensorDesarrollador:sensorActions.agregarSensorDesarrollador,
  traerTiposSensores:equipoActions.traerTiposSensores,
  traerGeneralSensor:equipoActions.traerGeneralSensor,
  
}

const connectedFormEditarSensor = connect(mapState, actionCreators)(FormEditarSensor);
export { connectedFormEditarSensor as FormEditarSensor };