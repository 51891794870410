import React from 'react';
import { connect } from 'react-redux';
import {Navbar,NavDropdown} from 'react-bootstrap';
import {accionColectivaActions, alertActions, consultaActions, notificacionActions, programaActions, userActions} from '../_actions';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Switch from "react-switch";
import {ModalDetalleLicencia} from './Perfil/ModalDetalleLicencia';
import {alertConfirm} from '../_helpers';
import ReactTooltip from 'react-tooltip';
import { PanelNotificaciones } from './Perfil/PanelNotificaciones';
import { InfoConsultaUser } from './Perfil';
import { FormContratarSuscrip } from './Tienda/FormContratarSuscrip';



class NavBar extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
        this.wrapperRef = React.createRef();
    }
    
    handleChange(checked) {
        if(checked){
            document.body.classList.remove('bg-oscuro');
            document.body.classList.add('modo-claro');            
        }else{
            document.body.classList.remove('modo-claro');
            document.body.classList.add('bg-oscuro');
        }
        this.setState({ checked });
    }
    
    state = {
        show: false,
        showDetalle:false,
        showNotificacion:false,
        showPagarSuscripcion:false,
        pasePrimeraVez:false
    };

    pagarSuscripcion = () => {
        this.setState({showPagarSuscripcion: true,showDetalle:false});
    };

    abrirNotificacion = () => {
        this.setState({showNotificacion: true});
    };

    handleClose = () => {
        this.setState({show: false,showDetalle:false,showNotificacion:false,showPagarSuscripcion:false});
    };
    handleCloseBurbuja = () => {
        this.props.limpiarBurbujaConsulta();
    };

    handleShow = () => {
        this.setState({show: true});
    };

    manejarSubmitEquipo = () => {
        this.setState({show: false});
    };

    mostrarDetalleLicencia = () => {
        this.setState({showDetalle: true});
    };
    

    componentDidMount(){
        if(this.props.misNotificaciones===undefined && this.props.user)this.props.getNotificacionesPU();
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.props.cerrarGlobo();
        }
    }

    confirmEjecucion=(accion)=>{
        const getAlert = alertConfirm('Confirmar',
                `¿Está seguro que desea ejecutar ${accion.item.nombre}?`,
                'Sí, ejecutar',
                'Cancelar',
                this.props.clearAlertConfirm,
                ()=>this.ejecutarAccion(accion),
                null,
                true,
                'warning',
                true
            )  
        this.props.enviarAlertConfirm(getAlert);
    }


    ejecutarAccion = (accion) => {
        this.props.ejecutarAccion(accion.item);
     };

     


    render(){
        const { user,userToken,misNotificaciones,misConsultas,accionColectiva,programa } = this.props;
        if(misConsultas===undefined && user && !this.state.pasePrimeraVez){
            this.setState({pasePrimeraVez:true})
            this.props.getConsultasPU();
        }
              
        if(accionColectiva.misAcciones===undefined && user && !this.state.pasePrimeraVez){
            this.props.getAccionesColectivasPU();
        }
        let consulta=undefined;
        if(misConsultas){
            misConsultas.forEach((consul, index) => {    
                if(consul.globo)consulta=consul;
            })
        }

        let accionesFavoritas = [];

        let soyUsuarioEmpresa = false;

        if(user){
            soyUsuarioEmpresa = user.empresa !==undefined;
            if(accionColectiva.misAcciones!==undefined){
                accionColectiva.misAcciones.forEach((val,index)=>{
                    if(val.favorita)accionesFavoritas.push({tipo:1,item:val});
                })
            }
            if(programa.misProgramas!==undefined){
                programa.misProgramas.forEach((val,index)=>{
                    if(val.favorita)accionesFavoritas.push({tipo:2,item:val});
                })
            }
        }
        return (
            <>
            <Navbar className="bg-navbar" sticky="top" expand="lg">
                <div className="theme-mode d-none d-lg-block d-md-block d-xl-block small text-muted">
                <span className="material-icons">settings</span>
                <label htmlFor="small-radius-switch">
                    <Switch
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        handleDiameter={25}
                        offColor="#224f6d"
                        onColor="#9cd6ef"
                        offHandleColor="#00f2c3"
                        onHandleColor="#ffffff"
                        height={30}
                        width={60}
                        activeBoxShadow="0px 0px 1px 2px #fffc35"
                        uncheckedIcon={
                        <div
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "silver",
                            paddingRight: 2
                            }}
                        >
                            <span className="material-icons txt-18">brightness_2</span>
                        </div>
                        }
                        checkedIcon={
                            <span className="material-icons txt-18 text-iot mt-e ml-e txt-shadow">brightness_7</span>
                        }
                        uncheckedhandleicon={
                        <div
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 20
                            }}
                        >
                        <span className="material-icons mt-2 ml-2 text-iot">wb_sunny</span>
                        </div>
                        }
                        className="react-switch"
                        id="small-radius-switch"
                    />
                </label>
                </div>
                <Link to={user?'/home/misEquipos':'/login'}>
                    <Navbar.Brand >
                        <Logo empresa={user?.empresa}/>
                    </Navbar.Brand>
                </Link>
              
                 {user && 
                        <>
                        <div>                   
                            <Navbar id="responsive-navbar-nav">
                                <div className="modo ">                                                    
                                        <ul className="navbar-nav mr-auto">                                        
                                            <li className="nav-item btn-modo border-gradient order-lg-first order-xs-3">
                                                <Link data-tip data-for='equipos' to='/home/misEquipos'>
                                                <span className="material-icons material-icons-outlined icon-gradient-evento">home</span>
                                                <span className="chip"><span className="d-none d-lg-block d-xl-block"> Home</span></span>
                                                <ReactTooltip place="bottom" id='equipos' aria-haspopup='false' >
                                                <span>Ir a Mis Equipos</span>
                                                </ReactTooltip>
                                                </Link>
                                            </li>
                                           {!soyUsuarioEmpresa && <li className="nav-item btn-modo border-gradient ">
                                                <Link data-tip data-for='tienda' to='/tienda/suscripcion'>
                                                <span className="material-icons icon-gradient-evento">add_shopping_cart</span>
                                                <span className="chip"><span className="d-none d-lg-block d-xl-block"> Tienda</span></span>
                                                <ReactTooltip place="bottom" id='tienda' aria-haspopup='false' >
                                                <span>Ir a Tienda</span>
                                                </ReactTooltip></Link>
                                            </li>}
                                            {(userToken && !soyUsuarioEmpresa) && <li className="nav-item ml-4 d-none d-lg-block d-xl-block">
                                                <button data-tip data-for='licencia' type="button" className="btns text-white btns-success btns-sm" onClick={()=>this.mostrarDetalleLicencia()}>                                                    
                                                    <span className="chip"><span className="d-none d-lg-block d-xl-block"> <i className="fas fa-check-circle mr-1"></i>
                                                     Licencia activa <span className="font-weigth-bold">{userToken.esGratuito?'de prueba:':':'}</span> {userToken.tipoSuscripcion.descripcion}</span></span>
                                                </button>
                                                <ReactTooltip place="bottom" id='licencia' aria-haspopup='false' >
                                                <span>Ver Mi Licencia</span>
                                                </ReactTooltip>                            
                                            </li>}
                                        </ul>
                                </div>
                            </Navbar>
                            {consulta && 
                                <>
                                    <div className="burbuja-chat">
                                        <div className="chat-type"  onClick={()=>this.props.toogleGlobo(consulta.id)}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <button type="button" className="close-btn" onClick={()=>this.handleCloseBurbuja()}><span className="material-icons">clear</span></button>                              
                                    </div>

                                    {consulta.globoAbierto && <div className="contenedor-chat" ref={this.wrapperRef}>
                                        <InfoConsultaUser  esUser={true} consulta={consulta} handleClose={this.handleCloseBurbuja}/>
                                    </div>}                                   
                                </>    
                            }
                            <div className="notificacion cursor" onClick={()=>this.abrirNotificacion()}>
                                <Badge className="bg-fucsia flash">{misNotificaciones?misNotificaciones.filter(not=>not.leida===false).length:0}</Badge>
                                <span className="material-icons">notifications</span>
                            </div>                     

                            <div className="derecha move">                         
                                    <ul className="">
                                        <li className="nav-item ml-3 mt-3 d-lg-block d-xl-block d-none text-truncate">
                                            <a className="nav-link font-weight-light" href="#">
                                            <p className="font-weight-bold scrolling text-truncate mt-3">
                                                <i className="fas fa-user-circle"></i> Usuario {user.username} <br></br>
                                                {process.env.NODE_ENV!=='production' && 
                                                    <span className="font-weight-light">AMBIENTE:<b>{process.env.NODE_ENV}</b></span>
                                                }
                                            </p>
                                            </a>
                                        </li>
                                    </ul>
                            </div>
                </div>
            <div className="drop-nav">                
                    <NavDropdown id="basic-nav-dropdown" title={<div><i className="fas fa-ellipsis-v"></i></div>}>                       
                            <NavDropdown.Item className="text-oscuro">
                                 <Link to="/perfil/misDatos"> <i className="material-icons-outlined icon-image-preview align-sub">account_circle</i> Mi perfil</Link>                               
                            </NavDropdown.Item>
                            <NavDropdown.Item>                                
                                <Link to="/perfil/usuarios">                              
                                <i className="material-icons-outlined icon-image-preview align-sub">group_work</i> Mis usuarios
                                </Link> 
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/tienda/misCompras">   
                                <span className="material-icons align-sub">add_shopping_cart</span> Mis compras
                                </Link> 
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to="/perfil/misConsultas">   
                                <span className="material-icons align-sub material-icons-outlined">contact_support</span> Mis consultas
                                </Link> 
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                <div className=" d-block  d-lg-none d-md-none d-xl-none small text-muted ml-3">
                                    <span className="material-icons mr-2 text-dark material-icons-outlined mt-2 ">settings</span>
                                    <label htmlFor="small-radius-switch">
                                    <Switch
                                        checked={this.state.checked}
                                        onChange={this.handleChange}
                                        handleDiameter={18}
                                        offColor="#224f6d"
                                        onColor="#2f9ac8"
                                        offHandleColor="#00f2c3"
                                        onHandleColor="#ffffff"
                                        height={30}
                                        width={50}
                                        activeBoxShadow="0px 0px 1px 2px #fffc35"
                                        uncheckedIcon={
                                        <div
                                            style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            color: "silver",
                                            paddingRight: 2
                                            }}
                                        >
                                            <span className="material-icons txt-18">brightness_2</span>
                                        </div>
                                        }
                                        checkedIcon={
                                            <span className="material-icons txt-18 text-iot mt-e ml-e">brightness_7</span>
                                        }
                                        uncheckedhandleicon={
                                        <div
                                            style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 20
                                            }}
                                        >
                                        <span className="material-icons mt-2 ml-2 text-iot">wb_sunny</span>
                                        </div>
                                        }
                                        className="react-switch"
                                        id="small-radius-switch"
                                    />
                                    </label>
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Divider className="d-block d-lg-none d-md-none d-xl-none"/>
                                    <NavDropdown.Item className="d-block d-lg-none d-md-none d-xl-none">
                                    {userToken && <li className="nav-item d-block d-lg-none d-xl-none">
                                                    <button type="button" className="btns btn-success btn-block btn-sm" onClick={()=>this.mostrarDetalleLicencia()}>
                                                        
                                                        <span className="chip"> <i className="fas fa-check-circle text-white"></i> {userToken.esGratuito&&'Licencia de prueba '}{userToken.tipoSuscripcion.descripcion}</span>
                                                    </button>                            
                                                </li>}
                                        </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item> 
                                    <Link to="/logout">                                
                                        <i className="material-icons-outlined icon-image-preview align-sub text-danger">exit_to_app</i> Salir
                                    </Link> 
                                </NavDropdown.Item>
                    </NavDropdown>                    
                </div>
              </>    
            }
            {this.state.showNotificacion && <PanelNotificaciones show={this.state.showNotificacion} handleClose={this.handleClose}/>}
            {this.state.showDetalle && <ModalDetalleLicencia pagarSuscripcion={this.pagarSuscripcion} show={this.state.showDetalle} handleClose={this.handleClose}/>} 
            {this.state.showPagarSuscripcion && <FormContratarSuscrip suscripcion={userToken.compra.itemsCompra[0].suscripcion} compra={userToken.compra} show={this.state.showPagarSuscripcion} handleClose={this.handleClose}/>}
        </Navbar>
        {(user && accionesFavoritas)&& 
            <div className="nav-secondary">
                <div className="menu-m-wrapper menu-m-wrapper--special"> 
                           
                    <ul className="menu-m">
                    <li className="text-white small"><span className="material-icons txt-16 text-iot aling-middle">grade</span> Mis Favoritos</li>
                        {accionesFavoritas.map((accion, index)=> 
                            <li key={accion.item.id} onClick={()=>this.confirmEjecucion(accion)}>
                                <span className="material-icons txt-16 text-iot aling-middle">grade</span> {accion.item.nombre}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        }
        </>
        );
    }
}

function mapState(state) {
    const { notificacion,authentication,consultas,accionColectiva,programa } = state;
    const { misConsultas } = consultas;
    const { user,userToken } = authentication;
    const { misNotificaciones } = notificacion;
    return { user,misNotificaciones,userToken,misConsultas,accionColectiva,programa };
}

const actionCreators = {    
    logout:userActions.logout,
    getConsultasPU:consultaActions.getConsultasPU,
    limpiarBurbujaConsulta:consultaActions.limpiarBurbujaConsulta,
    toogleGlobo:consultaActions.toogleGlobo,
    cerrarGlobo:consultaActions.cerrarGlobo,
    getNotificacionesPU:notificacionActions.getNotificacionesPU,
    getProgramasPU:programaActions.getProgramasPU,
    ejecutarAccion:accionColectivaActions.ejecutarAccion,
    enviarAlertConfirm:alertActions.enviarAlertConfirm,
    clearAlertConfirm:alertActions.clearAlertConfirm,
    getAccionesColectivasPU:accionColectivaActions.getAccionesColectivasPU
}

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };
