import React from 'react';
import { equipoService } from '../_services';
import {alertActions} from '../_actions';

export default class QR extends React.Component {
   
    state ={
      qr:''
    }

    componentDidMount(){
        //const qrBase = equipoService.getQR(this.props.id);
        equipoService.getQR(this.props.id).then(
          data => {   
              this.setState({
                qr:data.qrData
              });            
          },
          error=>{
            alertActions.error(error.message);
          }
        );
    }

    render(){
        return (
            <div style={{height: '100%', width:'100%'}}>
                 <img alt='' src={`data:image/jpeg;base64,${this.state.qr}`} />
            </div>
        );
    }
}