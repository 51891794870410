import React from 'react';
import { connect } from 'react-redux';
import {Modal,Row,Col,Card} from 'react-bootstrap';
import {LoadingBoton} from '../../_helpers';
import { alertActions, equipoActions } from '../../_actions';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {RadioGroup, Radio} from 'react-radio-group';

class ModalMoverDeCarpeta extends React.Component {
    setGrabando=(grab)=>{
        this.setState({grabando:grab});
      }
    
      state={
        grabando:false
      }
    
render() {
    let {equipo,carpetaRoot,carpetaActual} = this.props;
    let inic={
      id:carpetaActual.id,
      carpetaActual:carpetaActual,
      equipo: equipo     
    }

     let validateActualizar = {
     // nombre: Yup.string().min(2, 'Muy corto').max(100, 'No puede excedor los 100 carácteres').required('Requerido')
     };

     const validationSchema = Yup.object().shape(
      validateActualizar
     );
  return(

          <Modal show={this.props.show} onHide={()=>this.props.handleClose()}>
              <Modal.Header className="bg-verde-azulado text-white" closeButton>
                  <Modal.Title><span className="material-icons align-middle txt-29">drive_file_move</span> Mover de carpeta</Modal.Title>
              </Modal.Header> 
              <Modal.Body className="th_verde-azulado">
                 <Formik
                     enableReinitialize= {true}
                     initialValues={inic}
                     validationSchema={validationSchema}
                     onSubmit={(values,formProps ) => {  
                         if(values.id!==carpetaActual.id)  
                            this.props.grabarMover(values,this.setGrabando,this.props.handleClose,carpetaRoot);  
                            else
                            this.props.enviarError('Debe seleccionar una carpeta distinta a la actual')           
                     }}>
                             {({ errors, touched,values,setFieldValue }) => (
                                <Form>
                                 <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <label htmlFor="nombre">Mover a la carpeta:</label>
                                        <RadioGroup  name="id" selectedValue={values.id}
                                              onChange={(value)=> {
                                                setFieldValue(`id`,value);
                                              }}>
                                              <div className="mb-4">                                                       
                                                                <Card className="border-0 shadow mb-4">
                                                                    <Card.Body>
                                                                    <Row>
                                                                    <Col xs={9}>
                                                                        <label className="label-check font-weight-bold text-20" htmlFor={"root"} >
                                                                        Raiz
                                                                        </label>  
                                                                        </Col>
                                                                        <Col xs={3} className="border-left">
                                                                        <Radio className="option-input checkbox" id={"root"} value={carpetaRoot.id} /> 
                                                                        </Col> 
                                                                        </Row>
                                                                    </Card.Body>
                                                                    </Card>
                                                 {(carpetaRoot && carpetaRoot.carpetasHijas) && carpetaRoot.carpetasHijas.map((carp, indexTN)=>  
                                                 
                                                        <Card className="border-0 shadow mb-4">
                                                        <Card.Body>
                                                        <Row>
                                                            <Col xs={9}>
                                                            <label className="label-check font-weight-bold text-20" htmlFor={"Automatico"+indexTN} >
                                                            {carp.nombre}
                                                            </label>  
                                                            </Col>
                                                            <Col xs={3} className="border-left">
                                                                <Radio className="option-input checkbox" id={"Automatico"+indexTN} value={carp.id} /> 
                                                            </Col>
                                                        </Row>
                                                        </Card.Body>
                                                    </Card>       
                                                 )}
                                                 </div>
                                          </RadioGroup>
                                    </Col>
                                    </Row>
                                    <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                        <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                                        <i className="icon-times"></i> Cerrar
                                        </button>
                                        <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                                            <i className="icon-floppy"></i>  Grabar
                                            {this.state.grabando && <LoadingBoton grabando={this.state.grabando} />}
                                        </button>
                                    </div>
                                </Form>
                             )}
                 </Formik>
              </Modal.Body>                     
          </Modal> 
  )};
}

function mapState(state) { 
    return {};
}

const actionCreators = {
    grabarMover:equipoActions.moverDeCarpeta,
    enviarError:alertActions.enviarError
}

const connectedModalMoverDeCarpeta = connect(mapState, actionCreators)(ModalMoverDeCarpeta);
export { connectedModalMoverDeCarpeta as ModalMoverDeCarpeta };