import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FieldArray,getIn  } from 'formik';
import * as Yup from 'yup';
import { accionColectivaService } from '../../_services';
import {ErrorMessage,LoadingBoton, Role, tengoRol} from '../../_helpers';
import {Row,Col,Accordion,Card} from 'react-bootstrap';
import {sensorConstants,accionColectivaConstants,equipoConstants} from '../../_constants';
import {alertActions,accionColectivaActions, equipoActions, utilActions} from '../../_actions';


class FormAccionColectiva extends React.Component {
  
  constructor(props){
    super(props);
    this.muestroSensor = this.muestroSensor.bind(this);
    this.state.esDesarrollador = tengoRol([Role.DEV]);
    if(this.state.esDesarrollador)
      this.state.tipoAccionColectiva.push({id:accionColectivaConstants.ACCION_DESARROLLADOR, descripcion:'Acción propia'});
  }

  state = {
      esDesarrollador:false,
      grabando:false,
      esNuevo:true,
      tipoAccionColectiva: [{id:accionColectivaConstants.ACCION_APAGAR, descripcion:'Apagar'},{id:accionColectivaConstants.ACCION_PRENDER, descripcion:'Prender'}]   
  };

  muestroSensor = (tipoAccionColectiva,tipoWidget,tipoEquipo) => {   
    //let tipoSensor = parseInt(tipoSensorString);
    let value= true;
    
    if(tipoEquipo.id===equipoConstants.TIPO_EQUIPO_DESARROLLADOR){
      value = true;
    }else{
      if(tipoAccionColectiva==='1' || tipoAccionColectiva==='2' || tipoAccionColectiva===1 || tipoAccionColectiva===2 ){      
        if(tipoWidget===sensorConstants.TIPO_WIDGET_TOOGLE) value= true;
          else value= false;
      }else{
        if(tipoAccionColectiva==accionColectivaConstants.ACCION_DESARROLLADOR )value= false;
        else{
          if(tipoWidget===sensorConstants.TIPO_WIDGET_TOOGLE)value= true;
          else value= false;
        }      
      }
    }   
    return value;
  }

  componentDidMount(){
  }  
  
render() {
   
      const {accionColectiva,equipos} =this.props;
      const sensoresL = equipos.sensores;

      let inic={
        id: '',
        nombre: '',
        valor:'',
        tipoAccionColectiva:{id:''},
        sensores: []
      }

      const validationSchema = Yup.object().shape({
        nombre: Yup.string()
          .min(2, 'Muy corto')
          .max(50, 'Muy largo')
          .required('Requerido'),
        tipoAccionColectiva: Yup.string().required('Requerido'),  
        valor: Yup.number(),
        sensores: Yup.array().min(1,'Debe seleccionar al menos 1 sensor').required('Requerido')  
      });


      if(accionColectiva!==undefined && accionColectiva!=={})inic=utilActions.setearValores(inic,accionColectiva);

     

return(

  <div>
    <Formik
      enableReinitialize= {true}
      initialValues={inic}
      validationSchema={validationSchema}
      onSubmit={values => {       
          this.setState({grabando:true});  
          accionColectivaService.grabarAccionColectiva(values).then(
            data => {  
                this.props.agregarAccionColectivaGenerada(data,values.id==='');
               
                this.props.handleClose();
                this.setState({grabando:false});
            },
            error=>{
              this.props.enviarError(error);
              this.setState({grabando:false});
            }
        );

      }}
    >
      {({ errors, touched,values }) => (
        
        <Form>
          <Row>
              <Col xs={12}>
                  <label htmlFor="nombre">Nombre de la acción</label> <ErrorMessage name={`nombre`} />
                  <Field name="nombre" />
              </Col>
          </Row>
          <Row>
              <Col xs={12}>
                 <label htmlFor="tipoAccionColectiva.id">Tipo de acción </label><ErrorMessage name={`tipoAccionColectiva`} />
                 <Field value={values.tipoAccionColectiva.id} component="select" name={`tipoAccionColectiva.id`} >
                    <option value=''>Seleccione</option>                    
                    {this.state.tipoAccionColectiva && this.state.tipoAccionColectiva.map(ts =>
                      <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                    )}
                  </Field>
                
              </Col>
          </Row>           
          {(values.tipoAccionColectiva.id==accionColectivaConstants.ACCION_DESARROLLADOR || 
            values.tipoAccionColectiva.id==accionColectivaConstants.ACCION_DIMMER) &&
            <Row>
                <Col xs={12}>
                    <label htmlFor="valor">Valor </label>  <ErrorMessage name={`valor`} />
                    <Field name="valor" />
                  
                </Col>
            </Row>   
          }
                            
          <Row className="mt-4">
          <Col xs={12}>
          <FieldArray
                  name="sensores"
                  render={arrayHelpers => (
                      <div className="mt-4 mb-4">
                         <Accordion defaultActiveKey="0">
                         {equipos.misEquipos!==undefined && equipos.misEquipos.map((item, index)=> 
                            <Card key={item.id}>
                              <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                                  Sensores de {item.nombre}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={item.id}>
                                <Card.Body>
                                {item.sensores!==undefined && item.sensores.map((sensor, index)=>                                  
                                    {if(this.muestroSensor(values.tipoAccionColectiva.id,utilActions.extraerDeLista(sensor.id,sensoresL).shadows[0].tipoWidget.id,item.tipoEquipo)){
                                      return (
                                        <div key={sensor.id}>                                        
                                              <input
                                                  className="option-input checkbox"
                                                  name="sensor"
                                                  type="checkbox"
                                                  value={sensor}
                                                  checked={values.sensores.some(se=>se.id===sensor.id)}
                                                  onChange={e => {
                                                    if (e.target.checked) {
                                                      arrayHelpers.push(sensor);
                                                    } else {
                                                      const idx = values.sensores.findIndex(se=>se.id===sensor.id);
                                                      arrayHelpers.remove(idx);
                                                    }
                                                  }}
                                              />
                                             <label className="label-check">{utilActions.extraerDeLista(sensor.id,sensoresL).labelUsuario} - {sensor.tipoSensor.descripcion}</label>
                                          </div>
                                      )
                                    }}
                                  )}                           
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          )}

                          </Accordion>
                      </div>
                  )}
              />
               <ErrorMessage name={`sensores`} />
                </Col>
          </Row>

              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                  <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                  <i className="icon-times"></i> Cerrar
                  </button>
                  <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                      <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                  </button>
              </div>
             
        </Form>
      )}
    </Formik>
  </div>
)};
}

function mapState(state) {
  const { equipos,authentication } = state;
  const {user} = authentication;
  return { equipos,user };
}

const actionCreators = {
  agregarAccionColectivaGenerada:accionColectivaActions.agregarAccionColectivaGenerada,
  enviarError:alertActions.enviarError, 
  getEquiposPU: equipoActions.getEquiposPU
}

const connectedFormAccionColectiva = connect(mapState, actionCreators)(FormAccionColectiva);
export { connectedFormAccionColectiva as FormAccionColectiva };