import React from 'react';
import {DesarrolladorCard,DesarrolladorCardSimple} from '.';
import { MODO_LAYOUT } from '../../_helpers';
export default class Sensor extends React.Component {

    render(){

        const {modo,sensor,seleccionado,modoLayout,layouts} = this.props;
        return (
                <>
                    {modoLayout===MODO_LAYOUT.PC?
                        <DesarrolladorCard layouts={layouts} modo={modo}  sensor={sensor} modoSuperSimple={false} seleccionado={seleccionado}/>
                    :
                        <DesarrolladorCardSimple layouts={layouts} modo={modo} modoLayout={modoLayout} modoSuperSimple={true} sensor={sensor} seleccionado={seleccionado}/> 
                    }  
                </>
        );
    }
}
