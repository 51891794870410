import { consultaConstants } from '../_constants';
import { consultaService } from '../_services';
import { alertActions } from '.';

export const consultaActions = {    
    getConsultasPU,
    traerTiposProblema,
    agregarNuevaConsulta,
    enviarMensaje,
    cargarMensajes,
    cambiarEstadoConsulta,
    limpiarBurbujaConsulta,
    seleccionarConsultaGlobo,
    toogleGlobo,
    cerrarGlobo,
    nuevoMensajeChat
    
};

function seleccionarConsultaGlobo(id) {
    return dispatch => {
        dispatch(success(id));  
    };
    function success(id) { return { type: consultaConstants.SELECCIONAR_CONSULTA_BURBUJA, id } }
}


function cerrarGlobo() {
    return dispatch => {
        dispatch(success());  
    };
    function success() { return { type: consultaConstants.CERRAR_GLOBO } }
}

function toogleGlobo(id) {
    return dispatch => {
        dispatch(success(id));  
    };
    function success(id) { return { type: consultaConstants.TOOGLE_GLOBO, id } }
}

function limpiarBurbujaConsulta() {
    return dispatch => {
        dispatch(success());  
    };
    function success() { return { type: consultaConstants.LIMPIAR_BURBUJAS } }
}

function cambiarEstadoConsulta(id,nuevoEstado) {
    return dispatch => {
        consultaService.cambiarEstadoConsulta(id,nuevoEstado)
            .then(
                nEstado => { 
                    dispatch(success(id,nEstado));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(id,nEstado) { return { type: consultaConstants.ACTUALIZAR_ESTADO_CONSULTA, id,nEstado } }
}


function traerTiposProblema() {
    return dispatch => {
        consultaService.traerTiposProblema()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: consultaConstants.TIPOS_PROBLEMA, data } }
}

function getConsultasPU() {
    return dispatch => {
        consultaService.getConsultasPU()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: consultaConstants.CONSULTAS_GET_PU, data } }
}

function nuevoMensajeChat(json) {
    return dispatch => {       
        let data={
            mensaje:{
                cuerpo:json.mensaje,
                esMsgDeUser:false,
                fecha:json.fecha
            }
        }; 
        console.log(json);
        dispatch(success(data,json.id));  
    };

    function success(data,id) { return { type: consultaConstants.MENSAJE_USUARIO, data,id } }
}

function cargarMensajes(id,esUser,focusearFinal) {
    return dispatch => {
        consultaService.getPedido(id)
            .then(
                data => { 
                    dispatch(success(data));        
                    focusearFinal();                      
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: consultaConstants.CARGAR_MENSAJES, data } }
}



function enviarMensaje(id,mensaje,focusearFinal) {
    return dispatch => {
        consultaService.enviarMensajeUsr(id,mensaje)
            .then(
                data => { 
                    dispatch(success(id,data));    
                    focusearFinal();               
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(id,data) { return { type: consultaConstants.MENSAJE_USUARIO, id,data } }
}


function agregarNuevaConsulta(data) {
    return dispatch => {
           
         dispatch(success(data));      
    };

    function success(data) { return { type: consultaConstants.AGREGAR_NUEVA_CONSULTA,data} }
}

