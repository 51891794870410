import React from 'react';
import ReactDOM from "react-dom"
import { connect } from 'react-redux';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import {Modal,Col,Row} from 'react-bootstrap';
import {alertActions, productoActions} from '../../_actions';
import {ERR, ErrorMessage, LoadingBoton} from '../../_helpers';
import {RadioGroup, Radio} from 'react-radio-group';
import NumberFormat from 'react-number-format';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import Select from 'react-select';
import { productoConstants } from '../../_constants';
import PayPalBtn from './PayPalButton';
import Script from 'react-load-script'

const environment = process.env.REACT_APP_CUSTOM_NODE_ENV;

class FormContratarSuscrip extends React.Component {  

  state = {
    grabando:false,
    paso:0,
    values:{},
    errorTarjeta:undefined,
    erroresMP:[],
    bancos:[],
    idSuscripcionPaypal:'',
    loadingButtonPaypal:false
   };

   constructor(props){
    super(props);
    window.getIssuers = this.getIssuers.bind(this);
    window.getInstallments = this.getInstallments.bind(this);
    window.contratarSuscripcion  = this.props.contratarSuscripcion.bind(this);
    window.contratarSuscripcionPaypal  = this.props.contratarSuscripcionPaypal.bind(this);
    window.traerSuscripcion  = this.traerSuscripcion.bind(this);
    window.traerValues  = this.traerValues.bind(this);
    window.traerValuesPaypal = this.traerValuesPaypal.bind(this);
    window.handleClose  = this.props.handleClose.bind(this);
    window.setGrabando  = this.setGrabando.bind(this);
    window.traerCompra = this.traerCompra.bind(this);
    window.setearBancos= this.setearBancos.bind(this);
    window.setErroresMP = this.setErroresMP.bind(this);
    window.getIdSuscripcionPaypal= this.getIdSuscripcionPaypal.bind(this);
    window.setErrorTarjeta  = this.setErrorTarjeta.bind(this);
    
  }

  setGrabando=(grabando)=>{
    this.setState({grabando:grabando});         
  }

  componentDidMount(){
   
  }

  guessPaymentMethod(event) {
    let cardnumber = document.getElementById("cardNumber").value;      
      if (cardnumber.length >= 6) {
          let bin = cardnumber.substring(0,6);
          window.Mercadopago.getPaymentMethod({
              "bin": bin
          }, this.setPaymentMethod);
      }
  };
 
 setPaymentMethod(status, response) {
   
    if (status == 200) {
        let paymentMethod = response[0];
        document.getElementById('paymentMethodId').value = paymentMethod.id;
       
        window.getIssuers(paymentMethod.id);
        window.setErrorTarjeta(undefined);
    } else {
      window.setErrorTarjeta('Hubo un error con su numero de tarjeta, chequeelo e ingreselo nuevamente');
      console.log('error tarjeta credito:',response);
    }
 }

 setErrorTarjeta = (error)=>{
  this.setState({errorTarjeta:error})
 }
 setErroresMP = (error)=>{
  this.setState({erroresMP:error})
 }
 

 getIssuers= (paymentMethodId)=> {
  window.Mercadopago.getIssuers(
      paymentMethodId,
      this.setIssuers
  );
}

setearBancos=(bancos)=>{
  console.log(bancos);
  this.setState({bancos:bancos});
}

setIssuers(status, response) {
  if (status == 200) {  
      window.setearBancos(response);      
      window.getInstallments(
          document.getElementById('paymentMethodId').value,
          window.traerValues().valueFinal,
          response[0].id
      );
  } else {
      console.log(`issuers method info error: ${response}`);
  }
}

getInstallments(paymentMethodId, transactionAmount, issuerId){
  window.Mercadopago.getInstallments({
      "payment_method_id": paymentMethodId,
      "amount": parseFloat(window.traerValues().valueFinal),
      "issuer_id": parseInt(issuerId)
  }, this.setInstallments);
}

setInstallments(status, response){
  if (status == 200) {
      document.getElementById('installments').options.length = 0;
      response[0].payer_costs.forEach( payerCost => {
          let opt = document.createElement('option');
          opt.text = payerCost.recommended_message;
          opt.value = payerCost.installments;
          document.getElementById('installments').appendChild(opt);
      });
  } else {
    console.log(`installments method info error: ${response}`);
  }
}

getCardToken(){
  document.getElementById('transactionAmount').value = window.traerValues().valueFinal;
  let $form = document.getElementById('paymentForm');
  console.log('FORM QUE GRABO',environment,$form)

  if(environment==='production' || environment==='test')
      window.Mercadopago.createToken($form, this.setCardTokenAndPay);  
  else{
    let values = {
      suscripcion:window.traerSuscripcion(),
      compra:window.traerCompra(),
      values:window.traerValues(),
      response:{id:'5531df9645b9b9ab990d312a00185652'}
    }
    window.contratarSuscripcion(values,window.handleClose,window.setGrabando);
  }  
};

traerSuscripcion(){
  return this.props.suscripcion;
};
traerCompra(){
  return this.props.compra;
};

traerValues(){
  let envio = {
    valueFinal :this.state.values.valueFinal,
    cantidadMeses :this.state.values.cantidadMeses,
    payerEmail:document.getElementById("email").value
  }
  return envio;
};

traerValuesPaypal(){
  let envio = {
    values :this.state.values
  }
  return envio;
};

setCardTokenAndPay(status, response) {
  if (status == 200 || status == 201) {
    //en response.id se encuentra el token que debo enviar MP
    let values = {
      suscripcion:window.traerSuscripcion(),
      compra:window.traerCompra(),
      values:window.traerValues(),
      response:response
    }
    window.contratarSuscripcion(values,window.handleClose,window.setGrabando)
     
  } else {
    if(response.error==='bad_request'){
      let erroresMp = []
      response.cause.forEach((value, index) => {
        if(value.code==="325")
            erroresMp.push('Mes de vencimiento de tarjeta incorrecto')
        if(value.code==="326")
            erroresMp.push('Año de vencimiento de tarjeta incorrecto')
        if(value.code==="E301")
            erroresMp.push('Ingresa un número de tarjeta válido.')
        if(value.code==="E302")
            erroresMp.push('Revisa el código de seguridad.')        
        if(value.code==="316")
            erroresMp.push('Ingresa un nombre válido.')
        if(value.code==="324")
            erroresMp.push('El documento es inválido.')
            
      })
      window.setErroresMP(erroresMp);
    }
  
    console.log("Verify filled data!\n"+JSON.stringify(response, null, 4));
  }
};

pasarValue=(e,setFieldValue,setFieldTouched,campo)=> {
  setFieldTouched(campo,true);
  setFieldValue(campo,e.target.value);
}

activarSegundoPaso=(values)=> {
  
  if(values.medioPago.id===productoConstants.MEDIO_PAGO_MERCADOPAGO){
    const thisDos=this;
    if(!thisDos.props.identificationTypesMP){
        window.Mercadopago.getIdentificationTypes();    
        setTimeout(function (e){
         
          let arryaDocs=[];
          var sel = document.getElementById("docType");
          for (var i=0, n=sel.options.length;i<n;i++) { // looping over the options
            if (sel.options[i].value) arryaDocs.push({'id':sel.options[i].value,'descripcion':sel.options[i].label});
          }
          thisDos.props.guardarDocTypes(arryaDocs);
        },1500)
    }
  }

  if(values.medioPago.id===productoConstants.MEDIO_PAGO_PAYPAL){
    this.setearSuscripcionPaypal(values)
  }
   
}


setearSuscripcionPaypal=(values)=>{
    let idSuscripcion = '';
    if(values.cantidadMeses===1)idSuscripcion=this.props.suscripcion.idSuscripcionPaypalUno;
    if(values.cantidadMeses===3)idSuscripcion=this.props.suscripcion.idSuscripcionPaypalTres;
    if(values.cantidadMeses===6)idSuscripcion=this.props.suscripcion.idSuscripcionPaypalSeis;
    if(values.cantidadMeses===12)idSuscripcion=this.props.suscripcion.idSuscripcionPaypalDoce;

    this.setState({idSuscripcionPaypal:idSuscripcion,loadingButtonPaypal:true})
}

getIdSuscripcionPaypal=()=>{
  return this.state.idSuscripcionPaypal;
}

createSubscription(data, actions) {
    return actions.subscription.create({                                         
      plan_id: window.getIdSuscripcionPaypal()
    });
}



calcularAmount=(suscripcion,values)=>{
  let monto = 0;
  if(values.cantidadMeses===1)monto=suscripcion.costoPaypal;
  if(values.cantidadMeses===3)monto=suscripcion.costoTresPaypal;
  if(values.cantidadMeses===6)monto=suscripcion.costoSeisPaypal;
  if(values.cantidadMeses===12)monto=suscripcion.costoDocePaypal;
  return monto;
}

onApprove(data, detail) {
  console.log('COMPRA APROBADA',data,detail);
  let envio = window.traerValuesPaypal();
  envio.data = data;
  window.contratarSuscripcionPaypal(envio,window.handleClose,window.setGrabando);

}

paypalOnFinish=()=>{
  this.setState({loadingButtonPaypal:false})
}

paypalOnError = (err) => {
  console.warn("Error EN PAYPAL",err)
}

handleScriptCreate= () => {
  this.setState({ scriptLoaded: false })
}
 
handleScriptError= () => {
  this.setState({ scriptError: true })
}
 
handleScriptLoad= () => {
  window.Mercadopago.setPublishableKey("APP_USR-b758ed39-c941-4e46-95ac-1ac10d7edbdf");
  this.setState({ scriptLoaded: true })
}

render() {
      const {suscripcion,identificationTypesMP,esGratis,user,userToken} =this.props;
      const {bancos,values,loadingButtonPaypal} =this.state;     
      const esMercadoPago =  values?.medioPago?.id===productoConstants.MEDIO_PAGO_MERCADOPAGO;
      //Si ya tiene token y no es el usuario padre del mismo, no le permito la carga
      if(userToken!==undefined && user.tieneUsuarioPadre){
        this.props.handleClose();
        this.props.enviarError("Ya posees una suscripcion de la que no eres el dueño, solo podras contratar una nueva cuando no pertenezcas a ninguna suscripción.");
      }

      const {erroresMP} = this.state;
      let inic={
        cantidadMeses: '',
        medioPago:{id:''}
      }

      let valSchema = {};

      if(!esGratis){
        if(this.state.paso===0){
          valSchema.medioPago= Yup.object({id: Yup.string().required(ERR.REQ)});
        }
        if(this.state.paso===1){
          valSchema.cantidadMeses= Yup.number().required('Debe seleccionar cada cuantos meses desea pagar')
        }
        if(this.state.paso===2){
          valSchema.email = Yup.string().email('Ingrese un email valido').required(ERR.REQ).max(100,'Maximo 100 caracteres');
          valSchema.docNumber = Yup.number().typeError('Solo números').required(ERR.REQ).max(999999999,'Maximo 15 caracteres');
          valSchema.cardholderName = Yup.string().required(ERR.REQ).max(100,'Maximo 100 caracteres');
          valSchema.cardExpirationMonth = Yup.number().typeError('Solo números').required(ERR.REQ).max(12,'Maximo 2 caracteres');
          valSchema.cardExpirationYear = Yup.number().typeError('Solo números').required(ERR.REQ).max(9999,'Maximo 4 caracteres');
          valSchema.securityCode = Yup.number().typeError('Solo números').required(ERR.REQ).max(9999,'Maximo 4 caracteres');   
          valSchema.cardNumber = Yup.string().required(ERR.REQ).max(50,'Maximo 50 caracteres');   
        }
      }
      const divStyle = {
        visibility: 'hidden',
     }
      const validationSchema = Yup.object().shape(valSchema);
      //if(compra!==undefined)inic=utilActions.setearValores(inic,compra);
return(

  <div>
<Script
      url="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"
      onCreate={()=>this.handleScriptCreate()}
      onError={()=>this.handleScriptError()}
      onLoad={()=>this.handleScriptLoad()}
    />

        <Modal  size="lg" show={this.props.show} onHide={()=>this.props.handleClose()} backdrop="static" keyboard={false}>
            <Modal.Header className="bg-recursos text-dark" closeButton>
                <Modal.Title>{!esGratis?'Selección de período y Medios de Pago':'Empeza a disfrutar el periodo de prueba!'}</Modal.Title>
            </Modal.Header> 
            <Modal.Body className="th_recursos"> 
           
            <Formik
                enableReinitialize= {true}
                initialValues={inic}
                validationSchema={validationSchema}
                onSubmit={values => {                  
                    values.valueFinal = 0;
                    if(values.cantidadMeses===1)values.valueFinal=suscripcion.costo;
                    if(values.cantidadMeses===3)values.valueFinal=suscripcion.costoTres;
                    if(values.cantidadMeses===6)values.valueFinal=suscripcion.costoSeis;
                    if(values.cantidadMeses===12)values.valueFinal=suscripcion.costoDoce;  
                    values.esGratis = esGratis;
                    values.suscripcion=window.traerSuscripcion();
                    values.compra=window.traerCompra();
                    if(!esGratis){
                      if(this.state.paso===0){
                        this.setState({values:values,paso:1});      
                      }else{
                        if(this.state.paso===1)
                          this.setState({values:values,paso:2},this.activarSegundoPaso(values));                      
                        else
                          this.getCardToken();   
                      }              
                                        
                    }else{                      
                        this.props.contratarSuscripcion(values,window.handleClose,window.setGrabando);
                    }
                   
                    
                }}
              >
                {({ errors, touched,values,setFieldValue,setFieldTouched }) => (
                  <Form id="paymentForm">
                    {esGratis && 
                       <>
                       <Row>
                          <Col sm={12} className="mt-3">
                             <p className="lead text-center">¡Vas a probar por <span className="font-weight-bold">{suscripcion.cantMesesGratis} mes gratis</span> la suscripción!</p>
                             <hr></hr><p className="small text-center">Podrás cargar los datos de pago en cualquier momento para quedar suscripto de manera definitiva</p> <hr></hr>
                          </Col>
                      </Row>
                      <Row>
                          <Col sm={12}>
                            <div className="justify-content-center mt-3 p-3 row border-top bg-light">
                            <div  id="issuerInput">
                                <button className="btns btns-success" type="submit" disabled={this.state.grabando} >
                                <span className="material-icons align-middle">done_all</span> Empezar a usar gratis
                                    <LoadingBoton grabando={this.state.grabando}  />
                                </button>
                              </div>
                            </div>
                          </Col>
                      </Row>
                      </>
                      }
                      {(this.state.paso===0 && !esGratis ) &&
                        <>
                            <Row>
                              <Col sm={12}>
                                <label><span className="material-icons aling-middle">filter_1</span> Seleccioná el medio de pago</label>
                                  <hr></hr><ErrorMessage name={`cantidadMeses`} />

                                  <RadioGroup name="medioPago.id" selectedValue={values.medioPago.id}
                                  onChange={(value)=> setFieldValue(`medioPago.id`,value)}>                                      
                                          <div > 
                                              <Radio className="option-input checkbox" id={"merdioP"+1} value={productoConstants.MEDIO_PAGO_MERCADOPAGO} />
                                              <label className="label-check" htmlFor={"merdioP"+1}>Mercado pago</label>
                                              <hr></hr>
                                              <Radio className="option-input checkbox" id={"merdioP"+2} value={productoConstants.MEDIO_PAGO_PAYPAL} />
                                              <label className="label-check" htmlFor={"merdioP"+2}>Paypal</label>
                                              <hr></hr>
                                            </div>
                                  </RadioGroup>   
                              </Col>
                            </Row>
                            <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                            <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                            <i className="icon-times"></i> Cerrar
                            </button>
                            <button className="btns btns-secundario" type="submit" >
                            <span className="material-icons align-middle">credit_card</span> Seleccionar plataforma de pago
                            </button>
                          </div>
                         </>
                      }    
                     {(this.state.paso===1 && !esGratis ) &&
                      <>
                       
                      <Row>
                          <Col sm={12}>
                            <label><span className="material-icons aling-middle">filter_1</span> Seleccioná el período de supscripción</label>
                              <hr></hr><ErrorMessage name={`cantidadMeses`} />

                              <RadioGroup name="cantidadMeses" selectedValue={values.cantidadMeses}
                              onChange={(value)=> setFieldValue(`cantidadMeses`,value)}>                                      
                                    <div > 
                                        <Radio className="option-input checkbox" id={"cat"+1} value={1} />
                                        <label className="label-check" htmlFor={"cat"+1}>Mensual</label>
                                        <hr></hr>
                                        <Radio className="option-input checkbox" id={"cat"+2} value={3} />
                                        <label className="label-check" htmlFor={"cat"+2}>Trimestral</label>
                                        <hr></hr>
                                        <Radio className="option-input checkbox" id={"cat"+3} value={6} />
                                        <label className="label-check" htmlFor={"cat"+3}>Semestral</label>
                                        <hr></hr>
                                        <Radio className="option-input checkbox" id={"cat"+4} value={12} />
                                        <label className="label-check" htmlFor={"cat"+4}>Anual</label>
                                        <hr></hr>
                                        </div> 
                              </RadioGroup>   
                          </Col>
                           {values.cantidadMeses!=='' && <Col sm={12}>
                           <h6 htmlFor="cantidad"><Badge variant="secondary" className="txt-dark">Costo</Badge></h6>
                              {values.cantidadMeses===1 &&
                                <div>
                                  <Row>
                                    <Col xs={12}>
                                    <small className="text-dark">Te debitaremos desde <span className="font-weight-bold text-cielo"> {esMercadoPago?'Mercado Pago': 'Paypal'}</span> todos los meses </small>
                                    </Col>
                                    <Col xs={12}>
                                      <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald"><NumberFormat prefix={esMercadoPago?'$': 'USD'} value={esMercadoPago?suscripcion.costo: suscripcion.costoPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /> </h3>                   
                                    </Col>
                                    <Col xs={12} className="img-mercado">
                                    <Image src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"/>
                                  </Col>
                                  </Row>
                                </div>
                                
                              }
                               {values.cantidadMeses===3 &&
                                <div>
                                  <Row>
                                    <Col xs={12}>
                                    <small className="text-dark">Te debitaremos desde <span className="font-weight-bold text-cielo"> {esMercadoPago?'Mercado Pago': 'Paypal'}</span> cada 3 meses </small>
                                    </Col>
                                    <Col xs={12}>
                                      <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald"><NumberFormat prefix={esMercadoPago?'$': 'USD'} value={esMercadoPago?suscripcion.costoTres: suscripcion.costoTresPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></h3>
                                    <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald">Costo mensual de: <NumberFormat  decimalScale={2}  className="text-dark txt-22" prefix={esMercadoPago?'$': 'USD'} value={(esMercadoPago?suscripcion.costoTres: suscripcion.costoTresPaypal)/3} displayType={'text'} thousandSeparator="." decimalSeparator="," /></h3>                    
                                    </Col>
                                    <Col xs={12} className="img-mercado">
                                    <Image src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"/>
                                  </Col>
                                  </Row>
                                </div>
                              }
                               {values.cantidadMeses===6 &&
                               <div>
                                 <Row>
                                    <Col xs={12}>
                                    <small className="text-dark">Te debitaremos desde <span className="font-weight-bold text-cielo"> {esMercadoPago?'Mercado Pago': 'Paypal'}</span> cada 6 meses </small>
                                    </Col>
                                    <Col xs={12}>
                                      <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald"><NumberFormat prefix={esMercadoPago?'$': 'USD'} value={esMercadoPago?suscripcion.costoSeis: suscripcion.costoSeisPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," /></h3>  
                                    <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald">Costo mensual de: <NumberFormat decimalScale={2} className="text-dark txt-22" prefix={esMercadoPago?'$': 'USD'} value= {(esMercadoPago?suscripcion.costoSeis: suscripcion.costoSeisPaypal)/6} displayType={'text'} thousandSeparator="." decimalSeparator="," /> </h3>                    
                                    </Col>
                                    <Col xs={12} className="img-mercado">
                                    <Image src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"/>
                                  </Col>
                                  </Row>
                              </div>
                              }
                              {values.cantidadMeses===12 &&
                                <div>
                                  <Row>
                                    <Col xs={12}>
                                    <small className="text-dark">Te debitaremos desde <span className="font-weight-bold text-cielo"> {esMercadoPago?'Mercado Pago': 'Paypal'}</span> cada 12 meses </small>
                                    </Col>
                                    <Col xs={12}>
                                      <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald"> <NumberFormat prefix={esMercadoPago?'$': 'USD'}  value={esMercadoPago?suscripcion.costoDoce: suscripcion.costoDocePaypal}  displayType={'text'} thousandSeparator="." decimalSeparator="," /> </h3>  
                                    <hr></hr>
                                    <h3 className="txt-34 text-center txt-oswald">Costo mensual de: <NumberFormat  decimalScale={2} prefix={esMercadoPago?'$': 'USD'} value={(esMercadoPago?suscripcion.costoDoce: suscripcion.costoDocePaypal)/12} displayType={'text'} thousandSeparator="." decimalSeparator="," /></h3>                    
                                    </Col>
                                    <Col xs={12} className="img-mercado">
                                    <Image src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"/>
                                  </Col>
                                  </Row>
                                </div>
                              }
                          </Col>}                       
                      </Row> 
                      <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                        <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                        <i className="icon-times"></i> Cerrar
                        </button>
                        {this.state.paso===1 &&<button className="btns btns-secundario" type="submit" >
                        <span className="material-icons align-middle">credit_card</span> Seleccionar medio de pago 
                        </button>}
                      </div>
                      </>
                }
                      {(this.state.paso===2 && !esGratis && esMercadoPago) && <Row>
                          <Col sm={12}>
                            <div className="img-mercado mb-5"><Image src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"/></div>
                        
                              <h4>Detalles del comprador</h4>
                              <Row>
                                <Col sm={12}>
                                    <label htmlFor="email">E-mail</label>
                                    <input id="email" name="email" type="text" onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'email')}/>
                                    <ErrorMessage name={`email`} />
                                </Col>
                                <Col sm={12}>
                                    <label htmlFor="docType">Tipo de documento</label>
                                    <select id="docType" name="docType" data-checkout="docType" type="text">                                  
                                       {identificationTypesMP!==undefined && identificationTypesMP.map((doc, indexS)=>
                                            <option key={doc.id} value={doc.id}> 
                                                {doc.descripcion}
                                            </option> 
                                        )}
                                    </select>
                                </Col>
                                <Col sm={12}>
                                    <label htmlFor="docNumber">Número de documento</label>
                                    <input id="docNumber" name="docNumber" data-checkout="docNumber" type="text" onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'docNumber')}/>
                                    <ErrorMessage name={`docNumber`} />
                                </Col>
                              </Row>  
                              <h4>Detalles de la tarjeta</h4>
                              <Row>
                                <Col sm={12}>
                                    <label htmlFor="cardholderName">Titular de la tarjeta</label>
                                    <input id="cardholderName" data-checkout="cardholderName" type="text"  onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'cardholderName')} />
                                    <ErrorMessage name={`cardholderName`} />
                                </Col>
                                <Col sm={6}>
                                    <label htmlFor="">Fecha de vencimiento</label>
                                    <div>
                                      <Row>
                                        <Col xs={6}>
                                        <input type="number" placeholder="MM" id="cardExpirationMonth" data-checkout="cardExpirationMonth"  onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'cardExpirationMonth')} 
                                       autoComplete="off" maxLength="2"/>
                                        <ErrorMessage name={`cardExpirationMonth`} />
                                        </Col>
                                        {/* <Col><span className="date-separator">/</span></Col> */}
                                        <Col xs={6}><input type="number" placeholder="YY" id="cardExpirationYear" data-checkout="cardExpirationYear"  onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'cardExpirationYear')} 
                                        autoComplete="off" maxLength="2" />
                                         <ErrorMessage name={`cardExpirationYear`} />
                                        </Col>
                                      </Row>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                     <label htmlFor="securityCode">Código de seguridad</label>
                                    <input id="securityCode" data-checkout="securityCode" type="number" onChange={(e)=>this.pasarValue(e,setFieldValue,setFieldTouched,'securityCode')} 
                                      autoComplete="off" maxLength="3" />
                                       <ErrorMessage name={`securityCode`} />
                                </Col>
                                <Col sm={12}>
                                    <label htmlFor="cardNumber">Número de la tarjeta</label>
                                    <input type="text" id="cardNumber" data-checkout="cardNumber" onChange={(e)=>{
                                      this.guessPaymentMethod(e);
                                      this.pasarValue(e,setFieldValue,setFieldTouched,'cardNumber');}}
                                     autoComplete="off" />
                                      <ErrorMessage name={`cardNumber`} />
                                      {this.state.errorTarjeta &&
                                      <div className="help-block text-danger">
                                        <i className="fas fa-exclamation-circle"></i>
                                         {this.state.errorTarjeta}
                                      </div> }
                                </Col>
                                <Col sm={12}  id="issuerInput">
                                    <label htmlFor="issuer">Banco emisor</label>
                                    <Select id="issuer" data-checkout="issuer" name="issuer"
                                        isClearable
                                        value={bancos ? bancos.find(option => option.id === values.issuer) : ''}
                                        getOptionLabel={option =>`${option.name}`}
                                        getOptionValue={option => option.id}
                                        onChange={so =>{
                                            setFieldValue(`issuer`, so?so.id:[])
                                        }}
                                        isSearchable={true}
                                        placeholder="Seleccione"
                                        options={bancos}
                                    /> 
                                </Col> 
                                <Col sm={12}  id="installmentsInput" style={divStyle}>
                                  <label htmlFor="installments">Cuotas</label>
                                  <select type="text" id="installments" name="installments"></select>
                                </Col>                              
                              </Row>

                                
                             {(erroresMP!==undefined && erroresMP.length>0)&& <Row>
                                <Col sm={12}>
                                {erroresMP.map((errorMP, index)=> 
                                  <div className="help-block text-danger">
                                  <i className="fas fa-exclamation-circle"
                                  ></i> {errorMP}</div> 
                                )}
                                       
                                </Col>                              
                              </Row>}
                              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                <div  id="issuerInput">
                                     <button className="btns btns-success" type="submit" disabled={this.state.grabando} >
                                     <span className="material-icons align-middle">done_all</span> Finalizar compra
                                        <LoadingBoton grabando={this.state.grabando}  />
                                    </button>
                                  </div>
                              </div>
                              <input type="hidden" name="transactionAmount" id="transactionAmount"/>
                              <input type="hidden" name="paymentMethodId" id="paymentMethodId" />
                              <input type="hidden" name="description" id="description" />
                                   
                          </Col>
                          </Row>
                      }
                      {(this.state.paso===2 && !esGratis && !esMercadoPago) && 
                          <Row>
                            <Col sm={12}> 
                               <LoadingBoton grabando={loadingButtonPaypal} />
                               <PayPalBtn
                                  amount = {this.calcularAmount(suscripcion,values)}
                                  currency = "USD"
                                  createSubscription={this.createSubscription}
                                  onApprove={this.onApprove}
                                  catchError={this.paypalOnError}
                                  onError={this.paypalOnError}
                                  onCancel={this.paypalOnError}
                                  onButtonReady={this.paypalOnFinish}
                                />
                            </Col>
                          </Row>
                      }
                       
                       </Form>
                )}
              </Formik>
              
                   
            </Modal.Body>                     
        </Modal> 

  </div>
)};
}

function mapState(state) {
  const { user,userToken } = state.authentication;
  const { mediosPago,identificationTypesMP } = state.producto;
  return {mediosPago,identificationTypesMP,user,userToken};
}

const actionCreators = {
  contratarSuscripcion:productoActions.contratarSuscripcion,
  contratarSuscripcionPaypal:productoActions.contratarSuscripcionPaypal,
  guardarDocTypes:productoActions.guardarDocTypes,
  enviarError:alertActions.enviarError
}

const connectedFormContratarSuscrip = connect(mapState, actionCreators)(FormContratarSuscrip);
export { connectedFormContratarSuscrip as FormContratarSuscrip };