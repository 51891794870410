import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal,Card} from 'react-bootstrap';
import { eventoActions, alertActions, equipoActions } from '../../_actions';
import { eventoConstants } from '../../_constants';
import {FormEvento} from '..';
import InfoEvento from './InfoEvento';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import { alertConfirm, esConFull, esSinToken,esConIntermedio } from '../../_helpers';

class MisEventos extends React.Component {

    constructor(props){
       super(props);       
       this.handleShowConfirm=this.handleShowConfirm.bind(this);
    }

    state = {
        show: false,        
        alert:null,
        evento:{},
        showMasInfo:false
    };

    componentDidMount(){
        if(this.props.evento.misEventos===undefined)this.props.getEventosPU();
    }

    cerrarAlert = () => {
        this.setState({evento:{},alert: null});
    } 

    handleClose = () => {
        this.setState({show: false});
    };

    handleShow = () => {
        this.setState({
            evento:{},
            show: true
        });
    };

    handleShowEditar = (item) => {
        this.setState((prevState) => {
            return {
                show: true,
                evento:item
            };
        });
    };

    handleShowMasInfo = (item) => {
        this.setState((prevState) => {
            return {
                showMasInfo: true,
                evento:item
            };
        });
    };

    handleCloseMasInfo = () => {
        this.setState((prevState) => {
            return {
                evento:{},
                showMasInfo: false
            };
        });
    }
    eliminarEvento = () => {      
        this.props.eliminarEvento(this.state.evento);
        this.cerrarAlert();
    };

    handleShowConfirm = (data) => {
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${data.nombre}?`,
            'Sí, eliminar',
            'No',
            this.cerrarAlert,
            this.eliminarEvento,
            null,
            true
        )  
        this.setState((prevState) => {            
            return {
                evento:data,
                alert: getAlert
            };
        });
    }

    handleChange(event,idEvento) {
        this.props.toogleEvento(idEvento, event);      
    }
   

    render(){
        const { evento,userToken } = this.props;
        const conToken = !esSinToken(userToken);       

        const hayAcceso = (esConIntermedio(userToken) || esConFull(userToken));
        return (
            <div className="main-content">
                <Container fluid>   
                     {hayAcceso &&<Row className="mb-5 mt-5">
                        <Col xs={12}>
                            <button className="btns btns-arandano bg-gradient-evento text-white" onClick={this.handleShow}>
                            <i className="icon-modem align-middle txt-22"></i> Nuevo evento
                            </button>
                        </Col>
                    </Row>  
                     }               
                    <Row>     
                        <div className="cont-sensor mt-4">
                            <div className="d-flex flex-wrap  justify-content-center">
                                {evento.misEventos!==undefined && evento.misEventos.map((eventItem, index)=>                            
                                    <Col key={index} lg={4} xl={4} sm={12} md={6} className="mb-4">                                        
                                                    <div className="text-white th_arandano hoverable card-components shadow">
                                                    <Card.Header>
                                                        <Row>
                                                            <Col xs={9}>
                                                            <h3 className="txt-oswald font-weight-light text-truncate"> 
                                                                <i className="icon-modem txt-16 text-arandano aling-middle"></i> 
                                                                {eventItem.nombre}                                                          
                                                            </h3>
                                                            </Col>
                                                        </Row>
                                                        <div className=" txt-oswald font-weight-light"> 
                                                            {hayAcceso &&<label>
                                                                <Switch onChange={(event)=>this.handleChange(event,eventItem.id)} checked={eventItem.habilitado} onColor="#73c479" />
                                                            </label>}                                                           
                                                        </div>
                                                    </Card.Header>
                                                            <Card.Body>
                                                            <Card.Text className="text-muted font-weight-bold">
                                                                {eventItem.tipoEvento.id===eventoConstants.EVENTO_PROGRAMADO &&
                                                                    <>
                                                                        {eventItem.periodo.descripcion}
                                                                         <br/>
                                                                            Horario ejecución: <b>{eventItem.hora<10?'0'+eventItem.hora:eventItem.hora}:{eventItem.minuto<10?'0'+eventItem.minuto:eventItem.minuto}</b>
                                                                         <br/>
                                                                    </>
                                                                }
                                                                 {eventItem.tipoEvento.id===eventoConstants.EVENTO_AUTOMATICO &&
                                                                    <>  
                                                                        Condición:<br/>
                                                                        {eventItem.sensor.labelUsuario}<br/>
                                                                        {eventItem.condicion.descripcion} que {eventItem.valor}
                                                                        <br/>                                                                        
                                                                    </>
                                                                }
                                                            </Card.Text>
                                                            </Card.Body>
                                                            
                                                    </div>
                                                                                                                                                             
                                                    {hayAcceso &&<div className="edit">
                                                    <> 
                                                    <a data-tip data-for='elimina'>
                                                    <i className="fas fa-trash-alt mr-2 cursor" onClick={() => this.handleShowConfirm(eventItem)}></i>
                                                    </a>
                                                    <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                    <span>Elimina Equipo</span>
                                                    </ReactTooltip>                   
                                                    </>
                                                    <> 
                                                    <a data-tip data-for='edita'>
                                                        <i className="fas fa-pencil-alt mr-2 cursor" onClick={() => this.handleShowEditar(eventItem)}></i>
                                                        </a>
                                                    <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                    <span>Editar Equipo</span>
                                                    </ReactTooltip> 
                                                    </>    
                                                    <> 
                                                    <a data-tip data-for='info'>
                                                        <i className="fas fa-info-circle mr-2" onClick={()=>this.handleShowMasInfo(eventItem)}></i>
                                                        </a>
                                                    <ReactTooltip place="bottom" id='info' aria-haspopup='false' >
                                                    <span>InfoEquipo</span>
                                                    </ReactTooltip> 
                                                    </> 
                                                    </div>}
                                        </Col>                        
                                    )}  
                            </div> 
                        </div>
                    </Row>
                    <Row className="justify-content-center th_arandano">
                        <div className="cont-sensor mt-4">
                        <div className="d-flex flex-wrap  justify-content-center">
                    {(evento.misEventos===undefined || evento.misEventos.length===0) &&
                      
                        <>
                            <Col xs={12} md={7} lg={5}>
                            <div className="card-components border-left-c  fadeIn">
                                <Row className="p-3 ">
                                    <Col xs={2} className="text-center p-2">
                                    <i className="icon-bell txt-42 icon-gradient-evento "></i>
                                    </Col>
                                    <Col xs={10} className="text-right p-2">
                                        <span className="text-muted txt-12"> MIS EVENTOS</span> <br></br> 
                                        <span className="text-white txt-18 font-weight-bold"> NO EXISTEN EVENTOS DEFINIDOS</span> 
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                            {!hayAcceso &&
                                <Col xs={12} md={7} lg={5}>
                                    <div className="card-components border-left-c  fadeIn">
                                <Row className="p-3 ">
                                    <Col xs={2} className="text-center p-2">
                                    <i className="icon-bell txt-42 icon-gradient-evento "></i>
                                    </Col>
                                    <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS EVENTOS</span> <br></br> 
                                    <span className="text-white txt-18 font-weight-bold">Tu tipo de suscripción
                                    no permite utilizar esta sección, pasate a Full ya!</span> 
                                    </Col>
                                </Row>
                                </div>
                            </Col>}
                            {!conToken &&
                            <Col xs={12} md={7} lg={5}>
                                <div className="card-components border-left-c  fadeIn">
                            <Row className="p-3 ">
                                <Col xs={2} className="text-center p-2">
                                <i className="icon-bell txt-42 icon-gradient-evento "></i>
                                </Col>
                                <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS EVENTOS</span> <br></br> 
                                <span className="text-white txt-18 font-weight-bold">Contrata ya una suscripción full!</span> 
                                </Col>
                            </Row>
                            </div>
                        </Col>}
                        </> 
                    }
                    </div></div></Row>
                    
                </Container>

                {this.state.show && <Modal size="lg" show={this.state.show} onHide={this.handleClose}  backdrop="static">
                        <Modal.Header className="bg-gradient-evento text-white" closeButton>
                            <Modal.Title><i className="icon-modem"></i> Nuevo evento</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <FormEvento evento={this.state.evento}  handleClose={this.handleClose}/>
                        </Modal.Body>                     
                    </Modal>} 

                {this.state.showMasInfo && <InfoEvento evento={this.state.evento} show={this.state.showMasInfo} handleClose={this.handleCloseMasInfo} />}

                {this.state.alert}
             
            </div>
        );
    }
}

function mapState(state) {
    const { userToken } = state.authentication;
    const {equipos, evento } = state;
    return {equipos, evento,userToken };
}

const actionCreators = {
    agregarEventoGenerado:eventoActions.agregarEventoGenerado,
    enviarError:alertActions.enviarError,
    eliminarEvento:eventoActions.eliminarEvento,
    toogleEvento:eventoActions.toogleEvento,
    getEventosPU:eventoActions.getEventosPU,
    getEquiposPU: equipoActions.getEquiposPU
}

const connectedMisEventos = connect(mapState, actionCreators)(MisEventos);
export { connectedMisEventos as MisEventos };