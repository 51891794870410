import React from 'react';
import { connect } from 'react-redux';
import { sensorActions } from '../../_actions';
import {Row,Col} from 'react-bootstrap';
import { sensorConstants, equipoConstants } from '../../_constants';
import {EditarSensor} from './EditarSensor';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { Graficador, RoleID, TIPO_DATO } from '../../_helpers';
import ReactTooltip from 'react-tooltip';
import PuffLoader from "react-spinners/PuffLoader";
import _ from 'lodash';

class DesarrolladorCard extends React.Component {

    constructor(props) { 
       super(props);      
       this.updateDimmer = _.debounce(this.updateDimmer, 100);
    }
    state = {
        show:false,
        texto:'',
        longitud:''
    };

    handleChange(event,tipo) {
        tipo===1? this.setState({texto: event.target.value}): this.setState({longitud: event.target.value});
       
    }

    seleccionarSensor(){
        this.props.seleccionarSensor(this.props.sensor);
    };

    updateDimmer =(value)=> {
        this.props.enviarASensor(this.props.sensor,value,undefined,false);
    }

    enviarASensor(){
        if(!this.props.sensor.conectado || this.state.texto===undefined || this.state.texto==='')return;
        this.props.enviarASensor(this.props.sensor,this.state.texto,this.state.longitud,false);
        this.setState({
            texto: '',
            longitud:''
        }); 
    };

    render(){
      
        const {sensor,modo,seleccionado,modoSuperSimple,layouts}= this.props;
        const {ultimoValor,fechaUV}= sensor;  
        const soyDeveloperdeEsteEquipo = seleccionado?seleccionado.idRol===RoleID.DEV:false;
        const unidad = sensorConstants.style[sensor.tipoSensor.id].unidad;
        const claseColor = sensorConstants.style[sensor.tipoSensor.id].color;
        const esMapa= sensor.tipoWidget.id===sensorConstants.TIPO_WIDGET_MAPA;
        const linkInfoSensor = '/home/infoSensor';

        return (
        
        <>
            <div className="conexion text-white font-weight-bold txt-12">
            {(sensor.conectado) && <><span className="text-recursos-si si"> <PuffLoader size={12} color={"#0ca084"}/> CONECTADO</span></>} 
            {(!sensor.conectado) && <><span className="badge bg-danger no"><span className="material-icons align-middle text-white txt-14">wifi_off</span> NO CONECTADO</span></>}
            </div> 
                <div className={"card-components shadow "+claseColor +(!sensor.conectado && ' disabled shadow-none th_silver')}>
                    <div className="card-header">
                        <Row>
                            <Col xs={9} className="draggableZoneIot">
                                <h6 className="text-white font-weight-bold text-truncate">
                                    <i className={sensorConstants.style[sensor.tipoSensor.id].icono}></i>
                                    {sensor.labelShadow}                                   
                                </h6>   
                            </Col>
                            <div className="edit-sensor text-white">                            
                                <EditarSensor sensor={sensor} seleccionado={seleccionado} modoSuperSimple={modoSuperSimple} />
                                <Link data-tip data-for='chart' className="btn btn-circular " to={linkInfoSensor+'?id='+sensor.id} onClick={(event) => this.seleccionarSensor(event)}>
                                    <i className="icon-stats-dots"></i>
                                    <ReactTooltip place="bottom" id='chart' aria-haspopup='true' >
                                    <span>Ver Gráficos</span>
                                    </ReactTooltip>
                                </Link>
                            </div>
                   
                        </Row>
                    </div>
                        
                    {(modo===equipoConstants.TAB_DASHBOARD) && 
                   
                        <div className="card-body">
                            <Row className="align-items-center">
                                <Col xs={12} className="mt-3">
                                    <div className="datos"> 
                                        {sensor.tipoWidget.id!==sensorConstants.TIPO_WIDGET_GRAFICO &&<span className="text-muted"> 
                                            {fechaUV && <small> <Moment date={fechaUV} format="DD/MM/YYYY HH:mm:ss"/></small>}
                                        </span>}
                                         {ultimoValor!==undefined &&                                                            
                                            <div className="speedo widgets onoff mt-3">                                                
                                                <Graficador tipoWidget={sensor.tipoWidget.id} 
                                                    layouts={layouts}
                                                    sensor={sensor}
                                                    enviarASensor={this.props.enviarASensor}
                                                    updateDimmer={this.updateDimmer}                                                    
                                                    width={220} 
                                                    height={160}
                                                    valorMinReferencia={parseFloat(sensor.valorMinReferencia)} 
                                                    valorMaxReferencia={parseFloat(sensor.valorMaxReferencia)}
                                                    valor={ultimoValor}
                                                    unidad={unidad} />                                            
                                            </div>
                                        }  
                                    </div>
                                </Col>
                            </Row>
                           {(sensor.tipoSensor.id===sensorConstants.DESARROLLADOR && soyDeveloperdeEsteEquipo && sensor.tipoWidget.id!==sensorConstants.TIPO_WIDGET_GRAFICO) && 
                           <>
                           <Row className=" mt-2  justify-content-center">
                                <Col sm={9} className="text-center send">
                                <div className="input-group mb-4">
                                        <div className="input-group-prepend right-0">
                                            <div className="input-group-text cursor"> 
                                            <a data-tip data-for={'send'+sensor.id}>
                                                <i className="fas fa-paper-plane" onClick={(event) => this.enviarASensor(event)}></i>
                                                <ReactTooltip place="bottom"  id={'send'+sensor.id} aria-haspopup='true' >
                                                    <span>Enviar Mensaje</span>
                                                </ReactTooltip>
                                            </a> 
                                           
                                            </div>
                                            </div>
                                            <>
                                            {esMapa&&
                                            <span className='text-white'>Latitud</span>}
                                            <input className="font-weight-bold" type="text" value={this.state.texto} onChange={(event)=>this.handleChange(event,1)} />
                                            {esMapa && 
                                                <>
                                                    <span className='text-white'>Longitud</span>
                                                    <input className=" font-weight-bold" type="text" value={this.state.longitud} onChange={(event)=>this.handleChange(event,2)} />
                                                </>
                                            }
                                            </>
                                            <div>                                   
                                                {(sensor.tipoDato.id===TIPO_DATO.JSON && this.state.texto && !esMapa) &&
                                                    <small className={'text-white'}>{`{'${sensor.variableJson}':${this.state.texto}}`}</small>}
                                                {((this.state.texto || this.state.longitud) && esMapa) &&
                                                    <small className={'text-white'}>{`{'${sensor.variableJson}':${this.state.texto},'${sensor.variableLongitud}':${this.state.longitud}}`}</small>}
                                            </div>
                                    </div>
                                    
                                </Col>
                            </Row>
                            </>}
                        </div>
                        }
                    
                </div>
                </>
        );
    }
}

function mapState(state) {   
    return {  };
}

const actionCreators = {
    seleccionarSensor:sensorActions.seleccionarSensor,
    enviarASensor:sensorActions.enviarASensor
}

const connectedDesarrolladorCard = connect(mapState, actionCreators)(DesarrolladorCard);
export { connectedDesarrolladorCard as DesarrolladorCard };