import React from 'react';
import { CSVLink } from "react-csv";

export default class CsvExporter extends React.Component {

    render(){
        return (

            <div>   
                {this.props.data.length ? 
                <CSVLink data={this.props.data}
                 className="btns btn-csv"
                 target="_blank"
                 filename={"sensor.csv"}
                 ><i className="fas fa-cloud-download-alt"></i></CSVLink> : null }   
                           
            </div>
        );
    }
}
