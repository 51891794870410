import http from '../_helpers/http.service';

export const vistaService = {
    getVistasPU,
    grabarVista,
    eliminarVista,
    eliminarSensorDeVista
};

function getVistasPU() {    
    var url = '/api/vistas/pu';    
    return http.get(url);
}

function grabarVista(values) {    
    var url = '/api/vistas/grabar';    
    return http.post(url,JSON.stringify(values));    
}

function eliminarVista(values) {    
    var url = '/api/vistas/eliminar';
    return http.post(url,JSON.stringify(values));    
}

function eliminarSensorDeVista(vista,sensor) {    
    var url = '/api/vistas/eliminarSensorDeVista';
    return http.post(url,JSON.stringify({idVista:vista.id,idSensor:sensor.id}));    
}
