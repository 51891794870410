import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history} from '../../_helpers'; 
import {
    isBrowser,
    isMobile
  } from "react-device-detect";

class NavBarLateralTienda extends React.Component {
    
    constructor(props){
        super(props);
        this.toogleClasses = this.toogleClasses.bind(this);        
        this.state.active =!isMobile;
        
        this.state.pathActual=history.location.pathname;
        this.wrapperRef = React.createRef();
        history.listen((location, action) => {
             this.setState({pathActual:location.pathname});
        });
     }
    state = {
        active:false
    };     

    toogleClasses(event){
        this.setState({active: !this.state.active});
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) 
             this.setState({active: false});        
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    miClase = (link)=>{
        if(link===this.state.pathActual) return 'iconmenu'
        else return ''
    }
    render(){
        
        const {carritoCompra} = this.props;

        return (
            <nav ref={this.wrapperRef} className={this.state.active ? 'collapsed navbar-primary': 'navbar-primary'}>
                <div className="btn-expand-collapse item" onClick={(event) => this.toogleClasses(event)}>
                    <button className={this.state.active ? 'active flash hamburger hamburger-arrow-left': 'hamburger hamburger-arrow-left'}>
                        <span className="icon"></span>
                    </button>
                </div>
                <ul className="navbar-primary-menu">
                    {/*<li className="text-white mt-4">
                        <Link to="/tienda/carrito" className={this.miClase('/tienda/carrito')}>
                        <span className="material-icons">add_shopping_cart</span>
                             <div className="nav-label font-weight-bold"> Mi carrito compra</div>
                             {(carritoCompra!==undefined && carritoCompra.length>0)&&<Badge className="bg-fucsia text-white">{carritoCompra.length}</Badge>}
                       </Link>
                    </li>*/}
                    <li  className={this.state.active ? 'text-white  mt-4': ' text-white desplazar mt-4'}>
                    <Link to="/tienda/suscripcion" className={this.miClase('/tienda/suscripcion')} onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                    <div className="menu-circle-item"><span className="material-icons material-icons-outlined">ballot</span></div>
                        <div className="nav-label font-weight-bold"> Suscripciones</div>
                       </Link>
                    </li>   
                   {/* <li className="text-white">
                    <Link to="/tienda/productos" className={this.miClase('/tienda/productos')}>
                    <span className="material-icons material-icons-outlined">apps</span>
                        <div className="nav-label font-weight-bold">Productos</div>
                       </Link>
                </li>*/ }                 
                    <li className={this.state.active ? 'text-white ': ' text-white desplazar'}> 
                        <Link to="/tienda/misCompras" className={this.miClase('/tienda/misCompras')} onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                        <div className="menu-circle-item"><span className="material-icons material-icons-outlined">local_mall</span></div>
                        <div className="nav-label font-weight-bold"> Mis Compras</div>
                       </Link>
                    </li>               
                </ul>
            </nav>
        );
    }
}
function mapState(state) {       
    const { carritoCompra } = state.producto;
    return {carritoCompra };
}

const actionCreators = {    
}

const connectedNavBarLateralTienda = connect(mapState, actionCreators)(NavBarLateralTienda);
export { connectedNavBarLateralTienda as NavBarLateralTienda };