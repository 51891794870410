import { grupoConstants,userConstants } from '../_constants';
import { grupoService } from '../_services';
import { alertActions } from '.';
export const grupoActions = {
    getListadosGrupoPU,
    asignarRol,
    eliminarRol,
    reasignarRol,
    eliminarUsuario,
    agregarUsuario
};

function getListadosGrupoPU() {
    return dispatch => {
        grupoService.getListadosPU()
            .then(
                listados => { 
                    dispatch(success(listados));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(listados) { return { type: grupoConstants.GRUPO_GETPU_SUCCESS, listados } }
}



function agregarUsuario(values, handleExito) {
    return dispatch => {
        grupoService.agregarUsuario(values)
            .then(
                respuesta => { 
                    
                    if(respuesta.status==1){
                        dispatch(getListadosGrupoPU());  
                        handleExito();
                    }       
                    else dispatch(alertActions.enviarError(respuesta.message));                      
                  
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };
}


function eliminarUsuario(values) {
    return dispatch => {
        grupoService.eliminarUsuario(values)
            .then(
                data => {   
                    localStorage.setItem('userLogged',JSON.stringify(data.usuario));
                    dispatch(successMasinfo(data.usuario))
                    dispatch(getListadosGrupoPU());
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function successMasinfo(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
}
function asignarRol(item,rol) {
    return dispatch => {
        grupoService.asignarRol(item,rol)
            .then(
                respuesta => {        
                    if(respuesta.status==1){
                        dispatch(success(item.id)); 
                        dispatch(getListadosGrupoPU());  
                    }       
                    else dispatch(alertActions.enviarError(respuesta.message));                 
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(id) { return { type: grupoConstants.REMOVER_AUTORIZACION, id } }
}

function reasignarRol(item,rol,rel) {
    return dispatch => {
        grupoService.reasignarRol(item,rol,rel)
            .then(
                data => {                    
                    dispatch(getListadosGrupoPU());                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };
}

function eliminarRol(idEquipo,idRol,idUsuario) {
    return dispatch => {
        grupoService.eliminarRol(idEquipo,idRol,idUsuario)
            .then(
                data => {    
                    localStorage.setItem('userLogged',JSON.stringify(data.usuario));                
                    dispatch(successMasinfo(data.usuario));
                    dispatch(getListadosGrupoPU());             
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };
    function successMasinfo(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
}



