import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field  } from 'formik';
import * as Yup from 'yup';
import {Col,Row} from 'react-bootstrap';
import 'react-widgets/dist/css/react-widgets.css';
import {sensorActions,utilActions} from '../../../_actions';
import { LoadingBoton,ErrorMessage } from '../../../_helpers';
import WidgetChart from './WidgetChart';

class GraficoSimple extends React.Component {

  constructor(props){      
    super(props);
    this.state.cantidadHoras = this.props.sensor.cantidadHoras;
 }

  state={
    cantidadHoras:1
  }

  buscarMiHistorial=()=>{
    let values = {
      hayAlgo:false,
      id:this.props.sensor.id,
      shadow: {id:this.props.sensor.idShadow},
      cantidadHoras:this.props.sensor.cantidadHoras
    }

    this.props.actualizarHistorialWidget(this.setGrabando,values)
  }

  setGrabando=(grabando)=>{
    this.setState({grabando:grabando});         
   }

      componentDidMount(){        
      }
      
      render(){
        const {sensor,modoSuperSimple,layouts} = this.props;
        const {cantidadHoras,grabando} = this.state;

        let inic={
          id:sensor.id,
          cantidadHoras:cantidadHoras
        }
  
        const validationSchema = Yup.object().shape({
          cantidadHoras: Yup.number().typeError('Solo están permitidos números enteros')   
            .required('Requerido')
            .positive()
            .integer()
            .min(1, "Min 1")
            .max(24, "Max 24")
        });
        
          return(
              <>
                 {!modoSuperSimple && <Formik
                      enableReinitialize= {true}
                      initialValues={inic}
                      validationSchema={validationSchema}
                      onSubmit={values => {  
                          if(cantidadHoras!==values.cantidadHoras){
                            values.hayAlgo=true;
                            values.shadow= {id:this.props.sensor.idShadow};
                            this.props.actualizarHistorialWidget(this.setGrabando,values)
                          }                             
                      }}
                        >
                          {({ errors, touched,values,setFieldValue }) => (
                             <Form  className="" size="sm">
                                      <Row className="justify-content-center">
                                            <Col sm={3} lg={7}>                                             
                                                  <ErrorMessage name={`cantidadHoras`} /> 
                                                  <Field type="number" name="cantidadHoras"/>
                                                  
                                            </Col> 
                                            <div className="syncro">
                                              <button className="btns btns-link text-muted" type="submit" disabled={grabando}>
                                                <i className="fas fa-sync"></i>
                                              </button> 
                                            </div> 
                                      </Row>
                             </Form>
                    )}
                  </Formik>}

                  <div className="uptop">                      
                      <WidgetChart layouts={layouts} grabando={grabando} sensor={sensor} buscarMiHistorial={this.buscarMiHistorial}/>                       
                  </div>
              </>
         )
    }
}


function mapState(state) {
  return {};
}

const actionCreators = {
  actualizarHistorialWidget:sensorActions.actualizarHistorialWidget
}

const connectedGraficoSimple = connect(mapState, actionCreators)(GraficoSimple);
export { connectedGraficoSimple as GraficoSimple };