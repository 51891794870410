import React from 'react'
import { GoogleMap, useJsApiLoader,Marker  } from '@react-google-maps/api';

function useWidgetMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyALdKcJx5a3hE7oTubUBbHyzeLtB86bQkg"
  })

  const containerStyle = {
    width: props.width+'px',
    height: props.height+'px'
  };

  const [map, setMap] = React.useState(null)


  const position={    
    lat:parseFloat(props.sensor.ultimoValor[props.sensor.variableJson]),
    lng:parseFloat(props.sensor.ultimoValor[props.sensor.variableLongitud])  
}

if(isNaN(position.lng) || isNaN(position.lat)) throw "ERROR de lat o lang"

  const onLoad = React.useCallback(function callback(map) {   
    setMap(map);
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
          <Marker
            position={position}
          />
        </>
      </GoogleMap>
  ) : <></>
}

export default function WidgetMap (props){
  try{    
    return useWidgetMap(props);
  }catch(err){
    console.warn(err)
    return <div className="col-sm-12 bg-danger p-2 mb-3 mt-3 text-silver"> <i className="fas fa-exclamation-circle"></i>Error renderizando mapa</div>;
  }     
}

