import React from 'react';
import { connect } from 'react-redux';
import {Container,Col,Row} from 'react-bootstrap';
import { productoActions, userActions } from '../../_actions';
import {CardSuscripcion} from './CardSuscripcion'
import {FormContratarSuscrip} from './FormContratarSuscrip'

class Suscripcion extends React.Component {
    
    state={      
        show:false,
        esGratis:false
    }
    componentDidMount(){
        if(this.props.suscripcionesUsr===undefined)this.props.traerSuscripcionesUsr();  
              
    }

    static getDerivedStateFromProps(nextProps,nextState){
        
        if(nextProps.idSuscripcionDefault && nextProps.suscripcionesUsr!==undefined){
            nextProps.eliminarDefaultSuscripcion();
            const suscripcion =  nextProps.suscripcionesUsr.find(sus=>sus.id===nextProps.idSuscripcionDefault)
            return {
                 suscripcion:suscripcion,
                 show:true,
                 esGratis:nextProps.esGratis
             };   
        }
            
    }

   

    handleClose=()=>{
        this.setState({
            show:false,
            esGratis:false,
            suscripcion:{}
        })
    }

    contratar=(suscripcion,esGratis)=>{
        this.setState({
            suscripcion:suscripcion,
            show:true,
            esGratis:esGratis
        })
    }

    render(){
        const { suscripcionesUsr } = this.props;
        const suscripcionesUsrOrdenadas = !suscripcionesUsr?[]:[].concat(suscripcionesUsr)
        .sort((a, b) => a.costo > b.costo ? 1 : -1).map(i=>i);
        console.log(suscripcionesUsrOrdenadas)
        return (
            <div className="main-content">
            <Container fluid>
                <Row>
                    <div className="cont-sensor mt-5">
                        <div className="d-flex flex-wrap  justify-content-center">
                            {suscripcionesUsrOrdenadas!==undefined && suscripcionesUsrOrdenadas.map((suscripcion, indexS)=>
                                <Col lg={4} xl={4} sm={12} md={6} className="mb-4">
                                    <div className="text-white hoverable card-components">
                                        <div key={suscripcion.id}>
                                            <CardSuscripcion suscripcion={suscripcion} contratar={this.contratar} />
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </div>
                    </div>
                </Row>
                {this.state.show && <FormContratarSuscrip esGratis={this.state.esGratis} show={this.state.show} suscripcion={this.state.suscripcion} handleClose={this.handleClose} />} 
            </Container>
            </div>
        );
    }
}

function mapState(state) {       
    const { idSuscripcionDefault } = state.users;
    const { suscripcionesUsr } = state.producto;
    return {suscripcionesUsr,idSuscripcionDefault };
}

const actionCreators = {
    eliminarDefaultSuscripcion:userActions.eliminarDefaultSuscripcion,
    traerSuscripcionesUsr:productoActions.traerSuscripcionesUsr
}

const connectedSuscripcion = connect(mapState, actionCreators)(Suscripcion);
export { connectedSuscripcion as Suscripcion };

