import http from '../_helpers/http.service';

export const consultaService = {
    getConsultasPU,
    grabarNuevaConsulta,
    traerTiposProblema,
    getPedido,
    enviarMensajeUsr,
    cambiarEstadoConsulta
};

function cambiarEstadoConsulta(idPedido,estado) {    
    var url = '/api/consulta/cambiarEstado';        
    return http.post(url,JSON.stringify({id:idPedido,estado:estado}));    
}

function enviarMensajeUsr(idPedido,mensaje) {    
    var url = '/api/consulta/enviarMensaje';
    return http.post(url,JSON.stringify({id:idPedido,mensaje:mensaje}));    
}

function traerTiposProblema() {    
    var url = '/api/consulta/tiposProblema';        
    return http.get(url);    
}

function getPedido(idPedido) {    
    var url = '/api/consulta/pedido';        
    return http.post(url,JSON.stringify({id:idPedido}));    
}


function getConsultasPU() {    
    var url = '/api/consulta/pu';
    return http.get(url);    
}

function grabarNuevaConsulta(values) {    
    var url = '/api/consulta/grabar';
    return http.post(url,JSON.stringify(values));    
}