import React from 'react';
import {WidgetMapDireccion} from './WidgetMapDireccion';

export default class MapClass extends React.Component {

    constructor(props){
        super(props)
      }

      state={
          directions:undefined,
          error:undefined
      }

      shouldComponentUpdate(nextProps, nextState){       
    
        if(this.props.direcciones.length !== nextProps.direcciones.length ||
           this.state.directions!==nextState.directions ||
            this.state.error!==nextState.error){
          return true
        }else{
          return false
        }
      }

      componentDidMount(){
        this.actualizarRuta()
      }

      componentDidUpdate(nextProps, nextState){
        if(this.state.error===undefined && this.state.directions===nextState.directions)
          this.actualizarRuta()
      }

       actualizarRuta=()=>{
        const { direcciones,variableJson ,variableLongitud} = this.props;
        let origin;
        let destination;
        let waypoints;
        try{
          if(direcciones.length<3)return this.setState({directions:undefined,error:'Debe haber mas de dos direcciones para trazar una ruta'}); 
          let waypoints = [];
          direcciones.forEach(p => {
            const jsonParsed = JSON.parse(p.y);
            const way= {
              location: {lat: parseFloat(jsonParsed[variableJson]), lng:parseFloat(jsonParsed[variableLongitud])},
              stopover: true
            };
            if(isNaN(way.location.lat) || isNaN(way.location.lng)) throw "Latitud o longitud invalida"
            waypoints.push(way);
          });    
           origin = waypoints.shift().location;
           destination = waypoints.pop().location;     
      
        }catch(err){
          console.warn(err);
          return this.setState({directions:undefined,error:'Hubo algun error armando el mapa. Por favor chequear las coordenadas recibidas'}); 
        }     

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: 'DRIVING',
            waypoints: waypoints
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              this.setState({directions:result,error:undefined})
            } else {
             return  this.setState({directions:undefined,error:'Hubo algun error armando el mapa. Por favor chequear las coordenadas recibidas'}); 
             console.warn('ERR',result)
            }
          }
        );
       }
      
      render(){
        
          return(
              <>{
                this.state.error&&<div className="col-sm-12 bg-danger p-2 mb-3 mt-3 text-silver"> <i className="fas fa-exclamation-circle"></i>{this.state.error}</div>                 
               }
                {this.state.directions!==undefined &&  
                    <WidgetMapDireccion
                    directions={this.state.directions}
                    width={this.props.width}
                    height={this.props.height}/>}
              </>
         )
    }
}