import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
  import Table from 'react-bootstrap/Table';
  
  import {Container,Row,Col,Card} from 'react-bootstrap';
import { sensorService } from '../../_services';
import {EditarSensor} from '../Sensor/EditarSensor';
export default class InfoEvento extends React.Component {

    constructor(props){
       super(props);      
    }

    componentDidMount(){
      
    }

    activarSensor(){
      
    };

    state={
        evento:{}
    }

    render(){ 
        const eventoConst = this.props.evento;
        const tipoEvento = eventoConst && eventoConst.tipoEvento?eventoConst.tipoEvento:null; 
        

        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header className="bg-gradient-evento" closeButton>
                    <Modal.Title className="text-white">Detalles de {eventoConst?eventoConst.nombre:''}</Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    <Row className="mb-4">
                        <Col xs={12}  lg={6}>
                        <label>Nombre </label>
                    <h4>{eventoConst?eventoConst.nombre:''}</h4>
                        </Col>
                        <Col xs={12}  lg={6}>
                        <label>TipoEvento </label>
                        <h4>{tipoEvento?tipoEvento.descripcion:''}</h4>
                        </Col>
                    </Row>
                    <div>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Acción</th>
                                <th>Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventoConst.acciones!==undefined && eventoConst.acciones.map((accion, index)=> 
                                            <>
                                                <tr>
                                                <td>
                                                    { accion.id}
                                                </td>
                                                <td>
                                                        {accion.tipoAccion.descripcion}
                                                </td>
                                                <td>
                                                        {accion.tipoInformacion?accion.tipoInformacion.descripcion:''}
                                                </td>
                                                
                                                </tr>
                                            </>
                                            )}
                        </tbody>
                        </Table>
                    </div>
               
                   
                </Modal.Body>                     
            </Modal>
        );
    }
}