
export function refreshToken() {
  //  const cookies = new Cookies();
    const user = JSON.parse(localStorage.getItem('user'));
    return user?user.refresh_token:'';
}

export function authHeader() {
 //   const cookies = new Cookies();
    const user = JSON.parse(localStorage.getItem('user'));
    return {'Authorization':`Bearer ${user?user.access_token:''}`};
}

export function tengoRol(roles) {
    let tengoRol=false;

    try{
        //const cookies = new Cookies();
        const user =JSON.parse(localStorage.getItem('user'));
        if(!user)return tengoRol;
        const rolesCookie = user.roles;
        if(rolesCookie!==undefined)tengoRol = rolesCookie.some(rol=>roles.includes(rol));
    }catch(err){
        console.warn(err);
    }
    return tengoRol;
}

export function tengoToken(tipoToken,userToken) {
    let tengoRol=false;

    if(!userToken){
        if(tipoToken===TIPO_TOKEN.EXISTE)return true;
        else return false;
    }
    else{        
        if(tipoToken===userToken.tipoSuscripcion.tipoToken)
            return true;
    }
    return tengoRol;
   
}


export function esSinToken(userToken){   
    if(userToken===undefined) return true;
    else return false;
} 

export function esGratis(userToken){
    return (tengoToken(TIPO_TOKEN.GRATIS,userToken));
} 

export function esInicial(userToken){
    return (tengoToken(TIPO_TOKEN.INICIAL,userToken));
} 

export function esConIntermedio(userToken){
    return (tengoToken(TIPO_TOKEN.INTERMEDIO,userToken));
} 

export function esConFull(userToken){
    return (tengoToken(TIPO_TOKEN.FULL,userToken));
} 

export const TIPO_TOKEN = {
    GRATIS: 1,
    INICIAL: 2,
    INTERMEDIO: 3,
    FULL:4,
    EXISTE:5
}

export const ORIGEN_NOTIFICACION = {
    COMPRA : 1 ,
    EVENTO : 2 ,
    PROGRAMA : 3 ,
    GENERAL : 4 ,
    CONSULTAS : 5,
    GRUPO:6
}


export const RoleID = {
    DEV: 4,
    ADMIN: 1,
    USER_ADMIN:3,
    USER: 2
}

export const Role = {
    DEV: 'ROLE_DESARROLLADOR',
    ADMIN: 'ROLE_ADMIN',
    USER_ADMIN: 'ROLE_USER_ADMIN',
    USER: 'ROLE_USER'
}
