import {notificacionConstants ,userConstants} from '../_constants';


export function notificacion(state = {}, action) {
  switch (action.type) { 
    case notificacionConstants.NOTIFICACION_GETPU_SUCCESS:
      return { 
        ...state,
        misNotificaciones: action.data.misNotificaciones
      };   

      case notificacionConstants.NOTIFICACION_NUEVA:
        return { 
          ...state,
          misNotificaciones: state.misNotificaciones?state.misNotificaciones.concat(action.data.notificacion):[action.data.notificacion],
        };   

      case notificacionConstants.NOTIFICACION_MARCAR_LEIDAS:
        return { 
          ...state,
          misNotificaciones: state.misNotificaciones?state.misNotificaciones.map((noti,index)=>{
            noti.leida=true;
            return noti; 
          }
          ):[]
        };   
      case userConstants.LOGOUT:
        return {};
          
    default:
      return state
  }
}