export const programaConstants = {
    PROGRAMA_GET_PU_SUCCESS: 'PROGRAMA_GET_PU_SUCCESS',
    AGREGAR_PROGRAMA_GENERADO:'AGREGAR_PROGRAMA_GENERADO',
    PROGRAMA_ELIMINADO:'PROGRAMA_ELIMINADO',
    PROGRAMA_SELECCIONADO:'PROGRAMA_SELECCIONADO',
    ACTUALIZAR_PROGRAMA:'ACTUALIZAR_PROGRAMA',
    AGREGAR_TAREA_GENERADA:'AGREGAR_TAREA_GENERADA',
    TAREA_ELIMINADA:'TAREA_ELIMINADA',
    TAREA_SELECCIONADA:'TAREA_SELECCIONADA',
    EJECUTAR:'EJECUTAR',
    CANCELAR_EJECUCION:'CANCELAR_EJECUCION',
    ACTUALIZAR_TAREA:'ACTUALIZAR_TAREA',
    ESTADO_STAND_BY:1,
    ESTADO_EJECUTANDO:2,
    ESTADO_ESPERA_USUARIO:3,
    TIPO_ACCION_TAREA_INICIAL:1,
    TIPO_ACCION_TAREA_FINAL:2,
    ACTUALIZAR_EVENTO_TAREA:'ACTUALIZAR_EVENTO_TAREA',
    AGREGAR_EVENTO_TAREA:'AGREGAR_EVENTO_TAREA',
    ACTUALIZAR_ACCION_TAREA:'ACTUALIZAR_ACCION_TAREA',
    AGREGAR_ACCION_TAREA:'AGREGAR_ACCION_TAREA',
    EVENTO_TAREA_ELIMINADO:'EVENTO_TAREA_ELIMINADO',
    ACCION_TAREA_ELIMINADO:'ACCION_TAREA_ELIMINADO'

};
