import React from 'react';
import { connect } from 'react-redux';
import {Button, Row,Col} from 'react-bootstrap';
import { productoConstants } from '../../_constants';
import { productoActions } from '../../_actions';
import {ErrorMessage} from '../../_helpers';
import { Formik, Form,  Field } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';

class CardProductoTienda extends React.Component {
    
    state={      
        active:false
     }
    componentDidMount(){
    }


    render(){
        
        const { producto } = this.props;
        let inic={
            cantidad: '1'        
        }
      
        const validationSchema = Yup.object().shape({
            cantidad: Yup.number().min(1, 'Min 1').max(producto.stock,`No puede superar el stock disponible ${producto.stock}`).required('Requerido')
        });
  

        return (
            <div className="cont-sensor mt-4">
                <div className="text-white d-flex flex-wrap  justify-content-center">
                    Nombre:{producto.nombre} <br/>
                    Valor:
                    <NumberFormat prefix={'$'} value= {producto.valor} displayType={'text'} thousandSeparator="." decimalSeparator="," />                    
                    <br/>
                    Categoria:{producto.categoriaProducto.descripcion}<br/>
                    Modelo:{producto.modelo.descripcion}<br/>
                    {producto.stock===0?'Sin stock':
                    <>
                          <Formik
                                enableReinitialize= {true}
                                initialValues={inic}
                                validationSchema={validationSchema}
                                onSubmit={(values ) => { 
                                    this.props.agregarCarrito(producto,productoConstants.TIPO_COMPRA_PRODUCTO,values.cantidad);
                                }}>
                                {({ errors, touched,values }) => (
                                <Form>
                                     <Row>
                                        <Col sm={12}>
                                            <label htmlFor="cantidad">Cantidad </label>
                                            <ErrorMessage name={`cantidad`} />                                         
                                            <Field name="cantidad" />
                                        </Col>
                                    </Row>  
                                    <Row>
                                        <Col sm={12}>
                                            <Button type="submit">Agregar al carrito</Button>
                                        </Col>
                                    </Row>    
                                </Form>                             
                                )}
                            </Formik>

                    </>}
                </div>
           </div>

        );
    }
}

function mapState(state) {       
    return { };
}

const actionCreators = {
    agregarCarrito:productoActions.seleccionarProducto
}

const connectedCardProductoTienda = connect(mapState, actionCreators)(CardProductoTienda);
export { connectedCardProductoTienda as CardProductoTienda };

