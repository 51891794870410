import { accionColectivaConstants } from '../_constants';
import { accionColectivaService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const accionColectivaActions = {
    getAccionesColectivasPU,
    agregarAccionColectivaGenerada,
    eliminarAccionColectiva,
    ejecutarAccion,
    toogleFavorito
};

function toogleFavorito(values) {
    return dispatch => {
        accionColectivaService.toogleFavorito(values)
            .then(
                data => { 
                    if(values.tipo===1)
                        dispatch(success(values));   
                   if(values.tipo===2)
                        dispatch(successPrograma(values));                      
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );    };

    function successPrograma(values) { return { type: accionColectivaConstants.TOOGLE_FAVORITO_PROGRAMA, values } }
    function success(values) { return { type: accionColectivaConstants.TOOGLE_FAVORITO, values } }
}

function getAccionesColectivasPU() {
    return dispatch => {
        accionColectivaService.getAccionesColectivasPU()
            .then(
                data => { 
                    data.misAccionesColectivas.sort(function(a, b){
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    })
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: accionColectivaConstants.ACCION_COLECTIVA_GET_PU_SUCCESS, data } }
}

function agregarAccionColectivaGenerada(data,esNuevo) {
    return dispatch => {
        if(esNuevo)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: accionColectivaConstants.ACTUALIZAR_ACCION_COLECTIVA,data} }
    function success(data) { return { type: accionColectivaConstants.AGREGAR_ACCION_COLECTIVA_GENERADA,data} }
}

function eliminarAccionColectiva(accionColectivaLlegada,handleExito) {
    return dispatch => {
        accionColectivaService.eliminarAccionColectiva(accionColectivaLlegada)
            .then(
                vista => { 
                    dispatch(success(accionColectivaLlegada));
                    handleExito();
                    history.push('/home/misAcciones')                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(accionColectivaLlegada) { return { type: accionColectivaConstants.ACCION_COLECTIVA_ELIMINADA, accionColectivaLlegada } }
}

function ejecutarAccion(accionColectivaLlegada) {
    return dispatch => {
        accionColectivaService.ejecutarAccion(accionColectivaLlegada)
            .then(
                accion => { 
                    dispatch(success(accionColectivaLlegada));     
                    dispatch(alertActions.enviarExito("Ejecutado exitosamente!"));
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(accionColectivaLlegada) { return { type: accionColectivaConstants.EJECUTAR_ACCION_COLECTIVA, accionColectivaLlegada } }
}
