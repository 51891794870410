import http from '../_helpers/http.service';

export const equipoService = {
    getEquipoById,
    traerTiposSensores,
    grabarEquipo,
    eliminarEquipo,
    getEquiposPU,
    grabarEquipoUsuario,
    desasociar,
    traerTiposEquipo,
    asignarEquipoAUsuario,
    traerGeneralSensor,
    buscarEquipos,
    guardarLayout,
    traerCarpetas,
    grabarCarpeta,
    eliminarCarpeta,
    moverDeCarpeta,
    asociarEquipo
};

function asociarEquipo(idEquipo) {
   
    var url = '/api/equipo/asociar/';

    return http.post(url,JSON.stringify({idEquipo:idEquipo}));
}

function eliminarCarpeta(values) {   
    var url = '/api/usuario/eliminarCarpeta';    
    return http.post(url,JSON.stringify(values));
}

function moverDeCarpeta(values) {   
    var url = '/api/usuario/moverDeCarpeta';    
    return http.post(url,JSON.stringify(values));
}

function traerCarpetas() {   
    var url = '/api/usuario/traerCarpetas';    
    return http.get(url);
}



function grabarCarpeta(values) {   
    var url = '/api/usuario/grabarCarpeta';    
    return http.post(url,JSON.stringify(values));
}

function guardarLayout(values) {   
    var url = '/api/guardarLayout';    
    return http.post(url,JSON.stringify(values));
}

function buscarEquipos(values) {   
    var url = '/api/equipo/buscarEquipos';    
    return http.post(url,JSON.stringify(values));
}

function asignarEquipoAUsuario(values) {   
    var url = '/api/equipos/asignarEquipo';    
    return http.post(url,JSON.stringify(values));
}

function grabarEquipoUsuario(values) {   
    var url = '/api/equipo/actualizarEquipoUs';    
    return http.post(url,JSON.stringify(values));
}

function desasociar(equipo) {   
    var url = '/api/equipo/desasociar';
    return http.post(url,JSON.stringify({id:equipo.id}));
}

function getEquipoById(id) {   
    var url = '/api/equipo/'+id;
    return http.get(url);
}

function traerTiposEquipo() {   
    var url = '/api/equipo/tiposEquipo';        
    return http.get(url);
}

function traerTiposSensores() {   
    var url = '/api/equipo/tipoSensores';        
    return http.get(url);
}

function traerGeneralSensor() {   
    var url = '/api/equipo/traerGeneralSensor';        
    return http.get(url);
}


function getEquiposPU() {   
    var url = '/api/equipo/pu';
    return http.get(url);
} 

function eliminarEquipo(values) {
    var url = '/api/equipo/eliminar';
    return http.post(url,JSON.stringify(values));
}
    
function grabarEquipo(values) {
    var url = '/api/equipo/grabar';
    return http.post(url,JSON.stringify(values));
}