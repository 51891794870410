import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Accordion,Button,Card,Modal} from 'react-bootstrap';
import {FormEvento} from '../Evento'
import {programaActions} from '../../_actions';
import { alertConfirm } from '../../_helpers';
class ListaEventos extends React.Component {

    constructor(props){      
       super(props);
       
    }

    handleShowEventoN = () => {
        this.setState((prevState) => {
            return {
                show: true
            };
        });
    };

    handleShowEvento = (evento) => {
        this.setState((prevState) => {
            return {
                evento:evento,
                show: true
            };
        });
    };

    handleCloseEvento = () => {
        this.setState((prevState) => {
            return {
                evento:{},
                show: false
            };
        });
    };

    cerrarAlert = () => {
        this.setState({alert: null,evento:{}});
    }   

    handleShowConfirm = (evento,tarea) => {
        const getAlert = alertConfirm('Confirmar',
                `¿Está seguro que desea eliminar ${evento.nombre}?`,
                'Sí, eliminar',
                'No',
                this.cerrarAlert,
                this.confirmarEliminar,
                null,
                true
            )  
            this.setState({
                evento:evento,
                tarea:tarea,
                alert: getAlert
            });
    };

    confirmarEliminar= () => {
        let tarea = this.getTarea();
        this.props.confirmarEliminar(this.state.evento.id,tarea.id,tarea.programa.id);
        this.cerrarAlert();
    };

    getTarea= () => {
        let tarea = {};
        this.props.programa.tareas.forEach(t => {
            if(t.id===this.props.idTarea)tarea=t;
        });
        return tarea;
    }

    state={
        evento:{},
        tarea:{},
        show:false,
        showConfirm:false
    }

    render(){
       let tarea = this.getTarea();
        return (
                  <>
                        <Container fluid className="th_cielo">
                            <Row >
                                <Col sm={6}>
                                    <h3>{tarea.nombre}</h3>   
                                    <button type="button" onClick ={() => this.handleShowEventoN()} className="btns btn-success  btn-sm" size="sm"><i className="material-icons align-middle txt-16">add</i> Nuevo evento</button>                          
                                </Col>
                            </Row>                            
                                    {tarea.eventos!==undefined &&  tarea.eventos.map((evento, index)=>    
                                        <>                                  
                                            <span className="txt-oswald">{evento.nombre}</span>
                                                                                           
                                            <a>
                                                <i className="fas fa-pencil-alt mr-2" onClick ={() => this.handleShowEvento(evento)}></i> 
                                            </a>
                                        
                                            <a>
                                            <i className="fas fa-trash-alt mr-2" onClick ={() => this.handleShowConfirm(evento,tarea)}></i>
                                            </a>                                            
                                            Resumen de evento: {evento.tipoEvento.descripcion} 
                                        </>              
                                    )}
                        </Container>   

                  {this.state.show && <Modal size="lg" show={this.state.show} onHide={this.handleCloseEvento}  backdrop="static" keyboard={false}>
                        <Modal.Header className="bg-gradient-evento text-white" closeButton>
                            <Modal.Title><h4><i className="icon-modem"></i> Nuevo evento</h4></Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            {(tarea  && tarea.programa) &&
                                <FormEvento evento={this.state.evento} pertenecePrograma={true} idTarea={tarea.id} idPrograma={tarea.programa.id} handleClose={this.handleCloseEvento} />
                            }
                            
                        </Modal.Body>                     
                    </Modal> }

                {this.state.alert}    
              </>
        );
    }
}

function mapState(state) {  
    return {};
}

const actionCreators = {
    confirmarEliminar:programaActions.removerEventoTarea
}

const connectedListaEventos = connect(mapState, actionCreators)(ListaEventos);
export { connectedListaEventos as ListaEventos };