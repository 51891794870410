import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import {Modal,Col,Row} from 'react-bootstrap';
import {productoActions} from '../../_actions';
import {ERR, ErrorMessage, LoadingBoton} from '../../_helpers';
import { utilActions } from '../../_actions';
import {RadioGroup, Radio} from 'react-radio-group';
import { productoConstants } from '../../_constants';

class FormModificarCompra extends React.Component {  

  state = {
    grabando:false,
   };

   setGrabando=(grabando)=>{
    this.setState({grabando:grabando});         
   }

   componentDidMount(){
    if(this.props.mediosPago===undefined)this.props.traerMediosPago();
}
  
render() {
      const {compra,mediosPago} =this.props;
      let  requiereEnvio=false;
      
      let inic={
        tipoEnvio: {id:''},
        medioPago:{id:''}
      }

      let valSchema = {            
        medioPago: Yup.object({id: Yup.string().required(ERR.REQ)})
      }
   
      if(compra.tipoEnvio.id!==''){
        requiereEnvio=true;
        valSchema.tipoEnvio= Yup.object({id: Yup.string().required(ERR.REQ)});
      }

      const validationSchema = Yup.object().shape(valSchema);
      
      if(compra!==undefined)inic=utilActions.setearValores(inic,compra);
return(

  <div>
        <Modal show={this.props.show} onHide={()=>this.props.handleClose()} backdrop="static" keyboard={false}>
            <Modal.Header className="bg-arandano" closeButton>
                <Modal.Title>Categoría</Modal.Title>
            </Modal.Header> 
            <Modal.Body>
            <Formik
                enableReinitialize= {true}
                initialValues={inic}
                validationSchema={validationSchema}
                onSubmit={values => {       
                        
                    this.props.modificarCompra(values,this.props.handleClose,this.setGrabando);
                }}
              >
                {({ errors, touched,values,setFieldValue }) => (
                  <Form>
                     <Row>
                          <Col sm={12}>
                              <label htmlFor="cantidad"> Seleccione un medio de pago:  </label>
                              <ErrorMessage name={`medioPago`} />

                              <RadioGroup  name="medioPago" selectedValue={values.medioPago.id}
                              onChange={(value)=> setFieldValue(`medioPago.id`,value)}>
                                      {(mediosPago!==undefined) && mediosPago.map((medio, indexS)=>
                                          <div key={"mp"+medio.id}> 
                                              <label className="label-check" htmlFor={"medio-"+medio.id}>{medio.descripcion}</label>
                                              <Radio className="option-input checkbox" id={"medio-"+medio.id} value={medio.id} />
                                          </div> 
                                      )}
                              </RadioGroup>   
                          </Col>

                          {requiereEnvio && <Col sm={12}>
                              <label htmlFor="tipoEnvio">
                                  Seleccione un metodo de envio: </label> <ErrorMessage name={`tipoEnvio`} /> 
                              

                              <RadioGroup  name="tipoEnvio" selectedValue={values.tipoEnvio.id}
                                  onChange={(value)=> setFieldValue(`tipoEnvio.id`,value)}>                                  
                                      <> 
                                      <label className="label-check" htmlFor={"env-"+productoConstants.TIPO_ENVIO_CORREO}>Correo</label>
                                      <Radio className="option-input checkbox" id={"env-"+productoConstants.TIPO_ENVIO_CORREO} value={productoConstants.TIPO_ENVIO_CORREO} />

                                      <label className="label-check" htmlFor={"env-"+productoConstants.TIPO_ENVIO_RETIRO}>Retiro por local</label>
                                      <Radio className="option-input checkbox" id={"env-"+productoConstants.TIPO_ENVIO_RETIRO} value={productoConstants.TIPO_ENVIO_RETIRO} />
                                      </> 
                              </RadioGroup>   
                              
                          </Col>}
                      </Row> 
                       

                      <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                        <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                        <i className="icon-times"></i>  Cerrar
                        </button>
                        <button className="btns btns-secundario" type="submit"  disabled={this.state.grabando}>
                        <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                        </button>
                      </div> 
                  </Form>
                )}
              </Formik>


            </Modal.Body>                     
        </Modal> 

  </div>
)};
}

function mapState(state) {
  const { mediosPago } = state.producto;
  return {mediosPago};
}

const actionCreators = {
  traerMediosPago:productoActions.traerMediosPago,
  modificarCompra:productoActions.modificarCompra
}

const connectedFormModificarCompra = connect(mapState, actionCreators)(FormModificarCompra);
export { connectedFormModificarCompra as FormModificarCompra };