import React from 'react';
import { connect } from 'react-redux';
import {Modal,Card} from 'react-bootstrap';
import Moment from 'react-moment';
import { consultaActions, notificacionActions, grupoActions } from '../../_actions';
import { history, ORIGEN_NOTIFICACION } from '../../_helpers';

class PanelNotificaciones extends React.Component {

    state={
    }

    activarNoti = (notificacion) => {
        if(notificacion.origen.id===ORIGEN_NOTIFICACION.COMPRA)
            history.push('/tienda/misCompras')
       
        if(notificacion.origen.id===ORIGEN_NOTIFICACION.CONSULTAS){
            this.props.limpiarBurbujaConsulta();
            this.props.seleccionarConsultaGlobo(parseInt(notificacion.idRef))
        }          

        if(notificacion.origen.id===ORIGEN_NOTIFICACION.EVENTO)
            history.push('/home/misEventos')

        if(notificacion.origen.id===ORIGEN_NOTIFICACION.PROGRAMA)
            history.push('/home/programa?id='+notificacion.idRef)

        if(notificacion.origen.id===ORIGEN_NOTIFICACION.GRUPO){
            this.props.getListadosGrupoPU();
            history.push('/perfil/usuarios');

        }
           
        this.props.handleClose();    
    } 

    componentDidMount(){
        if(this.props.misNotificaciones && this.props.misNotificaciones.some(noti=>!noti.leida))
            this.props.marcarLeidas();
    }

    
render() {
  const {misNotificaciones} = this.props;
  return(

          <Modal show={this.props.show} onHide={()=>this.props.handleClose()} dialogClassName="modal-notificacion" backdropClassName={'transparent-bk'}>
              <Modal.Header className="bg-iot text-white">  
              <Card.Text className="text-dark text-center">
                  Notificaciones
                  </Card.Text>     
              </Modal.Header> 
              <Modal.Body className="">
                  {(misNotificaciones && misNotificaciones.length>0) &&<ul>
                      {misNotificaciones.map((noti, index)=>  
                            <li onClick={()=>this.activarNoti(noti)} >
                                <Card.Text className="text-dark mb-2 text-truncate">
                                 <span className="text-dark">{noti.titulo}</span>                                                  
                                </Card.Text>
                                <small className="text-muted"><Moment date={noti.fecha} format="DD/MM/YYYY hh:mm"/> </small> 
                            </li>
                        )}
                    </ul>}
                    {(!misNotificaciones || misNotificaciones.length===0) &&
                    <Card.Text className="text-dark text-center mb-4">
                        <small className="font-weight-bold">¡Estas al día, no hay notificaciones sin leer!</small>                          
                    </Card.Text>
                    }
            
              </Modal.Body>                     
          </Modal>
  )};
}

function mapState(state) { 
    const { misNotificaciones } = state.notificacion;
    return { misNotificaciones };
}

const actionCreators = {
    marcarLeidas:notificacionActions.marcarLeidas,
    seleccionarConsultaGlobo:consultaActions.seleccionarConsultaGlobo,
    limpiarBurbujaConsulta:consultaActions.limpiarBurbujaConsulta,
    getListadosGrupoPU:grupoActions.getListadosGrupoPU
}

const connectedPanelNotificaciones = connect(mapState, actionCreators)(PanelNotificaciones);
export { connectedPanelNotificaciones as PanelNotificaciones };