import { equipoConstants,productoConstants,sensorConstants,userConstants } from '../_constants';

export function equipos(state = {}, action) {
  switch (action.type) {
    case equipoConstants.EQUIPO_GET_REQUEST:
      return {
        ...state,
        equipo: action.equipo
      };
    case equipoConstants.EQUIPO_TRAER_TIPOS_SENSOR:
      return {
        ...state,
        tiposSensores: action.data.tiposSensores
      };  

    case equipoConstants.CARPETAS_USUARIO:
      return {
        ...state,
        carpetas: action.data.carpetas
      };  

      

      case equipoConstants.AGREGAR_EQUIPO_CARPETAR_ROOT:
      return {
        ...state,
        carpetas: action.data.carpetas
      }; 
      
    case equipoConstants.GENERAL_SENSOR:
      return {
        ...state,
        tiposWidget: action.data.listado.tiposWidget,
        tiposDatos: action.data.listado.tiposDatos
      };  
        
    case equipoConstants.EQUIPO_GET_SUCCESS:
      return {
        ...state,
        equipo: action.equipo
      };   
   
      case  equipoConstants.FINALIZAR_FORZADO:
        return { 
          ...state,
          seleccionado: action.seleccionado
        };
     

    case equipoConstants.EQUIPO_SELECCIONADO:
      return { 
        ...state,
        seleccionado: action.seleccionado
      };
      case productoConstants.EQUIPOS_DISPONIBLES:
        return {
          ...state,
          equiposDisponibles: action.data.equiposDisponibles
        };
      
    case equipoConstants.EQUIPO_GET_PU_SUCCESS:
      return { 
        ...state,
        misEquipos: action.data.misEquipos,
        seleccionado: 0,
        sensores:action.data.sensoresLista
      }; 
    case equipoConstants.EQUIPO_GET_ALL_FAILURE:
      return { 
        error: action.error
    };  
    case equipoConstants.NUEVO_VALOR:
      return {
        ...state,
        sensores: state.sensores.map(sensor => {             
              if (sensor.id === action.idSensor) {                
                let {ultimoValor,fechaUV,historialWidget, ...sensorCopy } = sensor;  
                const fechaUVNueva = new Date();    
                const newHist={
                  fecha:action.fecha,
                  valor:action.valor,
                  hora:action.hora,
                  minuto:action.minuto,
                  segundo:action.segundo
                }  
                if(historialWidget!==undefined)                  
                  historialWidget.push(newHist);       
                else historialWidget=[newHist]
                return {ultimoValor: action.valor,fechaUV:fechaUVNueva,historialWidget:historialWidget, ...sensorCopy};
              }else return sensor; 
        })
      };   
    case equipoConstants.NUEVO_ESTADO_SENSOR:
      return {
        ...state,
        sensores: state.sensores.map(sensor => {             
              if (sensor.id === action.idSensor) {                
                const {conectado, ...sensorCopy } = sensor;                
                return {conectado: action.conectado, ...sensorCopy};
              }else return sensor; 
        })
      };    
   
    case equipoConstants.AGREGAR_EQUIPO_ASOCIADO:
      return {
        ...state,
        misEquipos: state.misEquipos.concat(action.data.equipo),
        sensores:action.sensoresLista
      };

      case  equipoConstants.ACTUALIZAR_LAYOUT_EQUIPO:     
      return {
        ...state,
        misEquipos: state.misEquipos.map(eq => {                          
              if (eq.id === action.data.id) {                
                const {layouts, ...eqCopy } = eq;                
                return {layouts: action.data.layouts, ...eqCopy};
              }else return eq; 
        })
      };  

      case equipoConstants.ACT_ESTADO_EQUIPO:     
        return {
          ...state,
          misEquipos: state.misEquipos.map(eq => {                          
                if (eq.id === action.data.idEquipo) {                
                  const {conectado, ...eqCopy } = eq;                
                  return {conectado: action.data.nuevoEstado, ...eqCopy};
                }else return eq; 
          })
        };   

           
    case sensorConstants.SELECCIONAR_SENSOR:
      return {
        ...state,
        sensor: action.sensor
      };   
      
      
    case equipoConstants.ACTUALIZAR_EQUIPO_USUARIO:
      return {
        ...state,
        misEquipos: state.misEquipos.map(eq => {                          
              if (eq.id === action.data.equipo.id) {             
                return action.data.equipo;
              }else return eq; 
        })
      }; 
    case equipoConstants.AGREGAR_EQUIPO_USER:
      return {
        ...state,
        misEquipos: state.misEquipos?state.misEquipos.concat(action.data.equipo):[action.data.equipo]
      };    

    case sensorConstants.ACTULIZAR_SENSOR_USER:
      return {
        ...state,
        sensores: state.sensores.map(sensor => {          
              if (sensor.id === action.sensor.id) {
                
                const {labelUsuario,calibracion,valorMinReferencia,valorMaxReferencia, ...sensorCopy } = sensor;
                
                return {labelUsuario: action.sensor.labelUsuario,calibracion:action.sensor.calibracion,valorMinReferencia:action.sensor.valorMinReferencia,valorMaxReferencia:action.sensor.valorMaxReferencia, ...sensorCopy};
              }else return sensor;  
        })
      }; 

      case equipoConstants.DATA_HISTORIAL_WIDGET:
      return {
        ...state,
        sensores: state.sensores.map(sensor => {     
              if (sensor.id === action.data.id) {                
                const {historialWidget, ...sensorCopy } = sensor;
                return {historialWidget:action.data.historial, ...sensorCopy};
              }else return sensor;  
        })
      }; 

      

      case sensorConstants.NUEVO_SENSOR_DEVELOPER:
      return {
        ...state,
        sensores: state.sensores?state.sensores.concat(action.data.sensor):state.sensores=[action.data.sensor],
        misEquipos: state.misEquipos.map(eq => {
            if (eq.id===action.data.sensor.idEquipo){  
              if(!eq.sensores)
                  eq.sensores=[action.data.sensor]; 
              else      
                  eq.sensores=eq.sensores.concat(action.data.sensor);         
                  
            }             
          return eq;
        })
      }; 
      
      case sensorConstants.ACTULIZAR_SENSOR_DEVELOPER:
      return {
        ...state,
        sensores: state.sensores.map(sensor => {  
                    if (sensor.id === action.data.sensor.id) { 
                      let sNuevo = action.data.sensor;
                      sNuevo.fechaUV = sensor.fechaUV; 
                      sNuevo.historialWidget = sensor.historialWidget;
                      sNuevo.ultimoValor = sensor.ultimoValor;                                 
                      return sNuevo;
                    }else return sensor;     
        })
      }; 
      case sensorConstants.ELIMINAR_SENSOR_DEVELOPER:
        return {
          ...state,
          misEquipos: state.misEquipos.map(eq => {
              if (eq.id===action.sensor.idEquipo){                
                  eq.sensores=eq.sensores.filter(sen => sen.id !== action.sensor.id)                       
              }             
            return eq;
          }),
          sensores: state.sensores.filter(sen => sen.id !== action.sensor.id)                 
        }; 
    case sensorConstants.ACTULIZAR_TIPOS_SENSOR:
      return {
        ...state,
        tiposSensor: action.data
      };
    case equipoConstants.LISTADO_TIPOS_EQUIPO:
      return {
        ...state,
        tiposEquipo: action.data.tiposEquipo
      };
      
      
    case equipoConstants.DESASOCIAR_EQUIPO:
      return {
        ...state,
        sensoresLista: state.sensoresLista?state.sensoresLista.filter(sen => {
          const sensorViejo = action.equipoLlegado.sensores.find(valu=>valu.id===sen.id);
          if(sensorViejo) return false;
            else return true;
        }):[],
        misEquipos: state.misEquipos.filter(eq => eq.id !== action.equipoLlegado.id)
      };  
      case userConstants.LOGOUT:
        return {};
      
    default:
      return state
  }
}