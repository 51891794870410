import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import { notificacionActions } from './notificacion.actions';
import { equipoActions } from './equipo.actions';

export const userActions = {
    login,
    logout,
    register,
    getUsuarios,
    getLogueado,
    reenviarActivacion,
    actualizarUsuario,
    loginGoogle,
    cancelarSuscripcion,
    cancelarSuscripcionUser,
    actualizarToken,
    guardarContratacion,
    traerInfoGral,
    eliminarDefaultSuscripcion

};

function loginGoogle(gResponse,idEquipoNuevo,esEquipoNuevo) {
    //https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=
    return dispatch => {
        userService.loginGoogle(gResponse)
            .then(
                user => { 
                    
                    dispatch(success(user,idEquipoNuevo,esEquipoNuevo));
                    let pagina = '/home/misEquipos';
                    user.roles.forEach((subid, topic) => {
                        if(subid==='ROLE_ADMIN')pagina='/admin/equipos';
                    });
                    history.push(pagina);

                    userService.getLogueado()
                    .then(
                        userMasInfo => {
                            localStorage.setItem('userLogged',JSON.stringify(userMasInfo));
                            dispatch(successMasinfo(userMasInfo));
                        })
                    dispatch(notificacionActions.getNotificacionesPU());   
                    dispatch(equipoActions.getEquiposPU());        
                    
                },
                error => {                
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                   
                }
            );
    };

    function successMasinfo(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
    function success(user,idEquipoNuevo,esEquipoNuevo) { return { type: userConstants.LOGIN_SUCCESS, user,idEquipoNuevo,esEquipoNuevo } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function login(username, password,idEquipoNuevo,esEquipoNuevo,redirect) {
    
    return dispatch => {
        userService.login(username, password)
            .then(
                user => { 
                    
                    dispatch(success(user,idEquipoNuevo,esEquipoNuevo));
                    let pagina = '/home/misEquipos';
                    user.roles.forEach((subid, topic) => {
                        if(subid==='ROLE_ADMIN')pagina='/admin/equipos';
                    });
                    if(redirect)history.push(pagina);
                    userService.getLogueado()
                        .then(
                            userMasInfo  => {
                                  localStorage.setItem('userLogged',JSON.stringify(userMasInfo));
                                  dispatch(successMasinfo(userMasInfo));
                                },
                            error =>{ dispatch(alertActions.error(error))}
                        );
                    dispatch(notificacionActions.getNotificacionesPU()); 
                    dispatch(equipoActions.getEquiposPU());                       
                }, 
                error => {              
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                   
                }
            );
    };

    function successMasinfo(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
    function success(user,idEquipoNuevo,esEquipoNuevo) { return { type: userConstants.LOGIN_SUCCESS, user,idEquipoNuevo,esEquipoNuevo } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function actualizarToken(tokenActivo) {
    return dispatch => {
         dispatch(success(JSON.parse(tokenActivo)));
    };

    function success(tokenActivo) { return { type: userConstants.NUEVO_TOKEN, tokenActivo } }
}

function actualizarUsuario(user) {
    return dispatch => {
        userService.actualizarUsuario(user)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {                    
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(user) { return { type: userConstants.ACTUALIZAR_USUARIO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ACTUALIZAR_USUARIO_ERROR, error } }   
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    dispatch(alertActions.success('Usuario creado exitosamente. Recibira un mail para la confirmacion del correo ingresado.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function reenviarActivacion(codigoMail) {
    return dispatch => {
        userService.reenviarActivacion(codigoMail)
            .then(
                codigoMail => { 
                    dispatch(success());
                    dispatch(alertActions.success('Se ha reenviado correctamente la activacion al correo.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}



function getUsuarios() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(data) { return { type: userConstants.GETALL_SUCCESS, data } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getLogueado() {

    return dispatch => {
        userService.getLogueado()
            .then(
                
                user => {
                    localStorage.setItem('userLogged',JSON.stringify(user));
                    dispatch(success(user))
                },
                error => dispatch(failure(error))
            );
    };
    function success(user) { return { type: userConstants.GETLOGUEADO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function traerInfoGral() {

    return dispatch => {
        userService.traerInfoGral()
            .then(                
                data => {
                    dispatch(success(data))
                }
            );
    };
    function success(data) { return { type: userConstants.INFO_GENERAL_PERFIL, data } }
}





// prefixed function name with underscore because delete is a reserved word in javascript
function cancelarSuscripcion(user,handleExito) {
    return dispatch => {

        userService.cancelarSuscripcion(user)
            .then(
                data => { 
                    handleExito();
                    dispatch(success());
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            );
    };
    function success() { return { type: userConstants.SUSCRIPCION_CANCELADA } }
}

function cancelarSuscripcionUser(handleExito) {
    return dispatch => {

        userService.cancelarSuscripcionUser()
            .then(
                data => {                    
                    dispatch(success(data));
                    handleExito();
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            );
    };
    function success(data) { return { type: userConstants.SUSCRIPCION_CANCELADA_USER,data } }
}

function guardarContratacion(idSuscripcion,esGratis) {
    return dispatch => {
        dispatch(guardarContratacion3(idSuscripcion,esGratis));     
    };
    function guardarContratacion3(idSuscripcion,esGratis) { return { type: userConstants.GUARDAR_SUSCRIPCION,idSuscripcion,esGratis } }
}

function eliminarDefaultSuscripcion() {
    return dispatch => {
        dispatch(eliminarDefaultSuscripcion3());     
    };
    function eliminarDefaultSuscripcion3() { return { type: userConstants.ELIMINAR_DEFAULT_SUSCRIPCION } }
}


