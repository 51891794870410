import http from '../_helpers/http.service';

export const notificacionService = {
    getNotificacionesPU,
    marcarLeidas
};

function getNotificacionesPU() {    
    var url = '/api/notificaciones/traerPU';    
    return http.get(url);    
}

function marcarLeidas() {    
    var url = '/api/notificaciones/marcarLeidas';    
    return http.get(url);    
}

