import React from 'react';
import { connect } from 'react-redux';
import { equipoActions, sensorActions } from '../../_actions';
import {Row,Col,Modal} from 'react-bootstrap';
import { sensorConstants, equipoConstants } from '../../_constants';
import {EditarSensor} from './EditarSensor';
import { Graficador, WIDGET_TAMANIO ,history, MODO_LAYOUT} from '../../_helpers';
import PuffLoader from "react-spinners/PuffLoader";
import _ from 'lodash';
import { DesarrolladorCard } from './DesarrolladorCard';
import {RadioGroup, Radio} from 'react-radio-group';

class DesarrolladorCardSimple extends React.Component {

    constructor(props) { 
       super(props);      
       this.updateDimmer = _.debounce(this.updateDimmer, 100);
    }
    state = {
        show:false,
        showResize:false
    };

    handleChange(event,tipo) {
        tipo===1? this.setState({texto: event.target.value}): this.setState({longitud: event.target.value});       
    }

    cerrarOjito=()=>{
        this.setState({show:false})
    }

    abrirResize=()=>{
        this.setState({showResize:true})
    }

    cerrarResize=()=>{
        this.setState({showResize:false})
    }

    abrirOjito=()=>{
        this.setState({show:true})
    }    

    seleccionarSensor(){
        this.props.seleccionarSensor(this.props.sensor);
    };

    updateDimmer =(value)=> {
        this.props.enviarASensor(this.props.sensor,value,undefined,false);
    }

    definirValueInicial=()=>{
        const {layouts,sensor} = this.props;
        const key = sensor.idShadow.toString();

        const el= layouts.xs.find(valu => valu.i===key);
        if(el.w===6 && el.h===1)return WIDGET_TAMANIO.UnoUno.id;
        if(el.w===12 && el.h===1)return WIDGET_TAMANIO.UnoDos.id;
        if(el.w===6 && el.h===2)return WIDGET_TAMANIO.DosUno.id;
        if(el.w===12 && el.h===2)return WIDGET_TAMANIO.DosDos.id;

    }  

    generarResize=(nuevoId)=>{
        const {sensor} = this.props;
        let {layouts,seleccionado} = this.props;
        const key = sensor.idShadow.toString();
        const pathName  = history.location.pathname;
        const esEquipo = pathName!=='/home/listaVista';
        let el= layouts.xs.find(valu => valu.i===key);

        if(nuevoId===WIDGET_TAMANIO.UnoUno.id){
            el.h=WIDGET_TAMANIO.UnoUno.h;
            el.w=WIDGET_TAMANIO.UnoUno.w;
        }
        if(nuevoId===WIDGET_TAMANIO.UnoDos.id){
            el.h=WIDGET_TAMANIO.UnoDos.h;
            el.w=WIDGET_TAMANIO.UnoDos.w;
        }
        if(nuevoId===WIDGET_TAMANIO.DosUno.id){
            el.h=WIDGET_TAMANIO.DosUno.h;
            el.w=WIDGET_TAMANIO.DosUno.w;
        }
        if(nuevoId===WIDGET_TAMANIO.DosDos.id){
            el.h=WIDGET_TAMANIO.DosDos.h;
            el.w=WIDGET_TAMANIO.DosDos.w;
        }
        el.moved=true;

        layouts.forzar=true;

        this.props.enviarNuevoLayOut(JSON.stringify(layouts),esEquipo,seleccionado.id)
        this.cerrarResize();
    }   

    definirWidget=()=>{
        const {modoLayout,sensor} = this.props;

        const sensorFinal = sensor.shadows.find(valu=>valu.id===sensor.idShadow);

        if(modoLayout===MODO_LAYOUT.CELU_LARGE)return sensorFinal.tipoWidget.id;
        else{
            if(sensorFinal.tipoWidget.id===sensorConstants.TIPO_WIDGET_TOOGLE)
                return sensorConstants.TIPO_WIDGET_TOOGLE;
            else return sensorConstants.TIPO_WIDGET_TEXTO;
        }        
    }

    render(){      
        const {sensor,modo,seleccionado,modoSuperSimple,layouts}= this.props;
        const {ultimoValor}= sensor;  
        const unidad = sensorConstants.style[sensor.tipoSensor.id].unidad;
        const claseColor = sensorConstants.style[sensor.tipoSensor.id].color;
        return (        
        <>
                <div className={"card-components-sm shadow "+claseColor +(!sensor.conectado && ' disabled shadow-none')}>
                    <div className="border-disabled">
                        <Row className="p-3 ">
                            <Col xs={12} className="mt-2">
                                <h6 className="text-white font-weight-bold text-truncate mt-2 ">
                                <span className="conexion-si">{(sensor.conectado) && <><span className="text-recursos si"> <PuffLoader size={12} color={"#08dbb2"}/></span></>} </span>  
                                <span className="dragg"><i className="fas fa-expand-arrows-alt draggableZoneIot text-muted mr-2"></i></span> {sensor.labelShadow}                                   
                                </h6>  
                                <div className="font-weight-bold conexion txt-12">
                                    {(!sensor.conectado) && <><span className="badge text-tomate no"><span className="material-icons align-middle text-tomate txt-14">error</span>SIN CONEXIÓN</span></>}
                                </div> 
                            </Col>
                        </Row>
                        <div className="icon-editar" onClick={()=>this.abrirOjito()}>
                            <i className="fas fa-eye"></i>
                        </div>  
                        <div className="edit-sensor options text-white">                     
                            <EditarSensor sensor={sensor} seleccionado={seleccionado} modoSuperSimple={modoSuperSimple} />                                
                        </div> 
                        <div className="resize-mobile text-muted" onClick={()=>this.abrirResize()}>
                            <i className="fas fa-angle-right"></i>
                        </div>                
                        
                        {(modo===equipoConstants.TAB_DASHBOARD) && 
                            <div className="th_recursos widgets">
                                {ultimoValor!==undefined &&                                                     
                                    <Graficador tipoWidget={this.definirWidget()} 
                                        layouts={layouts}
                                        modoSuperSimple={modoSuperSimple}
                                        sensor={sensor}
                                        enviarASensor={this.props.enviarASensor}
                                        updateDimmer={this.updateDimmer}                                                    
                                        width={220} 
                                        height={160}
                                        valorMinReferencia={parseFloat(sensor.valorMinReferencia)} 
                                        valorMaxReferencia={parseFloat(sensor.valorMaxReferencia)}
                                        valor={ultimoValor}
                                        unidad={unidad} />         
                                }  
                            </div>
                        }
                     
                </div>
                </div>
                {this.state.show &&
                <Modal show={this.state.show} onHide={()=>this.cerrarOjito()} dialogClassName="modal-view">
                    <Modal.Header  className="text-white" closeButton>
                    </Modal.Header> 
                    <Modal.Body className="th_recursos mb-4">
                        <DesarrolladorCard sensor={sensor} modo={modo} seleccionado={seleccionado}/>               
                    </Modal.Body>                     
                </Modal>}

                {this.state.showResize &&
                <Modal show={this.state.showResize} onHide={()=>this.cerrarResize()} aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header  className="bg-recursos text-white" closeButton>
                        Tamaño de {sensor.labelShadow}
                    </Modal.Header> 
                    <Modal.Body className="th_recursos">
                        <RadioGroup  name="tipoEvento" selectedValue={this.definirValueInicial()}
                            onChange={(value)=> this.generarResize(value)}>
                                <label className="label-check" htmlFor="UnoUno">1X1</label>
                                    <Radio className="option-input checkbox" id="UnoUno" value={WIDGET_TAMANIO.UnoUno.id} />
                                    <br/>
                                <label className="label-check" htmlFor="UnoDos">1X2</label>
                                    <Radio className="option-input checkbox" id="UnoDos" value={WIDGET_TAMANIO.UnoDos.id} />
                                    <br/>
                                <label className="label-check" htmlFor="DosUno">2X1</label>
                                    <Radio className="option-input checkbox" id="DosUno" value={WIDGET_TAMANIO.DosUno.id} />
                                    <br/>
                                <label className="label-check" htmlFor="DosDos">2X2</label>
                                    <Radio className="option-input checkbox" id="DosDos" value={WIDGET_TAMANIO.DosDos.id} />
                                    <br/>

                        </RadioGroup>               
                    </Modal.Body>                     
                </Modal>}
               
                  </>     
        );
    }
}

function mapState(state) {   
    return {  };
}

const actionCreators = {
    seleccionarSensor:sensorActions.seleccionarSensor,
    enviarASensor:sensorActions.enviarASensor,
    enviarNuevoLayOut:equipoActions.enviarNuevoLayOut
}

const connectedDesarrolladorCardSimple = connect(mapState, actionCreators)(DesarrolladorCardSimple);
export { connectedDesarrolladorCardSimple as DesarrolladorCardSimple };