import React from 'react';
import { connect } from 'react-redux';
import {Row,Col} from 'react-bootstrap';
import Moment from 'react-moment';  
import { consultaActions } from '../../_actions';
import ReactTooltip from 'react-tooltip';

class InfoConsultaUser extends React.Component {
    constructor(props) {
        super(props);
        
        if(this.props.esUser)this.state.esUser=true;
            else this.state.esUser=false;
        if(this.props.consulta)this.state.idConsulta=this.props.consulta.id;
            
        this.handleChange = this.handleChange.bind(this);
        this.comentarioFinal = React.createRef();
      }

    traerMensajes(){
        this.props.cargarMensajes(this.state.idConsulta,this.props.esUser,this.focusearFinal);
    }

    focusearFinal=()=>{
        const thisP=this;
        setTimeout(function(){ thisP.comentarioFinal.current.scrollIntoView({ behavior: 'smooth' })}, 300);
        
    }

    handleChange = (event) => {
          this.setState({nuevoMensaje: event.target.value});
    };

    handleEnviar = () => {
        
        if(this.state.esUser){
            this.props.enviarMensaje(this.state.idConsulta,this.state.nuevoMensaje,this.focusearFinal);
            this.setState({
                nuevoMensaje:''
            });          
        }else{
            this.props.enviarMensajeAdmin(this.state.idConsulta,this.state.nuevoMensaje);
            this.setState({
                nuevoMensaje:''
            });
        }
       
    };

    state={
        pedido:[],
        mensajes:[],
        nuevoMensaje:'',
        esUser:true,
        idConsulta:''
    }

    render(){
        const {esDeCompra} = this.props;
        
        let pedido = {};
        if(this.props.esUser){
            this.props.misConsultas.forEach((con, index) => {                
                if(con.id===this.state.idConsulta)pedido = con;
             });
        }else{
            this.props.consultasAdm.forEach((con, index) => {                
                if(con.id===this.state.idConsulta)pedido = con;
             });
        }
        

        let { mensajes } = pedido;
        if(mensajes && mensajes.length===0)this.traerMensajes();
        if(!mensajes)mensajes=[];
        return (
            <>
                <Row className="p-4"> 
                <Col sm={12} >
                    <button className="btns btns-secundario mt-3 mb-4" onClick={()=>this.traerMensajes()}>Actualizar</button>
                    </Col>
                    <Col sm={12} >
                    {mensajes.length>0 && mensajes.map((mensaje, index)=>
                    
                        <div key={index} className="comentarios">
                            {this.state.esUser &&
                                <div>
                                    {mensaje.esMsgDeUser &&
                                    <>
                                    <div className="b-speak-i"><span className="material-icons">person</span></div>
                                        <div className="comentario-iot burbuja-iot" variant='success'>
                                           <p className="font-weigth-light text-muted txt-14"><i className="fas fa-calendar"></i> <Moment date={mensaje.fecha} format="DD/MM/YYYY hh:mm"/></p> 
                                            <p className="font-italic">
                                            "{mensaje.cuerpo}" 
                                        </p>
                                        </div>
                                    </>
                                    }
                                    {!mensaje.esMsgDeUser &&
                                    <>
                                         <div className="b-speak"><span className="material-icons material-icons-outlined">support_agent</span></div>
                                         <div className="comentario burbuja" variant='success'>
                                            <p className="font-weigth-light text-muted txt-14"><i className="fas fa-calendar"></i> <Moment date={mensaje.fecha} format="DD/MM/YYYY hh:mm"/></p> 
                                            <p className="font-italic">
                                            "{mensaje.cuerpo}" 
                                            </p>
                                        </div>
                                       
                                    </>
                                    }
                                </div>
                            }    
                                <div ref={this.comentarioFinal}></div>
                        </div>
                    )} 
                    </Col>
                </Row>
                {(pedido.estadoSt!==undefined && (pedido.estadoSt.id===1 || pedido.estadoSt.id===2)) &&
                    <div className="pie-fijo  p-4 row border-top bg-silver th_iot">
                        <div className="col-10 col-lg-12 send-b">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text cursor">
                                        <a data-tip data-for='send'>
                                            <i className="fas fa-paper-plane" onClick={this.handleEnviar}></i>
                                            <ReactTooltip place="bottom"  id='send' aria-haspopup='true' >
                                                <span>Enviar Mensaje</span>
                                            </ReactTooltip>
                                        </a>
                                    </div>
                                </div>
                                <input onChange={this.handleChange} type="text" value={this.state.nuevoMensaje}  placeholder="Escriba su mensaje..." />
                            </div>  
                        </div>              
                    </div>
                }
            </>
        );
    }
}


function mapState(state) {
    const { misConsultas } = state.consultas;
    const { consultasAdm } = state.consultas;
    return { misConsultas,consultasAdm };
}

const actionCreators = {     
    enviarMensaje:consultaActions.enviarMensaje,
    enviarMensajeAdmin:consultaActions.enviarMensajeAdmin,
    cargarMensajes:consultaActions.cargarMensajes    
}

const connectedInfoConsultaUser = connect(mapState, actionCreators)(InfoConsultaUser);
export { connectedInfoConsultaUser as InfoConsultaUser };