import React from 'react';
import WidgetMap from './WidgetMap';

export default class MapClassMarker extends React.Component {
      state={
      }

      shouldComponentUpdate(nextProps, nextState){       
        console.log('Mmarkermap',this.props, nextProps)
        
        if(this.props.sensor.ultimoValor===nextProps.sensor.ultimoValor){
          return false
        }else{
          return true
        }
      }

      componentDidMount(){        
      }
      
      render(){
        
          return(
              <>
                    <WidgetMap
                    sensor={this.props.sensor}
                    width={this.props.width}
                    height={this.props.height}/>
              </>
         )
    }
}