import React from 'react';
import { connect } from 'react-redux';
import { sensorActions,  utilActions } from '../../_actions';
import {EditarSensor} from '../Sensor/EditarSensor';
import MaterialTable from 'material-table';
import { sensorConstants } from '../../_constants';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Graficador, RoleID } from '../../_helpers';
import {Col,Row} from 'react-bootstrap';

const _ = require('lodash');

class TablaSensor extends React.Component {

    state = {
        show:false
    };

    seleccionarSensor(sensor){
        this.props.seleccionarSensor(sensor);
    };    

    render(){
        const {seleccionado,sensores} = this.props;
        const sensoresLista = utilActions.pasarListaSensoresAShadows(utilActions.llenarLista(seleccionado.sensores,sensores));
        const soyDeveloperdeEsteEquipo = seleccionado?seleccionado.idRol===RoleID.DEV:false;
        const linkInfoSensor = '/home/infoSensor';
        const header = [
            { title: <div>Nombre</div> , field: 'labelShadow',cellStyle:{width:'10%'},},

            !soyDeveloperdeEsteEquipo?
            { title: <div className="text-center">Tipo</div>, field: 'tipoSensor.descripcion' ,cellStyle:{width:'10%'},}:{},

            { title: <div className="text-center">Último valor</div>, cellStyle:{width:'30%',textAlign:'center'},
                render: row =>  
                <div>
                   
                    {(row.tipoWidget.id===sensorConstants.TIPO_WIDGET_TOOGLE && row.ultimoValor!==undefined ) &&                         
                            <Graficador tipoWidget={row.tipoWidget.id} 
                                sensor={row}   
                                enviarASensor={this.props.enviarASensor}                                             
                                width={220} 
                                height={160}
                                valor={row.ultimoValor}
                                unidad={''} />         
                    }  
                    {(row.tipoWidget.id===sensorConstants.TIPO_WIDGET_MAPA && row.ultimoValor!==undefined ) && 
                         <p className="txt-18 font-weight-bold ml-2">
                             {row.ultimoValor?`lat:${row.ultimoValor[row.variableJson]}
                             long:${row.ultimoValor[row.variableLongitud]}`:''}
                        </p>
                    }
                     {(row.tipoWidget.id!==sensorConstants.TIPO_WIDGET_TOOGLE && row.tipoWidget.id!==sensorConstants.TIPO_WIDGET_MAPA ) && 
                         <p className="txt-27 font-weight-bold ml-2">{(typeof(row.ultimoValor)==='string' || typeof(row.ultimoValor)==='number')?row.ultimoValor:''}</p>
                    }


               </div>
            },
            { title  : <div className="text-rigth">Acciones</div>,cellStyle:{width:'70%', textAlign:'right'},
                render: row => 
                <>
                <Row className="no-gutters d-content justify-content-end">
                    <Col className="col-auto">
                    <div className="d-inline-flex txt-18 ">
                        <Link data-tip data-for={'histo'+row.id} to={linkInfoSensor+'?id='+row.id} onClick={() => this.seleccionarSensor(row)}>
                        <i className="fas fa-history text-muted"></i>
                        </Link>
                        <ReactTooltip place="bottom" id={'histo'+row.id} aria-haspopup='true' >
                        <span>Histórico</span>
                        </ReactTooltip>
                    </div>
                    </Col>
                    <Col className="col-auto ml-1">
                    <EditarSensor seleccionado={seleccionado} sensor={row}/>
                    </Col>
                    </Row>
                    </> 
            }
        ];
        const options={
            search: true,
            sorting: true,
            headerStyle: { textAlign:'center'},
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 30 ,50, 75, 100]
        }  
        let datos= _.clone(sensoresLista)
        return (
            <div>
                {datos!==undefined &&
                    <MaterialTable
                        title={"Widgets"}
                        columns={header}
                        data={datos}
                        options={options}                  
                        localization={utilActions.traerPalabrasTabla()}
                    />
                }
            </div>
        );
    }
}

function mapState(state) {
    const { sensores } = state.equipos;
    return { sensores };
}

const actionCreators = {
    enviarASensor:sensorActions.enviarASensor,
    seleccionarSensor:sensorActions.seleccionarSensor
}

const connectedTablaSensor = connect(mapState, actionCreators)(TablaSensor);
export { connectedTablaSensor as TablaSensor };