import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Button,Col,Modal} from 'react-bootstrap';
import MaterialTable from 'material-table';
import {grupoConstants} from '../../_constants';
import {grupoActions, utilActions} from '../../_actions';
import { alertConfirm, Role, tengoRol } from '../../_helpers';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { FormAgregarUsuario } from './Forms/FormAgregarUsuario';



class MisUsuarios extends React.Component {

    componentDidMount(){      
        if(this.props.misAutorizaciones===undefined || this.props.misUsuarios===undefined )this.props.getListadosGrupoPU();
    }

    reasignarRol = (idRolNuevo,rel) => {
        this.props.reasignarRol(this.state.usuario,idRolNuevo,rel);             
        this.cerrarAlert();
    };

    asignarRol= (item,rol) => {
        this.props.asignarRol(item,rol);
        this.cerrarAlert();             
    };

    handleRechazar = (item) => {
        this.props.asignarRol(item,0);
        this.cerrarAlert();            
    };

    removerPermiso = (item) => {
        this.props.eliminarRol(item.equipo.id,item.rol.id,this.state.usuario.id);
        this.cerrarAlert();            
    };

    eliminarUsuario = (item) => {
        this.props.eliminarUsuario(item);    
        this.cerrarAlert();        
    };

    cerrarAlert=()=>{
        this.setState({alert: undefined,showMasInfoUsuario:false});
    }

    handleClose = () => {
        this.setState({showAgregarUsuario: false});
    };

    handleShowNU = () => {
        this.setState({showAgregarUsuario: true});
    };

    confirmarAccion = (tipo,item) => {
        let getAlert;
        
        if(tipo==='eliminarUsuario'){
            getAlert=alertConfirm('Confirmar',
                `¿Está seguro que desea eliminar el usuario? Se le quitaran todos los equipos, eventos, acciones creadas.`,
                'Sí, eliminar',
                'No',
                this.cerrarAlert,
                ()=>this.eliminarUsuario(item),
                null,
                true
            )  
        }  
        if(tipo==='eliminarPermiso'){
            getAlert=alertConfirm('Confirmar',
                `¿Está seguro que desea eliminar el usuario? Se le quitaran todos los equipos, eventos, acciones creadas.`,
                'Sí, eliminar',
                'No',
                this.cerrarAlert,
                ()=>this.removerPermiso(item),
                null,
                true
            )  
        }          
        if(tipo==='rechazarAutorizacion'){
            getAlert=alertConfirm('Confirmar',
                `¿Está seguro que desea rechazar la autorización?`,
                'Sí, rechazar',
                'No',
                this.cerrarAlert,
                ()=>this.handleRechazar(item),
                null,
                true
            )  
        }  
        
        this.setState({
            alert: getAlert
        });
    }

    confirmarAccionDosParams = (tipo,item,param2) => {
        let getAlert;
        
        if(tipo==='asignarRol'){
            getAlert=alertConfirm('Confirmar',
                `¿Está seguro que desea aprobar la autorización? Si asi lo hace, este usuario podra acceder a las funcionalidades del equipo solicitado`,
                'Sí, aprobar',
                'No',
                this.cerrarAlert,
                ()=>this.asignarRol(item,param2),
                null,
                true
            )  
        }  
        
        if(tipo==='reasignarRol'){
            getAlert=alertConfirm('Confirmar',
                `¿Está seguro que desea aprobar el cambio de rol? `,
                'Sí, cambiar',
                'No',
                this.cerrarAlert,
                ()=>this.reasignarRol(param2,item),
                null,
                true
            )  
        }  
        
        this.setState({
            alert: getAlert
        });
    }

    state={
        showAgregarUsuario:false,
        showMasInfoUsuario:false,
        alert:undefined
    }

    render(){
        const { misAutorizaciones,misUsuarios } = this.props;
        const soyDeveloper = tengoRol([Role.DEV]);
        const soyUserAdmin = tengoRol([Role.USER_ADMIN]);

        const header = [
            { title: 'Fecha solicitud', render: rowData =>  <Moment date={rowData.fecha} format="DD/MM/YYYY hh:mm"/>},
            { title: 'Estado', field: 'estado'},
            { title: 'Solicitante', field: 'solicitante'},            
            { title: 'Equipo', field: 'equipo'},
            { title: 'Acciones',
               render: rowData => 
               <div>
                    <button className="btn btn-link text-recursos" data-tip data-for='admin' onClick={() => this.confirmarAccionDosParams('asignarRol',rowData,soyDeveloper?grupoConstants.ROL_DESARROLLADOR:grupoConstants.ROL_USER_ADMIN)} ><span className="material-icons-outlined txt-30 ">admin_panel_settings</span></button>
                    <ReactTooltip place="bottom" id='admin' aria-haspopup='false' >
                            <span>Aprobar como Administrador</span>
                    </ReactTooltip>                     
                    <button className="btn btn-link text-cielo" data-tip data-for='user' onClick={() => this.confirmarAccionDosParams('asignarRol',rowData,grupoConstants.ROL_USER)} ><span className="material-icons-outlined txt-30">verified_user</span></button>
                    <ReactTooltip place="bottom" id='user' aria-haspopup='false' >
                            <span>Aprobar como usuario</span>
                    </ReactTooltip>     
                    <button className="btn btn-link text-danger" data-tip data-for='rechazo' onClick={() => this.confirmarAccion('rechazarAutorizacion',rowData)} ><span className="material-icons-outlined txt-30">highlight_off</span></button>
                    <ReactTooltip place="bottom" id='rechazo' aria-haspopup='false' >
                            <span>Rechazar</span>
                    </ReactTooltip>     
               </div>       
            }
          ];

          const headerUsuarios = [
            { title: 'Usuario', field: 'username'},
            { title: 'Cantidad de equipos', render: rowData => 
                <div>
                     {rowData.roles?rowData.roles.length:0}
                </div> 
             },   
            { title: 'Acciones',
               render: rowData => 
               <div>
                    <button className="btn btn-link text-danger" data-tip data-for='eliminar' onClick={() => this.confirmarAccion('eliminarUsuario',rowData)} ><span className="material-icons-outlined txt-30">delete</span></button>   
                    <ReactTooltip place="bottom" id='eliminar' aria-haspopup='false' >
                            <span>Eliminar</span>
                    </ReactTooltip>  
                    <button className="btn btn-link text-info" data-tip data-for='info' onClick={() => this.setState({usuario:rowData,showMasInfoUsuario:true})} ><span className="material-icons-outlined txt-30">info</span></button>
                    <ReactTooltip place="bottom" id='info' aria-haspopup='false' >
                            <span>Más Info</span>
                    </ReactTooltip>    
               </div>       
            }
          ];

          const headerUseRoles = [
            { title: 'Equipo', render: rowData =>          
                 rowData.equipo.nombre           
            }, 
            { title: 'Perfil', render: rowData => 
                <div>
                     {rowData.rol.id===grupoConstants.ROL_USER &&
                     'Usuario'}  
                      {rowData.rol.id===grupoConstants.ROL_USER_ADMIN &&
                     'Administrador'}  
                      {rowData.rol.id===grupoConstants.ROL_DESARROLLADOR &&
                     'Desarrollador'} 
                </div> 
             },   
            { title: 'Acciones',
               render: rowData => 
               <div>{rowData.rol.id===grupoConstants.ROL_USER &&
                        <>
                        <button className="btn btn-link text-recursos" data-tip data-for='asig-admin' onClick={() => this.confirmarAccionDosParams('reasignarRol',rowData,soyDeveloper?grupoConstants.ROL_DESARROLLADOR:grupoConstants.ROL_USER_ADMIN)} ><span className="material-icons-outlined txt-30 ">admin_panel_settings</span></button>
                        <ReactTooltip place="bottom" id='asig-admin' aria-haspopup='false' >
                            <span>Asignar Admin</span>
                        </ReactTooltip> 
                        </>   
                    }  
                    {rowData.rol.id===grupoConstants.ROL_USER_ADMIN &&
                        <>
                        <button className="btn btn-link text-cielo" data-tip data-for='asig-est' onClick={() => this.confirmarAccionDosParams('reasignarRol',rowData,grupoConstants.ROL_USER)} ><span className="material-icons-outlined txt-30 ">verified_user</span></button>  
                        <ReactTooltip place="bottom" id='asig-est' aria-haspopup='false' >
                            <span>Asignar Estándar</span>
                        </ReactTooltip> 
                        </>   
                    }  
                    {rowData.rol.id===grupoConstants.ROL_DESARROLLADOR &&
                        <>
                        <button className="btn btn-link text-cielo" data-tip data-for='asig-est' onClick={() => this.confirmarAccionDosParams('reasignarRol',rowData,grupoConstants.ROL_USER)} ><span className="material-icons-outlined txt-30 ">verified_user</span></button>  
                        <ReactTooltip place="bottom" id='asig-est' aria-haspopup='false' >
                        <span>Asignar Estándar</span>
                        </ReactTooltip> 
                        </>
                    } 
                        <button className="btn btn-link text-danger" data-tip data-for='remove' onClick={() => this.confirmarAccion('eliminarPermiso',rowData)} ><span className="material-icons-outlined txt-30 ">highlight_off</span></button>
                        <ReactTooltip place="bottom" id='remove' aria-haspopup='false' >
                        <span>Remover</span>
                        </ReactTooltip>  
               </div>       
            }
          ];
        const options={
            search: false,
            sorting: false,
            paging:false
        }  

        const optionsUser={
            search: true,
            sorting: true,
            paging:true
        }  

        return (
            <div className="main-content">
            <Container fluid>
                     <Row className="row mb-5 mt-5">
                         <Col sm={12}>
                            {misAutorizaciones!==undefined &&
                                <MaterialTable
                                    localization={utilActions.traerPalabrasTabla()}
                                    title='Solicitudes pendientes'
                                    columns={header}
                                    data={misAutorizaciones}
                                    options={options} 
                                />
                            }   
                        </Col> 
                    </Row>

                    {(soyUserAdmin || soyDeveloper )&&<Row>
                        <Col sm={12}>       
                            <button className="btns btns-iot text-dark" variant="primary" onClick={this.handleShowNU}>
                            <span className="material-icons material-icons-outlined">forum</span> Agregar usuario
                            </button>
                        </Col>  
                    </Row>}
                    <br/>
                    <Row className="row mb-5">
                         <Col sm={12}>
                            {(misUsuarios!==undefined) &&
                                <MaterialTable
                                    localization={utilActions.traerPalabrasTabla()}
                                    title='Usuarios'
                                    columns={headerUsuarios}
                                    data={misUsuarios}
                                    options={optionsUser}
                                />
                            }   
                        </Col> 
                    </Row>
            </Container>

            {this.state.showMasInfoUsuario && <Modal show={this.state.showMasInfoUsuario} onHide={()=>this.setState({usuario:undefined,showMasInfoUsuario:false})} backdrop="static"  >
                    <Modal.Header className="bg-cielo text-white" closeButton>
                        <Modal.Title className="text-truncate"> <small>Datos de</small> {this.state.usuario.username}</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body className="th_cielo">
                        <Container>
                            <Row className="row mb-5">
                                <Col sm={12}>
                                    {(this.state.usuario.roles!==undefined) &&
                                        <MaterialTable
                                            localization={utilActions.traerPalabrasTabla()}
                                            title='Mis roles'
                                            columns={headerUseRoles}
                                            data={this.state.usuario.roles}
                                            options={optionsUser}
                                        />
                                    }   
                                </Col> 
                            </Row>
                            <Row className="row mb-5">
                                <Col sm={12}>
                                     <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.setState({usuario:undefined,showMasInfoUsuario:false})}>
                                        <i className="icon-times"></i> Cerrar
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>                     
                </Modal>} 

                {this.state.showAgregarUsuario && 
                    <FormAgregarUsuario show={this.state.showAgregarUsuario} handleClose={()=>this.handleClose()}/>
                 }
                {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {
    const { misAutorizaciones,misUsuarios } = state.grupo;
    return {  misAutorizaciones,misUsuarios };
}

const actionCreators = {   
    asignarRol:grupoActions.asignarRol,
    eliminarRol:grupoActions.eliminarRol,
    reasignarRol:grupoActions.reasignarRol,
    eliminarUsuario:grupoActions.eliminarUsuario,
    getListadosGrupoPU:grupoActions.getListadosGrupoPU
}

const connectedMisUsuarios = connect(mapState, actionCreators)(MisUsuarios);
export { connectedMisUsuarios as MisUsuarios };
