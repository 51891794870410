import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {userService} from '../_services'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        userService.estaLogueado()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location,values:props.location?props.location.search:undefined } }} />
    )} />
)