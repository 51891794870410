export const consultaConstants = {
    CONSULTAS_GET_PU: 'CONSULTAS_GET_PU',
    CONSULTAS_GET_ALL:'CONSULTAS_GET_ALL',
    AGREGAR_NUEVA_CONSULTA:'AGREGAR_NUEVA_CONSULTA',
    MENSAJE_USUARIO:'MENSAJE_USUARIO',
    CARGAR_MENSAJES:'CARGAR_MENSAJES',
    MENSAJE_ADMIN:'MENSAJE_ADMIN',
    TIPOS_PROBLEMA:'TIPOS_PROBLEMA',
    ESTADO_CANCELAR:4,
    ACTUALIZAR_ESTADO_CONSULTA:'ACTUALIZAR_ESTADO_CONSULTA',
    CARGAR_MENSAJES_ADM:'CARGAR_MENSAJES_ADM',
    TRAER_TUTORIALES:'TRAER_TUTORIALES',
    GRABAR_TUTORIAL:'GRABAR_TUTORIAL',
    ELIMINAR_TUTORIAL:'ELIMINAR_TUTORIAL',
    ACT_TUTORIAL:'ACT_TUTORIAL',
    LIMPIAR_BURBUJAS:'LIMPIAR_BURBUJAS',
    SELECCIONAR_CONSULTA_BURBUJA:'SELECCIONAR_CONSULTA_BURBUJA',
    TOOGLE_GLOBO:'TOOGLE_GLOBO',
    CERRAR_GLOBO:'CERRAR_GLOBO',
    NUEVO_MENSAJE_CHAT:'NUEVO_MENSAJE_CHAT'
};
