import React from 'react';
import { Link } from 'react-router-dom';
import { history} from '../../_helpers'; 
import {
    isBrowser,
    isMobile
  } from "react-device-detect";

export default class NavBarLateralMiPerfil extends React.Component {
    constructor(props){
        super(props);
        this.toogleClasses = this.toogleClasses.bind(this);
        this.state = {active:true};
        this.state.active =!isMobile;
        
        this.state.pathActual=history.location.pathname;
 
        history.listen((location, action) => {
             this.setState({pathActual:location.pathname});
        });

        this.wrapperRef = React.createRef();
     }

    state = {
        active:false
    };
    miClase = (link)=>{
        if(link===this.state.pathActual) return 'iconmenu'
        else return ''
    }
    toogleClasses(event){
        this.setState((prevState) => {
            return {active: !this.state.active};
        });
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) 
            this.setState({active: false});
        
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    render(){
        
        return (
            <nav ref={this.wrapperRef} className={this.state.active ? 'collapsed navbar-primary': 'navbar-primary'}>
                <div className="btn-expand-collapse item" onClick={(event) => this.toogleClasses(event)}>
                    <button className={this.state.active ? 'active flash hamburger hamburger-arrow-left': 'hamburger hamburger-arrow-left'}>
                        <span className="icon"></span>
                    </button>
                </div>
                <ul className="navbar-primary-menu">
                    <li className={this.state.active ? 'text-white  mt-4': ' text-white desplazar mt-4'}>
                       <Link to="/perfil/misDatos" className={this.miClase('/perfil/misDatos')} onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                       <div className="menu-circle-item"><span className="material-icons material-icons-outlined">assignment_ind</span></div> 
                            <div className="nav-label font-weight-bold"> Mis datos</div>
                          </Link>
                    </li>
                    <li className={this.state.active ? 'text-white ': ' text-white desplazar'}>
                        <Link to="/perfil/usuarios" className={this.miClase('/perfil/usuarios')} onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                        <div className="menu-circle-item"><span className="material-icons material-icons-outlined">group_work</span></div>
                             <div className="nav-label font-weight-bold"> Mis usuarios</div>
                        </Link>
                    </li>   
                    <li className={this.state.active ? 'text-white ': ' text-white desplazar'}>
                    <Link to="/perfil/misConsultas"  className={this.miClase('/perfil/misConsultas')} onClick={(event) => (!isBrowser && this.toogleClasses(event))}>
                    <div className="menu-circle-item"><span className="material-icons material-icons-outlined">forum</span></div>
                        <div className="nav-label font-weight-bold"> Mis consultas</div>
                    </Link>
                    </li>                   
                               
                </ul>
            </nav>
        );
    }
}
