import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FieldArray  } from 'formik';
import * as Yup from 'yup';
import {ErrorMessage,ERR,TIPO_EVENTO, LoadingBoton,DIAS_SEMANA,PERIODO,TIPO_ACCION, TIPO_INFORMACION,TIPO_NOTIFICACION} from '../../_helpers';
import { eventoService,programaService} from '../../_services';
import {accionColectivaActions, alertActions,equipoActions,eventoActions, programaActions,utilActions} from '../../_actions';
import {Row,Col,Badge} from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import Select from 'react-select';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import {RadioGroup, Radio} from 'react-radio-group';
import { sensorConstants } from '../../_constants';

Moment.locale('es')
momentLocalizer()


const Paso0 = ({values,state,setFieldValue,optionsSensores}) =>(
  <>
  <Row>
    <Col xs={12}>
    <h5><Badge className=" text-white">Paso <b>1</b></Badge></h5>
    </Col>
            <Col xs={12} lg={5}>
                <label htmlFor="nombre">Nombre</label><ErrorMessage name={`nombre`} />
                <Field name="nombre" />
            </Col>
            <Col xs={12} lg={7}>
                <label >Tipo</label> <ErrorMessage name={`tipoEvento.id`} />
                <RadioGroup  name="tipoEvento" selectedValue={values.tipoEvento.id}
                      onChange={(value)=> setFieldValue(`tipoEvento.id`,value)}>
                          <label className="label-check" htmlFor="Automatico">Automático</label>
                                <Radio className="option-input checkbox"  type="checkbox" id="Automatico" value={TIPO_EVENTO.AUTOMATICO} />

                          <label className="label-check" htmlFor="Programado">Programado</label>
                                <Radio className="option-input checkbox"  type="checkbox" id="Programado" value={TIPO_EVENTO.PROGRAMADO} />

                </RadioGroup>
                
            </Col>
        </Row>
  </>
);

const Paso3 = ({values,state,setFieldValue,optionsSensores}) =>(
  <>
        <Row>
        <Col xs={12}>
        <h5><Badge className=" text-white">Paso<b>2</b></Badge></h5>
        </Col>

        {values.tipoEvento.id===TIPO_EVENTO.AUTOMATICO && 
                    <>
                      <Col xs={12}>
                          <b>¿Qué condicion debe cumplirse para activar al evento?</b>
                      </Col>
                      <Col xs={12} className="mb-4">
                            Mensaje explicativo personalizado segun el tipo de sensor. 
                      </Col>
                     <Col xs={12}>
                     <ErrorMessage name={`sensor.id`} />
                            <SelectSearch onChange={(selectedOption) =>setFieldValue(`sensor.id`, selectedOption)}
                                    options={optionsSensores} name={`sensor.id`}
                                    value={''+values.sensor.id}
                                    search={true}
                                    placeholder="Seleccione un sensor" />
                            
                     </Col>

                      <Col className="mt-4" xs={8} lg={6}>
                        <label>Condición</label><ErrorMessage name={`condicion.id`} />
                          <Field value={values.condicion.id} component="select" name={`condicion.id`} >
                              <option value=''>Seleccione</option>                    
                              {state.condiciones && state.condiciones.map(ts =>
                                <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                              )}
                          </Field>
                          
                      </Col>
                      <Col xs={4} lg={6} className="mt-4" >
                        <label htmlFor="valor">Valor</label><ErrorMessage name={`valor`} />
                          <Field name="valor" />
                          
                      </Col>
                    </>
          }
          {values.tipoEvento.id===TIPO_EVENTO.PROGRAMADO && 
              <>
                <Col xs={12}>
                    <b>¿Que dia y horarios desea ejecutar el evento?</b>
                </Col>
                <Col xs={12} className="mb-4">
                      Mensaje explicativo personalizado segun el tipo de evento. 
                </Col>
                <Col xs={8} lg={6}>
                      <label>Periodo</label><ErrorMessage name={`periodo.id`} />
                      <Field value={values.periodo ? values.periodo.id:''} component="select" name={`periodo.id`} >
                            <option value=''>Seleccione</option>                              
                            {state.periodos && state.periodos.map(ts =>
                              <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                            )}
                      </Field>
                    
                </Col>

                {values.periodo.id==PERIODO.SEMANAL?
               
                  <Col xs={12} lg={12}>

                    <label htmlFor="diaSemana">Dia de la semana</label><ErrorMessage name={`diaSemana`} />
                    <FieldArray
                      name="diaSemana"
                      render={arrayHelpers => (
                         <div className="mt-4 mb-4">                       
                              {DIAS_SEMANA.map((dia, index)=>  
                                  <div key={dia.id}>                                        
                                        <input
                                            className="option-input checkbox"
                                            name="sensor"
                                            type="checkbox"
                                            value={dia.id}
                                            checked={values.diaSemana.some(se=>se===dia.id)}
                                            onChange={e => {
                                              console.log(e)
                                              if (e.target.checked) {
                                                arrayHelpers.push(dia.id);
                                              } else {
                                                const idx = values.diaSemana.findIndex(se=>se===dia.id);
                                                
                                                arrayHelpers.remove(idx);
                                              }
                                            }}
                                        />
                                      <label className="label-check">{dia.descripcion}</label>
                                    </div>
                                )}                      
                        </div>
                      )}
                    />
                                             
                  </Col>
                :null}
                
                <Col xs={4} lg={6}>

                    <label htmlFor="hora">Horario</label>
                    <div>
                      <DateTimePicker
                        defaultValue={new Date(2020,6,6,values.hora,values.minuto,0,0)}
                        culture="en-GB"
                        format="HH:mm"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onChange={value =>{
                          value.getMinutes()?setFieldValue("minuto", value.getMinutes()):setFieldValue("minuto", 0)
                          value.getHours()?setFieldValue("hora", value.getHours()):setFieldValue("hora", 0)                     
                        }}
                        time={true}
                        date={false}
                      />
                    </div>           
                  
                </Col>
              </>
            }
        </Row>
  </>      
);

const Paso4 = ({values,state,setFieldValue,optionsSimple,accionColectiva,optionsSimpleToogle,user}) =>(
  <>
        <Row>
        <Col xs={12}>
        <h5><Badge className="text-white">Paso <b>3</b></Badge></h5>
        </Col>
            <Col xs={12}>
            <b>Configure las acciones a ejecutar</b><ErrorMessage name={`acciones`} />
            <FieldArray
                  name="acciones"
                  render={arrayHelpers => (
                      <div key={arrayHelpers.idx} className="form-body mt-2">
                        <div className="nueva-accion">
                            <button className="btns btns-iot btns-sm" type="button" onClick={() => arrayHelpers.push({ sensor: {id:''},tipoNotificacion: {id:''}, tipoAccion: {id:''}, tipoAccionColectiva: {id:''}, tipoInformacion: {id:''} })}>
                              <i className="material-icons align-middle txt-16">add</i> Nueva acción
                            </button>
                          </div>
                          {values.acciones.map((accion, index) => (
                            <Row key={index}  className="bg-light border-bottom mb-3 mt-3">
                              <div key={index} className="col-sm-12 mb-2">
                                <div>
                                    <div className="text-right mt-3">
                                        <button className="btn btn-link cursor text-danger delete " type="button" onClick={() => arrayHelpers.remove(index)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </div>                                  
                                      <br/>
                                      <label>Tipo de acción </label>
                                      <Field value={values.acciones[index].tipoAccion.id} component="select" name={`acciones.${index}.tipoAccion.id`} >
                                          <option value=''>Seleccione</option>                                        
                                          {state.tiposAcciones && state.tiposAcciones.map(ts =>
                                            <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                                          )}
                                      </Field>
                                      <ErrorMessage name={`acciones[${index}].tipoAccion.id`} />
                                  </div>
                                </div>
                                {(values.acciones[index].tipoAccion.id==TIPO_ACCION.NOTIFICACION) &&
                                     <>
                                      <Col xs={12}>
                                              <label>¿Por qué medio será notificado? </label>
                                              <FieldArray
                                                name={`acciones[${index}].tiposNotificaciones`}    
                                                render={arrayHelpers => (
                                                  <div className="mt-4 mb-4">                       
                                                        {state.tiposNotificaciones.map((tn, indexTn)=>                                                             
                                                            <div key={tn.id}>                                        
                                                                  <input
                                                                      className="option-input checkbox"
                                                                      name="tipoNoti"
                                                                      disabled={tn.id===TIPO_NOTIFICACION.TELEGRAM && !user.telegramChatId}
                                                                      type="checkbox"
                                                                      value={tn.id}
                                                                      checked={values.acciones[index].tiposNotificaciones?values.acciones[index].tiposNotificaciones.some(se=>se.id===tn.id):false}
                                                                      onChange={e => {
                                                                        if (e.target.checked) {
                                                                          arrayHelpers.push(tn);
                                                                        } else {
                                                                          const idx = values.acciones[index].tiposNotificaciones.findIndex(se=>se.id===tn.id);                                                                         
                                                                          arrayHelpers.remove(idx);
                                                                        }
                                                                      }}
                                                                  />
                                                                <label className="label-check">{tn.descripcion}</label>
                                                              </div>                                                            
                                                          )}                      
                                                  </div>
                                                )}
                                              />
                                              <ErrorMessage name={`acciones[${index}].tiposNotificaciones`} />  
                                              {values.tipoEvento.id===TIPO_EVENTO.AUTOMATICO && 'Te informaremos cuando se cumpla la condición'}
                                        </Col>
                                            {values.tipoEvento.id===TIPO_EVENTO.PROGRAMADO && 
                                               <>
                                                  <Col xs={12}>
                                                      <label>¿Qué información desea recibir? </label>
                                                      <FieldArray
                                                        name={`acciones[${index}].tiposInformaciones`}                                                        
                                                        render={arrayHelpers => (
                                                          <div className="mt-4 mb-4">                       
                                                                {state.tiposInformaciones.map((tn, indexTI)=>  
                                                                    <div key={tn.id}>                                        
                                                                          <input
                                                                              className="option-input checkbox"
                                                                              name="tipoNoti"
                                                                              type="checkbox"
                                                                              value={tn.id}
                                                                              checked={values.acciones[index].tiposInformaciones?values.acciones[index].tiposInformaciones.some(se=>se.id===tn.id):false}
                                                                              onChange={e => {
                                                                                if (e.target.checked) {
                                                                                  arrayHelpers.push(tn);
                                                                                } else {
                                                                                  const idx = values.acciones[index].tiposInformaciones.findIndex(se=>se.id===tn.id);                                                                         
                                                                                  arrayHelpers.remove(idx);
                                                                                }
                                                                              }}
                                                                          />
                                                                        <label className="label-check">{tn.descripcion}</label>
                                                                      </div>
                                                                  )}                      
                                                          </div>
                                                        )}
                                                      />
                                                      <ErrorMessage name={`acciones[${index}].tiposInformaciones`} />  
                                                  </Col>
                                                  <Col xs={12}>                                                  
                                                      <label>¿De qué sensores desea recibir información?</label>
                                                      <Select
                                                        isClearable
                                                        isMulti
                                                        value={optionsSimple ? optionsSimple.filter(obj => (values.acciones[index].sensores && values.acciones[index].sensores.some(cam =>cam.id===obj.id))) : []}
                                                        getOptionLabel={option =>`${option.descripcion}`}
                                                        getOptionValue={option => option.id}
                                                        onChange={so =>{
                                                            setFieldValue(`acciones[${index}].sensores`,so?so:[]);
                                                        }}
                                                        isSearchable={true}
                                                        placeholder="Seleccione una"
                                                        options={optionsSimple}
                                                    />  
                                                    <ErrorMessage name={`acciones[${index}].sensores`} />  
                                                  </Col>
                                                </>
                                            }
                                         </>
                                        }
                                        {(values.acciones[index].tipoAccion.id==TIPO_ACCION.COLECTIVA) &&
                                           <Col xs={12}>
                                              <label>¿Que acción colectiva desea ejecutar? </label>
                                              <FieldArray
                                                        name={`acciones[${index}].accionesColectivas`}
                                                        render={arrayHelpers => (
                                                          <div className="mt-4 mb-4">                       
                                                                {accionColectiva.misAcciones.map((tn, indexTN)=>  
                                                                    <div key={tn.id}>                                        
                                                                          <input
                                                                              className="option-input checkbox"
                                                                              name="tipoAccColec"
                                                                              type="checkbox"
                                                                              value={tn.id}
                                                                              checked={values.acciones[index].accionesColectivas?values.acciones[index].accionesColectivas.some(se=>se.id===tn.id):false}
                                                                              onChange={e => {
                                                                                if (e.target.checked) {
                                                                                  arrayHelpers.push(tn);
                                                                                } else {
                                                                                  const idx = values.acciones[index].accionesColectivas.findIndex(se=>se.id===tn.id);                                                                         
                                                                                  arrayHelpers.remove(idx);
                                                                                }
                                                                              }}
                                                                          />
                                                                        <label className="label-check">{tn.nombre}</label>
                                                                      </div>
                                                                  )}  
                                                                  {(accionColectiva.misAcciones===undefined || accionColectiva.misAcciones.length===0) &&
                                                                  'No hay acciones automaticas creadas.'}                   
                                                          </div>
                                                        )}
                                                      />
                                                      <ErrorMessage name={`acciones[${index}].accionesColectivas`} />  
                                          </Col>
                                        }

                                        {(values.acciones[index].tipoAccion.id==TIPO_ACCION.PARTICULAR) &&
                                            <>
                                             <Col xs={12}>
                                                  <label>¿Que tipo de acción desea ejecutar?   </label>
                                                  <Field value={values.acciones[index].tipoAccionColectiva?values.acciones[index].tipoAccionColectiva.id:''} component="select" name={`acciones.${index}.tipoAccionColectiva.id`} >
                                                      <option value=''>Seleccione</option>                                              
                                                      {state.tiposAccionesColectivas && state.tiposAccionesColectivas.map(ts =>
                                                        <option key={ts.id} value={ts.id}>{ts.descripcion}</option>
                                                      )}
                                                  </Field>
                                                  <ErrorMessage name={`acciones[${index}].tipoAccionColectiva.id`} />
                                              </Col>
                                             
                                              <Col xs={12}>
                                                <label>Elija sobre que sensores se ejecutara la accion</label>

                                                <Select
                                                    isClearable
                                                    isMulti
                                                    value={optionsSimpleToogle ? optionsSimpleToogle.filter(obj => (values.acciones[index].sensores && values.acciones[index].sensores.some(cam =>cam.id===obj.id))) : []}
                                                    getOptionLabel={option =>`${option.descripcion}`}
                                                    getOptionValue={option => option.id}
                                                    onChange={so =>{
                                                        setFieldValue(`acciones[${index}].sensores`,so?so:[]);
                                                    }}
                                                    isSearchable={true}
                                                    placeholder="Seleccione una"
                                                    options={optionsSimpleToogle}
                                                />  
                                                <ErrorMessage name={`acciones[${index}].sensores`} />        
                                              </Col>                                          
                                            </>
                                        }

                                {values.tipoEvento.id===TIPO_EVENTO.AUTOMATICO && 
                                    <Col xs={12} className="mb-3">
                                        <label htmlFor={`acciones[${index}].minutosEspera`}>
                                          Tiempo de espera entre actividad (en minutos)
                                        </label>
                                        <Field name={`acciones[${index}].minutosEspera`} />
                                        <ErrorMessage name={`acciones[${index}].minutosEspera`} />
                                    </Col>                                   
                                }
                                </Row>
                          ))}
                          
                      </div>
                  )}
              />

            
            
            </Col>
        </Row>
  </>      
);

class FormEvento extends React.Component {
  
  constructor(props){
    super(props);
    if (this.props.pertenecePrograma)this.state.esDePrograma=true;
    this.pasarPagina = this.pasarPagina.bind(this);
  }

  componentDidMount(){  
    if(this.props.tiposSensores===undefined)this.props.traerTiposSensores();
    if(this.props.tiposNotificaciones===undefined)this.props.traerListadoGeneralEvento();
    if(this.props.accionColectiva.misAcciones===undefined)this.props.traerAccionesColectivas();
  }

  state = {
      page:0,
      esNuevo:true,
      tipoSensores: [{id:'', descripcion:''}],
      errorPaso2:'',
      errorPaso4:'',
      grabando:false,
      esDePrograma:false
  };

  pasarPagina = () => {   
      this.setState(() => {
        return {
            page: this.state.page+1,
            errorPaso2: '',
            errorPaso4: ''
          };
      });
  }

  volverPagina = () => {   
    if (this.state.page!==0){
      this.setState(() => {
        return {
            page: this.state.page-1
          };
      });
    }
   
}
render() {
      const {evento,equipos,tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones,user} = this.props;
      const {page} = this.state;
      const options = [];
      const optionsSimple = [];
      const optionsSimpleToogle = [];
      
      let esNuevo = false;
      const cantidadPasos=3;

      const sensoresL = equipos.sensores;

      if(utilActions.isEmpty(evento))
         esNuevo =true

      equipos.misEquipos.forEach((eq, index) => { 
          const sensoresEq = [];
          if(eq.sensores){
          eq.sensores.forEach((sensor, index) => { 
            const s = utilActions.extraerDeLista(sensor.id,sensoresL);
            sensoresEq.push( {name: s.labelUsuario, value: (sensor.id+'')})
            optionsSimple.push( {descripcion: s.labelUsuario, id: (sensor.id)})
            if(s.shadows[0].tipoWidget.id===sensorConstants.TIPO_WIDGET_TOOGLE)optionsSimpleToogle.push( {descripcion: s.labelUsuario, id: (sensor.id)});
          }); 
        }

          if(sensoresEq.length>0){
            const grupo ={
              type: 'group',
              name: eq.nombre,
              items: sensoresEq
            }
            options.push(grupo)
          }      
      }); 


      let inic={
        id:'',
        nombre: '',
        valor:'',
        tipoSensor:{id:''},
        sensor:{id:''},
        sensores: [],
        acciones: [],
        condicion:{id:''},
        periodo:{id:''},
        tipoEvento:{id:''},
        hora:0,
        minuto:0,
        diaSemana:[],
        diaMes:'',
        tiposInformaciones:[],
        tiposNotificaciones:[],
        soyDePrograma:this.state.esDePrograma
      }

      const vSchemaPaso0 = Yup.object().shape({
        nombre: Yup.string()
          .min(2, 'Muy corto')
          .max(50, 'Muy largo')
          .required('Requerido'),
        tipoEvento: Yup.object({id: Yup.string().required(ERR.REQ)})
      });
      
      const vSchemaPaso1 = Yup.object().shape({
        sensor: Yup.object().when('tipoEvento', {
          is: (val) => val.id == TIPO_EVENTO.AUTOMATICO, 
          then: Yup.object({id: Yup.string().required(ERR.REQ)})
        }),
        condicion: Yup.object().when('tipoEvento', {
          is: (val) => val.id == TIPO_EVENTO.AUTOMATICO, 
          then: Yup.object({id: Yup.string().required(ERR.REQ)})
        }),
        valor: Yup.string().when('tipoEvento', {
          is: (val) => val.id == TIPO_EVENTO.AUTOMATICO, 
          then: Yup.string().required(ERR.REQ).max(50,ERR.MAX+" 50")
        }),
        periodo: Yup.object().when('tipoEvento', {
          is: (val) => val.id == TIPO_EVENTO.PROGRAMADO, 
          then: Yup.object({id: Yup.string().required(ERR.REQ)})
        }),
        diaSemana: Yup.array().when('periodo', {
          is: (val) => val.id == PERIODO.SEMANAL, 
          then: Yup.array().required(ERR.REQ).min(1,'Debe seleccionar al menos un día')
        }),
        hora: Yup.string().when('tipoEvento', {
          is: (val) => val.id == TIPO_EVENTO.PROGRAMADO, 
          then: Yup.string().required(ERR.REQ)
        }),
      });
      
      const vSchemaPaso2 = Yup.object().shape({
        acciones:Yup.array().required(ERR.REQ).min(1,'Debe definir al menos una acción').of(
          Yup.object().shape({
            tipoAccion: Yup.object({id: Yup.string().required(ERR.REQ)}),
            tiposNotificaciones: Yup.array().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.NOTIFICACION, 
              then: Yup.array().required(ERR.REQ).min(1,'Debe definir al menos una notificación').of(
                Yup.object({id: Yup.string().required(ERR.REQ)}))
            }),
            tiposInformaciones: Yup.array().test('tiposInformaciones','', function(item,schema){      
               
              if(inic.tipoEvento.id==TIPO_EVENTO.PROGRAMADO && this.parent.tipoAccion.id == TIPO_ACCION.NOTIFICACION && (item===undefined || item.length===0)){
                console.log('adentroTI',item)    
                return this.createError({
                  path:`tiposInformaciones`,
                  message:'Debe seleccionar al menos una opción 1'
                })
              }               
             }),
             sensores: Yup.array().test('sensores','', function(item){
              if ((item===undefined || item.length===0) &&((inic.tipoEvento.id===TIPO_EVENTO.PROGRAMADO && this.parent.tipoAccion.id == TIPO_ACCION.NOTIFICACION) || this.parent.tipoAccion.id == TIPO_ACCION.PARTICULAR)){
                console.log('adentro sensores',item)    
                return this.createError({
                   path:`sensores`,
                   message:'Debe seleccionar al menos un sensor'
                 })
                }
             }),
            accionesColectivas: Yup.array().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.COLECTIVA, 
              then: Yup.array().min(1,'Debe definir al menos una accion colectiva').of(
                Yup.object({id: Yup.string().required(ERR.REQ)}))
            }),
            tipoAccionColectiva: Yup.object().when('tipoAccion', {
              is: (val) => val.id == TIPO_ACCION.PARTICULAR, 
              then: Yup.object({id: Yup.string().required(ERR.REQ)})
            }),
            minutosEspera: Yup.number().typeError('Solo números').test('minutosEspera','', function(item,schema){
             if(inic.tipoEvento.id===TIPO_EVENTO.AUTOMATICO && (item===undefined || item===''))
                return this.createError({
                  path:`${this.path}`,
                  message:'Requerido'
                })
            })
          })
        )
      
      });
      
      const validationSchemas=[];
      validationSchemas.push(vSchemaPaso0);
      validationSchemas.push(vSchemaPaso1);
      validationSchemas.push(vSchemaPaso2);

      if(evento!==undefined)inic=utilActions.setearValores(inic,evento);
     
      console.log('VALUES INICIALES:',inic)
return(

  <div className="th_arandano">
       <div className="row">
          <div className="col-xs-12"> 
            <ul className="nav nav-tabs process-model more-icon-preocess" role="tablist">
                <li className={page===0?'active':''}>
                  <span>1</span>
                </li>
                <li className={page===1?'active':''}>
                  <span>2</span>
                </li>
                <li className={page===2?'active':''}>
                  <span>3</span>
                </li>             
              </ul>
            </div>
        </div>
        <div className="mt-8 steps">
            <Formik
              enableReinitialize= {true}
              initialValues={inic}
              validationSchema={validationSchemas[page]}
              onSubmit={values => {
                 
                  if(page===cantidadPasos -1){
                    this.setState({grabando:true});  
                    values.idTarea= this.props.idTarea;

                    if(this.state.esDePrograma){
                        programaService.grabarEventoTarea(values).then(
                          data => {  
                              this.props.handleClose();
                              this.props.agregarEventoEnPrograma(data,values.id==='',this.props.idTarea,this.props.idPrograma);  
                              this.setState({grabando:false});                           
                          },
                          error=>{
                            this.props.enviarError(error);
                            this.setState({grabando:false});       
                          }
                      );
                    }else {
                      if(values.tipoEvento.id===TIPO_EVENTO.AUTOMATICO){
                          values.acciones.forEach((acc, i1) => { 
                            acc.tiposInformaciones = [{id:TIPO_INFORMACION.EVENTO}];         
                          });
                       }
                      eventoService.grabarEvento(values).then(
                        data => {  
                            this.props.agregarEventoGenerado(data,values.id==='');
                            this.props.handleClose();
                            this.setState({grabando:false});       
                        },
                        error=>{
                          this.props.enviarError(error);
                          this.setState({grabando:false});       
                        }
                    );
                    }
                  }else{
                    this.pasarPagina();
                  }
                   
              }}
            >
              {({ errors, touched,values,setFieldTouched, onSubmit,setFieldValue,setFieldError }) => (
                <Row>
                  <Col xs={12}>
                    <Form>

                      {page===0 &&
                          <Paso0 values={values} state={this.props} setFieldValue={setFieldValue}/>
                      }
                      {page===1 && 
                          <>
                          <Paso3 values={values} equipos={this.props.equipos} state={this.props} optionsSensores={options} setFieldValue={setFieldValue}/>
                          </>
                      }
                      {page===2 &&                      
                          <>
                            <Paso4 user={user} values={values} state={this.props} accionColectiva={this.props.accionColectiva} optionsSensores={options} optionsSimple={optionsSimple} optionsSimpleToogle={optionsSimpleToogle} setFieldValue={setFieldValue}/>
                            {this.state.errorPaso4}
                          </>
                      }
                          
                        <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                            <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                            <i className="icon-times"></i> Cerrar
                            </button>
                            {page!==0 &&
                              <button className="btns btns-silver mr-3" type="button" onClick={()=>this.volverPagina()}>
                                  <i className="icon-chevron-left-outline"></i> Volver
                              </button>
                            }
                            {page===cantidadPasos -1 ?
                             
                              <button className="btns btns-secundario  mr-3" type="submit" disabled={this.state.grabando}>
                                  <i className="icon-floppy"></i> Grabar
                                  <LoadingBoton grabando={this.state.grabando}/>
                              </button>
                             
                              :<>                    
                              <button className="btns btns-arandano  mr-3" type="submit">
                                <i className="icon-chevron-right-outline"></i> Siguiente
                              </button>
                              </>
                            }
                          
                        </div>
                        
                    </Form>
                  </Col>
                </Row>
              )}
            </Formik>
    </div>
  </div>
)};
}

function mapState(state) {
  const { userData } = state.authentication;
  const user = userData;
  const { equipos, accionColectiva } = state;
  const { tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones} = state.evento;
  const { tiposSensores} = equipos;
  return { user,equipos,accionColectiva,tiposSensores,tiposNotificaciones,condiciones,tiposInformaciones,periodos,tiposAccionesColectivas,tiposAcciones };
}

const actionCreators = {
  agregarEventoGenerado:eventoActions.agregarEventoGenerado,
  agregarEventoEnPrograma:programaActions.agregarEventoGenerado,
  traerTiposSensores: equipoActions.traerTiposSensores,
  traerAccionesColectivas:accionColectivaActions.getAccionesColectivasPU,
  enviarError:alertActions.enviarError,
  traerListadoGeneralEvento:eventoActions.traerListadoGeneralEvento
}

const connectedFormEvento = connect(mapState, actionCreators)(FormEvento);
export { connectedFormEvento as FormEvento };