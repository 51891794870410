import React from 'react';
import '../bootstrap.min.css';
import { Router, Route, Switch, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { consultaActions, equipoActions,notificacionActions,productoActions,programaActions, userActions } from '../_actions';
import { history } from '../_helpers';
import { alertActions } from '../_actions';
import {  DetallePrograma, InfoSensor, MisAccionesColectivas, MisDatos, MisEventos, MisProgramas, MisVistas, NavBar, NavBarLateral, PrivateRoute} from '../_components';
import {Alerta} from  '../_components/Alerta';
import { LoginPage } from '../Navegacion/LoginPage';
import { LogoutPage } from '../Navegacion/LogoutPage';
import SockJsClient from '../_components/SockJsClient';
import { mqttEventosConstants } from '../_constants';
import 'react-grid-layout/css/styles.css'; 
import 'react-resizable/css/styles.css'; 
import {MisConsultas} from '../_components/Perfil/MisConsultas';
import { CarritoCompra, MisCompras, Suscripcion, Tienda } from '../_components/Tienda';
import NavBarLateralMiPerfil from '../_components/Perfil/NavBarLateralMiPerfil';
import {NavBarLateralTienda} from '../_components/Tienda/NavBarLateralTienda';
import { EquipoListado } from '../_components/EquipoListado';
import { userService } from '../_services';
import { MisEquipos } from '../_components/Equipo';
import { MisUsuarios } from '../_components/Perfil/MisUsuarios';

const appName=process.env.REACT_APP_NAME;
const sockJsRef = React.createRef();

class App extends React.Component {
    constructor(props) {       
        super(props);
        this.onMessage = this.onMessage.bind(this);  
        history.listen((location, action) => {
            this.props.clearAlerts();
        });

        if(userService.estaLogueado()){
            this.props.getLogueado();
            this.props.getEquiposPU();
        }
        
    }

    onMessage(json){
        const evento  = json.eventoSocket;
        if(evento===mqttEventosConstants.NUEVO_MENSAJE)
            this.props.nuevoMensajeChat(json)        
        if(evento===mqttEventosConstants.NUEVO_VALOR_SENSOR)
            this.props.nuevoValorSensor(json)
        if(evento===mqttEventosConstants.NUEVO_VALOR_PROGRAMA)
            this.props.actualizarPrograma(json.programa)    
        if(evento===mqttEventosConstants.NUEVO_VALOR_COMPRA)
            this.props.actualizarCompra(json.compra)      
        if(evento===mqttEventosConstants.NUEVA_NOTIFICACION)
            this.props.nuevaNotificacion(json.notificacion)       
        if(evento===mqttEventosConstants.ESTADO_EQUIPO)
            this.props.cambiarEstadoEquipo(json.idEquipo,json.nuevoEstado)     
        if(evento===mqttEventosConstants.NUEVO_TOKEN)
            this.props.actualizarToken(json.tokenActivo)   
        if(evento===mqttEventosConstants.ESTADO_SENSOR)
            this.props.nuevoEstadoSensor(json.idSensor,json.conectado)            
    }

    render() {
        const { alert,equipos,user,userData} = this.props;
       
        let {pathname} = history.location;
        pathname= pathname.substring(pathname.indexOf('/'),pathname.lastIndexOf('/'));
        return (
            <div>
              
                <Router basename={appName} history={history}>
                    {(alert.message || alert.confirm) &&
                        <Alerta data={alert} />                   
                    }
                   
                    {user && <SockJsClient user={userData} sensoresL={equipos.sensores} ref={sockJsRef} equipos={equipos.misEquipos} programas={this.props.programa.misProgramas} onMessage={this.onMessage} />}
                    <NavBar/>  

                    {pathname==='/home' && <NavBarLateral/>}
                    {pathname==='/perfil' && <NavBarLateralMiPerfil/>}
                    {pathname==='/tienda' && <NavBarLateralTienda />}
                    <Switch>
                        <PrivateRoute exact path={"/home/listaVista"} component={EquipoListado} />
                        <PrivateRoute exact path={"/home/listaEquipo"} component={EquipoListado} />
                        <PrivateRoute exact path={"/home/infoSensor"} component={InfoSensor} />
                        <PrivateRoute exact path={"/home/misVistas"} component={MisVistas} />
                        <PrivateRoute exact path={"/home/misEquipos"} component={MisEquipos} />
                        <PrivateRoute exact path={"/home/misAcciones"} component={MisAccionesColectivas} />
                        <PrivateRoute exact path={"/home/misEventos"} component={MisEventos} />
                        <PrivateRoute exact path={"/home/misProgramas"} component={MisProgramas} />
                        <PrivateRoute exact path={"/home/programa"} component={DetallePrograma} />

                        <PrivateRoute exact path={"/perfil/misDatos"} component={MisDatos} />
                        <PrivateRoute exact path={"/perfil/misConsultas"} component={MisConsultas} />
                        <PrivateRoute exact path={"/perfil/usuarios"} component={MisUsuarios} />

                        <PrivateRoute exact path={"/tienda/carrito"} component={CarritoCompra} />
                        <PrivateRoute exact path={"/tienda/suscripcion"} component={Suscripcion} />
                        <PrivateRoute exact path={"/tienda/productos"} component={Tienda} />
                        <PrivateRoute exact path={"/tienda/misCompras"} component={MisCompras} />
                        <PrivateRoute exact path={"/home/equipoNuevo"} component={MisEquipos} />
                        <Route path={"/logout"} component={LogoutPage} />
                        <Route path={"/login"} component={LoginPage} />                                   
                        <Redirect from="*" to="/home/misEquipos" />
                    </Switch>
                </Router>               
            </div>
            
        );
    }
}

function mapState(state) {
    const { alert,equipos,programa,authentication } = state;
    const { user,userData } = authentication;
    return { alert,equipos,programa,user,userData };
}

const actionCreators = {
    nuevoMensajeChat:consultaActions.nuevoMensajeChat,
    nuevoValorSensor:equipoActions.nuevoValorSensor,
    actualizarToken:userActions.actualizarToken,
    cambiarEstadoEquipo:equipoActions.cambiarEstadoEquipo,
    actualizarPrograma:programaActions.actualizarPrograma,
    actualizarCompra:productoActions.actualizarCompra,
    clearAlerts: alertActions.clear,
    getLogueado:userActions.getLogueado,
    nuevaNotificacion:notificacionActions.nuevaNotificacion,
    nuevoEstadoSensor:equipoActions.nuevoEstadoSensor,
    getEquiposPU: equipoActions.getEquiposPU
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };