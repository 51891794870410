import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Card,Modal} from 'react-bootstrap';
import { vistaActions, alertActions, utilActions, equipoActions } from '../../_actions';
import { sensorConstants } from '../../_constants';
import {FormNuevaVista} from '../../_components';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { alertConfirm, esSinToken } from '../../_helpers';

class MisVistas extends React.Component {

    constructor(props){
       super(props);       
       this.manejarVistaSeleccionada = this.manejarVistaSeleccionada.bind(this);
       this.handleShowConfirm=this.handleShowConfirm.bind(this);       
    }

    state = {
        show: false,
        showConfirm:false,
        vista:{}
    };

    componentDidMount(){
        if(this.props.vistas.misVistas===undefined)this.props.getVistasPU();       
    }

    handleClose = () => {
        this.setState({show: false,
                vista:{}
        });
    };

    handleShow = () => {
        this.setState({
            show: true
        });
    };

    handleShowEditar = (item) => {
        this.setState((prevState) => {
            return {
                show: true,
                vista:item
            };
        });
    };

    eliminarVista = () => {      
        this.props.eliminarVista(this.state.vista,this.cerrarAlert);
        this.handleClose();
    };
  
    manejarVistaSeleccionada(e,index){
        this.props.seleccionarVista(index);
    }

    cerrarAlert = () => {
        this.setState({vista:{},alert: null});
    } 
    handleShowConfirm = (data) => {
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${data.nombre}?`,
            'Sí, eliminar',
            'No',
            this.cerrarAlert,
            this.eliminarVista,
            null,
            true
        )  
        this.setState((prevState) => {            
            return {
                vista:data,
                alert: getAlert
            };
        });
    }

    buscarIcono=(sensoresL,sensor)=>{
        const sensorCompleto=utilActions.extraerDeLista(sensor.id,sensoresL);
        if(sensorCompleto){
            return sensorConstants.style[sensorCompleto.tipoSensor.id].icono;
        }else return ''
      
    }

    render(){
        const { vistas,equipos,userToken } = this.props;
        const conToken = !esSinToken(userToken);

        const sensoresL = equipos.sensores;
        return (
            <div className="main-content ">
                <Container fluid>   
                        {conToken &&<Row className="mb-5 mt-5">
                            <Col xs={12}>
                                <button className="btns btns-uva_d bg-gradient-vistas text-white" onClick={this.handleShow}>
                                <i className="icon-eyes align-middle txt-22"></i> Nueva vista
                                </button>
                            </Col>
                        </Row>}               
                    <Row> 
                    <div className="cont-sensor mt-4">
                            <div className="d-flex flex-wrap  justify-content-center">
                                {vistas.misVistas!==undefined && vistas.misVistas.map((vista, index)=> 
                            
                                <Col key={vista.id} lg={4} xl={4} sm={12} md={6} className="mb-4">
                                     <Link to="/home/listaVista" className="cursor" disabled={!conToken} onClick={(event) => this.manejarVistaSeleccionada(event, index)}>
                                        <div className="text-white th_uva hoverable card-components shadow">
                                        <Card.Header>
                                            <h3 className="txt-oswald font-weight-light"> 
                                                <i className="icon-eyes text-uva"></i>
                                                {vista.nombre}
                                            </h3>
                                        </Card.Header>
                                        <Card.Body className="th_uva">
                                            <Card.Text className="text-muted font-weight-bold">
                                                Mis Sensores 
                                            </Card.Text>
                                            <Card.Text className="text-center"> 
                                            {(sensoresL && vista.sensores!==undefined) && vista.sensores.slice(0,10).map((sensor, index2)=>
                                                <span key={index2}>
                                                    <i className={this.buscarIcono(sensoresL,sensor)+' i-circle'}></i>                                                       
                                                </span>
                                            )}
                                            </Card.Text>
                                        </Card.Body>
                                                
                                        </div>
                                    </Link>
                                    
                                    {conToken &&<div className="edit">
                                    <> 
                                                    <a data-tip data-for='elimina'>
                                                    <i className="fas fa-trash-alt mr-2 cursor" onClick={() => this.handleShowConfirm(vista)}></i>
                                                    </a>
                                                    <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                    <span>Eliminar vista</span>
                                                    </ReactTooltip>                   
                                                    </>
                                                    <> 
                                                    <a data-tip data-for='edita'>
                                                        <i className="fas fa-pencil-alt mr-2 cursor" onClick={() => this.handleShowEditar(vista)}></i>
                                                        </a>
                                                    <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                    <span>Editar vista</span>
                                                    </ReactTooltip> 
                                                    </>    
                                    </div>}
                                </Col>
                            )}  
                            </div> 
                        </div>
                    </Row>
                    <Row className="justify-content-center th_uva">
                    <div className="cont-sensor mt-5">
                    <div className="d-flex flex-wrap  justify-content-center">
                    {(vistas.misVistas===undefined || vistas.misVistas.length===0) &&
                        <>
                            <Col xs={12} md={7} lg={5}>
                                <div className="card-components border-left-c  fadeIn">
                                <Row className="p-3">
                                    <Col xs={2} className="text-center p-2">
                                    <i className="icon-bell txt-42 icon-gradient-vistas "></i>
                                    </Col>
                                    <Col xs={10} className="text-right p-2">
                                        <span className="text-muted txt-12"> MIS VISTAS</span> <br></br> 
                                        <span className="text-white txt-18 font-weight-bold"> NO EXISTEN <b>VISTAS</b> DEFINIDAS</span> 
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                            {!conToken &&
                             <Col xs={12} md={7} lg={5}>
                                 <div className="card-components border-left-c  fadeIn">
                                    <Row className="p-3 ">
                                 <Col xs={2} className="text-center p-2">
                                 <i className="icon-bell txt-42 icon-gradient-vistas"></i>
                                 </Col>
                                 <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS MISTAS</span> <br></br> 
                                 <span className="text-white txt-18 font-weight-bold">Tu tipo de suscripción no permite esta funcionalidad, compra en la tienda ya!</span> 
                                 </Col>
                                </Row>
                             </div>
                         </Col>}
                         </>
                    }
                    </div>
                    </div>
                    </Row>
                </Container>

                {this.state.show && <Modal show={this.state.show} onHide={this.handleClose} backdrop="static"  >
                    <Modal.Header className="bg-gradient-vistas text-white" closeButton>
                        <Modal.Title><i className="icon-eyes "></i> Nueva vista</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body className="th_uva">
                        <FormNuevaVista vista={this.state.vista}  handleClose={this.handleClose}/>
                    </Modal.Body>                     
                </Modal>} 

                {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {
    const { userToken } = state.authentication;
    const { vistas,equipos } = state;
    return { vistas,equipos,userToken };
}

const actionCreators = {
    getVistasPU:vistaActions.getVistasPU,
    getEquiposPU: equipoActions.getEquiposPU,
    seleccionarVista:vistaActions.seleccionarVista,
    agregarVistaGenerada:vistaActions.agregarVistaGenerada,
    enviarError:alertActions.enviarError,
    eliminarVista:vistaActions.eliminarVista
}

const connectedMisVistas = connect(mapState, actionCreators)(MisVistas);
export { connectedMisVistas as MisVistas };