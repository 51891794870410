import { productoConstants, userConstants } from '../_constants';
import { productoService, userService } from '../_services';
import { alertActions } from '.';
import {ESTADO_TOKEN} from '../_helpers';
import { history } from '../_helpers';

export const productoActions = {
   
    traerProductosUser,
    traerCategoriaProductoUsr,
    traerSuscripcionesUsr,
    traerTipoSuscripcion,
    traerMediosPago,
    seleccionarProducto,
    generarCompra,
    traerMisCompras,
    quitarProducto,
    modificarCompra,
    traerEStadosCompras,
    asignarEquipo,
    traerEquiposDisponibles,
    contratarSuscripcion,
    contratarSuscripcionPaypal,
    actualizarCompra,
    guardarDocTypes
};

function guardarDocTypes(docs) {  
    return dispatch => {  
        dispatch(success(docs));  
    }
    function success(docs) { return { type: productoConstants.DOCUMENT_TYPE_MP,docs} }
}

function traerEquiposDisponibles() {    
    return dispatch => {        
        productoService.traerEquiposDisponibles()
        .then(
            data => { 
                dispatch(success(data));      
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.EQUIPOS_DISPONIBLES,data} }
}



function contratarSuscripcionPaypal(values,handleExito,setGrabando) {    
    return dispatch => {        
        setGrabando(true);   
        productoService.contratarSuscripcionPaypal(values)
        .then(
            data => { 
                setGrabando(false);   
                dispatch(success(data));
                const tokens = data.compra.suscripcionesToken;
                if(tokens) {
                    const tokenActivo = tokens.find(valu=>valu.estado.id===ESTADO_TOKEN.ACTIVO);
                    dispatch(successGrabarNuevoToken(tokenActivo));
                    userService.guardarToken(data.tokenNuevo);
                }     
               
                history.push('/tienda/misCompras'); 
                handleExito(data);     
            },
            error => {  
                setGrabando(false);   
                dispatch(alertActions.error(error));            
            }
        );
    }
    function successGrabarNuevoToken(tokenActivo) { return { type: userConstants.NUEVO_TOKEN,tokenActivo} }
    function success(data) { return { type: productoConstants.COMPRA_EXITOSA,data} }
}

function contratarSuscripcion(values,handleExito,setGrabando) {    
    return dispatch => {        
        setGrabando(true);   
        productoService.contratarSuscripcion(values)
        .then(
            data => { 
                setGrabando(false);   
                dispatch(success(data));
                const tokens = data.compra.suscripcionesToken;
                if(tokens) {
                    const tokenActivo = tokens.find(valu=>valu.estado.id===ESTADO_TOKEN.ACTIVO);
                    dispatch(successGrabarNuevoToken(tokenActivo));
                    userService.guardarToken(data.tokenNuevo);
                }     
               
                history.push('/tienda/misCompras'); 
                handleExito(data);     
            },
            error => {  
                setGrabando(false);   
                dispatch(alertActions.error(error));            
            }
        );
    }
    function successGrabarNuevoToken(tokenActivo) { return { type: userConstants.NUEVO_TOKEN,tokenActivo} }
    function success(data) { return { type: productoConstants.COMPRA_EXITOSA,data} }
}

function actualizarCompra(compra) {    
    return dispatch => {    
        let data={};
        try{
             data={compra:JSON.parse(compra)};  
        }catch(err){
            data={compra:compra};  
        }
        
        dispatch(success(data));
    }
    function success(data) { return { type: productoConstants.MODIFICAR_COMPRA,data} }
}



function asignarEquipo(values,handleExito,setGrabando) {    
    return dispatch => {        
        setGrabando(true);   
        productoService.asignarEquipo(values)
        .then(
            data => { 
                setGrabando(false);   
                dispatch(success(data));       
                handleExito(data);     
            },
            error => {  
                setGrabando(false);   
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.ASIGNACION_EQUIPO_COMPRA,data} }
}

function traerEStadosCompras() {    
    return dispatch => {        
        productoService.traerEStadosCompras()
        .then(
            data => { 
                dispatch(success(data));      
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.ESTADOS_COMPRA,data} }
}


function modificarCompra(values,handleExito,setGrabando) {    
    return dispatch => {        
        setGrabando(true);   
        productoService.modificarCompra(values)
        .then(
            data => { 
                setGrabando(false);   
                dispatch(success(data));       
                handleExito();     
            },
            error => {  
                setGrabando(false);   
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.MODIFICAR_COMPRA,data} }
}

function generarCompra(values,handleExito) {    
    return dispatch => {        
        productoService.generarCompra(values)
        .then(
            data => { 
                dispatch(limpioCompra());   
                dispatch(success(data));       
                handleExito();     
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function limpioCompra() { return { type: productoConstants.LIMPIO_COMPRA} }
    function success(data) { return { type: productoConstants.COMPRA_EXITOSA,data} }
}



function traerMisCompras() {    
    return dispatch => { 
        productoService.traerMisCompras()
        .then(
            data => {                 
                dispatch(success(data));       
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.MIS_COMPRAS,data} }
}

function quitarProducto(producto) {    
    return dispatch => {       
        let data = {
            producto:producto
        }
        dispatch(quitarProducto(data)); 
    }
    function quitarProducto(data) { return { type: productoConstants.PRODUCTO_QUITAR,data} }
}


function seleccionarProducto(producto,tipoCompra,cantidad) {    
    return dispatch => {       
        let data = {
            cantidad:cantidad,
            tipoCompra:tipoCompra,
            producto:producto
        }
            dispatch(agregarProducto(data)); 
    }
    function agregarProducto(data) { return { type: productoConstants.PRODUCTO_SELECCIONAR,data} }
}

function traerTipoSuscripcion() {    
    return dispatch => {
        productoService.traerTipoSuscripcion()
        .then(
            data => { 
                dispatch(success(data));            
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.PRODUCTO_TIPO_SUSCRIPCION,data} }
}

function traerMediosPago() {    
    return dispatch => {
        productoService.traerMediosPago()
        .then(
            data => { 
                dispatch(success(data));            
            },
            error => {  
                dispatch(alertActions.error(error));            
            }
        );
    }
    function success(data) { return { type: productoConstants.MEDIOS_PAGO_LISTADO,data} }
}



function traerProductosUser() {
    
    return dispatch => {
        productoService.traerProductosUser()
        .then(
            data => { 
                dispatch(success(data));            
            },
            error => {          
                dispatch(alertActions.error(error));
            }
        );
    }

    function success(data) { return { type: productoConstants.PRODUCTO_LISTADO_USR,data} }
}




function traerCategoriaProductoUsr() {
    
    return dispatch => {
        productoService.traerCategoriaProductoUsr()
        .then(
            data => { 
                dispatch(success(data));            
            },
            error => {                
                dispatch(alertActions.error(error));
            }
        );
    }

    function success(data) { return { type: productoConstants.PRODUCTO_CATEGORIA_LISTADO_USR,data} }
}


function traerSuscripcionesUsr() {
    
    return dispatch => {
        productoService.traerSuscripcionUser()
        .then(
            data => { 
                dispatch(success(data));            
            },
            error => {                
                dispatch(alertActions.error(error));
            }
        );
    }

    function success(data) { return { type: productoConstants.SUSCRIPCION_LISTADO_USR,data} }
}





