import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal,Form,Card,DropdownButton,Dropdown} from 'react-bootstrap';
import {FormTarea,HistorialEjecuciones} from './';
import { history,alertConfirm } from '../../_helpers';
import queryString from 'query-string';
import { programaConstants } from '../../_constants';
import {programaActions,equipoActions} from '../../_actions';
import { ListaEventos } from './ListaEventos';
import { FormAccion } from './FormAccion';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import PuffLoader from "react-spinners/PuffLoader";
class DetallePrograma extends React.Component {

    constructor(props){      
       super(props);
       let params = queryString.parse(this.props.location.search);        
       const {id} = params; 
       this.state.idPrograma = parseInt(id);           
    }

    volverAtras = () =>{
        history.goBack();
    }
  
    handleCloseG = () => {
        let muestroModal={  
            tarea:{},
            show:false,
            showNuevo:false,
            showConfirm:false,
            showHistorial:false,
            showConfirmEjecucion:false,
            showEventos:false,
            showAcciones:false
        };   

        this.setState((prevState) => {
            return muestroModal;
        });
    };

    handleShowG = (tipoModal,tarea) => {

        let muestroModal={};

        if(tipoModal==='accionInicio')muestroModal={tarea:tarea,showAcciones: true,tipoAccion:1}; 
        if(tipoModal==='accionFin')muestroModal={tarea:tarea,showAcciones: true,tipoAccion:2};
        if(tipoModal==='eventos')muestroModal={tarea:tarea,showEventos: true}; 
        if(tipoModal==='continuar')muestroModal={alert: this.armarAlert('continuar',tarea)}; 
        if(tipoModal==='cancelar')muestroModal={alert: this.armarAlert('cancelar',tarea)}; 
        if(tipoModal==='historial')muestroModal={showHistorial: true}; 
        if(tipoModal==='nuevo')muestroModal={tarea:{},showNuevo: true}; 
        if(tipoModal==='editar')muestroModal={tarea:tarea,showNuevo: true};         
        if(tipoModal==='delete')muestroModal={alert: this.armarAlert('delete',tarea),tarea:tarea}; 
        if(tipoModal==='ejecutar')muestroModal={showConfirmEjecucion: true}; 

        this.setState((prevState) => {
            return muestroModal;
        });
    };
    armarAlert= (tipoModal,obj) => {
        let alert;
        if(tipoModal==='continuar')
            alert=alertConfirm('Confirmar continuar',
            `¿Está seguro que desea continuar la ejecución de ${obj.nombre}?`,
            'Sí, continuar',
            'No',
            this.cerrarAlert,
            this.continuarConfirmado,
            null,
            true
            )
        if(tipoModal==='delete')    
            alert=alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${obj.nombre}?`,
            'Sí, eliminar',
            'No',
            this.cerrarAlert,
            this.eliminarTarea,
            null,
            true
            )
        if(tipoModal==='cancelar')    
            alert=alertConfirm('Confirmar',
            `¿¿Está seguro que desea cancelar la ejecución de ${obj.nombre}?`,
            'Sí, cancelar',
            'No',
            this.cerrarAlert,
            this.cancelacionConfirmada,
            null,
            true
            )    
        return alert;
    }

    cerrarAlert = () => {
        this.setState({alert: null, tarea:{}});
    }   

    actualizarTareaComienzo = (event) => {     
        const { name, value } = event.target 
        this.setState({tareaComienza: value});
        if(value!==''){
            this.setState({errorComienzo: null});
        }
    };
    

    eliminarTarea = () => {   
        const values= Object.assign(this.state.tarea, {'idPrograma':this.traerPrograma().id});   
        this.props.eliminarTarea(values);
        this.cerrarAlert();
    };

    actualizarEstado = () => {   
        this.props.getProgramaPorId(this.traerPrograma().id);
    };

    ejecucionConfirmada = () => {   
        if(this.state.tareaComienza===null || this.state.tareaComienza===''){
            this.setState({errorComienzo: 'Debe seleccionar una tarea para comenzar'});
        }else{
            this.setState({errorComienzo: null});
            this.props.ejecutar(this.traerPrograma(),this.state.tareaComienza);
            this.handleCloseG();
        }
       
    };

    cancelacionConfirmada = () => {   
        this.props.cancelarEjecucion(this.traerPrograma());
        this.cerrarAlert();
    };

    continuarConfirmado = () => {   
        this.props.continuarEjecucion(this.traerPrograma());
        this.cerrarAlert();
    };

    traerPrograma = () =>{       
        const programa = this.props.misProgramas.filter(prog=>prog.id===this.state.idPrograma)[0];
        return programa;
    }

    intercambiar= (idTarea) =>{
        this.props.intercambiarTarea(idTarea,this.traerPrograma().id);
    }

    componentDidMount(){
        if(this.props.misProgramas===undefined)this.props.getProgramasPU();
    }


    state={
        idPrograma:undefined,
        tarea:{},
        show:false,
        showConfirm:false,
        programa:{},
        showHistorial:false,
        showConfirmEjecucion:false,
        tareaComienza:null,
        showEventos:false,
        showAcciones:false,
        tipoAccion:1,
        alert:null,
        errorComienzo:null
    }    

    render(){
        let loading = this.props.misProgramas===undefined;
        let programa={};
        if(!loading){
            programa = this.props.misProgramas.filter(prog=>prog.id===this.state.idPrograma)[0];
            if(programa===undefined){
                history.push('/home/misProgramas');
                loading=true;
            }
        }

       
        return (

            <div className={'main-content'}>
                {!loading &&<Container fluid>
                    <Row className="mb-5 mt-5">
                        <Col xs={6}>
                            <button type="button" onClick ={() => this.volverAtras()} className="btns btns-cielo text-white" size="lg"> <i className="fas fa-arrow-left"></i> Volver</button> 
                        </Col>
                    </Row>
                    <Row className="mb-5 justify-content-center">
                        <Col xs={12} md={8} lg={7}>
                        <div className="th_cielo  card-components-accion">
                            <div className="card-header">
                                <Row>
                                <Col className="align-self-start col-auto">
                                    <button type="button" onClick ={() => this.actualizarEstado()} className="btns btns-outline-maiz transparent btn-sm text-white" size="lg">Actualizar Estado</button>
                                    </Col>
                                    <Col className="col-auto">
                                    <div className="menu-floating-p">
                                    <Row>
                                        <Col xs={6} className="col-lg-auto text-right">
                                        <a data-tip data-for='editar'  className="btns btns-arandano btn-program " onClick={() => this.handleShowG('editar',{})}><i className="fas fa-plus"></i></a>
                                        <ReactTooltip place="bottom" id='editar' aria-haspopup='false' >
                                        <span>Nueva Tarea</span>
                                        </ReactTooltip>
                                        </Col>
                                        <Col xs={6} className="col-lg-auto">
                                        <a data-tip data-for='historia' className="btns btn-program btns-silver" onClick={() => this.handleShowG('historial',{})}><i className="fas fa-history text-dark"></i></a>
                                        <ReactTooltip place="bottom" id='historia' aria-haspopup='false' >
                                        <span>Historial Ejecuciones</span>
                                        </ReactTooltip>
                                        </Col>
                                    </Row>
                                    </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-body">
                            <Row>
                            <Col xs={10} className="col-lg-auto">
                                <span className="text-muted txt-oswald">PROGRAMA</span>
                                
                                <h3 className="txt-oswald text-white mayuscula">{programa.nombre}</h3>
                                <h6 className="text-muted">
                                    {programa.estado.descripcion}                                   
                                </h6>
                                {programa.estado.id===programaConstants.ESTADO_EJECUTANDO &&
                                    <>
                                        <h6 className="text-muted txt-oswald font-weight-light">
                                        {programa.ejecucionActual &&
                                         <>
                                            Fecha inicio:
                                             <Moment date={ programa.ejecucionActual.fechaInicio} format="DD/MM/YYYY HH:mm"/>
                                         </>}
                                        </h6>
                                    </>
                                } 
                            </Col>
                            
                            <div className="ejecutar bg-gradient-equipos hoverable">
                            {(programa.tareas && programa.tareas.length>0 && programa.estado.id===programaConstants.ESTADO_STAND_BY) &&
                                    <>
                                        <span className="txt-oswald">PLAY</span>
                                        <button data-tip data-for='play' type="button" className="btns btns-link text-silver" onClick={() => this.handleShowG('ejecutar',programa)}>
                                            <i className="fas fa-play-circle ml-2"></i>
                                        </button>
                                        <ReactTooltip place="bottom" id='play' aria-haspopup='false' >
                                        <span>Ejecutar Acción</span>
                                        </ReactTooltip>
                                    </>
                                }
                           </div>
                            <div className="ejecutar bg-play ">
                            {(programa.estado.id===programaConstants.ESTADO_EJECUTANDO) &&
                               <>
                                     <PuffLoader size={60} color={"#ffffffc2"}/>
                                    <span className="txt-oswald">STOP</span>                                                        
                                    <button data-tip data-for='stop' type="button" className="btns btns-link text-silver" onClick={() => this.handleShowG('cancelar',programa)}>
                                        <i className="far fa-stop-circle ml-2"></i>
                                    </button> 
                                    <ReactTooltip place="bottom" id='stop' aria-haspopup='false' >
                                        <span>Parar Acción</span>
                                    </ReactTooltip> 
                                </>                              
                           } 
                           </div>
                           <div className="ejecutar bg-gradient-programa ">
                                {programa.estado.id===programaConstants.ESTADO_ESPERA_USUARIO && 
                                    <>
                                        <span className="txt-oswald">CONTINUAR</span>                           
                                        <button data-tip data-for='sigue' type="button" className="btns btns-link text-white" onClick={() => this.handleShowG('continuar',programa)}>
                                            <i className="fas fa-play-circle ml-2"></i>
                                        </button>
                                        <ReactTooltip place="bottom" id='sigue' aria-haspopup='false' >
                                        <span>Continuar Acción</span>
                                    </ReactTooltip>  
                                    </>                              
                                }
                           </div>
                            </Row>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <hr></hr>
                    <div className="slide-mobile">
                    <div className="menu-m-wrapper menu-m-wrapper--special">    
                        <div className="cont-sensor mt-4">
                            <Col><h5>
                                <span className="badge bg-dark text-silver mb-3">
                                    <span className="font-weight-light">
                                        Tareas de</span> {programa.nombre}
                                </span>
                            </h5>
                            </Col>
                            <div className="d-flex-c flex-wrap  justify-content-center">
                                {programa.tareas!==undefined && programa.tareas.map((tarea, index)=>
                                    <>  
                                        <Col className="mb-6" key={tarea.id} xs={11}  lg={3}>
                                            <div className="text-white  th_maiz card-components shadow">
                                                <Card.Header>
                                                        <span className="badge bg-dark oswald text-silver">{tarea.orden}</span> 

                                                        <h4 className="txt-oswald text-silver mayuscula txt-18 mt-4-1 text-truncate">
                                                            {tarea.nombre} 
                                                        </h4>
                                                        {!(programa.estado.id===programaConstants.ESTADO_EJECUTANDO && tarea.ejecutandose) &&
                                                            <div className="edit">
                                                                <>
                                                                <a data-tip data-for='elimina'><i className="fas fa-trash-alt cursor"  onClick={() => this.handleShowG('delete',tarea)}></i></a>
                                                                <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                                <span>Eliminar Tarea</span>
                                                                </ReactTooltip>
                                                                </>
                                                                <>
                                                                <a data-tip data-for='edit'><i className="fas fa-pencil-alt cursor mr-4"  onClick={() => this.handleShowG('editar',tarea)}></i></a>
                                                                <ReactTooltip place="bottom" id='edit' aria-haspopup='false' >
                                                                <span>Editar Tarea</span>
                                                                </ReactTooltip>
                                                                </>
                                                                <DropdownButton  alignRight title={<div><i className="fas fa-ellipsis-v text-white"></i></div>}>
                                                                    <Dropdown.Item onClick={() => this.handleShowG('eventos',tarea)}>Eventos</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => this.handleShowG('accionInicio',tarea)}>Acciones de inicio</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => this.handleShowG('accionFin',tarea)}>Acciones de finalización</Dropdown.Item>
                                                                </DropdownButton>
                                                            
                                                            </div>
                                                        }
                                                        {(programa.estado.id===programaConstants.ESTADO_EJECUTANDO && tarea.ejecutandose) &&
                                                            <span  className="bg-play badge-play text-silver">
                                                                <i className="fas fa-play"></i> 
                                                                Tarea en ejecución
                                                            </span>
                                                        }
                                                        {(programa.estado.id===programaConstants.ESTADO_ESPERA_USUARIO && tarea.id===programa.idProximaTarea) &&
                                                            <span  className="bg-play badge-play  text-silver ">
                                                                <i className="fas fa-play"></i> 
                                                                A la espera de continuar
                                                            </span>
                                                        }
                                                </Card.Header>
                                                <Card.Body>
                                                <i className="far fa-clock text-muted"></i>
                                                <span className="txt-oswald mayuscula txt-18  ml-2 "></span>
                                                                <span className="txt-oswald font-weight-bold mr-4">{tarea.duracionDias?'Días:'+tarea.duracionDias+' ':''} </span>                                                        
                                                                <span className="txt-oswald font-weight-bold mr-4">{tarea.duracionHoras?'Hs:'+tarea.duracionHoras+' ':''}</span>
                                                                <span className="txt-oswald font-weight-bold mr-4">{tarea.minutos?'Min:'+tarea.minutos:''}</span>
                                               
                                                                                    
                                                </Card.Body>
                                            </div>
                                        </Col>
                                        {(programa.estado.id!==programaConstants.ESTADO_EJECUTANDO && index+1<programa.tareas.length) &&                                           
                                          <>  <a data-tip data-for='move'><div className="cursor-expand"><i className="fas fa-arrows-alt-h cursor text-white fa-2x mt-5 " onClick={() =>this.intercambiar(tarea.id)}></i>
                                             </div> </a>
                                             <ReactTooltip place="bottom" id='move' aria-haspopup='false' >
                                             <span>Modificar Orden</span>
                                             </ReactTooltip> 
                                             </>                                         
                                        }                         
                                    </>
                                )}
                            </div>
                        </div>
                        </div>
                    </div>  
                     {(programa.tareas===undefined || programa.tareas.length===0) &&
                         <Row className="justify-content-center  th_verde-azulado">
                         <Col className="card-components border-left-c  fadeIn" xs={12} md={7} lg={5}>
                             <Row className="p-3 ">
                                 <Col xs={2} className="text-center p-2">
                                 <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                 </Col>
                                 <Col xs={10} className="text-right p-2">
                                     <span className="text-muted txt-12"> MIS TAREAS</span> <br></br> 
                                     <span className="text-white txt-18 font-weight-bold"> NO EXISTEN TAREAS DEFINIDAS</span>
                                     
                                 </Col>
                             </Row>
                         </Col>
                         </Row>
                    }  
                </Container>}
                    {this.state.showNuevo && <Modal size="lg" show={this.state.showNuevo} onHide={() => this.handleCloseG()} backdrop="static" keyboard={false}>
                        <Modal.Header className="bg-gradient-programa text-silver" closeButton>
                             <Modal.Title><i className="icon-modem"></i> {this.state.tarea.id===''?'Nueva':'Editar'} tarea</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <FormTarea tarea={this.state.tarea} idPrograma={programa.id} cantidadTareas = {programa.cantidadTareas} handleClose={() => this.handleCloseG()} />
                        </Modal.Body>                     
                    </Modal>} 
                  

                    {this.state.showConfirmEjecucion && <Modal  aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false}
                         centered size="lg" show={this.state.showConfirmEjecucion} onHide={() => this.handleCloseG()}>
                        <Modal.Header className="bg-gradient-programa text-white" closeButton>
                            <Modal.Title>¿Esta seguro que desea iniciar la ejecución de <span className="mayuscula">{programa.nombre}</span> ?</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body className="th_cielo">
                            <Form>
                                <Form.Group>
                                    <Form.Label>¿En qué tarea desea iniciar la ejecución? {this.state.errorComienzo!==null && <div className="help-block text-danger">
                                        <i className="fas fa-exclamation-circle"
                                        ></i> {this.state.errorComienzo}</div>}</Form.Label>
                                    <Form.Control as="select" custom onChange={(event) => this.actualizarTareaComienzo(event)}>
                                        <option value={''}>Seleccione una</option>
                                        {programa.tareas!==undefined && programa.tareas.map((tarea, index)=>
                                            <option value={tarea.id}>{tarea.nombre}</option>
                                        )}
                                    </Form.Control>
                                   
                                </Form.Group>
                            </Form>
                            <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={(event) => {this.handleCloseG()}}>
                                <i className="icon-times"></i> Cerrar
                                </button>
                                <button className="btns btns-secundario" type="button"  onClick={() => this.ejecucionConfirmada()}>
                                <i className="icon-tick"></i> Confirmar
                            </button>
                            </div>
                        </Modal.Body>                     
                    </Modal>}

                  

                    {this.state.showHistorial && 
                         <HistorialEjecuciones show ={this.state.showHistorial} handleClose={()=>this.handleCloseG()} programa={programa} />
                    }

                   {this.state.showEventos && <Modal size="lg" show={this.state.showEventos} onHide={() => this.handleCloseG()} backdrop="static" keyboard={false}>
                        <Modal.Header className="bg-gradient-programa text-white" closeButton>
                            <Modal.Title>Eventos de <span className="mayuscula">{this.state.tarea.nombre}</span></Modal.Title>
                        </Modal.Header> 
                        <Modal.Body className="text-dark">
                             <ListaEventos programa={this.traerPrograma()} idTarea={this.state.tarea.id} />
                             <hr></hr>
                        </Modal.Body>                     
                    </Modal>}

                    {this.state.showAcciones && <Modal size="lg" show={this.state.showAcciones} onHide={() => this.handleCloseG()} backdrop="static" keyboard={false}>
                        <Modal.Header className="bg-gradient-programa text-silver" closeButton>
                            <Modal.Title>Acciones
                                 {this.state.tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL? ' iniciales ':' de finalización '}
                                 de {this.state.tarea.nombre}
                            </Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                             <FormAccion 
                              acciones={this.state.tipoAccion===programaConstants.TIPO_ACCION_TAREA_INICIAL? this.state.tarea.accionesIniciales:this.state.tarea.accionesFinales}
                              idTarea={this.state.tarea.id}
                              idPrograma={programa.id}
                              tipoAccion={this.state.tipoAccion}
                              handleClose={() => this.handleCloseG()}  />
                        </Modal.Body>                     
                    </Modal>}

                    {this.state.alert}
            </div>
        );
    }
}

function mapState(state) {
    const { equipos } = state;
    const {seleccionado,misProgramas} = state.programa;
    return {seleccionado,misProgramas,equipos};
}

const actionCreators = {
    ejecutar:programaActions.ejecutar,
    cancelarEjecucion:programaActions.cancelarEjecucion,
    continuarEjecucion:programaActions.continuarEjecucion,
    getProgramaPorId:programaActions.getProgramaPorId,
    eliminarTarea:programaActions.eliminarTarea,
    intercambiarTarea:programaActions.intercambiarTarea,    
    getProgramasPU:programaActions.getProgramasPU,
    getEquiposPU: equipoActions.getEquiposPU
}

const connectedDetallePrograma = connect(mapState, actionCreators)(DetallePrograma);
export { connectedDetallePrograma as DetallePrograma };