import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorMessage, LoadingBoton} from '../../_helpers';
import { equipoActions, utilActions } from '../../_actions';
import { Formik, Form,  Field } from 'formik';
import * as Yup from 'yup';

class FormEditarEquipo extends React.Component { 

  setGrabando=(grab)=>{
    this.setState({grabando:grab});
  }

  state={
    grabando:false
  }


     render(){
       let {equipo} = this.props;
        let inic={
          id:'',
          nombre: ''        
        }
    
         let validateActualizar = {
          nombre: Yup.string().min(2, 'Muy corto').max(100, 'No puede excedor los 100 carácteres').required('Requerido')
         };

   
         const validationSchema = Yup.object().shape(
          validateActualizar
         );

         if(equipo!==undefined)inic=utilActions.setearValores(inic,equipo);
         return(
            
             <div className="th_recursos">
                 <Formik
                     enableReinitialize= {true}
                     initialValues={inic}
                     validationSchema={validationSchema}
                     onSubmit={(values,formProps ) => {    
                      values.carpeta=this.props.carpeta;
                      values.carpetaRoot=this.props.carpetaRoot;
                      this.props.grabarEquipoUsuario(values,this.setGrabando,this.props.handleClose);
             
                     }}>
                             {({ errors, touched,values }) => (
                             <Form>
                                <Row>
                                  <Col sm={12}>
                                    <label htmlFor="nombre">Nombre </label>
                                    <ErrorMessage name={`nombre`} />
                                     <Field name="nombre" />
                                  </Col>
                                 </Row>
                                 <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                    <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                                    <i className="icon-times"></i> Cerrar
                                    </button>
                                    <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                                        <i className="icon-floppy"></i>  Grabar
                                          {this.state.grabando && <LoadingBoton grabando={this.state.grabando} />}
                                    </button>
                                  </div>
                             </Form>
                             )}
                 </Formik>
             </div>
         )};
}

function mapState(state) {
    return {  };
}

const actionCreators = {
  grabarEquipoUsuario:equipoActions.grabarEquipoUsuario
}

const connectedFormEditarEquipo = connect(mapState, actionCreators)(FormEditarEquipo);
export { connectedFormEditarEquipo as FormEditarEquipo };