import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal,Card,Button} from 'react-bootstrap';
import { accionColectivaActions, alertActions } from '../../_actions';
import { sensorConstants } from '../../_constants';
import {FormAccionColectiva} from '..';
import { alertConfirm, esConFull, esSinToken,esConIntermedio } from '../../_helpers';
import ReactTooltip from 'react-tooltip';
class MisAccionesColectivas extends React.Component {

    constructor(props){
       super(props);       
       this.handleShowConfirm=this.handleShowConfirm.bind(this);
    }

    state = {
        show: false,
        showConfirm:false,
        accionColectiva:{}
    };

    componentDidMount(){
        if(this.props.accionColectiva.misAcciones===undefined)this.props.getAccionesColectivasPU();
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {show: false,
                accionColectiva:{}};
        });
    };

    handleShow = () => {
        this.setState((prevState) => {
            return {
                show: true
            };
        });
    };

    handleShowEditar = (item) => {
        this.setState((prevState) => {
            return {
                show: true,
                accionColectiva:item
            };
        });
    };

    hacerFavorita= (item) => {
        const values = {
            tipo:1,
            id:item.id
        }
        this.props.toogleFavorito(values)
    };

    eliminarAccionColectiva = () => {      
        this.props.eliminarAccionColectiva(this.state.accionColectiva,this.props.clearAlertConfirm);
        this.handleClose();
    };

    handleShowConfirm = (data) => {
        const getAlert = alertConfirm('Confirmar',
                `¿Está seguro que desea eliminar esta acción?`,
                'Si, eliminar',
                'Cerrar',
                this.props.clearAlertConfirm,
                this.eliminarAccionColectiva,
                null,
                true,
                'warning',
                true
            )  
        this.props.enviarAlertConfirm(getAlert);
        this.setState({accionColectiva:data})
    };

    ejecutarAccion = (accion) => {
       this.props.ejecutarAccion(accion);
    };

    render(){
        const { accionColectiva,userToken} = this.props;
        const conToken = !esSinToken(userToken);       

        const hayAcceso = (esConIntermedio(userToken) || esConFull(userToken));
        return (
            <div className="main-content">
                <Container fluid>
                        {hayAcceso &&<Row className="mb-5 mt-5">
                        <Col xs={12}>
                            <button className="btns btns-verde-azulado  bg-gradient-action  text-white" onClick={this.handleShow}>
                            <i className="icon-action align-middle txt-22"></i> Nueva Acción
                            </button>
                        </Col>                          
                    </Row>}
                        <Row>        
                        <div className="cont-sensor mt-4">
                            <div className="d-flex flex-wrap  justify-content-center mt-3">
                                {accionColectiva.misAcciones!==undefined && accionColectiva.misAcciones.map((accion, index)=>                            
                                    <Col key={index} lg={4} xl={4} sm={12} md={6} className="mb-4">                                  
                                                    <div className="text-white th_verde-azulado hoverable card-components-accion shadow">
                                                            <Card.Body>
                                                            <Row>
                                                                <Col xs={3}>
                                                                <a data-tip data-for='ejecutar'>
                                                                {hayAcceso &&<Button className="btn btn-circular-play" type="button" onClick={()=>this.ejecutarAccion(accion)}><i className="icon-action"></i> </Button>}
                                                                </a>
                                                                <ReactTooltip place="bottom" id='ejecutar' aria-haspopup='false' >
                                                                <span>Ejecutar Acción</span>
                                                                </ReactTooltip>   
                                                               </Col>
                                                                <Col xs={9} className="text-right">
                                                                    <p className="txt-16 text-muted">Ejecutar acción</p>
                                                                    <h3 className="txt-oswald txt-27 font-weight-light text-truncate text-verde-azulado">
                                                                    {accion.tipoAccionColectiva.descripcion} - {accion.nombre}
                                                                    </h3>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                            <Col xs={12}>
                                                            <div className="text-right pie-accion">
                                                            <span className="txt-16 text-muted mr-3 d-none d-lg-block d-xl-block">Sensores en la acción </span>
                                                            {accion.sensores!==undefined && accion.sensores.slice(0,10).map((sensor, index2)=>
                                                                   <span className="i-circle" key={index2}> <i className={sensorConstants.style[sensor.tipoSensor.id].icono}></i> </span>                                                      
                                                                )}
                                                            </div>
                                                            </Col>
                                                            </Row>
                                                            </Card.Body>
                                                            
                                                    </div>
                                                    {hayAcceso &&<div className="edit">
                                                    <> 
                                                        <a data-tip data-for={'fav'+accion.id}>
                                                            <i className={accion.favorita?"fas fa-star text-iot mr-2 cursor rubberBand":"far fa-star  mr-2  cursor"} onClick={() => this.hacerFavorita(accion)}></i>
                                                        </a>
                                                        <ReactTooltip place="bottom" id={'fav'+accion.id} aria-haspopup='false' >
                                                             <span>Hacer favorita</span>
                                                        </ReactTooltip>
                                                        <a data-tip data-for='elimina'>
                                                             <i className="fas fa-trash-alt mr-2 cursor" onClick={() => this.handleShowConfirm(accion)}></i>
                                                        </a>
                                                        <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                             <span>Elimina Equipo</span>
                                                        </ReactTooltip>   
                                                        <a data-tip data-for='edita'>
                                                            <i className="fas fa-pencil-alt mr-2 cursor" onClick={() => this.handleShowEditar(accion)}></i>
                                                        </a>
                                                        <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                             <span>Editar Equipo</span>
                                                        </ReactTooltip> 
                                                    </>    
                                                    </div>}
                                        </Col>                        
                                    )}  
                            </div> 
                        </div>
                        </Row>
                        <Row className="justify-content-center th_verde-azulado">
                        <div className="cont-sensor mt-4">
                        <div className="d-flex flex-wrap  justify-content-center">
                    {(accionColectiva.misAcciones===undefined || accionColectiva.misAcciones.length===0) &&
                    <>
                            <Col xs={12} md={7} lg={5}>
                            <div className="card-components border-left-c  fadeIn">
                                <Row className="p-3 ">
                                    <Col xs={2} className="text-center p-2">
                                    <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                    </Col>
                                    <Col xs={10} className="text-right p-2">
                                        <span className="text-muted txt-12"> MIS ACCIONES</span> <br></br> 
                                        <span className="text-white txt-18 font-weight-bold"> NO EXISTEN ACCIONES DEFINIDAS</span>
                                       
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                            {!hayAcceso &&
                              <Col xs={12} md={7} lg={5}>
                                  <div className="card-components border-left-c  fadeIn">
                              <Row className="p-3 ">
                                  <Col xs={2} className="text-center p-2">
                                  <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                  </Col>
                                  <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS ACCIONES</span> <br></br> 
                                  <span className="text-white txt-18 font-weight-bold">Tu tipo de suscripción
                                   no permite utilizar esta sección, pasate a Full ya!</span> 
                                  </Col>
                              </Row>
                              </div>
                          </Col>}
                            {!conToken &&
                               <Col xs={12} md={7} lg={5}>
                                   <div className="card-components border-left-c  fadeIn">
                               <Row className="p-3 ">
                                   <Col xs={2} className="text-center p-2">
                                   <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                   </Col>
                                   <Col xs={10} className="text-right p-2"><span className="text-muted txt-12"> MIS ACCIONES</span> <br></br> 
                                   <span className="text-white txt-18 font-weight-bold">Contrata ya una suscripción!</span> 
                                   </Col>
                               </Row>
                               </div>
                           </Col>}
                       </>
                    }
                    </div>
                    </div>
                    </Row>
                    </Container> 
               
                   {this.state.show &&
                    <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header className="bg-gradient-action text-white" closeButton>
                            <Modal.Title><i className="icon-action"></i> Acción</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body className="th_verde-azulado"> 
                            <FormAccionColectiva accionColectiva={this.state.accionColectiva}  handleClose={()=>this.handleClose()}/>
                        </Modal.Body>                     
                    </Modal>} 
            </div>
        );
    }
}

function mapState(state) {
    const { userToken } = state.authentication;
    const { accionColectiva,equipos } = state;
    return { accionColectiva,equipos,userToken };
}

const actionCreators = {
    agregarAccionColectivaGenerada:accionColectivaActions.agregarAccionColectivaGenerada,
    ejecutarAccion:accionColectivaActions.ejecutarAccion,
    eliminarAccionColectiva:accionColectivaActions.eliminarAccionColectiva,
    getAccionesColectivasPU:accionColectivaActions.getAccionesColectivasPU,   
    enviarAlertConfirm:alertActions.enviarAlertConfirm,
    clearAlertConfirm:alertActions.clearAlertConfirm,
    toogleFavorito:accionColectivaActions.toogleFavorito
}

const connectedMisAccionesColectivas = connect(mapState, actionCreators)(MisAccionesColectivas);
export { connectedMisAccionesColectivas as MisAccionesColectivas };