import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row,Tab,Tabs} from 'react-bootstrap';
import Select from 'react-select';
import { productoActions } from '../../_actions';
import {CardProductoTienda} from './CardProductoTienda';

class Tienda extends React.Component {
    state={      
        active:false,
        categorias:[]
     }
    componentDidMount(){
        if(this.props.productosUsr===undefined)this.props.traerProductosUser();
        if(this.props.catProductosUsr===undefined)this.props.traerCategoriaProductoUsr();
    }

    render(){
        const { productosUsr,catProductosUsr } = this.props;
        return (
            <div className="main-content">
                <Container>
                    <Row>
                        <Col>
                            Filtros:
                            <label>
                                Categorias: 
                            </label>
                            <Select
                            value={catProductosUsr ? catProductosUsr.filter(obj => (this.state.categorias && this.state.categorias.some(cam =>cam.id===obj.id))) : []}
                                getOptionLabel={option =>`${option.descripcion}`}
                                getOptionValue={option => option.id}
                                onChange={categorias =>{
                                    this.setState(`categorias`,categorias)
                                }}
                                isMulti={true}
                                isSearchable={true}
                                placeholder="Seleccione mas de una"
                                options={catProductosUsr }
                            /> 
                        </Col>
                    </Row>
                </Container>
                    <Tabs  className="nav nav-pills justify-content-end nav-tabs mb-4"  defaultActiveKey="lista" id="uncontrolled-tab-example">                
                        <Tab eventKey="lista" title={'Lista'}>
                            <ul>                               
                                {productosUsr!==undefined && productosUsr.map((prod, indexS)=>  
                                    <li key={prod.id}>                       
                                        <CardProductoTienda producto={prod} /> 
                                    </li>       
                                )}
                            </ul>
                        </Tab>
                    </Tabs>
            </div>

        );
    }
}

function mapState(state) {       
    const { productosUsr,catProductosUsr } = state.producto;
    return {productosUsr,catProductosUsr };
}

const actionCreators = {
    traerProductosUser:productoActions.traerProductosUser,
    traerCategoriaProductoUsr:productoActions.traerCategoriaProductoUsr
}

const connectedTienda = connect(mapState, actionCreators)(Tienda);
export { connectedTienda as Tienda };

