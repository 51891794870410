import React,{useState,useEffect} from 'react'
import { GoogleMap, useJsApiLoader,DirectionsRenderer  } from '@react-google-maps/api';

function useWidgetMapDireccion(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyALdKcJx5a3hE7oTubUBbHyzeLtB86bQkg"
  })

  const containerStyle = {
    width: props.width+'px',
    height: props.height+'px'
  };

  const [map, setMap] = React.useState(null)
 
  const onLoad = React.useCallback(function callback(map) {   
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const {directions}=props;
  
  return isLoaded? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
        <DirectionsRenderer directions={directions} />
        </>
      </GoogleMap>
  ) : <></>
}

export function WidgetMapDireccion (props){  
  try{     
    return useWidgetMapDireccion(props);
  }catch(err){
    console.warn(err)
    return <div className="col-sm-12 bg-danger p-2 mb-3 mt-3 text-silver"> <i className="fas fa-exclamation-circle"></i>Error renderizando mapa</div>;
  }     
}


