export const productoConstants = {
    SUSCRIPCION_LISTADO_ADM:'SUSCRIPCION_LISTADO_ADM',
    SUSCRIPCION_LISTADO_USR:'SUSCRIPCION_LISTADO_USR',
    SUSCRIPCION_GRABAR:'SUSCRIPCION_GRABAR',
    SUSCRIPCION_ACTUALIZAR:'SUSCRIPCION_ACTUALIZAR',
    PRODUCTO_LISTADO_ADM:'PRODUCTO_LISTADO_ADM',
    PRODUCTO_LISTADO_USR:'PRODUCTO_LISTADO_USR',
    ASIGNACION_EQUIPO_COMPRA:'ASIGNACION_EQUIPO_COMPRA',
    EQUIPOS_DISPONIBLES:'EQUIPOS_DISPONIBLES',
    PRODUCTO_GRABAR:'PRODUCTO_GRABAR',
    PRODUCTO_ACTUALIZAR:'PRODUCTO_ACTUALIZAR',
    PRODUCTO_CATEGORIA_GRABAR:'PRODUCTO_CATEGORIA_GRABAR',
    PRODUCTO_CATEGORIA_ACTUALIZAR:'PRODUCTO_CATEGORIA_ACTUALIZAR',
    PRODUCTO_CATEGORIA_LISTADO_ADM:'PRODUCTO_CATEGORIA_LISTADO_ADM',
    PRODUCTO_CATEGORIA_LISTADO_USR:'PRODUCTO_CATEGORIA_LISTADO_USR',
    MODELO_GRABAR:'MODELO_GRABAR',
    ESTADOS_COMPRA:'ESTADOS_COMPRA',
    MODELO_ACTUALIZAR:'MODELO_ACTUALIZAR',
    MODELO_LISTADO_ADM:'MODELO_LISTADO_ADM',
    PRODUCTO_TIPO_SUSCRIPCION:'PRODUCTO_TIPO_SUSCRIPCION',
    MEDIOS_PAGO_LISTADO:'MEDIOS_PAGO_LISTADO',
    PRODUCTO_SELECCIONAR:'PRODUCTO_SELECCIONAR',
    MIS_COMPRAS:'MIS_COMPRAS',
    COMPRA_EXITOSA:'COMPRA_EXITOSA',
    PRODUCTO_QUITAR:'PRODUCTO_QUITAR',
    MODIFICAR_COMPRA:'MODIFICAR_COMPRA',
    MODIFICAR_COMPRA_ADM:'MODIFICAR_COMPRA_ADM',
    MEDIO_PAGO_EFECTIVO:1,
    MEDIO_PAGO_MERCADOPAGO:2,
    MEDIO_PAGO_TRANSFERENCIA:3,
    MEDIO_PAGO_PAYPAL:4,
    TIPO_COMPRA_LICENCIA:1,
    TIPO_COMPRA_PRODUCTO:2,
    TIPO_ENVIO_CORREO:1,
    TIPO_ENVIO_RETIRO:2,
    DOCUMENT_TYPE_MP:'DOCUMENT_TYPE_MP',
    LIMPIO_COMPRA:'LIMPIO_COMPRA'
};
