import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { equipoActions, utilActions, vistaActions } from '../_actions';
import {Container} from 'react-bootstrap';
import {VistaEstandar,VistaCervecera,VistaHuerta} from './Equipo';
import { VistaDesarrollador } from './Equipo/VistaDesarrollador';
import { equipoConstants } from '../_constants';
import { TIPO_VISTA } from '../_helpers';


class EquipoListado extends React.Component {
    
     state={
        pathName:'',
        tipoPlaca:1 //Esta es la Standard, la seleccionada traera la suya
     }

     componentDidMount() {   
        if(this.props.vistas.misVistas===undefined)this.props.getVistasPU();
        
    };
   
    render(){
        const { equipos,vistas } = this.props;
        const pathName  = this.props.match.path;
        let esEquipo = true;
        let seleccionado={};
        let tipoVista = TIPO_VISTA.EQUIPO;

        if(pathName==='/home/listaEquipo' && equipos.misEquipos){           
            let params = queryString.parse(this.props.location.search);        
            const id = params?params.id:null;          
            seleccionado = equipos.misEquipos.find(eq=>eq.id===id);
            if(!seleccionado)seleccionado= equipos.misEquipos[0];
            tipoVista=TIPO_VISTA.EQUIPO;           
        }
           
        if(pathName==='/home/listaVista' && vistas.misVistas){                      
            const indexSelec = vistas.seleccionado;
            seleccionado = vistas.misVistas[indexSelec?indexSelec:0];
            esEquipo=false;
            tipoVista=TIPO_VISTA.VISTA;         
        }
        return (
            
            <div className="main-content">
            <Container fluid>            

                {!utilActions.isEmpty(seleccionado) && 
                    <>
                        {(!esEquipo || seleccionado.tipoEquipo.id===equipoConstants.TIPO_EQUIPO_ESTANDAR) &&
                            <VistaEstandar seleccionado={seleccionado} tipoVista={tipoVista} />
                        }
                        {esEquipo && seleccionado.tipoEquipo.id===equipoConstants.TIPO_EQUIPO_DESARROLLADOR &&
                            <VistaDesarrollador seleccionado={seleccionado} tipoVista={tipoVista} />
                        }
                        {esEquipo && seleccionado.tipoEquipo.id===equipoConstants.TIPO_EQUIPO_CERVEZA &&
                            <VistaCervecera seleccionado={seleccionado} tipoVista={tipoVista} />
                        }
                        {esEquipo && seleccionado.tipoEquipo.id===equipoConstants.TIPO_EQUIPO_HUERTA &&
                            <VistaHuerta seleccionado={seleccionado} tipoVista={tipoVista} />
                        }
                    </>
                }    
                </Container> 
            </div>
        );
    }
}

function mapState(state) {
    const { equipos,vistas,authentication } = state;
    return { equipos,vistas,authentication };
}

const actionCreators = {   
    getEquiposPU: equipoActions.getEquiposPU,
    getVistasPU:vistaActions.getVistasPU
}

const connectedEquipoListado = connect(mapState, actionCreators)(EquipoListado);
export { connectedEquipoListado as EquipoListado };