import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        clase: 'bg-success',
        type: 'success',
        message: action.error.message?action.error.message:action.error,
        codigo: action.error.codigo?action.error.codigo:''
      };
    case alertConstants.ERROR:
      return {
        clase: 'bg-danger',
        type: 'error',
        message: action.error.message?action.error.message:action.error,
        codigo: action.error.codigo?action.error.codigo:''
      };
    case alertConstants.CONFIRM:
      return {
        ...state,
       confirm:action.confirm
      };  
    case alertConstants.CLEAR_CONFIRM:
      return {
        ...state,
       confirm:undefined
      };  
    case alertConstants.CLEAR:
      return {
        confirm:undefined
      };
    default:
      return state
  }
}