import React from 'react';
import { connect } from 'react-redux';
import {consultaActions} from '../../_actions';
import {consultaConstants} from '../../_constants';
import MaterialTable from 'material-table';
import Moment from 'react-moment';
class MisConsultasListado extends React.Component {
  
    state = {
        show: false,
        consulta:[]
    };

    handleClose = () => {
        this.setState({show: false});
    };

    handleMasInfo = (consulta) => {
        this.props.limpiarBurbujaConsulta();
        this.props.seleccionarConsultaGlobo(consulta.id);
    };

    handleCancelarConsulta = (consulta) => {
        this.props.cancelarConsulta(consulta.id,consultaConstants.ESTADO_CANCELAR);
    };
    

    render(){
        const { misConsultas } = this.props;
       
        const header = [
            { title: 'Id', field: 'id'},
            { title: 'Fecha Inicio', render: rowData =>  <Moment date={rowData.fecha} format="DD/MM/YYYY hh:mm"/>},
            { title: 'Estado', field: 'estadoSt.descripcion'},
            { title: 'Titulo', field: 'titulo'},            
            { title: 'Tipo problema', field: 'tipoProblemaSt.descripcion'},
            { title: 'Acciones',cellStyle:{width:'63%', textAlign:'right'},
               render: rowData =>                    
                   <>
                    <div className="menu-circle"><button className="btns btns-link cursor" onClick={(event) => this.handleMasInfo(rowData)} ><span className="material-icons material-icons-outlined">forum</span></button></div>     
                    <button className="btns btns-link cursor " onClick={(event) => this.handleCancelarConsulta(rowData)} ><span className="material-icons text-muted">delete</span></button>   
                     </>                      
            }
          ];

        const options={
            search: true,
            sorting: true
        }  

        return (
            <div>
                {misConsultas!==undefined &&
                    <MaterialTable
                        title='Mis consultas'
                        columns={header}
                        data={misConsultas}
                        options={options}
                    />
                }

                    {/*this.state.show&& <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false} >
                        <Modal.Header className="bg-iot text-dark" closeButton>
                            <Modal.Title><i className="far fa-comments mr-1"></i> <span className=" font-weight-light text-14">Consulta</span> <span className=" font-weight-bold text-14">{this.state.consulta.id}</span> </Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <InfoConsultaUser esUser={true} consulta={this.state.consulta} handleClose={this.handleClose}/>
                        </Modal.Body>                     
            </Modal>*/}      
            </div>
        );
    }
}

function mapState(state) {
    const { misConsultas } = state.consultas;
    return { misConsultas };
}

const actionCreators = {   
    cancelarConsulta:consultaActions.cambiarEstadoConsulta,
    seleccionarConsultaGlobo:consultaActions.seleccionarConsultaGlobo,
    limpiarBurbujaConsulta:consultaActions.limpiarBurbujaConsulta
}

const connectedMisConsultasListado = connect(mapState, actionCreators)(MisConsultasListado);
export { connectedMisConsultasListado as MisConsultasListado };