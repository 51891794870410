import http from '../_helpers/http.service';

export const eventoService = {
    getEventosPU,
    grabarEvento,
    eliminarEvento,
    toogleEvento,
    traerListadoGeneralEvento
};


function getEventosPU() {    
        var url = '/api/eventos/pu';    
        return http.get(url);
    
}

function traerListadoGeneralEvento() {    
        var url = '/api/eventos/traerListadoGeneralEvento';    
        return http.get(url);    
}


function toogleEvento(values) {    
        var url = '/api/eventos/toogle';    
        return http.post(url,JSON.stringify(values));   
}

function grabarEvento(values) {    
        var url = '/api/eventos/grabar';
        return http.post(url,JSON.stringify(values));    
}

function eliminarEvento(values) {    
        var url = '/api/eventos/eliminar';
        return http.post(url,JSON.stringify(values));    
}
