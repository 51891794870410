import { grupoConstants ,userConstants} from '../_constants';


export function grupo(state = {}, action) {
  switch (action.type) { 
    case grupoConstants.GRUPO_GETPU_SUCCESS:
      return { 
        ...state,
        misAutorizaciones: action.listados.listados.misAutorizaciones,
        misUsuarios: action.listados.listados.misUsuarios
      };   
      case grupoConstants.REMOVER_AUTORIZACION:
        return { 
          ...state,
          misAutorizaciones: state.misAutorizaciones.filter(auto => auto.id !== action.id)
        }; 
      case grupoConstants.ELIMINAR_AUTORIZACION:
        return { 
          ...state,
          misUsuarios: state.misUsuarios.filter(usuario => (usuario.equipo.id !== action.idEquipo && usuario.usuario.id !== action.idUsuario && usuario.rol.id !== action.idRol))
        };
        
        case userConstants.LOGOUT:
          return {};
          
    default:
      return state
  }
}