import React from 'react';
import { connect } from 'react-redux';
import {Button,Row,Spinner,Col,Container} from 'react-bootstrap';
import { productoConstants } from '../../_constants';
import { productoActions } from '../../_actions';
import {RadioGroup, Radio} from 'react-radio-group';
import FormModalUsuario from '../Perfil/FormModalUsuario';
import { Link } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import {ERR, ErrorMessage} from '../../_helpers';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

class CarritoCompra extends React.Component { 
    state={ 
        requiereEnvio:false,
        pasoCompra:0,
        totalCompra:0,
        showNuevoUsuario:false
     }
    componentDidMount(){
        if(this.props.mediosPago===undefined)this.props.traerMediosPago();
    }

    handleClose(){
        this.setState({
            showNuevoUsuario:false
        }); 
        if(this.props.user!==undefined)this.iniciarProcesoCompra();
    }

    calcularTotal(){
        const { carritoCompra } = this.props;     
        let totalCompra = 0;
        carritoCompra.forEach((prod, index)  => {
            let valor = 0;
            if(prod.tipoCompra===productoConstants.TIPO_COMPRA_LICENCIA)
                valor=prod.producto.costo;
            
            if(prod.tipoCompra===productoConstants.TIPO_COMPRA_PRODUCTO){
                valor=prod.producto.valor*prod.cantidad;
            }

            totalCompra = totalCompra + valor;
        });
        return totalCompra;
    }

    requiereEnvio(){
        const { carritoCompra } = this.props;     
         let requiereEnvio = false;
        carritoCompra.forEach((prod, index)  => {            
            if(prod.tipoCompra===productoConstants.TIPO_COMPRA_PRODUCTO)
                requiereEnvio=true;
        });
        return requiereEnvio;
    }

    iniciarProcesoCompra(){
        const { user } = this.props;
        
        //Primero debe existir el usuario
        if(user===undefined){
            this.setState({
                showNuevoUsuario:true
            }); 
        }else{
            let totalCompra = 0;
            let requiereEnvio = false;
            
            totalCompra = this.calcularTotal();
            requiereEnvio = this.requiereEnvio();
            this.setState({
                requiereEnvio:requiereEnvio,
                totalCompra:totalCompra,
                pasoCompra: 1
            }); 
        }
    }

    handleFinalizarExito=()=>{
        this.setState({
            pasoCompra: 3
        }); 
    }

    finalizarCompra(){
        let values = {
            carritoCompra:this.props.carritoCompra,
            tipoEnvio:this.state.tipoEnvio,
            medioPago:this.state.medioPago
        }

        this.props.generarCompra(values,this.handleFinalizarExito);
    }

    render(){
        const { carritoCompra,mediosPago } = this.props;
        const {requiereEnvio} = this.state;

        let inic={
            medioPago:{id:''},
            tipoEnvio:{id:''},
        }
      
        let valSchema = {            
            medioPago: Yup.object({id: Yup.string().required(ERR.REQ)})
        }
        if(requiereEnvio)valSchema.tipoEnvio= Yup.object({id: Yup.string().required(ERR.REQ)});

        const validationSchema = Yup.object().shape(valSchema);

        const header = [            
            { title: 'Producto', field: 'producto.nombre'},  
            { title: 'Cantidad' , field: 'cantidad'},
            { title: 'Costo unitario',
                render: row =>  
                    <div>   
                        <NumberFormat prefix={'$'} value= {row.tipoCompra===productoConstants.TIPO_COMPRA_LICENCIA?row.producto.costo:row.producto.valor} displayType={'text'} thousandSeparator="." decimalSeparator="," />                    
                        
                    </div> 
            },    
            { title: 'Subtotal',
            render: row =>  
                <div>     
                    <NumberFormat prefix={'$'} value= {row.tipoCompra===productoConstants.TIPO_COMPRA_LICENCIA?row.producto.costo*row.cantidad:row.producto.valor*row.cantidad } displayType={'text'} thousandSeparator="." decimalSeparator="," />                                      
                     
                </div> 
            },   
            { title: 'Acciones',
                render: row =>                     
                    <button type="button" className="btns btns-link text-verde-azulado" onClick={()=>this.props.quitarProducto(row)}>
                        Quitar
                    </button>
            }
        ];
        const options={
            search: false,
            sorting: false
        }  

        return (
        <div className="main-content">
            <Container fluid className="mt-5">
                    {this.state.pasoCompra===0 &&
                        <> 
                            {(carritoCompra===undefined || (carritoCompra!==undefined && carritoCompra.length===0)) &&
                               <Row className="justify-content-center  th_fucsia">
                               <Col className="card-components border-left-c  fadeIn" xs={12} md={7} lg={5}>
                                   <Row className="p-3 ">
                                       <Col xs={2} className="text-center p-2">
                                       <span className="material-icons icon-gradient-general txt-42">add_shopping_cart</span>
                                       </Col>
                                       <Col xs={10} className="text-right p-2">
                                           <span className="text-muted txt-12"> MIS EQUIPOS</span> <br></br> 
                                           <span className="text-white txt-18 font-weight-bold"> NO HAY PRODUCTOS EN EL CARRITO</span>
                                           <br></br> <span className="text-muted"> Pasa por la tienda y compra ya</span>
                                       </Col>
                                   </Row>
                               </Col>
                               </Row>
                            }
                    
                            {(carritoCompra!==undefined && carritoCompra.length>0) && 
                                <>
                                    <MaterialTable
                                        title={"Carrito de compra"}
                                        columns={header}
                                        data={carritoCompra}
                                        options={options}
                                    /> 

                                    Total de la compra : 
                                    <NumberFormat prefix={'$'} value= {this.calcularTotal()} displayType={'text'} thousandSeparator="." decimalSeparator="," />                    
                                    <Button type="button"  onClick={()=>this.iniciarProcesoCompra()}>Iniciar compra</Button>
                                </>
                            }     
                        </>
                    }

                    {this.state.pasoCompra===1 &&                      
                            <Formik
                                enableReinitialize= {true}
                                initialValues={inic}
                                validationSchema={validationSchema}
                                onSubmit={(values ) => { 
                                    this.setState({pasoCompra: 2,medioPago:values.medioPago,tipoEnvio:values.tipoEnvio});
                                }}>
                                {({ errors, touched,values,setFieldValue }) => (
                                <Form>
                                     <Row>
                                        <Col sm={12}>
                                            <label htmlFor="cantidad"> Seleccione un medio de pago:  </label>
                                            <ErrorMessage name={`medioPago`} />

                                            <RadioGroup  name="medioPago" selectedValue={values.medioPago.id}
                                            onChange={(value)=> setFieldValue(`medioPago.id`,value)}>
                                                    {(mediosPago!==undefined) && mediosPago.map((medio, indexS)=>
                                                        <div key={"mp"+medio.id}> 
                                                            <label className="label-check" htmlFor={"medio-"+medio.id}>{medio.descripcion}</label>
                                                            <Radio className="option-input checkbox" id={"medio-"+medio.id} value={medio.id} />
                                                        </div> 
                                                    )}
                                            </RadioGroup>   
                                        </Col>

                                        {requiereEnvio && <Col sm={12}>
                                            <label htmlFor="tipoEnvio">
                                                Seleccione un metodo de envio: </label>
                                            

                                            <RadioGroup  name="tipoEnvio" selectedValue={values.tipoEnvio.id}
                                                onChange={(value)=> setFieldValue(`tipoEnvio.id`,value)}>                                  
                                                    <> 
                                                    <label className="label-check" htmlFor={"env-"+productoConstants.TIPO_ENVIO_CORREO}>Correo</label>
                                                    <Radio className="option-input checkbox" id={"env-"+productoConstants.TIPO_ENVIO_CORREO} value={productoConstants.TIPO_ENVIO_CORREO} />

                                                    <label className="label-check" htmlFor={"env-"+productoConstants.TIPO_ENVIO_RETIRO}>Retiro por local</label>
                                                    <Radio className="option-input checkbox" id={"env-"+productoConstants.TIPO_ENVIO_RETIRO} value={productoConstants.TIPO_ENVIO_RETIRO} />
                                                    </> 
                                            </RadioGroup>   
                                            <ErrorMessage name={`tipoEnvio`} /> 
                                        </Col>}
                                    </Row>  
                                    <Row>
                                        <Col sm={12}>
                                            <Button type="submit">Procesar</Button>
                                        </Col>
                                    </Row>    
                                </Form>                             
                                )}
                            </Formik>
                    }   

                {this.state.pasoCompra===2 &&
                    <>
                         {this.state.medioPago.id===productoConstants.MEDIO_PAGO_EFECTIVO &&
                            <>
                                <div className="txt-white">
                                    La compra quedara en espera hasta que se realice el pago.                                     
                                </div>
                            </>
                         }
                         {this.state.medioPago.id===productoConstants.MEDIO_PAGO_TRANSFERENCIA &&
                            <>
                                <div className="txt-white">
                                    La compra quedara en espera hasta que nos envie el comprobante de transferencia.<br/>
                                    El mismo lo podra adjuntar en el seguimientos de sus compras en el perfil.<br/>
                                    Cualquier consulta, podra contactarse con nosotros<br/>
                                    DATOS TRANSFERENCIA:<br/>
                                    CBU 345435345345345345<br/>
                                    CUIT 3453453453<br/>
                                </div>
                               
                            </>
                         }
                         {this.state.medioPago.id===productoConstants.MEDIO_PAGO_MERCADOPAGO &&
                            <>
                                <div className="txt-white">
                                   Serás redirigido al sitio de pago de mercado pago. Donde una vez finalizado el mismo, volveras a esta misma pagina.                                   
                                </div>
                             
                            </>
                         }
                          <Button type="button" onClick={()=>this.finalizarCompra()}>Confirmar </Button>
                    </>
                }

                {(this.state.pasoCompra===3 && this.props.compraRealizada!==undefined) &&
                    <>
                     {this.props.compraRealizada.id===undefined?
                        <>
                            Realizando la compra... espere por favor.
                            <Spinner animation="border" />
                        </>
                      :
                      <>
                        Gracias por tu compra!<br/>
                        Numero de seguimiento : {this.props.compraRealizada?this.props.compraRealizada.id:''}<br/>

                        {(this.state.medioPago.id===productoConstants.MEDIO_PAGO_MERCADOPAGO && this.props.compraRealizada.refMercadoPago!==undefined)  &&
                            <>
                                <br/>
                                    Para finalizar el pago en mercado pago ingresa al siguiente link
                                <br/>
                                <form action="http://localhost:3000/tienda/carrito" method="POST" id="mercadoForm">
                                    <ScriptTag src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js" async={true} data-preference-id={this.props.compraRealizada.refMercadoPago} />                             
                                </form>
                            </>
                        }
                        
                        Podes hacer un seguimiento de la misma en:<br/>
                        
                        <Link to="/tienda/misCompras">Ir a mis compras</Link>
                      </>
                      }
                       
                    </>
                }          

            {this.state.showNuevoUsuario && <FormModalUsuario show={this.state.showNuevoUsuario} handleClose={this.handleClose} />}
            </Container>
            </div>
        );
    }
}

function mapState(state) {       
    const { mediosPago,carritoCompra,compraRealizada } = state.producto;
    const { authentication } = state;
    const { user } = authentication;
    return {mediosPago,carritoCompra,user,compraRealizada };
}

const actionCreators = {
    traerMediosPago:productoActions.traerMediosPago,
    generarCompra:productoActions.generarCompra,
    quitarProducto:productoActions.quitarProducto
}

const connectedCarritoCompra = connect(mapState, actionCreators)(CarritoCompra);
export { connectedCarritoCompra as CarritoCompra };

