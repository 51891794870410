export const equipoConstants = {
    EQUIPO_GET_REQUEST: 'EQUIPO_GET_REQUEST',
    EQUIPO_GET_SUCCESS: 'EQUIPO_GET_SUCCESS',
    EQUIPO_GET_FAILURE: 'EQUIPO_GET_FAILURE',
    ACT_ESTADO_EQUIPO:'ACT_ESTADO_EQUIPO',
    AGREGAR_EQUIPO_USER:'AGREGAR_EQUIPO_USER',
    LISTADO_TIPOS_EQUIPO:'LISTADO_TIPOS_EQUIPO',
    ELIMINAR_EQUIPO:'ELIMINAR_EQUIPO',
    EQUIPO_GET_ALL_SUCCESS: 'EQUIPO_GET_ALL_SUCCESS',
    EQUIPO_GET_ALL_FAILURE: 'EQUIPO_GET_ALL_FAILURE',
    EQUIPO_GET_PU_SUCCESS: 'EQUIPO_GET_PU_SUCCESS',
    VISTAS_GET_PU_SUCCESS: 'VISTAS_GET_PU_SUCCESS',
    EQUIPO_LIMPIAR_EQUIPO_NUEVO_SUCCESS:'EQUIPO_LIMPIAR_EQUIPO_NUEVO_SUCCESS',
    EQUIPO_SELECCIONADO:'EQUIPO_SELECCIONADO',
    NUEVO_VALOR:'NUEVO_VALOR',
    AGREGAR_EQUIPO_GENERADO:'AGREGAR_EQUIPO_GENERADO',
    ACTUALIZAR_EQUIPO_GENERADO:'ACTUALIZAR_EQUIPO_GENERADO',
    ACTUALIZAR_EQUIPO_USUARIO:'ACTUALIZAR_EQUIPO_USUARIO',
    AGREGAR_EQUIPO_ASOCIADO:'AGREGAR_EQUIPO_ASOCIADO',
    DESASOCIAR_EQUIPO:'DESASOCIAR_EQUIPO',
    EQUIPO_TRAER_TIPOS_SENSOR:'EQUIPO_TRAER_TIPOS_SENSOR',
    TIPO_EQUIPO_DESARROLLADOR:2,
    TIPO_EQUIPO_ESTANDAR:1,
    TIPO_EQUIPO_CERVEZA:3,
    TIPO_EQUIPO_HUERTA:4,
    TAB_DASHBOARD:1,
    TAB_INFO:2,
    GENERAL_SENSOR:'GENERAL_SENSOR',
    NUEVO_ESTADO_SENSOR:'NUEVO_ESTADO_SENSOR',
    ACTUALIZAR_LAYOUT_EQUIPO:'ACTUALIZAR_LAYOUT_EQUIPO',
    DATA_HISTORIAL_WIDGET:'DATA_HISTORIAL_WIDGET',
    FINALIZAR_FORZADO:'FINALIZAR_FORZADO',
    CARPETAS_USUARIO:'CARPETAS_USUARIO',
    AGREGAR_EQUIPO_CARPETA:'AGREGAR_EQUIPO_CARPETA',
    AGREGAR_EQUIPO_CARPETAR_ROOT:'AGREGAR_EQUIPO_CARPETAR_ROOT',
    ACTUALIZAR_CARPETA:'ACTUALIZAR_CARPETA',
    AGREGAR_CARPETA:'AGREGAR_CARPETA'
};
