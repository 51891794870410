import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col,Modal,Button,Badge} from 'react-bootstrap';
class HistorialEjecuciones extends React.Component {

    constructor(props){      
       super(props);
    }

    render(){
       const {programa,show,handleClose} = this.props;
        return (
            <Modal size="lg" show={show} onHide={()=>handleClose('historial')} backdrop="static" keyboard={false}>
                <Modal.Header className="bg-gradient-programa text-white" closeButton>
                    <Modal.Title>Historial de ejecuciones</Modal.Title>
                </Modal.Header> 
                <Modal.Body className="text-dark">
                        <Row className="mb-2">
                            <Col sm={12}>
                                <h3>{programa.nombre}</h3>  
                                <hr></hr>                          
                            </Col>
                         
                            </Row>
                            <Row>
                        {programa.historial!==undefined && programa.historial.map((historial, index)=>
                                <>             
                                    
                                        <Col xs={12}>
                                        <h5><Badge className="bg-success text-white  mr-3">{historial.fechaInicio}</Badge><Badge className="bg-success text-white">{historial.fechaFinalizacion}</Badge></h5>
                                            Panel con estadisticas de la ejecucion, quizas discriminado por tarea? promedios, maximos, minimos, etc                                
                                            <hr></hr>
                                        </Col>
                                    
                                        
                                    {historial.logs!==undefined && historial.logs.map((log, index)=>
                                        <div key={log.id}>
                                            <Col xs={12}>
                                        <h5><Badge className="bg-uva_d text-white mr-3">{log.tarea.nombre}</Badge> <Badge className="bg-uva_d text-white mr-3">{log.descripcion}</Badge><Badge className="bg-success text-white">{log.fecha}</Badge></h5>                             
                                            <hr></hr>
                                        </Col>
                                        
                                        </div>
                                    )}
                                </>
                        )}  
                        </Row>
                </Modal.Body>                     
            </Modal>
        );
    }
}

function mapState(state) {
   
    return {};
}

const actionCreators = {
}

const connectedHistorialEjecuciones = connect(mapState, actionCreators)(HistorialEjecuciones);
export { connectedHistorialEjecuciones as HistorialEjecuciones };