import { equipoConstants, vistaConstants } from '../_constants';
import { equipoService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { vistaActions } from './vista.actions';
import { accionColectivaActions } from './accionColectiva.actions';
import { eventoActions } from './evento.actions';
import { programaActions } from './programa.actions';

export const equipoActions = {
    moverDeCarpeta,
    grabarCarpeta,
    getEquipoById,
    getEquiposPU,    
    limpiarEquipoNuevo:limpiarEquipoNuevo,
    seleccionarEquipo,
    nuevoValorSensor,
    agregarEquipoGenerado,
    grabarEquipoUsuario,
    agregarEquipoAsociado,
    desasociar,
    traerTiposSensores,
    traerGeneralSensor,
    traerTiposEquipo,
    eliminarEquipo,
    cambiarEstadoEquipo,
    nuevoEstadoSensor,
    guardarLayout,
    enviarNuevoLayOut,
    finalizarForzado,
    traerCarpetas,
    eliminarCarpeta
    
};
function finalizarForzado(seleccionado,layouts) {
    return dispatch => {
          layouts.forzar=null;
          seleccionado.layouts=JSON.stringify(layouts);
          dispatch(success(seleccionado));         
    };
    function success(seleccionado) { return { type: equipoConstants.FINALIZAR_FORZADO, seleccionado } }
}



function moverDeCarpeta(values,setGrabando,handleClose,carpetaRoot) {
     setGrabando(true);
     return dispatch => {
         equipoService.moverDeCarpeta(values)
             .then(
                 data => { 
                     data.carpetas=carpetaRoot;

                     //si la carpeta origen es la root lo saco de equipos
                     if(values.carpetaActual.id===carpetaRoot.id){                        
                        data.carpetas.equipos.splice(data.carpetas.equipos.findIndex(valu=>valu.id===values.equipo.id),1)  
                     }else{
                        data.carpetas.carpetasHijas.forEach(carpHija => {
                           if(carpHija.id===values.carpetaActual.id){
                               carpHija.equipos.splice(carpHija.equipos.findIndex(valu=>valu.id===values.equipo.id),1)
                           }
                        });
                     }

                      //si la carpeta nueva es la root lo agrego ahi
                      if(values.id===carpetaRoot.id){                        
                        data.carpetas.equipos.push({id:values.equipo.id});
                     }else{
                        data.carpetas.carpetasHijas.forEach(carpHija => {
                           if(carpHija.id===values.id){
                               if(carpHija.equipos)
                                    carpHija.equipos.push({id:values.equipo.id});
                                    else carpHija.equipos=[{id:values.equipo.id}]
                           }
                        });
                     }

                     dispatch(success(data)); 
                     setGrabando(false); 
                     handleClose();      
                 },
                 error => {    
                     setGrabando(false); 
                     dispatch(alertActions.error(error));
                 }
             );
     };
 
     function success(data) { return { type: equipoConstants.AGREGAR_EQUIPO_CARPETAR_ROOT, data } }
 }

function eliminarCarpeta(values,handleClose,carpetaRoot) {
   // setGrabando(true);
    return dispatch => {
        equipoService.eliminarCarpeta(values)
            .then(
                data => { 
                    data.carpetas=carpetaRoot;

                    //si tiene equipos hijos los paso al root
                    if(values.equipos && values.equipos.length>0){
                        values.equipos.forEach(eq => {
                            data.carpetas.equipos.push({id:eq.id});
                        });
                    }

                    data.carpetas.carpetasHijas.splice(data.carpetas.carpetasHijas.findIndex(valu=>valu.id===values.id),1)                    

                    dispatch(success(data)); 
                 //   setGrabando(false); 
                    handleClose();      
                },
                error => {    
                    //setGrabando(false); 
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.AGREGAR_EQUIPO_CARPETAR_ROOT, data } }
}

function grabarCarpeta(values,setGrabando,handleClose,carpetaRoot) {
    setGrabando(true);
    return dispatch => {
        equipoService.grabarCarpeta(values)
            .then(
                data => {
                    data.carpetas=carpetaRoot;
                    if(values.id===''){                        
                        if(data.carpetas.carpetasHijas)
                            data.carpetas.carpetasHijas.push(data.carpeta);
                        else
                            data.carpetas.carpetasHijas=[data.carpeta];
                    }else{
                        data.carpetas.carpetasHijas=data.carpetas.carpetasHijas.map(cph => {             
                            if (cph.id === data.carpeta.id)                 
                              return data.carpeta;
                            else return cph; 
                        })
                    }
                    dispatch(success(data)); 
                    setGrabando(false); 
                    handleClose();      
                },
                error => {    
                    setGrabando(false); 
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.AGREGAR_EQUIPO_CARPETAR_ROOT, data } }
    //function successAct(data) { return { type: equipoConstants.ACTUALIZAR_CARPETA, data } }
}

function traerCarpetas() {
    return dispatch => {
        equipoService.traerCarpetas()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.CARPETAS_USUARIO, data } }
}

function guardarLayout(values) {
    return dispatch => {
        equipoService.guardarLayout(values)
            .then(
                data => {    
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };
   // function success(data) { return { type: equipoConstants.ELIMINAR_EQUIPO, data } }
}

function enviarNuevoLayOut(layouts,esEquipo,id) {
    return dispatch => {
        const data = {
            layouts:layouts,
            id:id
        }
       if(esEquipo)
         dispatch(actualizarLayoutEquipo(data));
       else
         dispatch(actualizarLayoutVista(data));  
       
    };
    function actualizarLayoutEquipo(data) { return { type: equipoConstants.ACTUALIZAR_LAYOUT_EQUIPO, data } }
    function actualizarLayoutVista(data) { return { type: vistaConstants.ACTUALIZAR_LAYOUT_VISTA, data } }
}

function cambiarEstadoEquipo(idEquipo,nuevoEstado) {
    return dispatch => {
        const data = {
            idEquipo,
            nuevoEstado
        }
        dispatch(success(data));   
    };

    function success(data) { return { type: equipoConstants.ACT_ESTADO_EQUIPO, data } }
}

function eliminarEquipo(values,exito) {
    return dispatch => {
        equipoService.eliminarEquipo(values)
            .then(
                data => { 
                   exito();          
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.ELIMINAR_EQUIPO, data } }
}



function traerTiposEquipo() {
    return dispatch => {
        equipoService.traerTiposEquipo()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.LISTADO_TIPOS_EQUIPO, data } }
}

function traerTiposSensores() {
    return dispatch => {
        equipoService.traerTiposSensores()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.EQUIPO_TRAER_TIPOS_SENSOR, data } }
}

function traerGeneralSensor() {
    return dispatch => {
        equipoService.traerGeneralSensor()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.GENERAL_SENSOR, data } }
}



function getEquipoById(equipo) {
    return dispatch => {
        dispatch(request({ equipo }));
        equipoService.getEquipoById(equipo)
            .then(
                equipo => { 
                    dispatch(success(equipo));                   
                },
                error => {                    
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(equipo) { return { type: equipoConstants.EQUIPO_GET_REQUEST, equipo } }
    function success(equipo) { return { type: equipoConstants.EQUIPO_GET_SUCCESS, equipo } }
    function failure(error) { return { type: equipoConstants.EQUIPO_GET_FAILURE, error } }
}


function getEquiposPU() {
    return dispatch => {
        equipoService.getEquiposPU()
            .then(
                data => { 
                    let sensoresLista = []
                    if(data.misEquipos!==undefined && data.misEquipos.length>0){
                        data.misEquipos.sort(function(a, b){
                            if(a.nombre < b.nombre) { return -1; }
                            if(a.nombre > b.nombre) { return 1; }
                            return 0;
                        })
                        data.misEquipos=data.misEquipos.map((eq, index) => {           
                                if(eq.sensores!==undefined)eq.sensores=eq.sensores.map((sensor, index) => { 
                                    let senCopia = {...sensor};      
                                    sensoresLista.push(senCopia);
                                    sensor={id:senCopia.id,tipoSensor:senCopia.tipoSensor};
                                    return sensor;
                                });
                                return eq;
                        });
                    } 
                    data.sensoresLista=sensoresLista;
                    dispatch(success(data));   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: equipoConstants.EQUIPO_GET_PU_SUCCESS, data } }
   
}



function seleccionarEquipo(seleccionado) {
    return dispatch => {       
         dispatch(success(seleccionado)); 
    };

    function success(seleccionado) { return { type: equipoConstants.EQUIPO_SELECCIONADO,seleccionado} }
}

function limpiarEquipoNuevo() {
    return dispatch => {       
         dispatch(success()); 
    };

    function success() { return { type: equipoConstants.EQUIPO_LIMPIAR_EQUIPO_NUEVO_SUCCESS} }
}

function nuevoValorSensor(json) {
    return dispatch => {       
        try{
            json.valor = JSON.parse(json.valor);
        }catch(err){

        }
        dispatch(success(json.idSensor,json.valor,json.fecha,json.hora,json.minuto,json.segundo)); 
    };

    function success(idSensor,valor,fecha,hora,minuto,segundo) { return { type: equipoConstants.NUEVO_VALOR,idSensor,valor,fecha,hora,minuto,segundo} }
}

function nuevoEstadoSensor(idSensor,conectado) {
    return dispatch => {       
         dispatch(success(idSensor,conectado)); 
    };

    function success(idSensor,conectado) { return { type: equipoConstants.NUEVO_ESTADO_SENSOR,idSensor,conectado} }
}


function agregarEquipoGenerado(data,esNuevo) {
    return dispatch => {      
        esNuevo?dispatch(success(data)):dispatch(successAct(data));   
    };

    function success(data) { return { type: equipoConstants.AGREGAR_EQUIPO_GENERADO,data} }
    function successAct(data) { return { type: equipoConstants.ACTUALIZAR_EQUIPO_GENERADO,data} }
    
}

function agregarEquipoAsociado(idEquipoNuevo,handleExito) {
    return dispatch => {  
        
        equipoService.asociarEquipo(idEquipoNuevo).then(
            data => {                
                if(data.status===3)
                    dispatch(alertActions.enviarExito(data.message));
                
                limpiarEquipoNuevo();  
                handleExito();
            },
            error=>{
              alertActions.error(error.message);
            }
          ); 
        
    };

    function success(data,sensoresLista) { return { type: equipoConstants.AGREGAR_EQUIPO_ASOCIADO,data,sensoresLista} }
}


function grabarEquipoUsuario(values,setGrabando,handleClose) {
    
    return dispatch => {  
        setGrabando(true);
        equipoService.grabarEquipoUsuario(values).then(
            data => {  
              
                if(values.id===''){
                    dispatch(success(data)); 
                    data.carpetas=values.carpeta;
                    if(data.carpetas.esRoot){
                        if(data.carpetas.equipos)
                            data.carpetas.equipos.push({id:data.equipo.id});
                            else
                            data.carpetas.equipos=[{id:data.equipo.id}];                        
                    }else{
                        data.carpetas=values.carpetaRoot;
                        data.carpetas.carpetasHijas.forEach(cp => {                            
                            if(cp.id===values.carpeta.id){
                                cp.equipos?
                                    cp.equipos.push({id:data.equipo.id})
                                    :
                                    cp.equipos=[{id:data.equipo.id}];
                            }
                        });
                        
                    }
                    dispatch(successAgregarCarpetaRoot(data));
                }
                else dispatch(successAct(data)); 
                handleClose();
                setGrabando(false);
            },
            error=>{
                dispatch(alertActions.error(error));
                setGrabando(false);
            }
          );
        
    };

    function successAgregarCarpetaRoot(data) { return { type: equipoConstants.AGREGAR_EQUIPO_CARPETAR_ROOT,data} }

   // function successAgregarCarpeta(data) { return { type: equipoConstants.AGREGAR_EQUIPO_CARPETA,data} }
    function success(data) { return { type: equipoConstants.AGREGAR_EQUIPO_USER,data} }
    function successAct(data) { return { type: equipoConstants.ACTUALIZAR_EQUIPO_USUARIO,data} }
}

function desasociar(equipoLlegado) {
    return dispatch => {
        equipoService.desasociar(equipoLlegado)
            .then(
                equipo => { 
                    dispatch(success(equipoLlegado));

                    dispatch(getEquiposPU());
                    dispatch(vistaActions.getVistasPU());
                    dispatch(accionColectivaActions.getAccionesColectivasPU());
                    dispatch(eventoActions.getEventosPU());
                    dispatch(programaActions.getProgramasPU());

                    history.push('/home/misEquipos')                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(equipoLlegado) { return { type: equipoConstants.DESASOCIAR_EQUIPO, equipoLlegado } }
}
