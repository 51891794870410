import React from 'react';
import { connect } from 'react-redux';
import {Row,Col} from 'react-bootstrap';
import { alertActions,consultaActions, productoActions } from '../../_actions';
import { consultaService } from '../../_services';
import { Formik, Form,  Field } from 'formik';
import {ERR, ErrorMessage, LoadingBoton} from '../../_helpers';
import Select from 'react-select';
import * as Yup from 'yup';

class FormConsulta extends React.Component {
 
    constructor(props){
        super(props);
        if(this.props.accion==='actualizar'){
             this.state.nuevo=false;
        }
     }
 
     state = {
      grabando:false,
         nuevo: true
     };
 
     componentDidMount(){
       if(this.props.tiposProblema===undefined)this.props.traerTiposProblema()
     }

     render(){
        const {tiposProblema,esDeCompra,compra} = this.props;

        let valoresIniciales={
            mensaje: '',
            tipoProblema:{id:''}
        }

         const validationSchema = Yup.object().shape({
            mensaje: Yup.string().min(2, 'Muy corto').max(1000, 'No puede excedor los 100 carácteres').required('Requerido'),
            tipoProblema: Yup.object({id: Yup.string().required(ERR.REQ)})
          });
   
         return(
         
             <div>
                 <Formik
                     enableReinitialize= {true}
                     initialValues={valoresIniciales}
                     validationSchema={validationSchema}
                     onSubmit={(values ) => {
                            if(esDeCompra){
                              values.esDeCompra=true;
                              values.compra=compra;
                            }else  values.esDeCompra=false;    
                            this.setState({grabando:true});                    
                            consultaService.grabarNuevaConsulta(values).then(
                              data => { 
                                this.setState({grabando:false});         
                                this.props.enviarSuccess('Se ha generado exitosamente la consulta.');
                                this.props.agregarNuevaConsulta(data);
                                this.props.handleClose();
                                if(esDeCompra){
                                  compra.pedidoSt = data.pedido;
                                  this.props.actualizarCompra(compra);
                                }
                               
                              },
                              error=>{
                                this.setState({grabando:false});      
                                this.props.enviarError(error);
                              }
                            );
          
                     }}>
                             {({ errors, touched,values,setFieldValue }) => (
                             <Form>
                               <Row>
                                  <Col sm={12}>                                   
                                    <label htmlFor="tipoProblema">Tipo Problema </label>  <ErrorMessage name={`tipoProblema`} />
                                        <Select
                                         theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                          ...theme.colors,
                                            primary25: 'LemonChiffon',
                                            primary: 'gold',
                                          },
                                        })}
                                          value={tiposProblema ? tiposProblema.find(option => option.id === values.tipoProblema.id) : ''}
                                          getOptionLabel={option =>`${option.descripcion}`}
                                          getOptionValue={option => option.id}
                                          onChange={so =>{
                                              setFieldValue(`tipoProblema`,so?so:'')
                                          }}
                                          isSearchable={true}
                                          placeholder="Seleccione uno"
                                          options={tiposProblema}
                                      />   
                                      
                                  </Col>
                                 </Row>
                                <Row>
                                  <Col sm={12}>
                                    <label htmlFor="mensaje">Mensaje </label> <ErrorMessage name={`mensaje`} />                                
                                    <Field className="form-control" name="mensaje" />
                                  </Col>
                                 </Row>
                                 <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                                  <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={()=>this.props.handleClose()}>
                                      <i className="icon-times"></i>  Cerrar
                                  </button>
                                  <button className="btns btns-secundario" type="submit"  disabled={this.state.grabando}>
                                      <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                                  </button>
                                </div> 
                             </Form>
                             )}
                 </Formik>
             </div>
         )};
}

function mapState(state) {
  const {tiposProblema} = state.consultas
    return { tiposProblema };
}

const actionCreators = {
  traerTiposProblema:consultaActions.traerTiposProblema,
  enviarError: alertActions.error,
  enviarSuccess:alertActions.success,
  agregarNuevaConsulta:consultaActions.agregarNuevaConsulta,
  actualizarCompra:productoActions.actualizarCompra
}

const connectedFormConsulta = connect(mapState, actionCreators)(FormConsulta);
export { connectedFormConsulta as FormConsulta };