import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { equipos } from './equipo.reducer';
import { vistas } from './vista.reducer';
import { consultas } from './consulta.reducer';
import { grupo } from './grupo.reducer';
import { notificacion } from './notificacion.reducer';
import { accionColectiva } from './accionColectiva.reducer';
import { evento } from './evento.reducer';
import { programa } from './programa.reducer';
import { producto } from './producto.reducer';


const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  equipos,
  vistas,
  consultas,
  grupo,
  notificacion,
  accionColectiva,
  programa,
  evento,
  producto
});

export default rootReducer;