import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Button} from 'react-bootstrap';
import {FormConsulta} from './';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import { MisConsultasListado } from './MisConsultasListado';
import { consultaActions } from '../../_actions';

class MisConsultas extends React.Component {

    componentDidMount(){
        if(this.props.consultas.misConsultas===undefined)
          this.props.getConsultasPU();
    }
    state={
        show:false
    }

    handleClose = () => {
        this.setState((prevState) => {
            return {show: false};
        });
    };

    handleShow = () => {
        this.setState((prevState) => {
            return {show: true};
        });
    };

    render(){
        return (
            <div className="main-content">
                <Container fluid>
                    <Row className="mb-4 mt-5">
                        <Col sm={12}>       
                            <button className="btns btns-iot text-dark" variant="primary" onClick={this.handleShow}>
                            <span className="material-icons material-icons-outlined">forum</span> Nueva Consulta
                            </button>
                        </Col>                        
                       
                    </Row>        
                    <Row>
                        <Col sm={12}>
                            <MisConsultasListado/>
                       </Col>
                    </Row>
                </Container>

                {this.state.show && <Modal show={this.state.show} onHide={this.handleClose} backdrop="static"  >
                    <Modal.Header className="bg-iot text-dark" closeButton>
                        <Modal.Title><i className="far fa-comments"></i> Nueva consulta</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body><FormConsulta handleClose={this.handleClose}/></Modal.Body>                     
                </Modal>} 
            </div>
        );
    }
}

function mapState(state) {
    const {  consultas } = state;
    return {  consultas };
}
const actionCreators = {
    getConsultasPU:consultaActions.getConsultasPU    
}

const connectedMisConsultas = connect(mapState, actionCreators)(MisConsultas);
export { connectedMisConsultas as MisConsultas };
