export const accionColectivaConstants = {
    ACCION_COLECTIVA_GET_PU_SUCCESS: 'ACCION_COLECTIVA_GET_PU_SUCCESS',
    ACCION_COLECTIVA_SELECCIONADA:'ACCION_COLECTIVA_SELECCIONADA',
    AGREGAR_ACCION_COLECTIVA_GENERADA:'AGREGAR_ACCION_COLECTIVA_GENERADA',
    ACCION_COLECTIVA_ELIMINADA:'ACCION_COLECTIVA_ELIMINADA',
    ACTUALIZAR_ACCION_COLECTIVA:'ACTUALIZAR_ACCION_COLECTIVA',
    EJECUTAR_ACCION_COLECTIVA:'EJECUTAR_ACCION_COLECTIVA',
    ACCIONES_INICIALES:1,
    ACCIONES_FINALIZACION:2,
    ACCION_APAGAR:1,
    ACCION_PRENDER:2,
    ACCION_DIMMER:3,
    ACCION_DESARROLLADOR:4,
    TOOGLE_FAVORITO:'TOOGLE_FAVORITO',
    TOOGLE_FAVORITO_PROGRAMA:'TOOGLE_FAVORITO_PROGRAMA'
};
