import { productoConstants,userConstants } from '../_constants';


export function producto(state = {carritoCompra:[]}, action) {
  switch (action.type) { 
   
    case productoConstants.PRODUCTO_LISTADO_USR:
      return { 
        ...state,
        productosUsr: action.data.productos
      };   
      case productoConstants.DOCUMENT_TYPE_MP:
        return { 
          ...state,
          identificationTypesMP: action.docs
        };       
      case productoConstants.MIS_COMPRAS:
        return { 
          ...state,
          misCompras:action.data.misCompras
        };
    case productoConstants.COMPRA_EXITOSA:
      return { 
        ...state,
        misCompras:state.misCompras===undefined?[action.data.compra]:state.misCompras.concat(action.data.compra),
        compraRealizada: action.data.compra,
        carritoCompra:[]
      };

      case productoConstants.MODIFICAR_COMPRA:
      return { 
        ...state,
        misCompras: state.misCompras.map(c =>
          (c.id === action.data.compra.id)
            ?   action.data.compra
            : c
          )
      }; 

    case productoConstants.ESTADOS_COMPRA:
      return { 
        ...state,
        estadosCompra:action.data.estadosCompra
      };   
    case productoConstants.LIMPIO_COMPRA:
        return { 
          ...state,
          compraRealizada:{}
        };    
    case productoConstants.PRODUCTO_TIPO_SUSCRIPCION:
      return { 
        ...state,
        tiposSuscripcion: action.data.tiposSuscripcion
      };   
   
    case productoConstants.SUSCRIPCION_LISTADO_USR:
      return { 
        ...state,
        suscripcionesUsr: action.data.suscripciones
      };
    case productoConstants.MEDIOS_PAGO_LISTADO:
      return { 
        ...state,
        mediosPago: action.data.mediosPago
      };
    case productoConstants.PRODUCTO_QUITAR:
    return { 
      ...state,
      carritoCompra:state.carritoCompra.filter(prod => prod.producto.id !== action.data.producto.producto.id)     
    };
    case productoConstants.PRODUCTO_SELECCIONAR:
      return { 
        ...state,
        carritoCompra:state.carritoCompra===undefined?[action.data]:state.carritoCompra.concat(action.data)
      };
    case productoConstants.PRODUCTO_CATEGORIA_LISTADO_USR:
      return { 
        ...state,
        catProductosUsr: action.data.categoriasProductos
      };   
    case userConstants.LOGOUT:
      return {};
          
    default:
      return state
  }
}