import { eventoConstants } from '../_constants';
import { eventoService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const eventoActions = {
    getEventosPU,
    agregarEventoGenerado,
    eliminarEvento,
    toogleEvento,
    traerListadoGeneralEvento
};

function getEventosPU() {
    return dispatch => {
        eventoService.getEventosPU()
            .then(
                data => { 
                    data.misEventos.sort(function(a, b){
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    })
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: eventoConstants.EVENTO_GET_PU_SUCCESS, data } }
}

function traerListadoGeneralEvento() {
    return dispatch => {
        eventoService.traerListadoGeneralEvento()
            .then(
                data => {                    
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: eventoConstants.EVENTO_DATA_GENERAL, data } }
}



function toogleEvento(idEvento,estado) {
    let values = {id:idEvento,habilitado:estado}
    return dispatch => {
        eventoService.toogleEvento(values)
            .then(
                estadoNuevo => {                    
                    dispatch(success(values));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(values) { return { type: eventoConstants.EVENTO_TOOGLE_ESTADO, values } }
}


function agregarEventoGenerado(data,esNuevo) {
    return dispatch => {
        if(esNuevo)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: eventoConstants.ACTUALIZAR_EVENTO,data} }
    function success(data) { return { type: eventoConstants.AGREGAR_EVENTO_GENERADO,data} }
}

function eliminarEvento(eventoLlegado) {
    return dispatch => {
        eventoService.eliminarEvento(eventoLlegado)
            .then(
                evento => { 
                    dispatch(success(eventoLlegado));
                    history.push('/home/misEventos')                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(eventoLlegado) { return { type: eventoConstants.EVENTO_ELIMINADO, eventoLlegado } }
}
