import http from '../_helpers/http.service';


export const accionColectivaService = {
    getAccionesColectivasPU,
    grabarAccionColectiva,
    eliminarAccionColectiva,
    ejecutarAccion,
    toogleFavorito
};

function toogleFavorito(values) {    
   var url = '/api/toogleFavorito';    
  return http.post(url,JSON.stringify(values));   
}

function getAccionesColectivasPU() {    
    var url = '/api/accionesColectivas/pu';    
    return http.get(url);   
}

function grabarAccionColectiva(values) {    
   var url = '/api/accionesColectivas/grabar';
   return http.post(url,JSON.stringify(values));    
}

function eliminarAccionColectiva(values) {    
   var url = '/api/accionesColectivas/eliminar';
   return http.post(url,JSON.stringify(values));    
}

function ejecutarAccion(accionColectiva) {    
   var url = '/api/accionesColectivas/ejecutar';
   return http.post(url,JSON.stringify({id:accionColectiva.id}));    
}
