import React from 'react';
import {equipoActions} from '../../_actions';
import { connect } from 'react-redux';
import {LoadingBoton} from '../../_helpers';

 class EquipoAgregar extends React.Component {

    handleConfirmar = () => {

        this.props.agregarEquipoAsociado(this.props.idEquipoNuevo,this.props.handleClose);
    };

    state={
        grabando:false
    }

    componentDidMount(){
        
    }

    render(){
        const { equipos,idEquipoNuevo } = this.props;
        const yaTengoEsteEquipo = equipos.misEquipos?equipos.misEquipos.some(valu=>valu.id===idEquipoNuevo):false;
        return (
            <div>
                {yaTengoEsteEquipo?
                <>
                     <p className="text-oscuro text-center">
                     Usted ya tiene asignado este equipo.
                    </p>
                    <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                    <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={this.props.handleClose}>
                    <i className="icon-times"></i> Cerrar
                    </button>
                    </div>
                </>
             :<>
                    <p className="text-oscuro text-center">
                    Esta por agregar a su cuenta de usuario el equipo ID {idEquipoNuevo}:
                    </p>
                    <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                    <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={this.props.handleClose}>
                    <i className="icon-times"></i> Cerrar
                    </button>
                    <button className="btns btns-secundario" type="submit" disabled={this.state.grabando} onClick={this.handleConfirmar}>
                      <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                    </button>
                    </div>
            </>}
                

            </div>
        );
    }
}

function mapState(state) {
    const {equipos} = state;
    return {equipos};
}

const actionCreators = {  
    agregarEquipoAsociado:equipoActions.agregarEquipoAsociado
    
}

const connectedEquipoAgregar = connect(mapState, actionCreators)(EquipoAgregar);
export { connectedEquipoAgregar as EquipoAgregar };