import React from 'react';
import { connect } from 'react-redux';
import Sensor from '../../_components/Sensor/Sensor';
import {isMobile} from "react-device-detect";
import {Col, Row,Tab,Tabs} from 'react-bootstrap';
import {EditarEquipo} from './EditarEquipo';
import {EditarVistaDesdeSensor} from '../Vista';
import {TablaSensor} from '../Sensor/';
import { equipoConstants } from '../../_constants';
import { equipoActions, utilActions } from '../../_actions';
import { TIPO_VISTA,MODO_LAYOUT } from '../../_helpers';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
const ResponsiveGridLayout = WidthProvider(Responsive);

class VistaDesarrollador extends React.Component {
    
    constructor(props){
        super(props);
        this.state.url = window.location.origin;    
        this.wrapperRef = React.createRef();  
        this.gridRef = React.createRef();  
        this.inputRefCheckbox = React.createRef();  

        this.actualizarLayout = _.debounce(this.actualizarLayout, 300);
    }

    actualizarLayout=(values)=> {
        this.props.guardarLayout(values);
    }

    onLayoutChange=(layout, layouts)=> {
        layouts.forzar=null;
        const values ={
            id:this.props.seleccionado.id,
            esEquipo:this.props.tipoVista===TIPO_VISTA.EQUIPO,
            layouts:layouts
        }
        this.actualizarLayout(values);
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target))           
            this.inputRefCheckbox.current.checked=false;
        
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    state={
        url:'',
        active:false,
        alertInside:null,
        renderizo:false
     }

    definirModoLayOut=(layouts,key)=>{
        if(!isMobile)return MODO_LAYOUT.PC;
        else{
            const element= layouts.xs.find(valu => valu.i===key);     
            if(element && (element.w===6 || element.h===1))return MODO_LAYOUT.CELU_MINI;
            else return MODO_LAYOUT.CELU_LARGE;
        }      
    } 

    
    render(){
        const {seleccionado,equipos}=this.props;
        const sensoresLista = utilActions.llenarLista(seleccionado.sensores,equipos.sensores);
        
        
        let layouts =undefined;

        if(seleccionado.layouts){
            layouts=JSON.parse(seleccionado.layouts);
            if(layouts.forzar){                               
               this.props.finalizarForzado(seleccionado,layouts);
            }
        }
       
        const colsRes={lg: 12, md: 12, xs: 12};
        const breakpoints={lg: 1200, md: 996, xs: 0};

        if(sensoresLista && layouts===undefined){
            layouts = {};
            utilActions.armarLayouts(layouts,sensoresLista);           
        }else{
            utilActions.armarLayoutsNuevoElemento(sensoresLista,layouts);
        }

        return (
            <div className="cont-sensor">
            <Col sm={12}>

                <Row className="bordes">
                    <Col className="mb-4 col-auto">
                         <h3 className=" mt-5"><i className="icon-sensor text-white"></i> <span className="mt-2 font-weight-light">{seleccionado.nombre}</span></h3>
                        
                    </Col>

                    <Col className="mb-4 col-auto" ref={this.wrapperRef}>
                        <div className="menu-floating">                        
                            <div className="fab-wrapper">
                                    <input id="fabCheckbox" ref={this.inputRefCheckbox}  type="checkbox" className="fab-checkbox" />
                                    <label className="fabs" htmlFor="fabCheckbox">
                                        <span className="fab-dots fab-dots-1"></span>
                                        <span className="fab-dots fab-dots-3"></span>
                                    </label>
                                    <div className="fab-wheel">
                                        <ul>
                                            {this.props.tipoVista===TIPO_VISTA.EQUIPO &&
                                                <EditarEquipo equipo={seleccionado}/>}
                                            {this.props.tipoVista===TIPO_VISTA.VISTA &&
                                                <EditarVistaDesdeSensor vista={seleccionado} equipos={equipos}/>
                                            } 
                                        </ul>
                                    </div>
                                </div>
                        </div>
                    </Col>
                </Row>
            </Col>

            <div className="tabs-custom mt-4">
                <Tabs  className="nav nav-pills justify-content-end nav-tabs mb-4"  defaultActiveKey="dashboard" id="uncontrolled-tab-example">            
                        <Tab eventKey="dashboard" title={<div>Dashboard</div>}>                            
                            <div className="justify-content-center th_recursos">
                                <ResponsiveGridLayout
                                    compactType={'vertical'}
                                    draggableHandle=".draggableZoneIot"
                                    className="layout" layouts={layouts}
                                    onLayoutChange={(layout,layouts)=>this.onLayoutChange(layout,layouts)}
                                    breakpoints={breakpoints}
                                    cols={colsRes}
                                    rowHeight={(isMobile? 130:245)}
                                    >                               
                                    {sensoresLista && utilActions.pasarListaSensoresAShadows(sensoresLista).map((item, index) => (
                                        <div key={item.idShadow.toString()}>                              
                                            <Sensor layouts={layouts} 
                                            modoLayout={this.definirModoLayOut(layouts,item.idShadow.toString())} 
                                            sensor={item} 
                                            modo={equipoConstants.TAB_DASHBOARD} 
                                            seleccionado={seleccionado}/>                                      
                                        </div>   
                                    ))}                                     
                                </ResponsiveGridLayout >
                            </div>
                        </Tab>
                        <Tab eventKey="list" title={<div>Listado</div>}>
                            <TablaSensor seleccionado={seleccionado} />
                        </Tab>                    
                </Tabs>
            </div>

            {(sensoresLista===undefined || sensoresLista.length===0 )&&
            <Row className="justify-content-center  th_verde-azulado">
                <Col className="card-components border-left-c  fadeIn mt-2" xs={12} md={7} lg={5}>
                    <Row className="p-3 ">
                        <Col xs={2} className="text-center p-2">
                        <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                        </Col>
                        <Col xs={10} className="text-right p-2">
                            <br></br> <span className="text-muted">No hay widgets cargados. Crea uno nuevo desde el menú desplable </span>
                        </Col>
                    </Row>
                </Col>
            </Row>}

            {this.state.alertInside}   
    </div> 

        );
    }
}

function mapState(state) {       
    const { equipos,vistas } = state;
    return { equipos,vistas };
}

const actionCreators = {
    guardarLayout:equipoActions.guardarLayout,
    finalizarForzado:equipoActions.finalizarForzado  
}

const connectedVistaDesarrollador = connect(mapState, actionCreators)(VistaDesarrollador);
export { connectedVistaDesarrollador as VistaDesarrollador };

