import React from 'react';
import { connect } from 'react-redux';
import {Modal} from 'react-bootstrap';
import { userActions } from '../../_actions';
import {C2C} from 'react-c2c';
const appName=process.env.REACT_APP_NAME;
class ModalCompartirEquipo extends React.Component {

    state={
        alert:null,
        url:window.location.origin
    }
    
render() {
  const {equipo} = this.props;
  return(

          <Modal show={this.props.show} onHide={()=>this.props.handleClose()}>
              <Modal.Header className="bg-whatt text-white" closeButton>
                  <Modal.Title><i className="fab fa-whatsapp"></i> Compartir</Modal.Title>
              </Modal.Header> 
              <Modal.Body className="th_whatt">
              <p className="text-oscuro"> 
                Puedes pasarle el siguiente link a cualquier persona con la que deseas compartir.
              </p>
                        <div className="mb-5">
                        <C2C 
                            text={`${this.state.url}${appName}/home/equipoNuevo?id=${equipo.id}`}
                            render={({ copied, handleClick }) =>
                            copied
                                ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                                : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                                }/>
                            <input value={`${this.state.url}${appName}/home/equipoNuevo?id=${equipo.id}`}
                            onChange={({target: {value}}) => this.setState({value, copied: false})} />
                        </div>
              </Modal.Body>                     
          </Modal> 
  )};
}

function mapState(state) { 
    return {};
}

const actionCreators = {
}

const connectedModalCompartirEquipo = connect(mapState, actionCreators)(ModalCompartirEquipo);
export { connectedModalCompartirEquipo as ModalCompartirEquipo };