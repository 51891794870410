import React from 'react';
import { connect } from 'react-redux';
import { alertConfirm, history, Role, RoleID, tengoRol } from '../../_helpers';
import Modal from 'react-bootstrap/Modal';
import { FormEditarSensor } from './FormEditarSensor';
import { vistaActions, sensorActions, alertActions } from '../../_actions';
import {Row,DropdownButton,ButtonGroup,Dropdown,NavDropdown,ListGroup,Tabs,Tab } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import ReactTooltip from 'react-tooltip';
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';

import {C2C} from 'react-c2c';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';


class EditarSensor extends React.Component {
    
    constructor(props){
        super(props);
     }

    state = {
        show: false,
        showMasInfo:false,
        alert: null
    };

    cerrarAlert = () => {
        this.setState({alert: null});
    }   

    handleClose = () => {
        this.setState((prevState) => {
            return {show: false};
        });
    };

    handleShow = () => {
        this.setState((prevState) => {
            return {show: true};
        });
    };

    handleCloseMasInfo = () => {
        this.setState((prevState) => {
            return {showMasInfo: false};
        });
    };

    handleShowMasInfo = () => {
        this.setState({showMasInfo: true});
    };

    eliminarSensorDeVista = () => {
      this.props.eliminarSensorDeVista(this.props.seleccionado,this.props.sensor);
    };

    mostrarConfirmacionEliminar=()=>{
        const alert=alertConfirm('¿Eliminar?',
            `¿Está seguro que desea eliminar el sensor?`,
            'Sí, eliminar',
            'No',
            this.props.clearAlertConfirm,
            ()=>this.eliminarSensorDeveloper(),
            null,
            true
            ) 

        this.props.enviarAlertConfirm(alert);
    }

    eliminarSensorDeveloper = () => {
        this.props.eliminarSensorDeveloper(this.props.sensor);
        this.props.clearAlertConfirm();
    };
   

    render(){
        const MQTTURL=process.env.REACT_APP_MQTT_URL;
        const APPURL=process.env.REACT_APP_SERVER_URL;       
        const {sensor,equipos,seleccionado,modoSuperSimple} =this.props;
        const pathName  = history.location.pathname;
        const {misEquipos} = equipos;
        const equipo = misEquipos.find(equ=>equ.id==sensor.idEquipo);
        const apikey = equipo?equipo.apikey:'';
        const linkInfoSensor = '/home/infoSensor';
        const soyDeveloperdeEsteEquipo = seleccionado?seleccionado.idRol===RoleID.DEV:false;
        const jsonServicio={
            "clientId":sensor.idEquipo,
            "apikey":apikey,
            "topic":sensor.nombreNexo
        };

        const jsonRespuestaServicio={
            "status":"1",
            "valor":"33.12",
        };

        const jsonRespuestaMalServicio={
            "status":"2",
            "error":"Error message"
        };

        
        return (
            <>
            {modoSuperSimple?
            <div>
                <NavDropdown
                    key={'down'}
                    id={`dropdown-button-drop-down`}
                    drop={'down'}
                    title={<div><i className="fas fa-cog options"></i></div>}
                >
                    <NavDropdown.Item eventKey="1" onClick={()=>this.handleShowMasInfo()}><i className="fas fa-info-circle  align-middle"></i></NavDropdown.Item>
                    <NavDropdown.Item eventKey="2" onClick={this.handleShow}><i className="fas fa-pencil-alt  align-middle cursor"></i></NavDropdown.Item>
                    {(pathName==='/home/listaVista' || (pathName==='/home/listaEquipo' && soyDeveloperdeEsteEquipo)) && <NavDropdown.Item eventKey="3" onClick={pathName==='/home/listaVista'?()=>this.eliminarSensorDeVista():()=>this.mostrarConfirmacionEliminar()}><i className="fas fa-trash-alt align-middle cursor"></i></NavDropdown.Item>}
                    <NavDropdown.Item eventKey="4"><Link  to={linkInfoSensor+'?id='+sensor.id}><i className="icon-stats-dots align-middle"></i></Link> </NavDropdown.Item>          
                    
                
                </NavDropdown>
            </div>
            :
            <div className="">      
                 {soyDeveloperdeEsteEquipo && 
                    <>
                        <a data-tip data-for='info'><i className="fas fa-info-circle mr-2 text-muted" onClick={()=>this.handleShowMasInfo()}></i></a>
                        <ReactTooltip place="bottom" id='info' aria-haspopup='true' >
                            <span>INFO</span>
                        </ReactTooltip>
                        <a data-tip data-for='edit'>
                            <i className="fas fa-pencil-alt mr-2 text-muted cursor" onClick={this.handleShow}></i>  
                        </a>                        
                        <ReactTooltip place="bottom" id='edit' aria-haspopup='true' >
                            <span>Editar</span>
                        </ReactTooltip>
                    </>
                }    
                 {pathName==='/home/listaVista' && 
                    <>
                        <a data-tip data-for='elimina'>
                            <i className="fas fa-trash-alt mr-2 text-muted cursor" onClick={()=>this.eliminarSensorDeVista()}></i>
                        </a>
                        <ReactTooltip place="bottom" id='elimina' aria-haspopup='true' >
                            <span>Eliminar</span>
                        </ReactTooltip>
                    </> 
                 } 
                 {(pathName==='/home/listaEquipo' && soyDeveloperdeEsteEquipo) && 
                        <><a data-tip data-for='elimina2'>
                         <i className="fas fa-trash-alt mr-2 text-muted cursor" onClick={()=>this.mostrarConfirmacionEliminar()}></i>
                         </a>
                         <ReactTooltip place="bottom" id='elimina2' aria-haspopup='true' >
                         <span>Eliminar</span>
                        </ReactTooltip>
                        </>
                 }  
                                      
             </div>  
            }
   {this.state.show &&<FormEditarSensor esAdmin={false} show={this.state.show} sensor={sensor} handleClose={this.handleClose}/>}      

{this.state.showMasInfo &&                     
<Modal show={this.state.showMasInfo} size="lg" onHide={()=>this.handleCloseMasInfo()} scrollable={true}>
    <Modal.Header className="bg-recursos" closeButton>
        <Modal.Title>Información de widget {sensor.nombre}</Modal.Title>
    </Modal.Header> 
    <Modal.Body className="th_recursos copiar-topic">

    <Tabs defaultActiveKey="conexion" id="uncontrolled-tab-example">
  <Tab eventKey="conexion" title="Conexión">
  <Row className="mb-5 mt-5">
            <Col xs={12} className="mb-4">
                <div>
                <h5>Url server</h5>
                <C2C
                text={MQTTURL}
                render={({ copied, handleClick }) =>
                    copied
                    ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                    : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                }
                />
                <input value={MQTTURL}
                onChange={({target: {value}}) => this.setState({value, copied: false})} />
                </div>
            </Col>                         
            <Col xs={12} className="mb-4">
                <div>
                <h5>Topic</h5>
                <C2C
                text={sensor.nombreNexo}
                render={({ copied, handleClick }) =>
                    copied
                    ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                    : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                }
                />
                <input value={sensor.nombreNexo}
                onChange={({target: {value}}) => this.setState({value, copied: false})} />
                </div>
            </Col>
            <Col xs={12} className="mb-4">
                <div>
                <h5>Usuario</h5>
                <C2C
                text={sensor.idEquipo}
                render={({ copied, handleClick }) =>
                    copied
                    ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                    : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                }
                />
                <input value={sensor.idEquipo}
                onChange={({target: {value}}) => this.setState({value, copied: false})} />
                </div>
            </Col>
            <Col xs={12} className="mb-4">
                <div>
                <h5>Password</h5>
                <C2C
                text={apikey}
                render={({ copied, handleClick }) =>
                    copied
                    ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                    : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                }
                />
                <input value={apikey}
                onChange={({target: {value}}) => this.setState({value, copied: false})} />
                </div>
            </Col>
         </Row>  
  </Tab>
  <Tab eventKey="adicional" title="Funcionalidad Adicional">
  <Row className="mb-5 mt-5">
                <Col xs={12} className="mb-4">
                    <div>
                    <h5>URL servicio de último valor</h5>
                    <C2C
                    text={APPURL+'/lastValue'}
                    render={({ copied, handleClick }) =>
                        copied
                        ? <div className="text-right flash"><span className="text-success font-weight-bold"><span className="material-icons-outlined align-middle">done</span> Copiado!</span></div>
                        : <div className="text-right"><button className="btn btn-link text-muted cursor" onClick={handleClick}><span><span className="material-icons-outlined align-middle">file_copy</span> Copiar</span></button></div>
                    }
                    />
                    <input value={APPURL+'/lastValue'}
                    onChange={({target: {value}}) => this.setState({value, copied: false})} />
                    </div>

        
                </Col>

                <Col xs={12} className="mb-4 ">
                <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <span className="font-weight-bold">HTTP GET Ej:</span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <small>
                                     Request:
                                    </small>
                                    <CodeMirror
                                    value={JSON.stringify(jsonServicio, null, 2) }
                                    options={{
                                        theme: 'monokai',
                                        autoRefresh:true,
                                        keyMap: 'sublime',
                                        mode: 'jsx',
                                        tabSize: 2,
                                        readOnly:true,
                                    }}
                                    />
                                    <small>
                                    Response OK (http status 200):
                                    </small>
                                    <CodeMirror
                                    value={JSON.stringify(jsonRespuestaServicio, null, 2) }
                                    options={{
                                        theme: 'monokai',
                                        autoRefresh:true,
                                        keyMap: 'sublime',
                                        mode: 'jsx',
                                        tabSize: 2,
                                        readOnly:true,
                                    }}
                                    />
                                     <small>
                                     Response Failure (http status 401 or 200):
                                    </small>
                                    <CodeMirror
                                    value=  {JSON.stringify(jsonRespuestaMalServicio, null, 2) }
                                    options={{
                                        theme: 'monokai',
                                        autoRefresh:true,
                                        keyMap: 'sublime',
                                        mode: 'jsx',
                                        tabSize: 2,
                                        readOnly:true,
                                    }}
                                    />
                                            
                                            
                                              
                                        
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                </Col>
         </Row> 
  </Tab>
</Tabs>
        
         
    </Modal.Body>                     
</Modal>}             
</>
        )
    }
}

function mapState(state) {
    const { vistas,equipos } = state;
    return { vistas,equipos };
}

const actionCreators = {   
    eliminarSensorDeVista:vistaActions.eliminarSensorDeVista,
    eliminarSensorDeveloper:sensorActions.eliminarSensorDesarrollador,
    enviarAlertConfirm:alertActions.enviarAlertConfirm,
    clearAlertConfirm:alertActions.clearAlertConfirm
}

const connectedEditarSensor = connect(mapState, actionCreators)(EditarSensor);
export { connectedEditarSensor as EditarSensor };