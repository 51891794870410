import http from '../_helpers/http.service';

export const sensorService = {
    getUltimoValor,
    getHistorial,
    actualizarSensorUsuario,
    agregarSensorDesarrollador,
    enviarASensor,
    eliminarSensorDesarrollador,
    eliminarRegistro,
    actualizarHistorialWidget
};
function enviarASensor(sensor,texto,longitud) {
    sensor= Object.assign(sensor, {'texto':texto,'longitud':longitud});    
   
    var url = '/api/sensor/enviarDeveloper';        
    return http.post(url,JSON.stringify(sensor));    
}



function actualizarHistorialWidget(values) {   
    var url = '/api/sensor/historialWidget';
    return http.post(url,JSON.stringify(values));     
}

function getUltimoValor(idSensor) {   
    var url = '/api/sensor/ultimo';
    return http.post(url,JSON.stringify({idSensor:idSensor}));     
}

function getHistorial(idSensor,tipo,fechaDesde,fechaHasta) {
    var url = '/api/sensor/historial';
    return http.post(url,JSON.stringify({idSensor:idSensor,tipo,fechaDesde,fechaHasta}));       
}

function eliminarRegistro(values) {    
    var url = "/api/sensor/eliminarRegistro";        
    return http.post(url,JSON.stringify(values));    
}


function actualizarSensorUsuario(sensor) {
   
    var url = '/api/sensor/actualizarLabel';        
    return http.post(url,JSON.stringify(sensor));    
    
}


function agregarSensorDesarrollador(params) {
   
    var url = '/api/equipo/agregarSensorDesarrollador';

    return http.post(url,JSON.stringify(params));        
    
}

function eliminarSensorDesarrollador(params) {
   
    var url = '/api/equipo/eliminarSensorDesarrollador';
        
    return http.post(url,JSON.stringify(params));
    
}
