import React from 'react';

export default class Logo extends React.Component {    
    

    render(){
        const {empresa} = this.props;
        const appName=process.env.PUBLIC_URL;
        const logoUrl = empresa!==undefined?empresa.logoUrl:undefined;

        let logoAlt = "Logo";
        let logoSrc = logoUrl!==undefined && logoUrl !==''?logoUrl:appName+'/images/logo.svg';
        let logoSrc2 = logoUrl!==undefined && logoUrl !==''?logoUrl:appName+'/images/logot.svg';

        
        return (
            <div>
                <img className="d-none d-lg-block d-xl-block"
                    src={logoSrc}
                    alt={logoAlt}
                />
         
                <img className="d-block d-lg-none d-xl-none"
                    src={logoSrc2}
                    alt={logoAlt}
                />
        </div>
        );
    }
}