import React from 'react';
import { connect } from 'react-redux';
import {Modal,Container,ListGroup,Col,Button,Card,Row,Tab,Tabs,Badge} from 'react-bootstrap';
import Moment from 'react-moment';
import { userActions } from '../../_actions';
import { alertConfirm } from '../../_helpers';

class ModalDetalleLicencia extends React.Component {


    state={
        alert:null
    }

    cerrarAlert = () => {
        this.setState({alert: null});
    } 

    cancelar = () => {      
        this.props.cancelarSuscripcionUser(this.cerrarAlert);
    };

    cancelarSuscripcion = () => {
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea cancelar la suscripción?`,
            'Sí, cancelar',
            'No',
            this.cerrarAlert,
            this.cancelar,
            null,
            true
        )  
        this.setState({
            alert: getAlert
        });
    }
    
    
render() {
  const {userToken,user} = this.props;
  return(

          <Modal show={this.props.show} onHide={()=>this.props.handleClose()}>
              <Modal.Header className="bg-gradient-equipos  text-white" closeButton>
                  <Modal.Title>Detalles de tu licencia</Modal.Title>
              </Modal.Header> 
              <Modal.Body>
              <Card.Text className="text-dark text-center">
                  <small className="text-muted font-weight-bold">Hola! Tu licencia se encuentra activa.
              Tipo de licencia</small>
              <h5><span className="badge bg-success text-white"><i className="fas fa-check"></i>  {userToken.tipoSuscripcion.descripcion}</span></h5>
             
              <br/>
              Esta incluye:
              <h5><span className="text-secundario font-weight-light"><i className="icon-sensor3"></i><span className="text-secundario font-weight-bold">{userToken.cantidadDispositivos}</span> Dispositivos </span> <Badge>{userToken.cantEquiposAsignados} Asignados</Badge></h5>
              <h5><span className="text-secundario font-weight-light"><i className="iotech-icon icon-sensor"></i><span className="text-secundario font-weight-bold">{userToken.cantidadSensores}</span> Widgets </span> <Badge>{userToken.cantSensoresAsignados} Asignados</Badge></h5>
              <h5><span className="text-secundario font-weight-light"><i className="icon-admin"></i><span className="text-secundario font-weight-bold">{userToken.cantidadUsuarios}</span> Usuarios </span> <Badge>{userToken.cantUsuariosAsignados} Asignados</Badge></h5>
              {userToken.cancelacionSolicitada &&
              <div>
                        La cancelacion se ha registrado exitosamente.
                        Con el ultimo pago realizado, la misma se encuentra habilitada hasta el 
                        <Moment date={userToken.fechaHasta} format="DD/MM/YYYY"/>
              </div>
              }
              </Card.Text>
              

        
              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                 {(!userToken.cancelacionSolicitada && !user.tieneUsuarioPadre) &&                
                    <button className="btns btns-outline-danger transparent" onClick={()=>this.cancelarSuscripcion()}><span className="material-icons-outlined align-middle">highlight_off</span> Cancelar suscripción</button>}
                <br/>
                {(userToken.esGratuito  && !user.tieneUsuarioPadre) &&
                    <>
                        EN PERIODO DE PRUEBA. Vence el  <Moment date={userToken.fechaHasta} format="DD/MM/YYYY"/>             
                        <button className="btns btns-danger" onClick={()=>this.props.pagarSuscripcion()}>Pagar suscripción</button>
                    </>
                }    

                <Card.Text className="small text-muted text-center mt-4">
                ¿Querés más?
                ¡Podes pasar por nuestra tienda para mejorar tu suscripción o compras mas equipos!
                {this.state.alert}
                </Card.Text>
              </div>
              </Modal.Body>                     
          </Modal> 
  )};
}

function mapState(state) { 
    const { userToken,user } = state.authentication;
    return { userToken,user };
}

const actionCreators = {
    cancelarSuscripcionUser:userActions.cancelarSuscripcionUser
}

const connectedModalDetalleLicencia = connect(mapState, actionCreators)(ModalDetalleLicencia);
export { connectedModalDetalleLicencia as ModalDetalleLicencia };