export const sensorConstants = {
    TEMPERATURA : 1, 
    HUMEDAD : 2,
    SWITCH : 3, 
    DIMMER : 4,
    LM35 : 5,
    BUZZER : 6,
    LDR : 7, 
    RELE : 8,
    LUMINARIA : 9,
    DS18B20 : 10,
    GPS : 11,
    DESARROLLADOR : 12,
    TIPO_WIDGET_TOOGLE : 1,
    TIPO_WIDGET_TACOMETRO : 2,
    TIPO_WIDGET_TERMOMETRO : 3,
    TIPO_WIDGET_LIQUIDO : 4,
    TIPO_WIDGET_TEXTO:5,
    TIPO_WIDGET_MAPA:6,
    TIPO_WIDGET_DIMMER:7,
    TIPO_WIDGET_GRAFICO:8,
    SELECCIONAR_SENSOR:'SELECCIONAR_SENSOR',
    ACTULIZAR_SENSOR_USER:'ACTULIZAR_SENSOR_USER',
    ACTULIZAR_SENSOR_DEVELOPER:'ACTULIZAR_SENSOR_DEVELOPER',
    NUEVO_SENSOR_DEVELOPER:'NUEVO_SENSOR_DEVELOPER',
    ELIMINAR_SENSOR_DEVELOPER:'ELIMINAR_SENSOR_DEVELOPER',
    ACTULIZAR_TIPOS_SENSOR:'ACTULIZAR_TIPOS_SENSOR',
    ELIMINAR_REGISTRO:'ELIMINAR_REGISTRO',
    style:[{},
            {icono:'icon-temperatura', color:'th_cielo',unidad:'°'},
            {icono:'icon-humedad', color:'th_fucsia',unidad:'%'},
            {icono:'icon-switch', color:'th_tomate',unidad:''},
            {icono:'icon-dimmer1', color:'th_verde-azulado',unidad:''},
            {icono:'icon-chevron-right-outline', color:'th_recursos',unidad:'°'},
            {icono:'icon-buzzer', color:'th_tomate',unidad:'°'},
            {icono:'icon-ldr', color:'th_fucsia',unidad:'°'},
            {icono:'icon-chevron-right-outline', color:'th_cielo',unidad:'°'},
            {icono:'icon-lamparita', color:'th_maiz',unidad:'°'},
            {icono:'icon-chevron-right-outline', color:'th_lima',unidad:'°'},
            {icono:'icon-icon-location', color:'th_recursos',unidad:'Ubic'},
            {icono:'icon-rss_feed', color:'th_recursos',unidad:'u'}
        ]
};
