import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FieldArray  } from 'formik';
import * as Yup from 'yup';
import { vistaService } from '../../_services';
import {ErrorMessage,LoadingBoton, Role, tengoRol} from '../../_helpers';
import {Row,Col,Accordion,Card} from 'react-bootstrap';
import {alertActions,equipoActions,utilActions,vistaActions} from '../../_actions';

class FormNuevaVista extends React.Component {
  
  componentDidMount(){
  }

  state = {
      esNuevo:true,
      grabando:false
  };
  
render() {
      const {vista,equipos} = this.props;
      const sensoresL = equipos.sensores;
     // const sensoresL = utilActions.pasarListaSensoresAShadows(sensoresLSimple);
      const soyDesarrolador =tengoRol([Role.DEV]);
      let inic={
        id:'',
        nombre: '',
        sensores: []
      }
     
      const validationSchema = Yup.object().shape({
        nombre: Yup.string()
          .min(2, 'Muy corto')
          .max(50, 'Muy largo')
          .required('Requerido'),
        sensores: Yup.array().min(1,'Debe seleccionar al menos 1 sensor')  

      });

      if(vista!==undefined && vista!=={})inic=utilActions.setearValores(inic,vista);

return(

  <div>
    <Formik
      enableReinitialize= {true}
      initialValues={inic}
      validationSchema={validationSchema}
      onSubmit={values => {
          this.setState({grabando:true});
          vistaService.grabarVista(values).then(
            data => {  
                this.props.agregarVistaGenerada(data,values.id==='');
                this.props.handleClose();
                this.setState({grabando:false});
            },
            error=>{
              this.setState({grabando:false});
              this.props.enviarError(error);
            }
        );

      }}
    >
      {({ errors, touched,values }) => (
        
        <Form>
          <Row>
            <Col xs={12}>
            <label htmlFor="nombre">Nombre de la vista </label><ErrorMessage name={`nombre`} />
            <Field name="nombre" />
            </Col>
          </Row>
          <Row className="mt-2">
          <Col xs={12}>
          <FieldArray
                  name="sensores"
                  render={arrayHelpers => (
                      <div className="mt-4 mb-4">
                         <Accordion defaultActiveKey="0">
                         {equipos.misEquipos!==undefined && equipos.misEquipos.map((item, index)=>
                            <Card key={item.id}>
                              <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                                  Sensores de {item.nombre}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={item.id}>
                                <Card.Body>
                                   {item.sensores!==undefined && item.sensores.map((sensor, index2)=>
                                      <div key={sensor.id}>                                        
                                        <input
                                            className="option-input checkbox"
                                            name="sensor"
                                            type="checkbox"
                                            value={sensor}
                                            checked={values.sensores.some(se=>se.id===sensor.id)}
                                            onChange={e => {
                                              if (e.target.checked) {
                                                arrayHelpers.push(sensor);
                                              } else {
                                                const idx = values.sensores.findIndex(se=>se.id===sensor.id);
                                                arrayHelpers.remove(idx);
                                              }
                                            }}
                                        />
                                         <label className="label-check">{utilActions.extraerDeLista(sensor.id,sensoresL).labelUsuario} {!soyDesarrolador && ' - '+sensor.tipoSensor.descripcion}</label>
                                        </div>
                                    )}

                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          )}

                          </Accordion>
                      </div>
                  )}
                 
              />
          
              <ErrorMessage name={`sensores`} />
             
                </Col>
          </Row>

              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                  <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                      <i className="icon-times"></i>  Cerrar
                  </button>
                  <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                      <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                  </button>
              </div>
             
        </Form>
      )}
    </Formik>
  </div>
)};
}
//export { FormNuevaVista as FormNuevaVista };

function mapState(state) {
  const { vistas,equipos } = state;
  return { vistas,equipos };
}

const actionCreators = {
  seleccionarVista:vistaActions.seleccionarVista,
  agregarVistaGenerada:vistaActions.agregarVistaGenerada,
  enviarError:alertActions.enviarError,
  eliminarVista:vistaActions.eliminarVista,
  getEquiposPU: equipoActions.getEquiposPU
}

const connectedFormNuevaVista = connect(mapState, actionCreators)(FormNuevaVista);
export { connectedFormNuevaVista as FormNuevaVista };