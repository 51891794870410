import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {errorConstants} from '../../_constants';
import {history, LoadingBoton} from '../../_helpers'; 
import { alertActions } from '../../_actions';
import { userActions } from '../../_actions';
import { userService } from '../../_services';
import BeatLoader from "react-spinners/BeatLoader";
import {Tabs,Tab,Button,Card,Container,Col,Row} from 'react-bootstrap';
import { GoogleLogin } from 'react-google-login';
import {GoogleReCaptcha,GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import { FormDatosUsuario } from '../../_components/Perfil/FormDatosUsuario';
import { FormCambiarPassword } from '../../_components/Perfil';
const appName=process.env.REACT_APP_NAME;
const gkey=process.env.REACT_APP_RECAPTCHA;
class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.history.location.state && this.props.history.location.state.from==='logout'){
            history.push(`/login`, { from: 'login' } );
            window.location.reload();
        }       
        this.props.logout();
        let esEquipoNuevo = false;
        let idEquipoNuevo = '';
        let codigoMail = '';

        let params2 = '';
       
        if (this.props.location!==undefined){
            params2 =  queryString.parse(this.props.location.search);
        }
       
        if (this.props.location.state!==undefined && this.props.location.state.from.pathname==='/home/equipoNuevo'){
            esEquipoNuevo=true;    
            let params = queryString.parse(this.props.location.state.values);  
            idEquipoNuevo = params.id;      
            this.props.enviarError("Para poder agregar este equipo, debe loguearse, o bien registrar un nuevo usuario.");
            this.state.esEquipoNuevo=esEquipoNuevo;
            this.state.idEquipoNuevo=idEquipoNuevo;
        }        
        if(params2.codigoMail)codigoMail=params2.codigoMail;
        this.state.codigoMail=codigoMail;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);      
        this.recuperarPassword = this.recuperarPassword.bind(this);
        
        if(params2.idSuscripcion)
           this.state.contratacion=true;
        
    }

    state = {
        username: '',
        password: '',
        submitted: false,
        esEquipoNuevo:undefined,
        idEquipoNuevo:undefined,
        codigoMail:undefined,
        mailValidado:false,
        linkVencido:false,
        token:'',
        hash:undefined,
        showRecPassword:false,
        grabando:false,
        mostrar:false,
        contratacion:false,
        idSuscripcion:undefined
    };

    handleClose = () => {
        this.setState({showRecPassword: false});
    };

    recuperarPassword(){
        
        if (this.state.username!==''){
            this.setState({grabando: true});
            userService.recuperarPassword(this.state.username).then(
                data => {             
                    this.setState({grabando: false,hash:data.hash});   
                    this.props.enviarSuccess('Hemos enviado un codigo a su casilla de correo.');
                    this.setState({showRecPassword:true});
                    
                },
                error=>{
                    this.setState({grabando: false});        
                    this.props.enviarError(error);
                }
              ); 
        }else{
            this.props.enviarError('Debe ingresar un usuario válido');
        }
        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        let par = {};
       
        if (this.props.location!==undefined){
            par =  queryString.parse(this.props.location.search);
        }
        
        if(par.idSuscripcion){
           this.setState({contratacion:true});
           this.props.guardarContratacion(par.idSuscripcion,par.esGratis);
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({ submitted: true });
        const { username, password,idEquipoNuevo,esEquipoNuevo,token } = this.state;
        
        if(token==='')
            alert('Debe completar el captcha')
        
        if (username && password && token!=='') {
            this.props.login(username, password,idEquipoNuevo,esEquipoNuevo,true);
        }
    }

    cargarGtoken(token) {
        this.setState({ token: token });
        
    }

    errorGtoken(error) {
       console.log('ERROR GTOKEN:',error);
    }

    

    responseGoogleSuc = (response) => {
        const {idEquipoNuevo,esEquipoNuevo } = this.state;
        this.props.loginGoogle(response,idEquipoNuevo,esEquipoNuevo);
    }

    responseGoogleFail = (response) => {
        console.log('FALLO DE GOOGLE:',response);
    }
    

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted,contratacion } = this.state;

        if(this.state.mailValidado && this.state.codigoMail!==''){
            this.props.enviarExito("La cuenta ha sido activada exitosamente. Ahora puede ingresar.");          
        }
        if(this.state.linkVencido){
            let error={
                message:' El link utilizado se encuentra vencido.',
                codigo:errorConstants.LINK_VENCIDO
            }
            this.props.enviarError(error);
        }
        return (
            <div>
            <Container>
                <Row  className="justify-content-center mt-5 mb-5">
                    <Col lg={7} sm={12} md={9} xs={12}>
                    <Card className="shadow">
                    <Card.Header className="p-2 text-center d-none d-lg-block d-md-block d-xl-block">
                    <img 
                        src={appName+'/images/lock.png'}
                        alt="LOGIN"
                    />
                    </Card.Header>
                    <Card.Body>
                    <Tabs  className="nav nav-justified nav-tabs mb-4"  defaultActiveKey={contratacion?'registracion':'login'} id="uncontrolled-tab-example">
                      
                                        <Tab eventKey="login" title={<div><i className=""></i> Login</div>}>                                                
                                                <form name="form" >
                                                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                                        <label htmlFor="username">Username</label>  
                                                        {submitted && !username &&
                                                            <div className="help-block text-danger"><i className="fas fa-exclamation-circle"></i> Usuario Inválido</div>
                                                        }
                                                        <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="icon-admin"></i></div>
                                                        </div>
                                                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                                                       
                                                        </div>
                                                    </div>
                                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                        <label htmlFor="password">Password</label>
                                                        {submitted && !password &&
                                                            <div className="help-block text-danger"><i className="fas fa-exclamation-circle"></i> Contraseña Inválida</div>
                                                        }
                                                        <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                        <div className="input-group-text"><i className="icon-lock"></i> </div>
                                                        </div>
                                                        <input type={this.state.mostrar?"text":"password"} autoComplete="current-password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                                        <button type="button" className="btns btns-link ojo" onClick={()=>this.setState({mostrar:!this.state.mostrar})}><span className="material-icons material-icons-outlined txt-27">{this.state.mostrar?"remove_red_eye":"visibility_off"}</span></button>
                                                        </div>
                                                    </div>
                                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                    
                                                    <GoogleReCaptchaProvider reCaptchaKey={gkey} onFailure={error => this.errorGtoken(error)}>
                                                             <GoogleReCaptcha 
                                                             onVerify={token => this.cargarGtoken(token)}
                                                             onFailure={error => this.errorGtoken(error)} />
                                                     </GoogleReCaptchaProvider>
                                                    </div>

                                                    <Row className="justify-content-center mb-2 mt-5">
                                                    <button className="btns btns-iot" type="submit" onClick={(e)=>this.handleSubmit(e)}>Ingresar <span className="material-icons">keyboard_arrow_right</span>
                                                        {loggingIn &&
                                                           <div className="sweet-loading">
                                                           <BeatLoader  
                                                             size={10}
                                                             color={"#FFFFFF"}
                                                           />
                                                       </div>
                                                        }
                                                        </button>
                                                    </Row>
                                                    <div className="row justify-content-center mt-3 border-bottom">
                                                         <Button disabled= {this.state.grabando} className="cursor text-verde-azulado" variant="link"  onClick={this.recuperarPassword}><i className="fas fa-arrow-alt-circle-right"></i> Olvidé mi contraseña</Button>
                                                         <LoadingBoton grabando={this.state.grabando}/>
                                                    </div>
                                                    <Row className="justify-content-center mt-2">
                                                        <GoogleLogin
                                                            clientId="541188124489-1njg5avosov7ojmfck7k1papjhhjkrdn.apps.googleusercontent.com"
                                                            buttonText="Ingresar con Gmail"
                                                            onSuccess={this.responseGoogleSuc}
                                                            onFailure={this.responseGoogleFail}
                                                            cookiePolicy={'single_host_origin'}
                                                            isSignedIn={false}
                                                            offline ={true}
                                                        />
                                                    </Row>
                                                </form>
                                        </Tab>
                                        <Tab eventKey="registracion" title={<div><i className=""></i> Registrarme</div>}>
                                            <FormDatosUsuario accion='nuevo'  />
                                        </Tab>
                        </Tabs>
                    </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>  

            {this.state.showRecPassword && <FormCambiarPassword hash={this.state.hash} esRecuperacion={true} show={this.state.showRecPassword} handleClose={()=>this.handleClose()} />} 
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    loginGoogle:userActions.loginGoogle,
    logout: userActions.logout,
    guardarContratacion:userActions.guardarContratacion,
    enviarError: alertActions.error,
    enviarSuccess:alertActions.success
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };