import React from 'react';
import { connect } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import {ListGroup,Col,Card,Row} from 'react-bootstrap';
import {isMobile,  isBrowser} from "react-device-detect";
import { equipoActions,vistaActions } from '../_actions';
import { Link } from 'react-router-dom';
import { history} from '../_helpers';
 

class NavBarLateral extends React.Component {

    constructor(props){
       super(props);
       this.manejarEquipoSeleccionado = this.manejarEquipoSeleccionado.bind(this);
       this.manejarVistaSeleccionada = this.manejarVistaSeleccionada.bind(this);
       this.toogleClasses = this.toogleClasses.bind(this);       
       this.state.pathActual=history.location.pathname;
       this.wrapperRef = React.createRef();
       history.listen((location, action) => {
            this.setState({pathActual:location.pathname+location.search});
       });
    }

    state={
        active:false
    }

    toogleClasses(){
        this.setState(
            {active: !this.state.active});
        
    }

    handleClickOutside=(event)=> {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) 
            this.setState({active: false});        
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    miClase = (link)=>{
        if(link===this.state.pathActual) return 'iconmenu';
        else return '';
    }
    manejarEquipoSeleccionado(e,index){
        this.props.seleccionarEquipo(index);
        if(!isBrowser) this.toogleClasses()
    }

    manejarVistaSeleccionada(e,index){
        this.props.seleccionarVista(index);
        if(!isBrowser) this.toogleClasses()
    }    
     
    render(){    
        const { equipos,vistas,user } = this.props;        
        return (
          
            <nav ref={this.wrapperRef} className={this.state.active ? 'collapsed navbar-primary': 'navbar-primary hide-accordion'}>
                <div className="btn-expand-collapse item" onClick={() => this.toogleClasses()}>
                    <button className={this.state.active ? 'active flash hamburger hamburger-arrow-left': 'hamburger hamburger-arrow-left'}>
                        <span className="icon"></span>
                    </button>
                    {user && 
                        <div className="user text-left d-block d-lg-none d-xl-none">
                        <Row>
                            <Col xs={2} className="text-center mt-2"> <i className="fas fa-user-circle text-white"></i></Col>
                            <Col  xs={6} className="text-white text-truncate">
                                <span className="font-weight-bold txt-22">{user.username}</span>
                                <h6 className="font-weight-light"> ( AMBIENTE:{process.env.NODE_ENV})</h6>
                            </Col>
            
                        </Row>
                        </div>                                    
                    }
                </div>
             
                <ul className="navbar-primary-menu ">
                <Accordion defaultActiveKey="0">
                    <li className="text-white mt-4 th_recursos">
                    <i className="icon-sensor3 icon-gradient-equipos"></i>
                                    <div className="nav-label-sub">
                                    <Accordion.Toggle  as={Card.Header}  variant="link" eventKey="1" >
                                    <span className="texto font-weight-bold"> Equipos</span>
                                    <span className="arrow-acc"><i className="fas ml-3 fa-angle-down"></i></span>
                                    </Accordion.Toggle>
                                    </div>
                                    <Accordion.Collapse eventKey="1">
                                        <Card className="mt-2">
                                            <ListGroup variant="flush"  className={this.state.active ? '': 'desplazar'}>
                                                <ListGroup.Item  className="text-truncate">
                                                    <div>
                                                <Link to="/home/misEquipos" className={this.miClase('/home/misEquipos')} onClick={() => (!isBrowser && this.toogleClasses())}>
                                                <div className="menu-circle"><i className="material-icons material-icons-outlined">contactless</i></div>
                                                    <span> Mis Equipos</span>
                                                </Link>
                                                </div>
                                                </ListGroup.Item>
                                                    {equipos.misEquipos!==undefined && equipos.misEquipos.map((item, index)=> 
                                                        <ListGroup.Item  key={index} className="text-truncate">
                                                        <div>
                                                            <Link to={"/home/listaEquipo?id="+item.id} className={this.miClase("/home/listaEquipo?id="+item.id)} onClick={(event) => this.manejarEquipoSeleccionado(event, index)}>
                                                            <div className="menu-circle"><i className="material-icons material-icons-outlined">speaker_phone</i></div>
                                                                <span> {item.nombre}</span>
                                                            </Link>
                                                        </div>
                                                        </ListGroup.Item>
                                                   
                                                    )} 
                                            </ListGroup>
                                        </Card>
                                    </Accordion.Collapse>
                    </li>
                    <li className="text-white mt-4 th_uva">
                        <i className="icon-eyes icon-gradient-vistas"></i>
                                    <div className="nav-label-sub">
                                        <Accordion.Toggle  as={Card.Header}  variant="link" eventKey="2" >
                                            <span className="texto font-weight-bold">Vistas</span>
                                            <span className="arrow-acc"><i className="fas ml-3 fa-angle-down"></i></span>
                                        </Accordion.Toggle>
                                    </div>
                                    <Accordion.Collapse eventKey="2">
                                        <Card className="mt-2">
                                            <ListGroup variant="flush"  className={this.state.active ? '': 'desplazar'}>
                                                <ListGroup.Item  className="text-truncate">
                                                    <Link to="/home/misVistas" className={this.miClase('/home/misVistas')} onClick={() => (!isBrowser && this.toogleClasses())}>
                                                        <div className="menu-circle"><i className="material-icons material-icons-outlined">remove_red_eye</i></div>
                                                        <span> Mis Vistas</span>
                                                    </Link>  
                                                </ListGroup.Item>
                                                {vistas.misVistas!==undefined && vistas.misVistas.map((item, index)=>  
                                                    <ListGroup.Item  key={index}  className="text-truncate">
                                                    <div>
                                                        <Link to={"/home/listaVista?id="+item.id} className={this.miClase('/home/listaVista?id='+item.id)} onClick={(event) => this.manejarVistaSeleccionada(event, index)}>
                                                            <div className="menu-circle"><i className="material-icons material-icons-outlined">view_compact</i></div>
                                                            <span> {item.nombre}</span>
                                                        </Link>
                                                    </div>
                                                    </ListGroup.Item>                                                   
                                                )} 
                                            </ListGroup>
                                        </Card>
                                    </Accordion.Collapse>
                    </li>

                    <li className={this.state.active ? 'text-white th_cielo': ' text-white th_cielo desplazar'}>
                         <Link to="/home/misProgramas" className={this.miClase('/home/misProgramas')} onClick={() => (!isBrowser && this.toogleClasses())}>
                             <div className="menu-circle-item"><i className="icon-th-list icon-gradient-programa txt-21"></i></div> 
                             <div className="nav-label font-weight-bold"> Mis Programas</div>
                        </Link>
                    </li>

                    <li className={this.state.active ? 'text-white th_verde-azulado': ' text-white th_verde-azulado desplazar'}>
                        <Link to="/home/misAcciones" className={this.miClase('/home/misAcciones')} onClick={() => (!isBrowser && this.toogleClasses())}>
                        <div className="menu-circle-item"><i className="icon-action icon-gradient-action"></i></div> 
                             <div className="nav-label font-weight-bold"> Mis Acciones</div>
                        </Link>
                    </li>
                    <li className={this.state.active ? 'text-white th_arandano': ' text-white th_arandano desplazar'}>
                        <Link to="/home/misEventos" className={this.miClase('/home/misEventos')} onClick={() => (!isBrowser && this.toogleClasses())}>
                        <div className="menu-circle-item"><i className="icon-modem icon-gradient-evento"></i></div> 
                             <div className="nav-label font-weight-bold"> Mis Eventos</div>
                        </Link>
                    </li>
                    </Accordion>
                </ul>
          </nav>
        );
    }
}

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    const { equipos,vistas } = state;
    return { equipos,vistas,user };
}


const actionCreators = {
    seleccionarEquipo:equipoActions.seleccionarEquipo,
    seleccionarVista:vistaActions.seleccionarVista
}

const connectedNavBarLateral = connect(mapState, actionCreators)(NavBarLateral);
export { connectedNavBarLateral as NavBarLateral };