import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { programaService } from '../../_services';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {alertActions,programaActions, utilActions} from '../../_actions';
import { LoadingBoton,ErrorMessage } from '../../_helpers';


class FormPrograma extends React.Component {
  
  constructor(props){
    super(props);
    if(this.props.programa.id!==undefined)this.state.esNuevo=false;
  }

  state = {
      esNuevo:true,
      grabando:false
  };

  componentDidMount(){  
     
  }
  
render() {

      const {programa} = this.props;
      let inic={
        id:'',
        nombre: ''
      }
      
      const validationSchema = Yup.object().shape({
        nombre: Yup.string()
          .min(2, 'Muy corto')
          .max(50, 'Muy largo el nombre')
          .required('Requerido')
      });

      if(programa!==undefined && programa!=={})inic=utilActions.setearValores(inic,programa);

return(

  <div>
    <Formik
      enableReinitialize= {true}
      initialValues={inic}
      validationSchema={validationSchema}
      onSubmit={values => {
       
          this.setState({grabando:true});
          programaService.grabarPrograma(values).then(
            data => {  
                this.props.agregarProgramaGenerado(data,values.id==='');
                this.props.handleClose();
                this.setState({grabando:false});
            },
            error=>{
              this.props.enviarError(error);
              this.setState({grabando:false});
            }
        );

      }}
    >
      {({ errors, touched,values }) => (
        
        <Form>
          <Row>
          <Col xs={12}>
          <label htmlFor="nombre">Nombre</label><ErrorMessage name={`nombre`} />
              <Field name="nombre" />
              
              </Col>
          </Row>

              <div className="justify-content-center mt-5 p-3 row border-top bg-light">
                  <button className="btn btn-link text-secundario cursor mr-3" type="button" onClick={() => this.props.handleClose()}>
                  <i className="icon-times"></i>  Cerrar
                  </button>
                  <button className="btns btns-secundario" type="submit" disabled={this.state.grabando}>
                  <i className="icon-floppy"></i> Grabar <LoadingBoton grabando={this.state.grabando}/>
                  </button>
              </div>
             
        </Form>
      )}
    </Formik>
  </div>
)};
}
//export { FormPrograma as FormPrograma };

function mapState(state) {
  return {  };
}

const actionCreators = {
  agregarProgramaGenerado:programaActions.agregarProgramaGenerado,
  enviarError:alertActions.enviarError
}

const connectedFormPrograma = connect(mapState, actionCreators)(FormPrograma);
export { connectedFormPrograma as FormPrograma };