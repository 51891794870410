import http from '../_helpers/http.service';

export const grupoService = {
    getListadosPU,
    asignarRol,
    eliminarRol,
    reasignarRol,
    eliminarUsuario,
    agregarUsuario
};

function agregarUsuario(values) {   
    var url = '/api/grupo/agregarUsuario';
    return http.post(url,JSON.stringify(values));
}

function getListadosPU() {   
    var url = '/api/autorizaciones/listado';        
    return http.get(url);
}

function eliminarUsuario(values) {   
    var url = '/api/grupo/eliminarUsuario';
    return http.post(url,JSON.stringify(values));
}

function reasignarRol(item,rol,rel) {   
    var url = '/api/grupo/reasignarRol';
    return http.post(url,JSON.stringify({idUsuario:item.id,idEquipo:rel.equipo.id,idRol:rol}));
}

function asignarRol(item,rol) {
    var url = '/api/grupo/asignarRol';
    return http.post(url,JSON.stringify({id:item.id,idRol:rol}));
}

function eliminarRol(idEquipo,idRol,idUsuario) {   
    var url = '/api/grupo/removerRol';
    return http.post(url,JSON.stringify({idEquipo:idEquipo,idRol:idRol,idUsuario:idUsuario}));
}
