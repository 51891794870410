import React from 'react';
import { connect } from 'react-redux';
import Sensor from '../../_components/Sensor/Sensor';
import {Col, Row,Tab,Tabs} from 'react-bootstrap';
import {EditarEquipo} from './EditarEquipo';
import {EditarVistaDesdeSensor} from '../Vista';
import {TablaSensor} from '../Sensor/';
import {WhatsappShareButton} from "react-share";
import { equipoConstants } from '../../_constants';
import { utilActions } from '../../_actions';

class VistaHuerta extends React.Component {

    state={
        active:false
    }

    
    render(){
         const {seleccionado,equipos,tipoVista}=this.props;
        const sensoresLista = utilActions.llenarLista(seleccionado.sensores,equipos.sensores)
        return (
            <div className="cont-sensor">
            <Col sm={12}>

                <Row className="bordes">
                    <Col className="mb-4 col-auto">
                        <h3 className=" uppercase mt-5"><i className="icon-sensor"></i> <span className="mt-2 font-weight-light">{seleccionado.nombre} CONECTADO:{seleccionado.conectado?' SI, estoy conectado':' NO TURRO!'}</span></h3>
                        <WhatsappShareButton url={'/equipoNuevo?id='} />
                    </Col>

                     <Col className="mb-4 col-auto">
                        <div className="menu-floating">
                            <div className="fab-wrapper">
                                <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
                                    <label className="fabs" for="fabCheckbox">
                                        <span className="fab-dots fab-dots-1"></span>
                                        <span className="fab-dots fab-dots-3"></span>
                                    </label>
                                    <div className="fab-wheel">
                                    <ul>
                                        {tipoVista===1 &&
                                            <EditarEquipo equipo={seleccionado}/>}
                                        {tipoVista===5 &&
                                            <EditarVistaDesdeSensor vista={seleccionado} equipos={equipos}/>} 
                                        
                                        </ul>
                                    </div>
                                </div>
                         </div>
                    </Col>
                </Row>
            </Col>

            <div className="tabs-custom mt-1">
                <Tabs  className="nav nav-pills justify-content-end nav-tabs mb-4"  defaultActiveKey="card" id="uncontrolled-tab-example">
            
                <Tab eventKey="card" title={<div>Sensores</div>}>
                        <div className="slide-mobile">
                            <div className="d-flex-c flex-wrap  justify-content-center"> 
                                {sensoresLista!==undefined && sensoresLista.map((sensor, indexS)=>                                
                                    <Sensor key={indexS} sensor={sensor} modo={equipoConstants.TAB_INFO}/>   
                                )}
                            </div>
                        </div>

                    </Tab>
                    <Tab eventKey="list" title={<div><i className="fas fa-list-ul mt-1"></i> Listado</div>}>
                         <TablaSensor seleccionado={seleccionado} />
                    </Tab>
                    <Tab eventKey="dasboard" title={<div>Dashboard</div>}>
                        <div className="slide-mobile"> 
                            <div className="d-flex-c flex-wrap  justify-content-center"> 
                                {sensoresLista!==undefined && sensoresLista.map((sensor, indexS)=>                                
                                        <Sensor key={indexS} sensor={sensor} modo={equipoConstants.TAB_DASHBOARD}/>   
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
    </div> 

        );
    }
}

function mapState(state) {       
    const { equipos,vistas } = state;
    return { equipos,vistas };
}

const actionCreators = {
}

const connectedVistaHuerta = connect(mapState, actionCreators)(VistaHuerta);
export { connectedVistaHuerta as VistaHuerta };

