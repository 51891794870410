import React from 'react';
import {connect} from 'react-redux';
import {Container,Row,Card, Modal,Col,Badge} from 'react-bootstrap';
import {equipoActions } from '../../_actions';
import {sensorConstants } from '../../_constants';
import {Link} from 'react-router-dom';
import {FormEditarEquipo } from './FormEditarEquipo';
import {Role, tengoRol,esSinToken,history, alertConfirm} from '../../_helpers';
import MoonLoader from "react-spinners/MoonLoader";
import queryString from 'query-string';
import { EquipoAgregar } from './EquipoAgregar';
import ReactTooltip from 'react-tooltip';
import { FormCarpeta } from '../Home/FormCarpeta';
import { ModalMoverDeCarpeta } from '../Home/ModalMoverDeCarpeta';

class MisEquipos extends React.Component {

    constructor(props){
       super(props);
       this.manejarEquipoSeleccionado = this.manejarEquipoSeleccionado.bind(this);      
    }

    state={
       show:false,
       showCarpeta:false,
       carpeta:undefined,
       alert:undefined,
       equipo:undefined,
       showMoverCarpeta:false
    }

    componentDidMount(){
        if(this.props.carpetas===undefined)this.props.traerCarpetas();
        this.esEquipoNuevo();       
        this.hayContratacionDefault();
    }

    handleClose=()=>{
        this.setState({
            show: false,
            showCarpeta:false,
            carpeta:undefined,
            equipo:undefined,
            showMoverCarpeta:false});
    }

    nuevoEquipo=()=>{
        this.setState({show: true});
    }

    editarCarpeta=(carpeta)=>{
        this.setState({
            showCarpeta: true,
            carpeta:carpeta});
    }

    moverCarpeta=(equipo,carpetaRoot,carpetaActual)=>{
        this.setState({
            showMoverCarpeta: true,
            equipo:equipo,
            carpetaRoot:carpetaRoot,
            carpetaActual:carpetaActual});
    }

    editarEquipo=(equipo)=>{
        this.setState({
            show: true,
            equipo:equipo});
    }

    nuevaCarpeta=()=>{
        this.setState({showCarpeta: true});
    }

    eliminarCarpeta = (data) => {
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${data.nombre}? Los dispositivos en la misma pasan a la carpeta raiz`,
            'Sí, eliminar',
            'No',
            this.cerrarAlert,
            this.eliminarCarp,
            null,
            true
        )  
        this.setState({
                carpeta:data,
                alert: getAlert
            });
    }

    cerrarAlert = () => {
        this.setState({carpeta:{},alert: null});
    } 

    eliminarCarp = () => {      
        this.props.eliminarCarpe(this.state.carpeta, this.cerrarAlert,this.props.carpetas);
      
    };

    manejarEquipoSeleccionado(e,index){
        this.props.seleccionarEquipo(index);
    }    

    esEquipoNuevo (){

        var equipoNuevo = false;
        var idEquipoNuevo = '';

        if (this.props.location.pathname!==undefined && this.props.location.pathname==='/home/equipoNuevo'){
            equipoNuevo=true;
            let params = queryString.parse(this.props.location.search);
            idEquipoNuevo = params.id;
            this.setState({idEquipoNuevo: idEquipoNuevo,mostrarAsociarEquipo: true});          
        }

        if (this.props.authentication.equipoNuevo!==undefined && this.props.authentication.equipoNuevo.esEquipoNuevo){
            this.setState({
                    idEquipoNuevo:this.props.authentication.equipoNuevo.idEquipoNuevo,
                    mostrarAsociarEquipo: true
            });   
        }  
    };    

    hayContratacionDefault=()=>{
        if(this.props.idSuscripcionDefault){
            history.push('/tienda/suscripcion');
        }
    }

    handleCloseAsociarEquipo = () => {
        this.setState({
                mostrarAsociarEquipo: false,
                idEquipoNuevo: ''});
        history.push('/home/misEquipos');
    };

    armarListaEquipos= (equiposActual,equiposGeneral) => {
    
        let equiposMostrar = [];
        equiposActual.forEach(eq => {
            const eqfinal = equiposGeneral.find(val=>val.id===eq.id)  
            if(eqfinal)equiposMostrar.push(eqfinal)
        });
        
        return equiposMostrar;
    };

    render(){
        const { equipos,carpetas } = this.props;
        const { userToken,user } = this.props;
        const soyDeveloper = tengoRol([Role.DEV]);
        const sinToken = esSinToken(userToken);

        const params = queryString.parse(this.props.location.search);
        let {carp}=params;

        if(carpetas!==undefined){
            if(carp===undefined){
                carp=carpetas;
                carp.esRoot=true;
            }
            else {
                if(carpetas.carpetasHijas){
                    carp = carpetas.carpetasHijas.find(valu=>valu.id===carp)
                    carp.esRoot=false;
                }
            }
        }
        return (
            <div className="main-content mt-5">

                <Container fluid>
                    <Row className="bordes justify-content-center">
                        <Col className="mb-4 col-auto">
                        <h1 className="mt-5 txt-30 font-weight-light text-white text-truncate"><i className="material-icons txt-30 align-baseline">emoji_people</i> Bienvenido a tu Dashboard <span className="font-weight-bold ml-2"> {user?user.username:''}!</span></h1>                          
                        </Col>
                    </Row>
                </Container>                
                <Container fluid className="mt-4">
                {(!sinToken)&&
                    <Row className="mt-5">
                       {soyDeveloper &&<Col lg={3}>
                           
                                <button data-tip data-for='nuevoDispo' disabled={(userToken && equipos.misEquipos && userToken.cantidadDispositivos<=equipos.misEquipos.length)} className="btns btns-recursos btn-block text-dark mt-2 mb-2"  onClick={()=>this.nuevoEquipo()}>
                                        <i className="icon-modem align-middle txt-22"></i> Nuevo dispositivo
                                </button>
                               
                                {(userToken && equipos.misEquipos && userToken.cantidadDispositivos<=equipos.misEquipos.length) &&
                                    <ReactTooltip place="bottom" id='nuevoDispo' aria-haspopup='false' >
                                    <span>Alcanzaste el límite de equipos para tu suscripción.</span>
                                    </ReactTooltip>}
                                
                              
                        </Col>}
                        {carp && carp.esRoot &&<Col lg={3}>
                           
                            <button className="btns-verde-azulado btns  btn-block text-white mt-2 mb-2" id="nuevaCarpeta"  onClick={()=>this.nuevaCarpeta()}>
                            <span className="material-icons align-middle txt-29">create_new_folder</span> Nueva carpeta
                            </button>                               
                            <ReactTooltip place="bottom" id='nuevaCarpeta' aria-haspopup='false' >
                                <span>Crear nueva carpeta </span>
                            </ReactTooltip>
                        
                        </Col>}
                    </Row>
                }
                    {(carp && !carp.esRoot) &&
                    <div className="fixed-bottombtn row">
                    <Row>
                        <Col lg={12}>
                           <Link to={"/home/misEquipos"} className="btns btns-iot cursor ml-3">                    
                               <i className="fas fa-long-arrow-alt-left align-middle txt-22"></i> Volver
                           </Link>
                       </Col>
                   </Row>
                   </div>}
                   {!sinToken && <div className="cont-sensor mt-5-1">
                        {equipos.misEquipos!==undefined?<div className="d-flex flex-wrap  justify-content-center">

                            {(carp && carp.carpetasHijas) && carp.carpetasHijas.map((carp, index)=>  
                                <Col key={index} lg={3} xl={3} xs={6} md={6} className="mb-4">
                                            <Link to={"/home/misEquipos?carp="+carp.id} className="cursor">
                                                <div className="folder th_equipos mt-3">
                                                <span className="material-icons align-middle txt-29">topic</span>
                                       
                                                 </div>  
                                                 <div className="badge-container">
                                                 <h4 className="text-center mt-3"> 
                                                       <Badge className="bg-verde-azulado badge-pill text-white text-truncate"> 
                                                        {carp.nombre}
                                                        </Badge>
                                                    </h4>
                                                 </div> 
                                            </Link> 
                                            <div className="custom-edit">
                                            <div className="edit">                                                                                       
                                                <a data-tip data-for='elimina'><i className="fas fa-trash-alt mr-2 cursor"  onClick={() => this.eliminarCarpeta(carp)}></i></a>
                                                    <ReactTooltip place="bottom" id='elimina' aria-haspopup='false' >
                                                        <span>Elimina carpeta</span>
                                                    </ReactTooltip>
                                                <a data-tip data-for='edita'><i className="fas fa-pencil-alt mr-2 cursor"  onClick={() => this.editarCarpeta(carp)}></i></a>
                                                <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                    <span>Editar carpeta</span>
                                                </ReactTooltip>
                                            </div>
                                            </div>                                    
                                </Col>
                            )}

                            {carp && carp.equipos!==undefined && equipos.misEquipos!==undefined  && this.armarListaEquipos(carp.equipos,equipos.misEquipos).map((equipo, index)=>  
                                <Col key={index} lg={4} xl={4} sm={12} md={6} className="mb-4">
                                        <Link to={"/home/listaEquipo?id="+equipo.id} className="cursor"  onClick={(event) => this.manejarEquipoSeleccionado(event, index)}>
                                            <div className="text-white th_equipos hoverable card-components shadow">
                                                <Card.Header><h3 className="txt-oswald font-weight-light text-recursos"> <i className="material-icons mr-1 align-middle">contactless</i> {equipo.nombre}</h3></Card.Header>
                                                    <Card.Body>
                                                    <Card.Text className="text-muted font-weight-bold">
                                                    Mis Sensores
                                                    </Card.Text>
                                                    <Card.Text className="text-center">
                                                        {equipo.sensores!==undefined && equipo.sensores.slice(0,10).map((sensor, index2)=>
                                                        <span className="i-circle" key={index2}>
                                                            <i className={sensorConstants.style[sensor.tipoSensor.id].icono}></i>                                                       
                                                        </span>
                                                        )}
                                                    </Card.Text>
                                                </Card.Body>
                                            </div>
                                        </Link> 
                                        <div className="edit">  
                                             <a data-tip data-for='moverCarpeta'><span className="material-icons align-middle txt-29" onClick={() => this.moverCarpeta(equipo,carpetas,carp)}>drive_file_move</span></a>
                                            <ReactTooltip place="bottom" id='moverCarpeta' aria-haspopup='false' >
                                                <span>Mover de carpeta</span>
                                            </ReactTooltip>
                                            <a data-tip data-for='edita'><i className="fas fa-pencil-alt mr-2 cursor"  onClick={() => this.editarEquipo(equipo)}></i></a>
                                            <ReactTooltip place="bottom" id='edita' aria-haspopup='false' >
                                                <span>Editar equipo</span>
                                            </ReactTooltip>
                                        </div>
                                </Col>
                            )}
                        </div>:
                        <Row className="justify-content-center mt-5"><MoonLoader size={100} color={"#B1B1B1"} /></Row>}

                    </div>}

                    <Row className="justify-content-center th_verde-azulado">
                    <div className="cont-sensor mt-4">
                    <div className="d-flex flex-wrap  justify-content-center">
                    
                    {(sinToken && !this.state.soyDeveloper && (equipos.misEquipos===undefined || equipos.misEquipos.length===0)) &&
                        <>
                        <Col xs={12} md={7} lg={5}>
                        <div className="card-components border-left-c  fadeIn mt-2">
                        <Row className="p-3">
                            <Col xs={2} className="text-center p-2">
                            <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                            </Col>
                            <Col xs={10} className="text-right p-2">
                                    <span className="text-muted txt-12"> MIS EQUIPOS</span> <br></br> 
                                    <span className="text-white txt-18 font-weight-bold"> NO EXISTEN EQUIPOS ASOCIADOS</span>
                                    <br></br> <span className="text-muted"> Pasá por la tienda y compra ya!</span>
                                </Col>
                        </Row>
                        </div>
                        </Col>
                    </>   
                    }

                {sinToken &&
                <>
                    <Col xs={12} md={7} lg={5}>
                    <div className="card-components border-left-c  fadeIn mt-2">
                    <Row className="p-3">
                        <Col xs={2} className="text-center p-2">
                            <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                        </Col>
                        <Col xs={10} className="text-right p-2">
                            <span className="text-muted txt-12"> MIS EQUIPOS</span> <br></br> 
                            <span className="text-white txt-18 font-weight-bold"> No posees ninguna suscripción activa</span>
                            <br></br> <span className="text-muted">  Pasá por la tienda y contrata una nueva!</span>
                        </Col>
                    </Row>
                    </div>
                    </Col>
                </>  
                }

                {(sinToken && this.state.soyDeveloper && (equipos.misEquipos===undefined || equipos.misEquipos.length===0)) &&
                                        <>
                                        <Col xs={12} md={7} lg={5}>
                                        <div className="card-components border-left-c  fadeIn mt-2">
                                        <Row className="p-3">
                                            <Col xs={2} className="text-center p-2">
                                            <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                            </Col>
                                            <Col xs={10} className="text-right p-2">
                                                            <span className="text-muted txt-12"> MIS EQUIPOS</span> <br></br> 
                                                            <span className="text-white txt-18 font-weight-bold">  NO EXISTEN EQUIPOS ASOCIADOS</span>
                                                            <br></br> <span className="text-muted">Podes crearlo ahora mismo o bien comprar una placa con nuestra app incluída.</span>
                                                        </Col>
                                        </Row>
                                        </div>
                                        </Col>
                                    </>  
                    }

                    {(!sinToken && (equipos.misEquipos!==undefined && equipos.misEquipos.length===0)) &&
                        
                        <>
                                        <Col xs={12} md={7} lg={5}>
                                        <div className="card-components border-left-c  fadeIn mt-2">
                                        <Row className="p-3">
                                            <Col xs={2} className="text-center p-2">
                                            <i className="icon-bell txt-42 icon-gradient-equipos "></i>
                                            </Col>
                                            <Col xs={10} className="text-right p-2">
                                                            <span className="text-muted txt-12"> MIS EQUIPOS</span> <br></br> 
                                                            <span className="text-white txt-18 font-weight-bold">  NO EXISTEN EQUIPOS ASOCIADOS</span>
                                                            <br></br> <span className="text-muted">No tenes una licencia activa, pasá por la tienda y contrata ya!</span>
                                                        </Col>
                                        </Row>
                                        </div>
                                        </Col>
                                    </>
                    }
                     </div>
                    </div>
                    </Row>
                </Container>

                {this.state.alert}

                {this.state.showMoverCarpeta &&
                <ModalMoverDeCarpeta show={this.state.showMoverCarpeta} equipo={this.state.equipo} carpetaRoot={this.state.carpetaRoot} carpetaActual={this.state.carpetaActual} handleClose={this.handleClose}/>} 

                {this.state.show && <Modal show={this.state.show} onHide={() =>this.handleClose()}>
                    <Modal.Header className="bg-recursos text-dark" closeButton>
                        <Modal.Title><i className="material-icons mr-1 align-middle">contactless</i> Equipo</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body><FormEditarEquipo equipo={this.state.equipo} carpeta={carp} carpetaRoot={carpetas} handleClose={this.handleClose}/></Modal.Body>                     
                </Modal>} 

                {this.state.showCarpeta && <Modal show={this.state.showCarpeta} onHide={() =>this.handleClose()}>
                    <Modal.Header className="bg-verde-azulado text-white" closeButton>
                        <Modal.Title><span className="material-icons align-middle txt-29">create_new_folder</span> Nueva Carpeta</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body><FormCarpeta carpeta={this.state.carpeta} carpetaRoot={carpetas} handleClose={this.handleClose}/></Modal.Body>                     
                </Modal>} 

                {this.state.mostrarAsociarEquipo && <Modal show={this.state.mostrarAsociarEquipo} onHide={()=>this.handleCloseAsociarEquipo()}>
                    <Modal.Header  className="bg-recursos text-dark" closeButton>
                        <Modal.Title>Asociar equipo ID {this.state.idEquipoNuevo} a la cuenta </Modal.Title>
                    </Modal.Header> 
                    <Modal.Body><EquipoAgregar idEquipoNuevo={this.state.idEquipoNuevo} handleClose={this.handleCloseAsociarEquipo}/></Modal.Body>                     
                </Modal>}         
            </div>
        );
    }
}

function mapState(state) {
    const { equipos,authentication,users } = state;
    const {idSuscripcionDefault}=users;
    const { userToken,user } = authentication;  
    const { carpetas } = equipos;  
    return { userToken,equipos,user,authentication,carpetas,idSuscripcionDefault };
}


const actionCreators = {
    seleccionarEquipo:equipoActions.seleccionarEquipo,
    traerCarpetas: equipoActions.traerCarpetas,
    eliminarCarpe: equipoActions.eliminarCarpeta
}

const connectedMisEquipos = connect(mapState, actionCreators)(MisEquipos);
export { connectedMisEquipos as MisEquipos };