import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormNuevaVista } from './FormNuevaVista';
import { connect } from 'react-redux';
import { vistaActions,alertActions } from '../../_actions';
import { alertConfirm } from '../../_helpers';
import ReactTooltip from 'react-tooltip';

 class EditarVistaDesdeSensor extends React.Component {    
    state = {
        show: false
    }
    handleClose = () => {
        this.setState({show: false});
    };
    handleShow = () => {
        this.setState({show: true});
    };

    handleEliminarVista = () => {
        
        const getAlert = alertConfirm('Confirmar',
            `¿Está seguro que desea eliminar ${this.props.vista.nombre}?`,
            'Sí, eliminar',
            'No',
            this.props.clearAlertConfirm,
            this.eliminarVista,
            null,
            true
        )  
        this.props.enviarAlertConfirm(getAlert)
    }

    eliminarVista = () => {     
        this.props.eliminarVista(this.props.vista,this.props.clearAlertConfirm);
    };
    

    render(){
        return (
             <div>
                
                 <li onClick={()=>this.handleEliminarVista()}>
                    <a data-tip data-for='eliminar' className="fab-action fab-action-btn fab-btn-2">
                        <i className="fas fa-trash-alt mr-2" ></i>
                    </a>
                    <ReactTooltip place="bottom" id='eliminar' aria-haspopup='false' >
                    <span>Eliminar</span>
                    </ReactTooltip>
                 </li>
                 <li  onClick={()=>this.handleShow()}>
                    <a data-tip data-for='editar' className="fab-action fab-action-btn fab-btn-3">
                        <i className="fas fa-pencil-alt mr-2"></i> 
                    </a>  
                    <ReactTooltip place="bottom" id='editar' aria-haspopup='false' >
                    <span>Editar</span>
                    </ReactTooltip>             
                 </li>
              
                {this.state.show && <Modal show={this.state.show} onHide={()=>this.handleClose()}>
                    <Modal.Header  className="bg-arandano text-white" closeButton>
                        <Modal.Title><i className="material-icons-outlined icon-image-preview  txt-27 align-sub">remove_red_eye</i> Vista</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body className="th_arandano">
                        <FormNuevaVista vista={this.props.vista} 
                        handleClose={this.handleClose}/>   
                        </Modal.Body>                     
                </Modal>} 
                
                                            
             </div>   

        );
    }
}


function mapState(state) {       
    return { };
}

const actionCreators = {
    eliminarVista:vistaActions.eliminarVista,
    enviarAlertConfirm:alertActions.enviarAlertConfirm,
    clearAlertConfirm:alertActions.clearAlertConfirm
}

const connectedEditarVistaDesdeSensor = connect(mapState, actionCreators)(EditarVistaDesdeSensor);
export { connectedEditarVistaDesdeSensor as EditarVistaDesdeSensor };
