import { sensorConstants,equipoConstants } from '../_constants';
import { sensorService, equipoService } from '../_services';
import { alertActions } from '.';
import { vistaActions } from './vista.actions';
import { accionColectivaActions } from './accionColectiva.actions';
import { programaActions } from './programa.actions';
import { eventoActions } from './evento.actions';
import { equipoActions } from './equipo.actions';

export const sensorActions = {
    seleccionarSensor,
    actualizarSensorUsuario,
    agregarSensorDesarrollador,
    enviarASensor,
    eliminarSensorDesarrollador,
    eliminarRegistro,
    actualizarHistorialWidget
    
};


function actualizarHistorialWidget(setGrabando,values) {
    setGrabando(true);
    return dispatch => {
        sensorService.actualizarHistorialWidget(values)
        .then(
            data => { 
                setGrabando(false);  
                dispatch(success(data));
            },
            error =>dispatch(alertActions.error(error))        
            );
    }
    function success(data) { return { type: equipoConstants.DATA_HISTORIAL_WIDGET,data} }
}

function agregarSensorDesarrollador(data, esNuevo) {
    
    return dispatch => {       
         if(esNuevo)
            dispatch(success(data)); 
         else  dispatch(successAct(data));    
    };

    function success(data) { return { type: sensorConstants.NUEVO_SENSOR_DEVELOPER,data} }
    function successAct(data) { return { type: sensorConstants.ACTULIZAR_SENSOR_DEVELOPER,data} }
}

function eliminarRegistro(values,borrarRegistroHistorial) {
    
    return dispatch => {
        sensorService.eliminarRegistro(values)
        .then(
            data => { 
                    borrarRegistroHistorial(values.idRegistro);
                    if(data.ultimoValor)dispatch(nuevoValorSensor(values.id,data.ultimoValor[0])); 
            },
            error =>dispatch(alertActions.error(error))        
            );
    }
    function nuevoValorSensor(idSensor,valor) { return { type: equipoConstants.NUEVO_VALOR,idSensor,valor} }
}

function eliminarSensorDesarrollador(sensor) {
    
    return dispatch => {
        sensorService.eliminarSensorDesarrollador(sensor)
        .then(
            user => { 
                    dispatch(success(sensor));  
                    dispatch(equipoActions.getEquiposPU());
                    dispatch(vistaActions.getVistasPU());
                    dispatch(accionColectivaActions.getAccionesColectivasPU());
                    dispatch(eventoActions.getEventosPU());
                    dispatch(programaActions.getProgramasPU());          
            },
            error => {                
               
                dispatch(alertActions.error(error));
            
            }
        );
    }

    function success(sensor) { return { type: sensorConstants.ELIMINAR_SENSOR_DEVELOPER,sensor} }
}

function actualizarSensorUsuario(sensor) {
    
    return dispatch => {       
         dispatch(success(sensor)); 
    };

    function success(sensor) { return { type: sensorConstants.ACTULIZAR_SENSOR_USER,sensor} }
}

function enviarASensor(sensor,texto,longitud,estoySwitcheando) {
    return dispatch => {

        if(estoySwitcheando){
            if(sensor.ultimoValor==sensor.valorEncendido)texto=sensor.valorApagado;
                else texto=sensor.valorEncendido;
        }

        sensorService.enviarASensor(sensor,texto,longitud)
        .then(
            user => { 
                //dispatch(success(sensor,texto));            
            },
            error => {                
               
                dispatch(alertActions.error(error));
            
            }
        );
    }

}

function seleccionarSensor(sensor) {
    return dispatch => {       
         dispatch(success(sensor)); 
    };

    function success(sensor) { return { type: sensorConstants.SELECCIONAR_SENSOR,sensor} }
}