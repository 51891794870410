import { vistaConstants } from '../_constants';
import { vistaService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const vistaActions = {
    seleccionarVista,
    getVistasPU,
    agregarVistaGenerada,
    eliminarVista,
    eliminarSensorDeVista
};

function getVistasPU() {
    return dispatch => {
        vistaService.getVistasPU()
            .then(
                data => {                     
                    data.misVistas.sort(function(a, b){
                        if(a.nombre < b.nombre) { return -1; }
                        if(a.nombre > b.nombre) { return 1; }
                        return 0;
                    })
                    dispatch(success(data.misVistas));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(misVistas) { return { type: vistaConstants.VISTAS_GET_PU_SUCCESS, misVistas } }
}

function agregarVistaGenerada(data,esNueva) {
    return dispatch => {
        if(esNueva)      
            dispatch(success(data));   
        else dispatch(successAct(data));     
    };

    function successAct(data) { return { type: vistaConstants.ACTUALIZAR_VISTA,data} }
    function success(data) { return { type: vistaConstants.AGREGAR_VISTA_GENERADA,data} }
}

function seleccionarVista(seleccionado) {
    return dispatch => {       
         dispatch(success(seleccionado)); 
    };

    function success(seleccionado) { return { type: vistaConstants.VISTA_SELECCIONADA,seleccionado} }
}

function eliminarVista(vistaLlegada,exito) {
    return dispatch => {
        vistaService.eliminarVista(vistaLlegada)
            .then(
                vista => { 
                    dispatch(success(vistaLlegada));
                    history.push('/home/misVistas');
                    exito();                
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(vistaLlegada) { return { type: vistaConstants.VISTA_ELIMINADA, vistaLlegada } }
}

function eliminarSensorDeVista(vistaLlegada,sensor) {
    return dispatch => {
        vistaService.eliminarSensorDeVista(vistaLlegada,sensor)
            .then(
                vista => { 
                    dispatch(success(vistaLlegada,sensor));             
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(vistaLlegada,sensor) { return { type: vistaConstants.VISTA_SENSOR_ELIMINADO, vistaLlegada,sensor } }
}
