import React from 'react';
import { connect } from 'react-redux';
import {Modal} from 'react-bootstrap';
import { FormEditarEquipo } from './FormEditarEquipo';
import { alertActions, equipoActions } from '../../_actions';
import { FormEditarSensor } from '../Sensor/';
import { alertConfirm, Role, tengoRol,RoleID } from '../../_helpers';
import { ModalCompartirEquipo } from './ModalCompartirEquipo';
import ReactTooltip from 'react-tooltip';


class EditarEquipo extends React.Component {
    
    constructor(props){
        super(props);
       
        this.state.soyDeveloper = tengoRol([Role.DEV]);
        this.state.soyAdmin = tengoRol([Role.ADMIN]);
    }

    state = {
        show: false,
        showConfirm: false,
        showCompartir:false,
        soyDeveloper:false,
        soyAdmin:false,
        showToolTip:false,
        alertInside:null
    };

    handleClose = () => {
        this.setState(
            {showConfirm: false,show: false,showNuevoSensor: false,showCompartir: false}
        );
    };

    handleShow = (accion) => {
        let muestroModal={};

        if(accion==='borrar')muestroModal={showConfirm: true}; 
        if(accion==='editar')muestroModal={show: true};
        if(accion==='nuevoSensor')muestroModal={showNuevoSensor: true};
        if(accion==='compartir')muestroModal={showCompartir: true};

        this.setState(muestroModal);
    };

    verNuevoSensor = () => {

        const {userToken,equipos}= this.props;
        if(!userToken ||( equipos.sensores && equipos.sensores.length>userToken.cantidadSensores) ){
            const getAlert = alertConfirm('No permitido',
                `No tiene permitido por su licencia agregar más sensores!`,
                null,
                'Cerrar',
                this.props.clearAlertConfirm,
                null,
                null,
                true,
                'danger',
                false
            )  
            this.props.enviarAlertConfirm(getAlert);

        }else{
            this.setState({
                showNuevoSensor: true
            });
        }
       
    };

    eliminarEquipo = ()=>{
        const {idRol} = this.props.equipo;

        let mensaje=(idRol===RoleID.USER_ADMIN || idRol===RoleID.DEV)?'Al ser administrador de este equipo, el mismo se eliminara para todos los usuarios asociados':'';

        const getAlert = alertConfirm('Confirmar',
            `¿Esta seguro que desea desasociar a ${this.props.equipo.nombre} de su cuenta? ${mensaje}
            `,
            'Si, desasociar',
            'Cerrar',
            this.props.clearAlertConfirm,
            this.desasociar,
            null,
            true,
            'danger',
            true
        )  
        this.props.enviarAlertConfirm(getAlert);
    }

    setShow = (valor) => {
        this.setState({showToolTip: valor});
    };

    desasociar = () => {      

        this.props.desasociar(this.props.equipo);
    };

    render(){

        return (
             <div>
                 
                {(this.state.soyAdmin || this.state.soyDeveloper) &&
                <li onClick={() =>this.handleShow('editar')}>
                   <a data-tip data-for='editar' className="fab-action fab-action-btn fab-btn-1">
                        <i className="fas fa-pencil-alt mr-2" ></i>
                    </a>
                    <ReactTooltip place="bottom" id='editar' aria-haspopup='false' >
                    <span>Editar Equipo</span>
                    </ReactTooltip>
                </li>
                }  
                <li onClick={() => this.eliminarEquipo()}>
                    <a data-tip data-for='eliminar' className="fab-action fab-action-btn fab-btn-2">
                         <i className="fas fa-trash-alt mr-2"></i> 
                     </a>
                     <ReactTooltip place="bottom" id='eliminar' aria-haspopup='false' >
                    <span>Eliminar equipo</span>
                    </ReactTooltip>
  
                </li>
                <li onClick={() =>this.handleShow('compartir')}>
                    <a data-tip data-for='what' className="fab-action fab-action-btn fab-btn-3">  
                        <i className="fab fa-whatsapp" ></i>     
                    </a> 
                    <ReactTooltip place="bottom" id='what' aria-haspopup='false' >
                    <span>Compartir en Whatsapp</span>
                    </ReactTooltip>                
                </li>
                {this.state.soyDeveloper &&
                <li onClick={() =>this.verNuevoSensor()}>
                    <a data-tip data-for='nuevo' className="fab-action fab-action-btn fab-btn-4">
                        <i className="fas fa-plus mr-2"></i>
                    </a>
                    <ReactTooltip place="bottom" id='nuevo' aria-haspopup='false' >
                    <span>Nueva Variable</span>
                    </ReactTooltip> 
                </li>
                }  
                      
               {this.state.show && <Modal show={this.state.show} onHide={() =>this.handleClose()}>
                    <Modal.Header className="bg-recursos text-dark" closeButton>
                        <Modal.Title>Equipo</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body><FormEditarEquipo equipo={this.props.equipo} handleClose={() =>this.handleClose()}/></Modal.Body>                     
                </Modal>} 

               {this.state.showNuevoSensor && <FormEditarSensor show={this.state.showNuevoSensor} equipo={this.props.equipo} handleClose={() =>this.handleClose()}/>}
               {this.state.showCompartir && <ModalCompartirEquipo show={this.state.showCompartir} equipo={this.props.equipo} handleClose={() =>this.handleClose()}/>}
                                            
             </div>   

        );
    }
}

function mapState(state) {       
    const { userToken } = state.authentication;
    const { equipos } = state;
    return { userToken,equipos };
}

const actionCreators = {
    desasociar:equipoActions.desasociar,
    enviarAlertConfirm:alertActions.enviarAlertConfirm,
    clearAlertConfirm:alertActions.clearAlertConfirm
}

const connectedEditarEquipo = connect(mapState, actionCreators)(EditarEquipo);
export { connectedEditarEquipo as EditarEquipo };

